import styled from 'styled-components'
import { useState } from 'react'
import { ToggleSwitch } from '../shared/ToggleSwitch'
import { formCss } from '../../_helpers/shared-css'

const AdminAtsManagementWrapper = styled.div`
  display: flex;
  position: relative;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 5px;
  background: #fff;

  &:before {
    content: 'OR';
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    left: 30%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 25px;
    background:white;
    z-index:2;
    color: #707070;
  }

  &:after {
    position: absolute;
    content: '';
    left: 30%;
    top: 0;
    bottom: 0;
    background-color: #ccc;
    width: 2px;
    transform: translateX(-50%);
  }
  
  .left {
    width: 30%;
    padding: 30px;
    box-sizing: border-box;

    .description-text {
      color: #707070;
      font-size: 16px;
      display: inline-block;
      margin: 15px 0 0 70px;
    }
  }

  .right {
    width: 70%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 40px;

    .applicant-pool-text {
      width: 40%;
      color: #707070;
      font-size: 16px;
      display: inline-block;
      margin: 15px 30px 0 0;
    }

    .applicant-pool-options {
      width: 60%;
      display: flex;

      ${formCss}

      .option-wrapper {
        margin-right: 20px;
      }
    }
  }
`

export const AdminAtsManagement = () => {
  const [importAllApplicants, setImportAllApplicants] = useState(false)

  const toggleImportAllApplicants = (val) => {
    setImportAllApplicants(val)
  }

  return (
    <AdminAtsManagementWrapper>
      <div className='left'>
        <ToggleSwitch
          value={importAllApplicants || false}
          activeText='Import all applicants'
          inactiveText='Import all applicants'
          onToggleFunction={(value) => {
            toggleImportAllApplicants(!value)
          }}
        />
        <span className='description-text'>This will import all applicants regardless of flag/status within your ATS.</span>
      </div>
      <div className='right'>
        <div className='applicant-pool-text'>Select from the following choices to customize your Applicant pool for this job.</div>
        <div className='applicant-pool-options'>
          <div className='option-wrapper'>
            <div className='custom-checkbox'>
              <input
                type='radio'
                onChange={() => { console.log('change thing') }}
              />
              <label htmlFor='' />
            </div>
            <div className='custom-checkbox-label'>Selected</div>
          </div>
          <div className='option-wrapper'>
            <div className='custom-checkbox'>
              <input
                type='radio'
                onChange={() => { console.log('change thing') }}
              />
              <label htmlFor='' />
            </div>
            <div className='custom-checkbox-label'>Good Fit</div>
          </div>
          <div className='option-wrapper'>
            <div className='custom-checkbox'>
              <input
                type='radio'
                onChange={() => { console.log('change thing') }}
              />
              <label htmlFor='' />
            </div>
            <div className='custom-checkbox-label'>Unlabeled</div>
          </div>
        </div>
      </div>
    </AdminAtsManagementWrapper>
  )
}
