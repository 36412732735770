import styled from 'styled-components'
import { React, useState } from 'react'
import Modal from 'react-modal'
import { formCss } from '../../_helpers/shared-css'
import { useLocationActions } from '../../_actions'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { BarLoader } from 'react-spinners'

import Select from 'react-select'
import states from '../../_helpers/states'

const AdminAddLocationWrapper = styled.div`
  margin-left: auto;
  margin-top: 15px;

  .add-user-button {
    background-color: #81BCBF;
    font-family: 'Montserrat';
    color: #fff;
    cursor: pointer;
    border: none;
    padding: 12px 20px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
  }
`

const ModalContent = styled.div`
  padding: 0 30px;
  h3 {
    display: inline-block;
    color: #707070;
    margin: 5px 0 25px 0;
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    maxHeight: '700px',
    borderRadius: '15px',
    position: 'relative'
  }
}

// const DeleteUserButton = styled.button`
//   border: 1px solid #ccc;
//   color: #707070;
//   font-weight: bold;
//   text-transform: uppercase;
//   padding: 8px 15px;
//   font-size: 12px;
//   border-radius: 15px;
//   background: none;
//   display: inline-block;
//   float: right;
//   background: none;
//   color: #707070;
//   box-shadow: none;
// `

const FormWrapper = styled.div`
  margin-top: 25px;
  ${formCss}

  input[type="text"]{
    max-width: 500px;
  }

  button {
    padding: 15px 40px;
    border-radius: 30px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;

    &.save {
      border: 1px solid #81BCBF;
      background: #81BCBF;
      color: #fff;
      margin-right: 30px;
    }

    &.cancel {
      color: #707070;
      background: transparent;
      border: 1px solid #C8D1D3;
      box-shadow: none;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    .form-element {
      flex: 0 0 400px;
    }
  }

  .small {
    max-width: 400px !important;
    margin-right: 20px;
  }

  .email {
    display: inline-block;
  }

  .ext-wrapper {
    max-width: 100px;
    margin-right: auto;
    margin-left: 20px;
  }
`

export const AdminAddLocation = ({ companyId, saveCB }) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function afterOpenModal () {
    console.log('on after open')
  }

  function closeModal () {
    setIsOpen(false)
  }

  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const [state, setState] = useState(states[0])
  const [isLoading, setIsLoading] = useState(false)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    address: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    city: Yup.string().required('City is required'),
    zipcode: Yup.string().length(5).matches(/^[0-9]{5}/).required('Zip Code is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const saveLocation = async () => {
      setIsLoading(true)
      await locationActions.createLocation({
        ...data,
        state: state.value,
        company_id: companyId
      })
      setIsLoading(false)
      saveCB()
      closeModal()
    }
    saveLocation()
  }

  return (
    <AdminAddLocationWrapper>
      <button className='add-user-button' onClick={openModal}>Add Location</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=''
      >
        <ModalContent>
          <h3>Add Location</h3>
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-element'>
                <label>Title</label>
                <input
                  name='title'
                  type='text'
                  {...register('title')}
                  className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.title?.message}</div>
              </div>
              <div className='form-element'>
                <label>Location Address</label>
                <input
                  name='address'
                  type='text'
                  {...register('address')}
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.address?.message}</div>
              </div>
              <div className='form-element'>
                <label>Location Address 2 (Suite No.)</label>
                <input
                  name='address_2'
                  type='text'
                  {...register('address_2')}
                  className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.address_2?.message}</div>
              </div>
              <div className='form-element'>
                <label>City</label>
                <input
                  name='city'
                  type='text'
                  {...register('city')}
                  className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.city?.message}</div>
              </div>
              <div className='form-element'>
                <label>State</label>
                <div style={{ maxWidth: 500 }}>
                  <Select
                    name='state'
                    required
                    value={state}
                    options={states}
                    onChange={(newState) => setState(newState)}
                  />
                </div>
                <div className='invalid-feedback'>
                  {state === '' || state === undefined || state === null
                    ? 'State is required'
                    : ''}
                </div>
              </div>
              <div className='form-element'>
                <label>Zip Code</label>
                <input
                  name='zipcode'
                  type='text'
                  {...register('zipcode')}
                  className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.zipcode?.message}</div>
              </div>
              {isLoading
                ? (
                  <div style={{ paddingLeft: 20 }}>
                    <BarLoader color='#81BCBF' />
                  </div>
                  )
                : (
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='btn btn-primary'
                  >
                    {isSubmitting && (
                      <span className='spinner-border spinner-border-sm mr-1' />
                    )}
                    Save
                  </button>
                  )}
            </form>
          </FormWrapper>
        </ModalContent>
      </Modal>
    </AdminAddLocationWrapper>
  )
}
