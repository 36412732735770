import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import ModalWrapper from '../../admin/shared/ModalWrapper'
import { ConditionalOfferLetter } from '../../admin/documents/ConditionalOfferLetter'
import { useTasksActions } from '../../_actions'
import { useReloadJob } from '../../_helpers'

function ApplicantTaskButton (props) {
  const history = useHistory()
  const { reloadJob } = useReloadJob()
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  function handleClick () {
    taskActions.action(props.task.id, props.task_action)
      .then((v) => {
        // The difference between viewing a resource and going to a component is a history.push vs... window.open(row.link, '_blank')
        if (props.task.relative_form_path.substring(0, 4) === 'http') {
          window.open(props.task.relative_form_path, '_blank')
          reloadJob(props.job_id)
            .then((j) => {
              const path = '/jobs/' + props.job_id
              history.push(path)
            })
        } else {
          const path = props.task.relative_form_path + '/' + props.task.id
          history.push(path)
        }
      })
  }

  return (
    <button className={props.btn_class} type='button' onClick={handleClick}>
      {props.btn_text}
    </button>
  )
}

const ApplicantTasksButtons = ({ row, job }) => {
  const [documentModalOpen, setDocumentModalOpen] = useState(false)
  return (
    <div>
      {row.downloadLink && (
        <button
          className='download'
          onClick={() => {
            // console.log('clicked the download', row.downloadLink)
          }}
        >
          Download
        </button>
      )}
      {row.status === 'completed' && row.title === 'Conditional Offer Letter' && (
        <button
          className='view'
          onClick={() => {
            if (row.title === 'Conditional Offer Letter') {
              window.open(`${process.env.REACT_APP_API_URL}${row.artifact}`, '_blank')
            }
            // console.log('clicked the view', row.id)
          }}
        >
          View
        </button>
      )}
      {row.status === 'completed' && row.title !== 'Conditional Offer Letter' && (
        <ApplicantTaskButton
          task={row}
          btn_text='View'
          btn_class='view'
          task_action='view'
          job_id={job.id}
        />
      )}
      {row.status === 'Not Started' && row.title === 'Conditional Offer Letter' && (
        <button
          className='view'
          onClick={() => {
            if (row.title === 'Conditional Offer Letter') {
              setDocumentModalOpen(true)
            }
            // console.log('clicked the view', row.id)
          }}
        >
          View
        </button>
      )}
      {(row.status === 'started' ||
      row.status === 'Need Signature' ||
        row.status === 'awaiting_submission' ||
      row.status === 'submitted') && (
        /* <button className="complete" onClick={()=>{console.log('clicked the complete', row.id)}}>Complete</button> */
        <ApplicantTaskButton
          task={row}
          btn_text='Continue'
          btn_class='get-started'
          task_action='view'
          job_id={job.id}
        />
      )}
      {(row.can_start === true && row.status === 'delivered') && (
      // <button className="get-started" onClick={()=>{console.log('clicked the get started', row.id)}}>Get Started</button>
        <ApplicantTaskButton
          task={row}
          btn_text='Get Started'
          btn_class='get-started'
          task_action='start'
          job_id={job.id}
        />
      )}
      {row.title === 'Conditional Offer Letter' && <ModalWrapper
        modalOpen={documentModalOpen}
        closeModal={() => setDocumentModalOpen(false)}
        modalTitleText='Conditional Offer Letter'
        modalContent={<ConditionalOfferLetter job={job} document={row} />}
        modalMinHeight={450}
                                                   />}
    </div>
  )
}

export default ApplicantTasksButtons
