import { AdminJobDetails } from '../components/Admin/AdminJobDetails'
import AdminWrapper from './shared/AdminWrapper'
import { useParams } from 'react-router-dom'
import { useJobActions } from '../_actions'
import HeaderWrapper from './shared/HeaderWrapper'
import { history } from '../_helpers'

const AdminJobCreation = () => {
  const { locationId } = useParams()
  const jobActions = useJobActions(localStorage.getItem('access_token'))

  const saveJob = (data, templateName) => {
    const createTheJob = async () => {
      await jobActions.createJob(data, templateName)
      history.push(`/admin/locations/${data.location_id}/dashboard`)
    }
    createTheJob()
  }

  return (
    <AdminWrapper>
      <HeaderWrapper
        title='Create Job'
        link={
          locationId
            ? `/admin/locations/${locationId}/dashboard`
            : '/admin/company/dashboard'
        }
      />
      <AdminJobDetails saveJob={saveJob} saveText='Save' />
    </AdminWrapper>
  )
}

export default AdminJobCreation
