import pureListOfCountries from 'iso3166-2-db/i18n/en'
import { getDataSet } from 'iso3166-2-db/api'
import { Controller, useFormContext } from 'react-hook-form'
import { useArrayContext } from '../../../contexts/useArrayContext'
import { useStateContext } from '../../../contexts/useStateContext'
import { useEffect } from 'react'
const listOfCountries = getDataSet('en', pureListOfCountries)

const blank = {
  admin: 'blank',
  fips: '',
  iso: '',
  name: ''
}

const HookFormArrayRegionSelector = ({ countryName, label, fieldName }) => {
  const { index, arrayName } = useArrayContext()
  const { state, setState } = useStateContext()
  const { control, formState } = useFormContext()
  const { errors } = formState

  const regions = listOfCountries[countryName].regions.sort((a, b) =>
    a.iso.localeCompare(b.iso)
  )

  useEffect(() => {
    if (regions[0].admin !== 'blank') regions.unshift(blank)

    /* setValue(`${arrayName}.${index}.${fieldName}`, regions[0].iso)
        let newList = [...state[arrayName]].map((item, index) => {
            if (index === 0) return {...item, [fieldName]: regions[0].iso}
            else return item

        })
        setState({...state, [arrayName]: newList}) */
  }, [])

  const onChange = (e, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue=''
          render={({ field }) => (
            <select
              {...field}
              onChange={(e) => field.onChange(onChange(e, index))}
            >
              {regions.map((region) => (
                <option key={region.iso} value={region.iso}>
                  {region.name}
                </option>
              ))}
            </select>
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

const HookFormArrayCountrySelector = ({ label, fieldName }) => {
  const { index, arrayName } = useArrayContext()
  const { state, setState } = useStateContext()
  const { control, formState, setValue } = useFormContext()
  const { errors } = formState

  const onChange = (e, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    newList[id].state = ''
    setState({ ...state, [arrayName]: newList })
    setValue('state', '')
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue=''
          render={({ field }) => (
            <select
              {...field}
              onChange={(e) => field.onChange(onChange(e, index))}
            >
              {Object.keys(listOfCountries).map((isoCode) => (
                <option key={isoCode} value={isoCode}>
                  {listOfCountries[isoCode].name}
                </option>
              ))}
            </select>
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayCountrySelector, HookFormArrayRegionSelector }
