import { useEffect, useState } from 'react'
import AdminWrapper from './shared/AdminWrapper'
import { useParams } from 'react-router-dom'
import { useJobActions, useUserActions, useActionActions, useDBAlertsActions, useJobAdverseActionActions } from '../_actions'
import HeaderWrapper from './shared/HeaderWrapper'
import { ApplicantDetails } from '../components/ApplicantDetails'
import { AdminDashboardTabs } from '../components/Admin/AdminDashboardTabs'
import { JobDetails } from '../components/shared/JobDetails'
import { BarLoader } from 'react-spinners'
import { useRecoilState } from 'recoil'
import { userAtom, dbAlertsAtom } from '../_state'
import queryString from 'query-string'
import { TableWrapper, TableHeader, ProductListTitle } from '../components/ApplicantDetailAlertsAndNotifications/TableComponents'
import { ActionButton } from '../components/ApplicantDetailActions/ActionButton'
import ModalWrapper from './shared/ModalWrapper'
import JobsEdit from './jobs/edit'
import ApplicantEdit from './applicant/edit'

const AdminJobReview = () => {
  const { jobId, userId, locationId } = useParams()

  const [job, setJob] = useState({})
  const [jobLoading, setJobLoading] = useState(true)
  const [jobUser, setJobUser] = useState({})
  const [jobUserLoading, setJobUserLoading] = useState(true)
  const [applicantDetailsData, setApplicantDetailsData] = useState({})

  const userActions = useUserActions(localStorage.getItem('access_token'))
  const dbAlertActions = useDBAlertsActions(jobId)
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const actionActions = useActionActions(localStorage.getItem('access_token'))
  const jobAdverseActionActions = useJobAdverseActionActions(localStorage.getItem('access_token'))

  const urlParams = queryString.parse(window.location.search)

  const [user] = useRecoilState(userAtom)
  const [dbAlerts] = useRecoilState(dbAlertsAtom)

  const [editJobModalOpen, setEditJobModalOpen] = useState(false)
  const [editApplicantModalOpen, setEditApplicantModalOpen] = useState(false)

  useEffect(() => {
    const fetchdata = async () => {
      const userApi = await userActions.getUserInfo(userId)
      dbAlertActions.getAll()
      setJobUser(userApi.user)
      setJobUserLoading(false)

      const j = await jobActions.getById(jobId)
      setJob(j)
      setJobLoading(false)

      setApplicantDetailsData({
        dateApplied: j.date_applied,
        daysSinceConditionalOffer: j.days_since_conditional_offer,
        address: j.location?.display,
        email: userApi.user.email,
        phone: userApi.user.phone || '-'
      })
    }
    fetchdata()
  }, [])

  const saveJobAdverseAction = async (data) => {
    const mappedJAAData = {
      job_id: job?.id,
      applicant_notice_text: data.applicantNoticeText,
      business_implication: data.businessImplications,
      time_since_offense: data.timeSinceOffense,
      notes: data.notes
    }
    const extras = {
      adverse_action_flag_ids: data.selectedFlagIds,
      adverse_action_assess_ids: data.selectedAssessIds
    }
    if (job?.job_adverse_action?.id) {
      await jobAdverseActionActions.update(job?.job_adverse_action?.id, mappedJAAData, extras)
    } else {
      await jobAdverseActionActions.add(mappedJAAData, extras)
    }
  }

  const applicantName = jobUserLoading ? 'Applicant' : `${jobUser.first_name} ${jobUser.last_name}`
  const jobTitle = job?.title || '-'

  // this shit is extra gross AF, but i need the conditional offer to show here and also parsed erx objs
  // const conditionalOfferTask = job?.tasks
  //   ? job?.tasks?.filter((t) => t.title === 'Conditional Offer Letter')?.map((t) => {
  //     return {
  //       id: t.id,
  //       title: t.title,
  //       link: '#modal',
  //       enabled: t.status !== 'Completed',
  //       status: t.status
  //     }
  //   })
  //   : []
  const applicantTasks = job?.tasks
    ? job?.tasks?.map((t) => {
      if (t.title === 'Conditional Offer Letter') {
        return {
          id: t.id,
          title: t.title,
          link: '#modal',
          enabled: t.status !== 'Completed',
          status: t.status
        }
      } else {
        return {
          id: t.id,
          title: t.title,
          link: '#',
          enabled: false,
          status: t.status
        }
      }
    })
    : []

  const tmpErxReports = []
  const erxReports = job?.report && Object.keys(job?.report).length > 0
    ? Object.keys(job?.report).reduce((k, i) => {
      if (job?.report[i]?.length > 0) {
        const reportObj = job?.report[i]
        const result = i.replace(/([A-Z])/g, ' $1')
        const title = result.charAt(0).toUpperCase() + result.slice(1)
        tmpErxReports.push({
          id: i,
          title,
          link: '#modal',
          enabled: true,
          data: reportObj,
          date: job?.last_erx_callback?.created_at
        })
      }
      return tmpErxReports
    })
    : []
  const reportsArray = [...applicantTasks, ...erxReports]

  return (
    <AdminWrapper>
      <HeaderWrapper title={applicantName} backToText='Back To Applicants' link='/admin/applicants' />
      {jobUserLoading
        ? <div style={{ paddingLeft: 20 }}><BarLoader color='#81BCBF' /></div>
        : <>
          <ApplicantDetails
            details={applicantDetailsData}
            editable
            editClick={() => setEditApplicantModalOpen(true)}
          />
          <ModalWrapper
            modalOpen={editApplicantModalOpen}
            closeModal={() => setEditApplicantModalOpen(false)}
            modalTitleText='Edit Applicant'
            modalContent={<ApplicantEdit savedCallback={() => window.location.reload()} />}
            modalMinHeight={450}
          />
        </>}
      <div style={{ width: '100%', height: 20 }} />
      {jobLoading
        ? <div style={{ paddingLeft: 20 }}><BarLoader color='#81BCBF' /></div>
        : <>
          <JobDetails
            details={{
              jobTitle,
              openings: 'N/A',
              location: job?.location
                ? `${job?.location?.city}, ${job?.location?.state}`
                : '-',
              startDate: job?.start_date || '-',
              payAmount: job?.amount || '-',
              payType: job?.display_pay_structure || '-',
              department: job?.department?.title || '-',
              vacation: job?.vacation || '-',
              bonus: job?.bonus || '-',
              status: job?.display_exempt_status || '-',
              commission_notes: job?.commission_notes
            }}
            edit
            editClick={() => setEditJobModalOpen(true)}
          />
          <ModalWrapper
            modalOpen={editJobModalOpen}
            closeModal={() => setEditJobModalOpen(false)}
            modalTitleText='Edit Job'
            modalContent={<JobsEdit savedCallback={() => window.location.reload()} />}
            modalMinHeight={450}
          />
        </>}
      {jobLoading
        ? <div style={{ paddingLeft: 20 }}><BarLoader color='#81BCBF' /></div>
        : !job?.actions?.find(a => a.type === 'ConditionalOffer')?.enabled
            ? <div style={{ marginTop: 50, paddingBottom: 50 }}>
              <TableWrapper>
                <TableHeader>
                  <h3 style={{ paddingLeft: 10 }}>Actions</h3>
                </TableHeader>
                <ProductListTitle>
                  Begin Hiring Process
                </ProductListTitle>
                <div style={{ padding: 30 }}>
                  <p style={{ marginTop: 0 }}>To begin the process of hiring this applicant, please confirm all applicant and job details are correct. Once the information is verified, click the button below to begin the hiring process.</p>
                  <div style={{ padding: 20, backgroundColor: '#81BCBF', color: 'white', marginBottom: 20, borderRadius: 5 }}>
                    <h5 style={{ padding: 0, margin: 0, marginBottom: 10 }}>IMPORTANT NOTICE</h5>
                    <p style={{ padding: 0, margin: 0, marginBottom: 0 }}>Upon clicking the below button, your Applicant will receive an invitation to begin the hiring process which includes compliance documents, resources and their conditional offer.</p>
                  </div>
                  <ActionButton
                    buttonText='Begin Process'
                    buttonAction={() => {
                      actionActions.sendConditionalOffer(jobId).then((apiData) => {
                        window.location = apiData.redirect_to
                      })
                    }}
                    buttonEnabled
                    buttonColor='blue'
                    buttonFresh={false}
                  />
                </div>
              </TableWrapper>
            </div>
            : <AdminDashboardTabs
                job={job}
                applicantName={applicantName}
                jobTitle={jobTitle}
                defaultTabIndex={urlParams.tabId ? parseInt(urlParams.tabId) : 0}
                jobAdverseAction={job?.job_adverse_action_info}
                flags={job?.adverse_action_flags_available}
                assesses={job?.company_assesses?.map((aaa) => {
                  return {
                    val: aaa.id,
                    label: aaa.title
                  }
                })}
                actions={job?.actions?.map((a) => {
                  return {
                    id: a.id,
                    title: a.title,
                    buttonText: a.button_text,
                    buttonAction: () => {
                      actionActions.execute(a.id, a.type).then((apiData) => {
                        // debugger;
                        window.location = apiData.redirect_to
                      })
                    },
                    buttonEnabled: a.enabled, // disabled buttons are grayed out
                    buttonColor: a.enabled ? 'blue' : 'brown', // blue or brown buttons in design (disabled buttons are grayed out)
                    buttonFresh: a.primary // true or false to show if button action has been done already, disabled buttons should probably be undefined
                  }
                })}
                tasks={reportsArray}
                orders={job?.orders?.map((o) => {
                  return {
                    id: o.id,
                    ordered: o.ordered,
                    completed: o.completed,
                    status: o.status,
                    productName: o.product_name,
                    productId: o.product_id
                  }
                })}
                alerts={dbAlerts?.map((a) => {
                  return {
                    id: a.id,
                    date: a.display_date,
                    type: a.type,
                    display_type: a.display_type,
                    details: a.details,
                    alert_messages: a.alert_messages,
                    title: a.title,
                    editLink: a.sender_id === user?.id ? `/admin/locations/${locationId}/users/${userId}/jobs/${jobId}/alerts/${a.id}/edit` : undefined
                  }
                })}
                submitData={saveJobAdverseAction}
              />}
    </AdminWrapper>
  )
}

export default AdminJobReview
