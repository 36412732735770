import { useFetchWrapper } from '../_helpers'

export { useAlertMessageActions }

function useAlertMessageActions (jobId, notificaionId) {
  const token = localStorage.getItem('access_token')

  const baseUrl = (jobId, notificaionId) =>
    `${process.env.REACT_APP_API_URL}/jobs/${jobId}/notifications/${notificaionId}/alert_messages`
  const fetchWrapper = useFetchWrapper(token)

  return {
    sendMessage
  }

  function sendMessage (message) {
    return fetchWrapper.post(baseUrl(jobId, notificaionId), {
      message
    })
  }
}
