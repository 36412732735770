import styled from 'styled-components'
import Table from 'rc-table'
import { BarLoader } from 'react-spinners'
import { Link } from 'react-router-dom'
import { basicButton } from '../../_helpers/shared-css'

const TableTitle = styled.div`
  font-size: 24px;
  color: ${(props) =>
    props.tableTitleColor ? props.tableTitleColor : '#272D3B'};
  font-weight: bold;
`

const TableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  margin-top: 15px;

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;

      tr:hover td {
        background: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;
    }

    .table-buttons {
      display: flex;
    }

    svg {
      width: 20px;
    }

    button {
      border: 1px solid #c8d1d3;
      padding: 12px 25px 8px 25px;
      display: inline-flex;
      border-radius: 20px;
      height: 41px;
      box-sizing: border-box;
      text-decoration: none;
      color: #272d3b;
      font-weight: bold;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: 'Montserrat';
      background: #fff;

      &:hover {
        background-color: #81bcbf;
        border: 1px solid #81bcbf;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`

const TableTitleWrapper = styled.div`
  display: flex;
  padding: 20px;
`

const StyledLink = styled.div`
  margin: 10px 0 0 auto;

  a:visited {
    color: white;
  }

  a {
    ${basicButton}
    z-index: 5;

    &:hover {
      cursor: pointer;
      background-color: #73a9ac;
    }
  }
`

export const ApplicantGenericTable = ({
  data,
  tableTitle,
  tableTitleColor,
  columns,
  loading,
  buttonLink = undefined,
  buttonText = undefined
}) => {
  return (
    <TableWrapper>
      {tableTitle && (
        <TableTitleWrapper>
          <TableTitle tableTitleColor={tableTitleColor}>{tableTitle}</TableTitle>
          {buttonLink && (
            <StyledLink><Link to={buttonLink}>{buttonText}</Link></StyledLink>
          )}
        </TableTitleWrapper>
      )}
      {loading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : null}
      <Table
        columns={columns}
        data={data}
        rowKey={(record) => record.id} // need to include a unqiue id for react element keys
      />
    </TableWrapper>
  )
}
