import styled from 'styled-components'

import { JobDetail } from '../shared/JobDetail'
import { ReactComponent as IconEdit } from '../../resources/images/icons/edit2.svg'

const JobDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;

  .details-group {
    display: grid;
    width: 100%;
    grid-template-columns: auto 120px auto auto auto;
    padding: 30px;
    box-sizing: border-box;
    row-gap: 20px;
    position: relative;
  }

  .edit {
    width: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border-left: 1px solid #ccc;

    &:hover {
      cursor: pointer;
    }
  }
`

export const JobDetails = ({ details, edit, editClick }) => {
  return (
    <JobDetailsWrapper>
      <div className='details-group'>
        <JobDetail label='Job Title' detail={details.jobTitle} />
        <JobDetail label='Location' detail={details.location} />
        <JobDetail label='Pay Amount' detail={String(details.payAmount)} />
        <JobDetail label='Department' detail={details.department} />
        <JobDetail label='Bonus' detail={details.bonus} />
        <JobDetail label='Openings' detail={String(details.openings)} />
        <JobDetail label='Start Date' detail={details.startDate} />
        <JobDetail label='Pay Type' detail={details.payType} />
        <JobDetail label='Vacation' detail={details.vacation} />
        <JobDetail label='Status' detail={details.status} />
        {!!details.commission_notes && <JobDetail label='Commission' detail={details.commission_notes} />}
      </div>
      {edit && (
        <span className='edit'>
          <IconEdit
            onClick={() => {
              editClick && editClick()
            }}
          />
        </span>
      )}
    </JobDetailsWrapper>
  )
}
