import { useEffect, useRef, useState } from 'react'
import { FormContentWrapper } from '../../shared/FormContentWrapper'

const QuestForm = ({ questForm, job, backToJob, save, final }) => {
  const [page, _setPage] = useState(1)
  const canvasRef = useRef(null)
  const iframeRef = useRef(null)

  console.log(iframeRef)
  /* const { pdfDocument } = usePdf({
    file: `data:application/pdf;base64,${questForm}`,
    page,
    canvasRef
  }) */

  useEffect(() => {
    console.log(iframeRef.current)
  }, [])
  return (

    <FormContentWrapper>
      <form className='form-wrapper'>
        <div>
          {/* <canvas ref={canvasRef} /> */}
          <iframe ref={iframeRef} id='pdfIframe' style={{ border: '0', width: '100%', height: '980px' }} src={`data:application/pdf;base64,${questForm}`} />
        </div>
        <div className='form-element'>
          {/* <button className='btn btn-primary' type='button' onClick={() => backToJob(job.id)}>Go Back</button> */}
          {!final && <button className='btn btn-primary' type='button' onClick={() => save(questForm)}>Finish</button>}
          {/* <button className='btn btn-primary' type='button' onClick={() => iframeRef.current.contentWindow.print()}>Print</button> */}
        </div>
      </form>
    </FormContentWrapper>

  )
}

export { QuestForm }

/* <DocumentWrapper>
      <div><iframe src={`data:application/pdf;base64,${questForm}#view=fitH`} loading='lazy' /></div>
  </DocumentWrapper> */
