import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'
import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  margin-top: -30px;
  font-size: 20px;
  margin-bottom: -20px;

  .back-link {
    margin-left: 20px;
    display: flex;
    margin-top: 35px;
    justify-content: center;
    color: #baafa3;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;

    .linkText {
    }

    .svgwrapper {
      margin-right: 10px;
    }

    svg {
      fill: #baafa3;
      width: 20px;
    }
  }
`

const HeaderWrapper = ({ title, link, backToText }) => {
  return (
    <Header>
      <h2 style={{ color: '#81BCBF' }}>{title}</h2>
      <a className='back-link' href={link}>
        <div className='svgwrapper'>
          <IconArrow />
        </div>
        <div className='linkText'>{backToText || 'Back to dashboard'}</div>
      </a>
    </Header>
  )
}

export default HeaderWrapper
