import React from 'react'

const ArrayContext = React.createContext(null)

const useArrayContext = () => React.useContext(ArrayContext)

const ArrayProvider = (props) => {
  const { children, ...data } = props
  return (
    <>
      <ArrayContext.Provider value={data}>{children}</ArrayContext.Provider>
    </>
  )
}

export { useArrayContext, ArrayProvider }
