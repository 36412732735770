import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'
import AdminWrapper from '../../../shared/AdminWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDepartmentActions, useLocationActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'
import { FormContentWrapper } from '../../../shared/AdminStyledComponets'

const DepartmentNew = () => {
  const { companyId } = useParams()
  const departmentActions = useDepartmentActions(
    localStorage.getItem('access_token')
  )
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )

  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Amount is required'),
    lookup: Yup.string().required('Amount is required'),
    location_id: Yup.string().required('Amount is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await departmentActions.add(data)
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  useEffect(() => {
    locationActions.getLocationList(companyId).then((apiData) => {
      setLocations(apiData)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminWrapper>
      <h1>New Department</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <>
            <FormContentWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-element'>
                  <label>Title</label>
                  <input
                    name='title'
                    type='text'
                    {...register('title')}
                    className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{errors.title?.message}</div>
                </div>
                <div className='form-element'>
                  <label>Lookup</label>
                  <input
                    name='lookup'
                    type='text'
                    {...register('lookup')}
                    className={`form-control ${
                    errors.lookup ? 'is-invalid' : ''
                  }`}
                  />
                  <div className='invalid-feedback'>{errors.lookup?.message}</div>
                </div>
                <div className='form-element'>
                  <label>Location</label>
                  <select
                    {...register('location_id')}
                    className={`form-control ${
                    errors.location_id ? 'is-invalid' : ''
                  }`}
                    name='location_id'
                  >
                    <option value='' disabled>
                      Please select
                    </option>
                    {locations.map((d) => {
                      return <option value={`${d.id}`}>{d.title}</option>
                    })}
                  </select>
                  <div className='invalid-feedback'>
                    {errors.location_id?.message}
                  </div>
                </div>
                {isLoading
                  ? (
                    <div style={{ paddingLeft: 20 }}>
                      <BarLoader color='#81BCBF' />
                    </div>
                    )
                  : (
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='btn btn-primary'
                    >
                      {isSubmitting && (
                        <span className='spinner-border spinner-border-sm mr-1' />
                      )}
                      Save
                    </button>
                    )}
              </form>
            </FormContentWrapper>
          </>
          )}
    </AdminWrapper>
  )
}

export default DepartmentNew
