import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'
import AdminWrapper from '../../../shared/AdminWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useBonusActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'
import { FormContentWrapper } from '../../../shared/AdminStyledComponets'

const BonusEdit = () => {
  const { bonusId, companyId } = useParams()
  const bonusActions = useBonusActions(localStorage.getItem('access_token'))
  const [bonus, setBonus] = useState({ bonus: {} })

  const [isLoading, setIsLoading] = useState(true)

  // form validation rules
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await bonusActions.update(bonusId, {
        ...data,
        company_id: companyId
      })
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  useEffect(() => {
    bonusActions.get(bonusId).then((apiData) => {
      setBonus(apiData)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminWrapper>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <>
            <h1>Edit Bonus</h1>
            <FormContentWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-element'>
                  <label>Amount</label>
                  <input
                    defaultValue={bonus.bonus.amount}
                    name='amount'
                    type='text'
                    {...register('amount')}
                    className={`form-control ${errors.amount ? 'is-invalid' : ''
                      }`}
                  />
                  <div className='invalid-feedback'>{errors.amount?.message}</div>
                </div>
                {isLoading
                  ? (
                    <div style={{ paddingLeft: 20 }}>
                      <BarLoader color='#81BCBF' />
                    </div>
                    )
                  : (
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='btn btn-primary'
                    >
                      {isSubmitting && (
                        <span className='spinner-border spinner-border-sm mr-1' />
                      )}
                      Save
                    </button>
                    )}
              </form>
            </FormContentWrapper>
          </>
          )}
    </AdminWrapper>
  )
}

export default BonusEdit
