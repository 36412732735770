import { useState } from 'react'
import { AdminAddApplicant } from '../components/Admin/AdminAddApplicant'
import AdminWrapper from './shared/AdminWrapper'
import { useParams } from 'react-router-dom'
import { useUserActions } from '../_actions'
import HeaderWrapper from './shared/HeaderWrapper'
import { history } from '../_helpers'
import { useRecoilState } from 'recoil'
import { userAtom } from '../_state'

const AdminApplicantCreation = () => {
  const { locationId } = useParams()
  const [user] = useRecoilState(userAtom)
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const [isLoading, setIsLoading] = useState(false)

  const saveApplicant = (data, url) => {
    setIsLoading(true)
    const createTheApplicant = async () => {
      const applicant = await userActions.createApplicant(data, locationId, user?.company?.id)
      setIsLoading(false)
      history.push(url.replace(':userId', applicant.user.id))
    }
    createTheApplicant()
  }

  return (
    <AdminWrapper>
      <HeaderWrapper
        title='Create Applicant'
        link={locationId ? `/admin/locations/${locationId}/dashboard` : '/admin/company/dashboard'}
      />
      <div style={{ height: 40 }} />
      <AdminAddApplicant
        saveApplicant={saveApplicant}
        saveLinkText='Save & Exit'
        linkJob={locationId ? `/admin/locations/${locationId}/dashboard` : '/admin/company/dashboard'}
        loading={isLoading}
      />
    </AdminWrapper>
  )
}

export default AdminApplicantCreation
