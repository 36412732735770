import styled from 'styled-components'
import { ReactComponent as IconShield } from '../../resources/images/icons/shield-check-regular.svg'
import ReactJson from 'react-json-view'

const EmailWrapper = styled.div`
  margin: auto;
  max-width: 620px;
  color: #707070;
  background: #fff;
  padding: 0 20px;
  width: 100%;

  .label {
    font-size: 7px;
    color: #81BCBF;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin: 10px 0 5px 0;
  }

  .email-main-content {
    display: flex;
    flex-direction: column;
  }

  .email-section-header {
    display: flex;
    background-color: #f1efed;
    padding: 30px;
    align-items: center;
    margin-bottom: 10px;

    .email-section-header-title {
      font-size: 12px;
      font-weight: bold;
      color: #272D3B;
    }

    .email-section-header-status {
      margin-left: auto;
      text-align: right;
      display: flex;

      span {
        display: block;
        font-weight: bold;
        margin: 2px 0;
      }

      .status-details-label {
        font-size: 7px;
        text-transform: uppercase;
        color: #1A1A1A;
      }
      .status-details-status {
        font-size: 12px;
        color: #05B427;
      }
      .status-details-time {
        font-size: 9px;
        color: #707070;
      }

      .status-icon {
        padding: 5px;
        border-left: 1px solid #ccc;
        margin-left: 7px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .email-section-info-blocks {
    display: flex;
    font-size: 10px;

    .email-section-info {
      flex: 1;

      dl {
        display: flex;
        flex-wrap: wrap;

        dd, dt {
          width: 50%;
          margin: 0 0 3px 0;
        }

        dt {
          font-weight: bold;
        }
      }
    }
  }

  .email-details-3cols {
    display: flex;
    font-size: 10px;
    padding: 10px 0;
    align-items: center;

    span {
      align-items: center;
    }

    .cols-time {
      width: 30%
    }

    .cols-info {
      flex: 1;
    }

    .cols-right {
      text-align: right;
      margin-left: auto;
    }
  }

  .email-section-logs {
    margin-bottom: 40px;

    .email-details-3cols {
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        border-bottom: 0px solid transparent;
      }
    }
  }

  .email-footer {
    display: flex;
    background: #fff !important;

    img {
      width: 75px;
    }

    .footer-details {
      margin-left: auto;
      font-size: 8px;
    }
  }
`

export const GenericDocumentView = ({ doc }) => {
  console.log(doc)
  return (
    <>
      <EmailWrapper>
        <div className='email-main-content'>
          <div className='email-section-header'>
            <div className='email-section-header-title'>{doc.title}</div>
            <div className='email-section-header-status'>
              <div className='status-details'>
                <span className='status-details-label'>Status</span>
                <span className='status-details-status'>Completed</span>
                <span className='status-details-time'>{doc.date}</span>
              </div>
              <div className='status-icon'><IconShield /></div>
            </div>
          </div>
        </div>
        <ReactJson
          name={doc.title}
          src={doc.data}
          displayArrayKey={false}
          quotesOnKeys={false}
          sortKeys
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </EmailWrapper>
    </>
  )
}
