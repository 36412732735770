import styled from 'styled-components'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'

import { history } from '../../_helpers'

const AdminQuickActionWrapper = styled.div`
  display: inline-block;

  .quick-actions-title {
    font-size: 24px;
    color: #555;
    font-weight: bold;
  }

  .tiles {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }

  .tile {
    box-sizing: border-box;
    display: inline-block;
    min-width: 270px;
    min-height: 155px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #272d3b33;
    padding: 20px;
    position: relative;
    margin-right: 20px;
    background-color: ${(props) => (props.color ? props.color : '#707070')};

    svg {
      width: 25px;
      height: 25px;
    }

    &:hover {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      .tile-arrow {
        background-color: #fff;

        svg {
          fill: #81bcbf;
          transform: rotate(140deg);
        }
      }
    }
  }

  .tile-icon,
  .tile-title {
    display: block;
  }

  .tile-title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
  }

  .tile-arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: 15px;
    background-color: #fff;
    display: flex;

    svg {
      margin: auto;
      width: 25px;
      transform: rotate(180deg);
      fill: #e5955a;
    }
  }

  // .or {
  //   margin: 25px;
  //   position: relative;
  //   font-size: 12px;
  //   font-weight: bold;

  //   &:before, &:after {
  //     content: '';
  //     display: block;
  //     height: 55px;
  //     width: 2px;
  //     background-color: #ddd;
  //     opacity: 0.3;
  //     position: absolute;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }

  //   &:before {
  //     top: -60px;
  //   }

  //   &:after {
  //     bottom: -60px;
  //   }
  // }
`

const handleQuickActionClick = (link) => {
  history.push(link)
}

// export const AdminQuickActions = () => {
//   return <AdminQuickActionsWrapper>
//     {/* <span className="quick-actions-title">Quick Actions</span> */}
//     <div className="tiles">
//       <div className="tile add-applicant" onClick={()=>{handleQuickActionClick('/add-applicant')}}>
//         <span className="tile-icon"><IconFolder /></span>
//         <span className="tile-title">Add Applicant</span>
//         <span className="tile-arrow"><IconArrow /></span>
//       </div>
//       {/* <span className="or">OR</span> */}
//       <div className="tile add-job" onClick={()=>{handleQuickActionClick('/add-job')}}>
//         <span className="tile-icon"><IconMagnifyingGlass /></span>
//         <span className="tile-title">Add Job</span>
//         <span className="tile-arrow"><IconArrow /></span>
//       </div>
//     </div>
//   </AdminQuickActionsWrapper>;
// };

export const AdminQuickAction = ({ color, link, icon, text }) => {
  return (
    <AdminQuickActionWrapper color={color}>
      <div
        className='tile'
        onClick={() => {
          handleQuickActionClick(link)
        }}
      >
        <span className='tile-icon'>{icon}</span>
        <span className='tile-title'>{text}</span>
        <span className='tile-arrow'>
          <IconArrow />
        </span>
      </div>
    </AdminQuickActionWrapper>
  )
}
