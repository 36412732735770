import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { jobs as JobsAtom, userAtom, loadingState } from '../_state'
import styled from 'styled-components'
import { Header2 } from '../components/Headers'
// containers for component wrappers
import { DashboardJobsTable } from '../components/DashboardJobsTable'
import Modal from 'react-modal'
import { ReactComponent as IconClose } from '../resources/images/icons/close.svg'
import { SignaturePad } from '../components/shared/SignaturePad'
import { ApplicantWrapper } from './ApplicantWrapper'
import { useJobActions, useUserActions } from '../_actions'

const ModalContent = styled.div`
  padding: 15px;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}
const TableWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
`
const Header3 = styled.h3`
  font-size: 26px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: #272d3b;
  line-spacing: 32;
`
// #F0EEEC; light grey
// #272D3B; med Grey
// #81BCBF; Light blue
// #DE7E53; Orange
// #304755; Sorta grey
// #707070; grey med-dark
// #BAAFA3; bronze grey

const Dashboard = () => {
  const [jobs] = useRecoilState(JobsAtom)
  const [loading, setLoading] = useRecoilState(loadingState)
  const user = useRecoilValue(userAtom)
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const [validSignature, setValidSignature] = useState(null)
  const [validInitials, setValidInitials] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalState, setModalState] = useState(null)
  const [modalPage, _setModalPage] = useState(1)

  useEffect(() => {
    const mState = validInitials === false && validSignature === false ? 0 : validInitials === false && validSignature === true ? 2 : validInitials === true && validSignature === false ? 1 : 3
    setModalState(mState)
    if (validInitials === false || validSignature === false) {
      openModal()
    }
  }, [validInitials, validSignature])

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true)
      try {
        await jobActions.getAll()
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }
    fetchJobs()

    userActions.getInitialsUrl()
      .then((ret) => {
        setValidInitials(true)
      })
      .catch((e) => {
        if (e === undefined) {
          setValidInitials(false)
        }
      })

    userActions.getSignatureUrl()
      .then((ret) => {
        setValidSignature(true)
      })
      .catch((e) => {
        if (e === undefined) {
          setValidSignature(false)
        }
      })

    // eslint-disable-next-line
  }, [])

  function openModal () {
    setIsOpen(true)
  }

  function closeModal () {
    setIsOpen(false)
  }

  function afterOpenModal () {
    // things to do after modal opening
  }

  const setSignatureUrl = (url) => {
    setValidSignature(true)
    if (validSignature === true && validInitials === true) {
      closeModal()
    }
  }

  const setInitialsUrl = (url) => {
    setValidInitials(true)
    if (validSignature === true && validInitials === true) {
      closeModal()
    }
  }

  const getModal = () => {
    switch (modalState) {
      case 0: return (
        <>
          {modalPage === 1 &&
            <>
              <h2 ref={(modalTitle) => modalTitle}>
                Please add a signature to your profile
              </h2>
              <SignaturePad type='signature' canvasWidth={500} canvasHeight={300} setUrl={setSignatureUrl} />
            </>}
          {modalPage === 2 &&
            <>
              <h2 ref={(modalTitle) => modalTitle}>
                Please add your initials to your profile
              </h2>
              <SignaturePad type='initials' canvasWidth={240} canvasHeight={175} setUrl={setInitialsUrl} />
            </>}
        </>
      )
      case 1: return (
        <>
          <h2 ref={(modalTitle) => modalTitle}>
            Please add a signature to your profile
          </h2>
          <SignaturePad type='signature' canvasWidth={500} canvasHeight={300} setUrl={setSignatureUrl} />
        </>
      )
      case 2: return (
        <>
          <h2 ref={(modalTitle) => modalTitle}>
            Please add your initials to your profile
          </h2>
          <SignaturePad type='initials' canvasWidth={240} canvasHeight={175} setUrl={setInitialsUrl} />
        </>
      )
    }
  }

  return (
    <>
      <ApplicantWrapper>
        <Header2 user={user} />
        {/* <TilesWrapper /> */}
        <Header3>Open Jobs</Header3>
        <TableWrapper>
          <DashboardJobsTable data={jobs} loading={loading} />
        </TableWrapper>
      </ApplicantWrapper>
      {(!loading && (validSignature === false || validInitials === false)) && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel='Signature Modal'
          ariaHideApp={false}
        >
          <ModalContent>
            <IconClose onClick={closeModal} />
            {getModal()}
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export { Dashboard }
