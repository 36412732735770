import { useFetchWrapper } from '../_helpers'

export { useAdverseActionAssessActions }

function useAdverseActionAssessActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/adverse_action_assess`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    list,
    add,
    get,
    update,
    remove
  }

  function list (company_id) {
    return fetchWrapper.get(`${baseUrl}?company_id=${company_id}`)
  }

  function add (adverse_action_assess) {
    return fetchWrapper.post(`${baseUrl}`, { adverse_action_assess })
  }

  function get (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, adverse_action_assess) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { adverse_action_assess })
  }

  function remove (id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
  }
}
