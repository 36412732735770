import * as React from 'react'
import styled from 'styled-components'

import IconProfile from '../../resources/images/icons/profile.svg'

const StyledProfileButton = styled.div`
  display: inline-flex;
  width: 90px;
  height: 70px;

  svg {
    margin: auto;
    width: 32px;
    opacity: 0.5;
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    width: 45px;
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &:hover {
    cursor: pointer;

    svg {
      opacity: 1;
    }

    img {
      border: 2px solid rgba(255, 255, 255, 1);
    }
  }
`

export const ProfileButton = ({ profileImg }) => {
  return (
    <StyledProfileButton>
      {profileImg
        ? (
          <img src={profileImg} alt='profile_img' />
          )
        : (
          <IconProfile />
          )}
    </StyledProfileButton>
  )
}
