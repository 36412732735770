import { useFetchWrapper } from '../_helpers'

export { useDepartmentActions }

function useDepartmentActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/departments`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    list,
    listByCompany,
    add,
    get,
    update,
    remove
  }

  function list (location_id) {
    return fetchWrapper.get(`${baseUrl}?location_id=${location_id}`)
  }

  function listByCompany (company_id) {
    return fetchWrapper.get(`${baseUrl}/by_company?company_id=${company_id}`)
  }

  function add (department) {
    return fetchWrapper.post(`${baseUrl}`, { department })
  }

  function get (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, department) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { department })
  }

  function remove (id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
  }
}
