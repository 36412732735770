import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  HookFormArrayInput,
  HookFormArraySelect,
  HookFormArrayRegionSelector,
  HookFormArrayDateRange
} from '../../shared/HookFormComponents'
import { DegreeOrCertification } from './DegreeOrCertification'
import { ArrayProvider, useArrayContext } from '../../../contexts/useArrayContext'
import { useStateContext } from '../../../contexts/useStateContext'

const Education = () => {
  const { index, arrayName } = useArrayContext()
  const { setValue } = useFormContext()

  return (
    <>
      <HookFormArraySelect
        fieldName='type'
        label='Type of Institution'
        options={[
          { value: '', label: '' },
          { value: 'HS', label: 'High School' },
          { value: 'CU', label: 'College/University' },
          { value: 'TB', label: 'Trade of Business School' },
          { value: 'GED', label: 'GED' },
          { value: 'GRAD', label: 'Graduate School' }
        ]}
        postOnchange={(e, id) => {
          const fieldPrefix = `${arrayName}.${index}.`
          setValue(fieldPrefix + 'graduated', 'No')
          setValue(fieldPrefix + 'degree', '')
          setValue(fieldPrefix + 'major', '')
          setValue(fieldPrefix + 'minor', '')
        }}
      />
      <HookFormArrayInput
        fieldName='name'
        label='Institution Name'
        type='text'
      />
      <HookFormArrayInput fieldName='city' label='City' type='text' />
      <HookFormArrayRegionSelector
        countryName='US'
        fieldName='state'
        label='State'
      />
      <HookFormArrayDateRange label='Years Attended' />
      <DegreeOrCertification />
    </>
  )
}

const EducationHistory = ({ name }) => {
  const { control } = useFormContext()
  const { state, setState } = useStateContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  const addEducation = (e) => {
    const list = state[name].map((item) => {
      return item
    })
    list.push(e)
    setState({ ...state, [name]: list })
  }

  const removeEducaction = (idx) => {
    const list = state[name].filter((e, i) => {
      return i !== idx
    })
    setState({ ...state, [name]: list })
  }

  return (
    <>
      <div className='form-element'>
        <button
          type='button'
          className='add-remove-button'
          onClick={() => {
            append()
            addEducation({})
          }}
        >
          + Add Education Entry
        </button>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className='form-element'>
          <ArrayProvider arrayName='educationHistory' index={index}>
            <Education id={item.id} />
          </ArrayProvider>
          <button
            type='button'
            onClick={() => {
              remove(index)
              removeEducaction(index)
            }}
          >
            - Remove Entry
          </button>
        </div>
      ))}
    </>
  )
}

export { Education, EducationHistory }
