import { Link } from 'react-router-dom'

const StepFive = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <h3>Congrats!</h3>
      <h3 style={{ paddingBottom: 0 }}>Your account has been created.</h3>
      <div style={{ color: '#707070' }}>
        You should receive an email from us confirming your account as well as
        providing you some welcome information and some tips to keep the hiring
        process easy.
      </div>
      <Link
        to='login'
        className='button-basic'
        style={{ display: 'inline-block', marginTop: 40 }}
      >
        Take me to my dashboard
      </Link>
    </div>
  )
}

export default StepFive
