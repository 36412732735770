import { AdminJobDetails } from '../components/Admin/AdminJobDetails'

const AdminJobCreationModal = (saveJob, saveLinkText, loading, closeModal) => {
  return (
    <div>
      <AdminJobDetails saveJob={saveJob} saveText='Save' loading={loading} closeModal={closeModal} />
    </div>
  )
}

export default AdminJobCreationModal
