import React from 'react'
import * as Yup from 'yup'
import { ApplySignature, ApplySignatureValidationSchema } from '../../../shared/HookFormComponents'
import { Step } from './Step'

const LegalNotice = (props) => {
  const validationSchema = Yup.object().shape(ApplySignatureValidationSchema)
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <ApplySignature />
      </Step>
    </>
  )
}
LegalNotice.componentName = 'LegalNotice'
export { LegalNotice }
