import * as Yup from 'yup'
import { SocialSecurityNumber } from '../SocialSecurityNumber'
import { Step } from './Step'

const SSNInfo = (props) => {
  const validationSchema = Yup.object().shape({
    ssn: Yup.string()
      .matches('^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$', {
        message: 'SSN must be in the format ###-##-####'
      })
      .required('SSN is required'),
    confirmSsn: Yup.string()
      .oneOf([Yup.ref('ssn'), null], 'SSN fields must match')
      .required('Confirm SSN i˜s required')
  })

  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>Social Security Verification</h3>
        <div style={{ paddingBottom: 20 }}>
          Use the fields below to enter and confirm your social security
          number
        </div>
        <SocialSecurityNumber readOnly={props.readOnly} />
      </Step>
    </>
  )
}
SSNInfo.componentName = 'SSNInfo'
export { SSNInfo }
