import { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { BarLoader } from 'react-spinners'

const AdminAddApplicantWrapper = styled.div`
  max-width: 500px;

  form {
    label {
      display: block;
      font-weight: bold;
      color: #272d3b;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .form-element {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    input {
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #d4d3d3;
      color: #272d3b;
    }

    input[type='submit'] {
      background-color: #81bcbf;
      box-shadow: 0px 20px 50px #0000004d;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 25px;
      min-width: 200px;
      border-radius: 25px;
      border: none;
      margin-top: 25px;
    }

    input.whiteButton {
      background-color: white;
      margin-left: 20px;
      color: #707070;
    }
  }
`

export const AdminAddApplicant = ({
  saveApplicant,
  saveLinkText,
  linkJob,
  saveExitText,
  exitLink,
  loading,
  usePhone,
  hideEmail,
  user
}) => {
  const [buttonPress, setButtonPress] = useState(0)

  // form validation rules
  const validationSchema = !usePhone && !hideEmail
    ? Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().required('Email is required'),
      verify_email: Yup.string().oneOf(
        [Yup.ref('email'), null],
        'Emails must match'
      )
    })
    : Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string()
    })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    saveApplicant && saveApplicant(data, buttonPress === 0 ? linkJob : exitLink)
  }

  return (
    <AdminAddApplicantWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-element'>
          <label>First Name</label>
          <input
            defaultValue={user?.first_name}
            {...register('firstName')}
            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
            name='firstName'
            type='text'
          />
          <div className='invalid-feedback'>{errors.firstName?.message}</div>
        </div>
        <div className='form-element'>
          <label>Last Name</label>
          <input
            defaultValue={user?.last_name}
            {...register('lastName')}
            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
            name='lastName'
            type='text'
          />
          <div className='invalid-feedback'>{errors.lastName?.message}</div>
        </div>
        {!hideEmail && <>
          <div className='form-element'>
            <label>Email Address</label>
            <input
              defaultValue={user?.email}
              {...register('email')}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              name='email'
              type='text'
            />
            <div className='invalid-feedback'>{errors.email?.message}</div>
          </div>

          <div className='form-element'>
            <label>Verify Email Address</label>
            <input
              defaultValue={user?.email}
              {...register('verify_email')}
              className={`form-control ${errors.verify_email ? 'is-invalid' : ''
                }`}
              name='verify_email'
              type='text'
            />
            <div className='invalid-feedback'>{errors.verify_email?.message}</div>
          </div>
        </>}
        {usePhone && <div className='form-element'>
          <label>Phone</label>
          <input
            defaultValue={user?.phone}
            {...register('phone')}
            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            name='phone'
            type='text'
          />
          <div className='invalid-feedback'>{errors.phone?.message}</div>
        </div>}
        {loading && (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
        )}
        {!loading && (
          <>
            <input
              disabled={isSubmitting}
              type='submit'
              value={saveLinkText || 'continue'}
              onClick={() => {
                setButtonPress(0)
              }}
            />
            {exitLink &&
              <input
                disabled={isSubmitting}
                className='whiteButton'
                type='submit'
                value={saveExitText || 'continue'}
                onClick={() => {
                  setButtonPress(1)
                }}
              />}
          </>)}
      </form>
    </AdminAddApplicantWrapper>
  )
}
