import iconEyeOff from '../resources/images/icons/eye_off.svg'
import logoImg from '../resources/images/employifi_logo_full.png'
import peopleImg from '../resources/images/people_standing.png'

export const loginWrapperCss = `
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: 'Montserrat';
`

export const basicButton = `
  border: none;
  font-family: 'Montserrat';
  font-size: 12px;
  background-color: #81BCBF;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  padding: 15px 50px;
  box-sizing: border-box;
  //width: 250px;
  box-shadow: 0px 20px 50px #0000004D;
  border-radius: 40px;
  margin: 25px auto;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    background-color: #73a9ac;
  }

  &:disabled {
    background-color: #cde4e5;
  }
`

export const formCss = `
  .form-content {
    max-width: 450px;
    margin: auto;
  }
    
  .form-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 480px;

    h3 {
        font-size: 28px;
        color: #354754;
        font-weight: bold;
        text-align: left;
        margin-bottom: 45px;
    }
  }

  button, .button-basic {
    ${basicButton}
  }

  .dont-have-account {
    margin-top: 40px;
    font-weight: 500;
  }

  .forget-password, .sign-up {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #DE7E53;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .sign-up {
    display: inline-block;
    font-size: 16px;
  }

  .form-element {
    width: 100%;
    margin-bottom: 30px;
    position: relative;

    label, input {
      display: block;
    }

    label {
      font-size: 10px;
      text-transform: uppercase;
      color: #707070;
      text-align: left;
      font-weight: bold;
      margin-bottom: 10px;

      b.deletion {
        font-weight: 500;
        font-size: 12px;
        text-transform: initial;
      }
    }

    input[type="text"], input[type="password"] {
      border-radius: 20px;
      border: 1px solid #B9AFA4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
    }

    textarea {
      border-radius: 5px;
      border: 1px solid #b9afa4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
      float: left;
      margin-right: 20px;
      display: inline;
    }

    .invalid-feedback {
      position: absolute;
      color: #e41a1a;
      font-size: 12px;
      transform: translateX(-40px);
      top: 0;
      right: -40px;
      height: 30px;
      display: flex;
      align-items: center;
    }

    .toggle-password {
      display: block;
      position: absolute;
      top: 31px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-image: url(${iconEyeOff});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .cancel-button {
    display: block;
    margin: 25px auto;
  }

  .back-link {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #BAAFA3;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;

    svg {
      fill: #BAAFA3;
      width: 30px;
      margin-right: 10px;
    }
  }

  .draw-radio-wrapper {
    padding: 40px 0;
    transform: translateX(-30px);
    display: flex;
    justify-content: center;

    >input  {
      margin: 0 10px 0 40px;
    }
  }

  .type-it-input {
    display: block;
    align-self: flex-start;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid #707070;
    min-width: 250px;

    &.initials {
      min-width: 150px;
      margin-left: 30px;
    }
  }

  .clear-button {
    padding: 10px 40px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 6px;
    border: 1px solid #d4d3d3;
    color: #272D3B;
    border-radius: 20px;
    margin-top: 10px;
    display: inline-flex;
    margin-left: auto;

    &:hover {
      color: #fff;
      background-color: #81BCBF;
      cursor: pointer;
    }
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
    background: #ddd;
    margin: 0;
    border-radius: 100%;
    position: relative;
    float: left;
  }

  .custom-checkbox label {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background: #fff;
    border: 1px solid #707070;
  }

  .custom-checkbox input[type='checkbox']:checked + label {
    background: #81bcbf;
    border: 1px solid #81bcbf;

    &:before {
      content: '';
      display: inline-block;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      height: 10px;
      width: 5px;
      margin-left: 60%;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
      position: absolute;
      top: 2px;
      left: -6px;
    }
  }

  .custom-checkbox-label {
    font-size: 14px;
    margin: 2px 0 0 30px;
  }
`

export const loginGraphicCss = `
  flex: 1;
  background-color: #ffd226;
  background-image: url(${peopleImg});
  background-size: cover;
  background-position: bottom -60px right;
  text-align: center;
  background-repeat: no-repeat;

  .employifi-logo {
    width: 200px;
    height: 185px;
    margin: 55px auto 10px auto;
    background-image: url(${logoImg});
    background-size: contain;
    background-repeat: no-repeat;
  }

  span {
    font-weight: 500;
    font-size: 24px;
    color: #fff;
    display: block;
    max-width: 375px;
    margin: 0 auto 25px auto;
  }
`

export const tabsCss = `
  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;

    li {
      color: #272D3B;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 0;
      margin-right: 50px;
      position: relative;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #DE7E53;
      }
    }
  }
}
`
