import './email.css'
import logo from './employifi-logo.svg'

export const BasicEmail = () => {
  return (
    <>
      <div className='email-wrapper'>
        <div className='email-content'>
          <div className='email-body'>
            <div className='top-image-placeholder' />
            <div className='email-body-text'>
              <p>Mr. Songer,</p>
              <p>Oh, ow! What a strange person. Ni! Ni! Ni! Ni! Oh! Come and see the violence inherent in the system! Help, help, I’m being repressed!</p>
              <p>Well, what do you want? It’s only a model. We shall say ‘Ni’ again to you, if you do not appease us. Who’s that then? Well, what do you want? Who’s that then? A newt? We shall say ‘Ni’ again to you, if you do not appease us. Knights of Ni, we are but simple travelers who seek the enchanter who lives beyond these woods.</p>
              <p>You can’t expect to wield supreme power just ‘cause some watery tart threw a sword at you! We want a shrubbery!! Well, Mercia’s a temperate zone! You don’t frighten us, English pig-dogs! Go and boil your bottoms, sons of a silly person! I blow my nose at you, so-called Ah-thoor Keeng, you and all your silly English K-n-n-n-n-n-n-n-niggits!</p>
              <p>Well, I didn’t vote for you. Found them? In Mercia?! The coconut’s tropical! Camelot! He hasn’t got shit all over him. Shh! Knights, I bid you welcome to your new home. Let us ride to Camelot! Well, she turned me into a newt.</p>
              <p>Camelot! Well, how’d you become king, then? He hasn’t got shit all over him. We found them. Well, she turned me into a newt. I am your king.</p>
              <p>I don’t want to talk to you no more, you empty-headed animal food trough water! I fart in your general direction! Your mother was a hamster and your father smelt of elderberries! Now leave before I am forced to taunt you a second time! I am your king.</p>
              <p>She looks like one. How do you know she is a witch? The swallow may fly south with the sun, and the house Martin or the plover may seek warmer climes in winter, yet these are not strangers to our land.</p>
              <br />
              <br />
              <p>Sincerely,<br /><br /> Jaffe Joffer <br />MacDowells<br /> Ruler Of Zamunda</p>
            </div>
          </div>
          <div className='email-footer'>
            <div className='footer-left'>
              <span>compliance & hiring powered by</span>
              <img src={logo} alt='Employifi Logo' />
            </div>
            <div className='footer-right'>
              <p>This email was intended for Joshua Forstot (ECD & Founder of dough. Creative + Tech | Problem Solver | Speaker | Advisor). </p>
              <p>You are receiving these communications from Employifi on behalf of [Company Name].</p>
              <p>Company and Employifi legal copy can go here. I suggest you try it again, Luke. This time, let go your conscious self and act on instinct. Don’t be too proud of this technological terror you’ve constructed. The ability to destroy a planet is insignificant next to the power of the Force.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
