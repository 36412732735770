import { useState, useEffect, useMemo } from 'react'

import { useCompanyActions } from '../../_actions'

import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'

import AdminWrapper from '../shared/AdminWrapper'
import DeleteLink from '../shared/DeleteLink'

const CompaniesList = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const companyActions = useCompanyActions(localStorage.getItem('access_token'))

  const COLUMNS = [
    {
      dataIndex: 'display_name',
      key: 'display_name',
      title: 'Display Name'
    },
    {
      dataIndex: 'ein_number',
      key: 'ein_number',
      title: 'EIN'
    },
    {
      dataIndex: 'address',
      key: 'address',
      title: 'Address'
    },
    {
      dataIndex: 'address_2',
      key: 'address_2',
      title: 'Address 2'
    },
    {
      dataIndex: 'city',
      key: 'city',
      title: 'City'
    },
    {
      dataIndex: 'state',
      key: 'state',
      title: 'State'
    },
    {
      dataIndex: 'postal_code',
      key: 'postal_code',
      title: 'Postal Code'
    },
    {
      dataIndex: 'contact_number',
      key: 'contact_number',
      title: 'Contact Number'
    },
    {
      dataIndex: 'contact_name',
      key: 'contact_name',
      title: 'Contact Name'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'status-column',
      render (_, row) {
        const deleteCompany = async () => {
          setIsLoading(true)
          await companyActions.deleteCompany(row.id)
          const companies = await companyActions.getCompanyList()
          setData(companies)
          setIsLoading(false)
        }
        return (
          <span>
            <a href={`/admin/companies/${row.id}/edit`}>Edit</a> |
            <DeleteLink
              row={row}
              confirmationText={row.display_name}
              deleteAction={deleteCompany}
              resourceName='Company'
              fakeLink={`/admin/companies/${row.id}#delete`}
            />
          </span>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await companyActions.getCompanyList()
      setData(companies)
      setIsLoading(false)
    }
    fetchCompanies()
  }, [])

  return (
    <AdminWrapper>
      <ApplicantGenericTable
        loading={isLoading}
        tableTitle='Admin Companies'
        tableTitleColor='#E28A57'
        columns={columns}
        data={data}
        rowKey='id'
        buttonLink='/admin/companies/new'
        buttonText='Add'
      />
    </AdminWrapper>
  )
}

export default CompaniesList
