import styled from 'styled-components'
import logo from './employifi-logo.svg'
import { ReactComponent as IconShield } from '../resources/images/icons/shield-check-regular.svg'

const EmailWrapper = styled.div`
  border: 1px solid #eee;
  margin: auto;
  max-width: 620px;
  color: #707070;
  background: #fff;
  padding: 0 20px;

  .label {
    font-size: 7px;
    color: #81BCBF;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin: 10px 0 5px 0;
  }

  .email-main-content {
    display: flex;
    flex-direction: column;
  }

  .email-section-header {
    display: flex;
    background-color: #f1efed;
    padding: 30px;
    align-items: center;
    margin-bottom: 10px;

    .email-section-header-title {
      font-size: 12px;
      font-weight: bold;
      color: #272D3B;
    }

    .email-section-header-status {
      margin-left: auto;
      text-align: right;
      display: flex;

      span {
        display: block;
        font-weight: bold;
        margin: 2px 0;
      }

      .status-details-label {
        font-size: 7px;
        text-transform: uppercase;
        color: #1A1A1A;
      }
      .status-details-status {
        font-size: 12px;
        color: #05B427;
      }
      .status-details-time {
        font-size: 9px;
        color: #707070;
      }

      .status-icon {
        padding: 5px;
        border-left: 1px solid #ccc;
        margin-left: 7px;
        
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .email-section-info-blocks {
    display: flex;
    font-size: 10px;

    .email-section-info {
      flex: 1;

      dl {
        display: flex;
        flex-wrap: wrap;

        dd, dt {
          width: 50%;
          margin: 0 0 3px 0;
        }

        dt {
          font-weight: bold;
        }
      }
    }
  }

  .email-details-3cols {
    display: flex;
    font-size: 10px;
    padding: 10px 0;
    align-items: center;

    span {
      align-items: center;
    }

    .cols-time {
      width: 30%
    }

    .cols-info {
      flex: 1;
    }

    .cols-right {
      text-align: right;
      margin-left: auto;
    }
  }

  .email-section-logs {
    margin-bottom: 40px;

    .email-details-3cols {
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        border-bottom: 0px solid transparent;
      }
    }
  }

  .email-footer {
    display: flex;
    background: #fff !important;

    img {
      width: 75px;
    }

    .footer-details {
      margin-left: auto;
      font-size: 8px;
    }
  }
`

export const EducationEmploymentReportEmail = ({ emailData }) => {
  return (
    <EmailWrapper>
      <div className='email-main-content'>
        <div className='email-section-header'>
          <div className='email-section-header-title'>Education Report</div>
          <div className='email-section-header-status'>
            <div className='status-details'>
              <span className='status-details-label'>Status</span>
              <span className='status-details-status'>Completed</span>
              <span className='status-details-time'>10/22/2023 5:15PM PDT</span>
            </div>
            <div className='status-icon'><IconShield /></div>
          </div>
        </div>
        <div className='email-section-info-blocks'>
          <div className='email-section-info'>
            <span className='label'>Information provided</span>
            <dl>
              <dt>School:</dt>
              <dd>Tyler Junior</dd>
              <dt>Location:</dt>
              <dd>Tyler, TX USA</dd>
              <dt>Start Date:</dt>
              <dd>August 2007</dd>
              <dt>End Date:</dt>
              <dd>March 2008</dd>
              <dt>Degree Earned:</dt>
              <dd>None</dd>
              <dt>Major:</dt>
              <dd>Nursing</dd>
            </dl>
          </div>
          <div className='email-section-info'>
            <span className='label'>Employifi Verified Data</span>
            <dl>
              <dt>School:</dt>
              <dd>Tyler Junior College</dd>
              <dt>Location:</dt>
              <dd>Tyler, TX USA</dd>
              <dt>Start Date:</dt>
              <dd>August 2007</dd>
              <dt>End Date:</dt>
              <dd>March 2008</dd>
              <dt>Degree Earned:</dt>
              <dd>None</dd>
              <dt>Major:</dt>
              <dd>Nursing</dd>
            </dl>
          </div>
        </div>
        <div className='email-section-notes'>
          <span className='label'>Notes & Flags</span>
          <div className='email-details-3cols'>
            <span className='cols-time'>10/21/2022 8:40AM PDT</span>
            <span className='cols-info'>School name was truncated by Applicant. No alert for concern.</span>
            <span className='cols-right'>Leslie Garfias</span>
          </div>
        </div>
        <div className='email-section-logs'>
          <span className='label'>Process Logging</span>
          <div className='email-details-3cols'>
            <span className='cols-time'>10/20/2022 3:45 PM PDT</span>
            <span className='cols-info'>Submit product order</span>
            <span className='cols-right'>Employifi Automation</span>
          </div>
          <div className='email-details-3cols'>
            <span className='cols-time'>10/21/2022 2:15 PM PDT</span>
            <span className='cols-info'>Applicant began Application Verification</span>
            <span className='cols-right'>Jennifer Rubensteinberg</span>
          </div>
          <div className='email-details-3cols'>
            <span className='cols-time'>10/22/2022 9:33 AM PDT</span>
            <span className='cols-info'>The information provided by the NSCH has been verified with a discrepancy found in school name.</span>
            <span className='cols-right'>Leslie Garfias</span>
          </div>
          <div className='email-details-3cols'>
            <span className='cols-time'>10/22/2022 5:15 PM PDT</span>
            <span className='cols-info'>Order Completed</span>
            <span className='cols-right'>Employifi Automation</span>
          </div>
        </div>

        <div className='email-section-header'>
          <div className='email-section-header-title'>Employment Report</div>
          <div className='email-section-header-status'>
            <div className='status-details'>
              <span className='status-details-label'>Status</span>
              <span className='status-details-status'>Completed</span>
              <span className='status-details-time'>10/22/2023 5:15PM PDT</span>
            </div>
            <div className='status-icon'><IconShield /></div>
          </div>
        </div>
        <div className='email-section-info-blocks'>
          <div className='email-section-info'>
            <span className='label'>Information provided</span>
            <dl>
              <dt>Employer:</dt>
              <dd>Treliant</dd>
              <dt>Position:</dt>
              <dd>Sr. QC Specialist</dd>
              <dt>Location:</dt>
              <dd>Richardson, TX</dd>
              <dt>Start Date:</dt>
              <dd>December 2019</dd>
              <dt>End Date:</dt>
              <dd>March 2021</dd>
            </dl>
          </div>
          <div className='email-section-info'>
            <span className='label'>Employifi Verified Data</span>
            <dl>
              <dt>Employer:</dt>
              <dd>Treliant Risk Advisors</dd>
              <dt>Position:</dt>
              <dd>Sr. QC Specialist</dd>
              <dt>Location:</dt>
              <dd>Richardson, TX</dd>
              <dt>Start Date:</dt>
              <dd>May 2017</dd>
              <dt>End Date:</dt>
              <dd>March 2021</dd>
            </dl>
          </div>
        </div>
        <div className='email-section-notes'>
          <span className='label'>Notes & Flags</span>
          <div className='email-details-3cols'>
            <span className='cols-info'>School name was truncated by Applicant. No alert for concern.</span>
          </div>
        </div>
      </div>
      <div className='email-footer'>
        <img src={logo} alt='employifi logo' />
        <span className='footer-details'>Request No: {emailData.requestNo} / {emailData.completedDatetime}</span>
      </div>
    </EmailWrapper>
  )
}
