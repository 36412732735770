import { CountrySelector, RecoilInput, RegionSelector } from '../../shared/HookFormComponents'
import { useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts'

const Address = ({ readOnly }) => {
  const { state, setState } = useStateContext()
  const { formState, register, setValue } = useFormContext()
  return (
    <>
      <div className='form-element'>
        <label>Street Address 1</label>
        <RecoilInput
          type='text'
          name='streetLine1'
          value={state.streetLine1}
          className={`form-control ${
                        formState.errors.streetLine1 ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Street Address 2 (ex. Apt. Suite. )</label>
        <RecoilInput
          type='text'
          name='streetLine2'
          value={state.streetLine2}
          className={`form-control ${
                        formState.errors.streetLine2 ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>City</label>
        <RecoilInput
          type='text'
          name='city'
          value={state.city}
          className={`form-control ${
                        formState.errors.city ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>State</label>
        <RegionSelector
          name='state'
          countryFieldName='country'
          register={register}
          state={state}
          stateSetter={setState}
        />
        <div className='invalid-feedback'>
          {formState.errors.state?.message}
        </div>
      </div>
      <div className='form-element'>
        <label>Postal Code</label>
        <RecoilInput
          type='text'
          name='postalCode'
          value={state.postalCode}
          className={`form-control ${
                        formState.errors.postalCode ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Country</label>
        <CountrySelector
          name='country'
          register={register}
          regionFieldName='state'
          state={state}
          stateSetter={setState}
          setValue={setValue}
          disabled={readOnly}
        />
        <div className='invalid-feedback'>
          {formState.errors.country?.message}
        </div>
      </div>
    </>
  )
}

export { Address }
