import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { stepsAtom, personalInfoAtom, job as jobAtom, loadingState } from '../../../_state'
import {
  NameAndDobInfo,
  AddressInfo,
  ContactInfo,
  FormTimeline,
  SSNInfo,
  DriverLicenseInfo,
  AliasInfo
} from '../shared/steps'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useFakeData, useReloadJob } from '../../../_helpers'
import { useTasksActions } from '../../../_actions'

const PersonalInformation = () => {
  const [personalInfo, setPersonalInfo] = useRecoilState(personalInfoAtom)
  const [loading, setLoading] = useRecoilState(loadingState)
  const [steps, setSteps] = useRecoilState(stepsAtom)
  const { id } = useParams()
  const sectionHeading = 'Personal Information'
  const { setPersonalInfoData } = useFakeData()
  const { backToJob } = useReloadJob()
  const job = useRecoilValue(jobAtom)
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  const resetPersonalInformation = useResetRecoilState(personalInfoAtom)

  useEffect(() => {
    setLoading(true)
    setSteps(1)
    taskActions.get(id)
      .then((ret) => {
        console.log(ret)
        if (ret.data !== null) {
          setPersonalInfo(ret.data)
        } else {
          resetPersonalInformation()
        }
        setLoading(false)
      })
  }, [])

  const _save = (id, stepName, _data) => new Promise((resolve) => {
    setLoading(true)
    const submission = {
      data: {}
    }
    submission.data = _data
    /* if (stepName === 'DriverLicenseInfo') {
      submission.data.driverLicense = _data
    } else {
      submission.data = _data
    } */

    console.log(submission)
    taskActions.update(id, submission)
      .then((av) => {
        setLoading(false)
        resolve(av)
      })
  })

  const updatePage = (page) => {
    setSteps(page)
  }

  const setPreFillData = () => {
    setPersonalInfoData()
  }

  const flow = [NameAndDobInfo, AddressInfo, ContactInfo, SSNInfo, DriverLicenseInfo, AliasInfo]

  const getStepComponent = (type, step) => {
    const pageNav = { updatePage, setPreFillData, backToJob, totalSteps: flow.length, currentStep: step, id }
    if (step < flow.length) {
      pageNav.next = step + 1
    }
    if (step > 1) {
      pageNav.prev = step - 1
    }

    return React.createElement(type, {
      pageNav,
      state: personalInfo,
      setState: setPersonalInfo,
      sectionHeading,
      job,
      id,
      stepName: type.name,
      save: _save,
      loading,
      readOnly: false
    })
  }

  const getCurrentPage = () => {
    return getStepComponent(flow[steps - 1], steps)
  }

  return (
    <ApplicantWrapper>
      <FormContentWrapper>
        <FormTimeline steps={flow.length} currentStep={steps} updatePage={updatePage} />
        {getCurrentPage()}
      </FormContentWrapper>
    </ApplicantWrapper>
  )
}

export default PersonalInformation
