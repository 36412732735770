import * as React from 'react'
import styled from 'styled-components'
import Table from 'rc-table'

import { ReactComponent as IconStar } from '../../resources/images/icons/star.svg'
import { ReactComponent as IconStarFilled } from '../../resources/images/icons/star-filled.svg'

const HiresTableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;

      tr:hover td {
        background: none;
      }
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;
    }

    .accepted {
      color: #de7e53;
    }

    .table-star {
      padding: 0 0 0 15px;

      svg .st0 {
        fill: #de7e53;
      }

      &:hover {
        cursor: pointer;
      }
      svg {
        width: 20px;
      }
    }

    .hire-conditional span {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #c8d1d3;

      &.green {
        background-color: #7ec749;
      }

      &.gray {
        background-color: #707070;
      }
    }

    .table-buttons {
      display: flex;
    }

    a {
      border: 1px solid #c8d1d3;
      padding: 8px 25px 10px 25px;
      display: inline-flex;
      border-radius: 20px;
      height: 41px;
      min-width: 130px;
      justify-content: center;
      box-sizing: border-box;
      text-decoration: none;
      color: #334856;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &:first-of-type {
        margin-right: 10px;
        padding: 12px 35px;
      }

      &.view {
        background-color: #81bcbf;
        color: #fff;
      }

      &:hover {
        border: 1px solid #334856;
      }
    }
  }
`

interface HiresTableProps {
  data: any
}

export const HiresTable = ({ data }: HiresTableProps) => {
  const columns = [
    {
      title: '',
      dataIndex: 'a',
      key: 'a',
      width: 10,
      className: 'table-star',
      onCell: (record: any, index: any) => ({
        onClick(e: any) {
          // console.log('Click cell', ` row ${index}`, record, e.target)
        }
      }),
      render(rec: any) {
        return (
          <span className="star-wrapper">
            {rec.starred ? <IconStarFilled /> : <IconStar />}
          </span>
        )
      }
    },
    { title: 'Name', dataIndex: 'b', key: 'b' },
    { title: 'Status', dataIndex: 'c', key: 'c' },
    {
      title: 'Conditional',
      dataIndex: 'd',
      key: 'd',
      className: 'hire-conditional',
      render(rec: any) {
        return <span className={rec}></span>
      }
    },
    { title: 'Offer Age', dataIndex: 'e', key: 'e' },
    { title: 'Applied', dataIndex: 'f', key: 'f' },
    { title: 'Updated', dataIndex: 'g', key: 'g' },
    {
      title: '',
      dataIndex: 'h',
      key: 'h',
      className: 'table-buttons',
      render(val: any) {
        return (
          <a
            key="1"
            className={val === 'Accepted' ? 'view' : 'manage'}
            href="/">
            {val === 'Accepted' ? 'View' : 'Manage'}
          </a>
        )
      }
    }
  ]

  return (
    <HiresTableWrapper>
      <Table
        columns={columns}
        data={data}
        rowKey={(record) => record.b}
        rowClassName={(record) => {
          return record.c === 'Accepted' ? `accepted` : ''
        }}
      />
    </HiresTableWrapper>
  )
}
