import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'
import { useCompanyActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'

import { basicButton } from '../../../../_helpers/shared-css'

import styled from 'styled-components'

const StyledTitle = styled.div`
    font-size: 24px;
    color: #E28A57;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 20px;
`

const StyledFormWrapper = styled.div`
    width: 100%;
    box-shadow: 0px 3px 6px #272d3b33;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 40px;
    margin-top: 15px;
`

const StyledInput = styled.div`
  padding: 15px;
`

const StyledLink = styled.div`
  padding-left: 20px;
  button {
    ${basicButton}
  }
`

const ConditionalOfferDaysEdit = () => {
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const [days, setDays] = useState(null)
  const { companyId } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    companyActions.getCompany(companyId).then((companyApi) => {
      setDays(companyApi?.company?.conditional_offer_expiration_days)
      setIsLoading(false)
    })
  }, [])

  const onValueChange = (e) => {
    setDays(parseInt(e.target.value))
  }

  const formSubmit = (e) => {
    e.preventDefault()
    const save = async () => {
      setIsLoading(true)
      await companyActions.updateCompany(companyId, { conditional_offer_expiration_days: days })
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  return (
    <StyledFormWrapper>
      <StyledTitle>Conditional Offer Expiration Days</StyledTitle>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <form onSubmit={formSubmit}>
            <StyledInput>
              <input
                type='text'
                value={days}
                onChange={onValueChange}
              />
            </StyledInput>
            <StyledLink>
              <button
                type='submit'
                disabled={isLoading}
                className='btn btn-primary'
              >
                Save
              </button>
            </StyledLink>
          </form>
          )}
    </StyledFormWrapper>
  )
}

export default ConditionalOfferDaysEdit
