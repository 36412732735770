import { useArrayContext } from '../../../contexts/useArrayContext'
import {
  HookFormArraySelect,
  HookFormArrayRadioGroup,
  HookFormArrayInput
} from '../../shared/HookFormComponents'
import { useFormContext } from 'react-hook-form'

const DegreeOrCertification = () => {
  const { index, arrayName } = useArrayContext()
  const { getValues } = useFormContext()

  const instType = getValues(`${arrayName}.${index}.type`)

  const hsOptions = () => {
    return (
      <>
        <div className='form-element'>
          <HookFormArrayRadioGroup
            label='Did you graduate?'
            fieldName='graduated'
            values={['Yes', 'No']}
          />
        </div>
        <div className='form-element'>
          <HookFormArraySelect
            label='Diploma Received'
            fieldName='degree'
            options={[
              { label: 'High School Diploma', value: 'High School Diploma' },
              { label: 'GED', value: 'GED' }
            ]}
          />
        </div>
      </>
    )
  }

  const cuOptions = () => {
    return (
      <>
        <div className='form-element'>
          <HookFormArrayRadioGroup
            label='Did you graduate?'
            fieldName='graduated'
            values={['Yes', 'No']}
          />
        </div>
        <div className='form-element'>
          <HookFormArrayInput label='Major' fieldName='major' type='text' />
        </div>
        <div className='form-element'>
          <HookFormArrayInput label='Minor' fieldName='minor' type='text' />
        </div>
        <div className='form-element'>
          <HookFormArraySelect
            label='Degree Received'
            fieldName='degree'
            options={[
              { label: 'Associate', value: 'Associate' },
              { label: 'Bachelors', value: 'Bachelors' },
              { label: 'Masters', value: 'Masters' },
              { label: 'Doctoral', value: 'Doctoral' }
            ]}
          />
        </div>
      </>
    )
  }

  const tbOptions = () => {
    return (
      <>
        <div className='form-element'>
          <HookFormArrayRadioGroup
            label='Did you graduate?'
            fieldName='graduated'
            values={['Yes', 'No']}
          />
        </div>
        <div className='form-element'>
          <HookFormArrayInput
            label='Degree Received'
            fieldName='degree'
            type='text'
          />
        </div>
      </>
    )
  }

  switch (instType) {
    case 'HS':
      return hsOptions()
    case 'CU':
      return cuOptions()
    case 'TB':
      return tbOptions()
  }
}

export { DegreeOrCertification }
