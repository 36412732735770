import styled from 'styled-components'

const ApplicantJobTitleRowhWrapper = styled.div`
  font-weight: bold;
  display: flex;

  .job-title {
    display: block;
    color: #707070;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .company {
    display: block;
    color: #baafa3;
    font-size: 18px;
  }

  .toggle {
    margin-left: auto;
  }
`

export const ApplicantJobTitleRow = ({ jobTitle, company }) => {
  return (
    <ApplicantJobTitleRowhWrapper>
      <div>
        <span className='job-title'>{jobTitle}</span>
        <span className='company'>{company}</span>
      </div>
      <div className='toggle'>
        {/* <ToggleSwitch
          value={jobAlertsActive}
          activeText='Job Alerts'
          inactiveText='Job Alerts'
          onToggleFunction={() => {
            setJobAlertsActive(!jobAlertsActive)
          }}
        /> */}
      </div>
    </ApplicantJobTitleRowhWrapper>
  )
}
