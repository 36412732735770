import { useStateContext } from '../../../contexts'
import { RadioGroup } from '../../shared/HookFormComponents'
import { useFormContext, useWatch } from 'react-hook-form'

const GeneralEmployment = (readOnly) => {
  const { register } = useFormContext()
  const { state, setState } = useStateContext()
  const watchExtraSkills = useWatch({
    name: 'extralSkills'
  })

  return (
    <>
      <RadioGroup
        name='eligibleToWorkInUS'
        values={['Yes', 'No']}
        label='Are you eligible to work in the US?'
      />
      <RadioGroup
        name='eighteenOrOlder'
        values={['Yes', 'No']}
        label='Are you 18 years of age or older?'
      />
      <RadioGroup
        name='extralSkills'
        values={['Yes', 'No']}
        label='Do you have any special skills, experience
                            and/or training that would enhance your
                            ability to perform the position applied for?'
      />
      {watchExtraSkills === 'Yes' && (
        <div className='form-element'>
          <label>Please provide details...</label>
          <textarea
            {...register('extraSkillsDetail')}
            onChange={(e) => {
              setState({
                ...state,
                extraSkillsDetail: e.target.value
              })
            }}
          />
        </div>
      )}
      <RadioGroup
        name='ableToPerformWithoutAccommodation'
        values={['Yes', 'No']}
        label='Are you able to perform the essential functions of the job for which you are applying with or without a reasonable accommodation?'
      />
    </>
  )
}

export { GeneralEmployment }
