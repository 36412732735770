import { useEffect, useRef, useState } from 'react'
import { useGoogleMapsHelper } from '../../../_helpers'
import styled from 'styled-components'
import { PageLoaderRef } from '../../../_helpers/page-loader-ref'
import { useQuestActions } from '../../../_actions'

const MapWrapper = styled.div`
  width: 960px;
  height: 480px;
  margin: 40px;
  div.loader {
    width: 100px;
    height: 100px;
  }
  div.info {
    height: 100%;
    float: left;
    vertical-align: center;
  }
  div.infoItem {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #707070;
    text-align: left;
    font-weight: bold;
    margin-bottom: 7px;
  }
  div.map {
    width: 50%;
    height: 100%;
    display: none;
    margin-left: 50%;
  }
  button {
    border: none;
    font-family: 'Montserrat';
    font-size: 12px;
    background-color: #81bcbf;
    color: #fff;
    font-weight: bold;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 40px;

    &:hover {
      cursor: pointer;
      background-color: #73a9ac;
    }

    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }
`
const MapComponent = ({ job, address, location, setQuestForm }) => {
  const ref = useRef()
  const loaderRef = useRef()
  const googleMapsHelper = useGoogleMapsHelper()
  const [loading, setLoading] = useState(true)
  const [geoInfo, setGeoInfo] = useState(null)
  const [siteCode, setSiteCode] = useState(null)
  const questActions = useQuestActions(localStorage.getItem('access_token'))
  const center = { lat: location.Latitude, lng: location.Longitude }
  console.log(address)
  useEffect(() => {
    if (loading) {
      ref.current.style.display = 'none'
      loaderRef.current.style.display = 'block'
    } else {
      ref.current.style.display = 'block'
      loaderRef.current.style.display = 'none'
    }
  }, [loading])
  useEffect(() => {
    setLoading(true)
    const markers = []
    googleMapsHelper.init().then((apis) => {
      console.log(apis)
      console.log(center)
      const mapOptions = {
        mapTypeId: apis.maps.MapTypeId.ROADMAP,
        center
      }
      const geocoder = new apis.geocoding.Geocoder()
      const bounds = new apis.core.LatLngBounds()
      geocoder.geocode({ address })
        .then((response) => {
          console.log(response)
          const map = new apis.maps.Map(ref.current, mapOptions)

          markers.push(new apis.marker.Marker({
            position: response.results[0].geometry.location,
            map
          }))
          markers.push(new apis.marker.Marker({
            position: center,
            map
          }))
          markers.forEach((marker) => {
            bounds.extend(marker.getPosition())
          })
          map.fitBounds(bounds, 100)
          map.setCenter(bounds.getCenter())
          console.log('Finished Geocoding...')
          console.log(loaderRef.current)
          setLoading(false)
        })
    })
  }, [])

  const getQRCode = (siteId) => {
    questActions.request_form(job.id, siteId)
      .then((c) => {
        setSiteCode(c.Data.AttachmentEncodedString)
        // setSiteCode(`data:image/png;base64,${c.Data.AttachmentEncodedString}`)

        const bytes = atob(c.Data.AttachmentEncodedString)
        const array = new Uint8Array(new ArrayBuffer(bytes.length))
        for (let i = 0; i < bytes.length; i++) {
          array[i] = bytes.charCodeAt(i)
        }
        const blob = new Blob(array, { type: 'application/pdf' })
        // const file = new File(blob, 'quest.pdf', { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        setSiteCode(c.Data.AttachmentEncodedString)
        setQuestForm(c.Data.AttachmentEncodedString)
        console.log(blob)
      })
  }
  return (
    <>
      <div style={{ width: '100%' }}>
        <MapWrapper>
          <PageLoaderRef ref={loaderRef} />
          <div className='info'>
            <div className='infoItem'>{location.AddressName}</div>
            <div className='infoItem'>{location.Address1}</div>
            <div className='infoItem'>{location.Address2}</div>
            <div className='infoItem'>{location.City}, {location.State} {location.Zip}</div>
            <div className='infoItem'>Approximately {Math.round(location.Distance)} miles</div>
            <div><button onClick={() => getQRCode(location.IDQuestCollectionSite)}>Select This Site</button></div>
            {/* <div>{siteCode && <img src={`data:image/png;base64,${siteCode}`} />}</div> */}
            {/* <div>{siteCode && <img src={`data:image/png;base64,${siteCode}`} />}</div> */}
          </div>
          <div className='map' ref={ref} id='map' />
        </MapWrapper>
        <div>{siteCode && <iframe src={`data:application/pdf;base64,${siteCode}`} />}</div>
      </div>
    </>
  )
}

export { MapComponent }
