import * as Yup from 'yup'
import React from 'react'
import { Name, DateOfBirth } from '../index'
import { Step } from './Step'

const NameAndDobInfo = (props) => {
  // form validation rules

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    dateOfBirth: Yup.string().required('Date of Birth is required')
  })

  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>Your Name and Date of Birth</h3>
        <div style={{ paddingBottom: 20 }}>
          Use the fields below to enter your legal first name and last name.
          This will be utilized throughout the hiring process.
        </div>
        <Name readOnly={props.readOnly} />
        <DateOfBirth readOnly={props.readOnly} />
      </Step>
    </>
  )
}

NameAndDobInfo.componentName = 'NameAndDobInfo'
export { NameAndDobInfo }
