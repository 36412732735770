import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useArrayContext } from '../../../contexts/useArrayContext'
import { useStateContext } from '../../../contexts/useStateContext'

const HookFormArrayRadioGroup = ({
  label,
  fieldName,
  values,
  defaultValue
}) => {
  const { arrayName, index } = useArrayContext()
  const { control, formState } = useFormContext()
  const { errors } = formState
  const { state, setState } = useStateContext()

  /* useEffect(() => {
        let value = defaultValue ? defaultValue : values[0]
        setValue(`${arrayName}.${index}.${fieldName}`, value)
        let newList = [...state[arrayName]].map((item, index) => {
            if (index === 0) return {...item, [fieldName]: value}
            else return item

        })
        setState({...state, [arrayName]: newList})
    },[]); */

  const onChange = (e, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <div className='input-group-container'>
          {React.Children.toArray(
            values.map((value) => {
              return (
                <>
                  <div className='input-group'>
                    <div className='form-element'>
                      <Controller
                        control={control}
                        name={`${arrayName}.${index}.${fieldName}`}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              checked={field.value === value}
                              type='radio'
                              value={value}
                              onChange={(e) =>
                                field.onChange(onChange(e, index))}
                            />
                            <label>{value}</label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </>
              )
            })
          )}
        </div>
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayRadioGroup }
