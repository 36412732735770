import { RecoilInput, RegionSelector } from '../../shared/HookFormComponents'
import { useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts'

const DriverLicense = ({ readOnly }) => {
  const { state, setState } = useStateContext()
  const { formState, register } = useFormContext()
  return (
    <>
      <div className='form-element'>
        <label>First Name</label>
        <RecoilInput
          type='text'
          name='dlFirstName'
          className={`form-control ${
              formState.errors.dlFirstName ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Middle Name</label>
        <RecoilInput
          type='text'
          name='dlMiddleName'
          className={`form-control ${
              formState.errors.dlMiddleName ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Last Name</label>
        <RecoilInput
          type='text'
          name='dlLastName'
          className={`form-control ${
              formState.errors.dlLastName ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>License Number</label>
        <RecoilInput
          type='text'
          name='dlLicenseNumber'
          className={`form-control ${
              formState.errors.dlLicenseNumber ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Date of Birth</label>
        <RecoilInput
          id='dateOfBirth'
          subType='date'
          type='text'
          name='dlDateOfBirth'
          className={`form-control ${
              formState.errors.dlDateOfBirth ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Street Address 1</label>
        <RecoilInput
          type='text'
          name='dlStreetLine1'
          className={`form-control ${
              formState.errors.dlStreetLine1 ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Street Address 2 (ex. Apt. Suite. )</label>
        <RecoilInput
          type='text'
          name='dlStreetLine2'
          className={`form-control ${
              formState.errors.dlStreetLine2 ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>City</label>
        <RecoilInput
          type='text'
          name='dlCity'
          className={`form-control ${
              formState.errors.dlCity ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>State</label>
        <RegionSelector
          name='dlState'
          countryFieldName='country'
          register={register}
          state={state}
          stateSetter={setState}
        />
      </div>
      <div className='form-element'>
        <label>Postal Code</label>
        <RecoilInput
          type='text'
          name='dlPostalCode'
          className={`form-control ${
              formState.errors.postalCode ? 'is-invalid' : ''
            }`}
        />
      </div>
      <div className='form-element'>
        <label>Expiration Date</label>
        <RecoilInput
          id='dlExpirationDate'
          subType='date'
          type='text'
          name='dlExpirationDate'
          className={`form-control ${
              formState.errors.dlExpirationDate
                ? 'is-invalid'
                : ''
            }`}
        />
      </div>
    </>
  )
}

export { DriverLicense }
