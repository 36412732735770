import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { history } from '../../_helpers'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useCompanyActions, useUserActions, useLocationActions } from '../../_actions'
import { debounce } from 'lodash'

import states from '../../_helpers/states'

import AdminWrapper from '../shared/AdminWrapper'
import { BarLoader } from 'react-spinners'

import { FormContentWrapper, TabWrapper, TableSearchWrapper } from '../shared/AdminStyledComponets'
import { UploadControl } from '../../components/shared/UploadControl'

import { AdminAddUser } from '../../components/Admin/AdminAddUser'
import { AdminEditUser } from '../../components/Admin/AdminEditUser'
import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'

import { AdminAddLocation } from '../../components/Admin/AdminAddLocation'
import { AdminEditLocation } from '../../components/Admin/AdminEditLocation'

import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

import BonusList from './settings/bonuses/list'
import DepartmentsList from './settings/departments/list'
import AdminProductsList from './settings/products/list'
import AdverseActionAssessList from './settings/adverse_action_assess/list'
import AdverseActionDaysEdit from './settings/adverse_action_days/edit'
import queryString from 'query-string'
import ConditionalOfferDaysEdit from './settings/conditional_offer_expiration/edit'

const urlParams = queryString.parse(window.location.search)

const CompaniesEdit = () => {
  const api_url = process.env.REACT_APP_API_URL || 'http://localhost:3000'
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const locationActions = useLocationActions(localStorage.getItem('access_token'))

  const { companyId } = useParams()

  const [apiCompany, setApiCompany] = useState({})

  const [state, setState] = useState()

  const [isLoading, setIsLoading] = useState(true)

  const [defaultAssignedUsers, setDefaultAssignedUsers] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])

  const [locationSearchText, setLocationSearchText] = useState('')
  const [locations, setLocations] = useState([])

  const [tabIndex, setTabIndex] = useState((urlParams.tabId ? parseInt(urlParams.tabId) : 0))

  const fetchUsers = async () => {
    setSearchUserIsLoading(true)
    const users = await companyActions.getUsers(companyId)
    setDefaultAssignedUsers(users.users)
    setSearchUserIsLoading(false)

    if (userSearchText) {
      searchUsers(userSearchText)
    } else {
      setAssignedUsers(users.users)
    }
  }

  const fetchLocations = async () => {
    const apilocations = await locationActions.getLocationList(companyId)
    setLocations(apilocations)
  }

  const fetchCompany = () => {
    companyActions.getCompany(companyId).then(async (apiData) => {
      setApiCompany(apiData.company)
      setState(
        states.find((s) => s.value === apiData.company.state) || state[0]
      )
      const apiusers = await companyActions.getUsers(companyId)
      setAssignedUsers(apiusers.users)
      setDefaultAssignedUsers(apiusers.users)

      await fetchLocations()

      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchCompany()
    fetchLocations()
  }, [])

  // form validation rules
  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required('Display Name is required'),
    ein_number: Yup.string().required('EIN Number is required'),
    address: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    city: Yup.string().matches(/^[aA-zZ\s]+$/).required('City is required'),
    postal_code: Yup.string().length(5).matches(/^[0-9]{5}/).required('Postal Code is required'),
    contact_name: Yup.string().required('Contact Name is required'),
    contact_number: Yup.string().required('Contact Number is required'),
    erx_id: Yup.string()
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const saveCompany = async () => {
      setIsLoading(true)
      await companyActions.updateCompany(companyId, {
        ...data,
        state: state.value
      })
      setIsLoading(false)
      history.push('/admin/companies')
    }
    saveCompany()
  }

  const uploadImage = (img, type) => {
    const formData = new FormData()
    formData.append('company_id', companyId)
    formData.append('upload_type', type)
    formData.append('upload', img)
    fetch(api_url + `/companies/${companyId}/${type}`, {
      method: 'POST',
      body: formData,
      headers: { 'X-AUTH0-TOKEN': `${localStorage.getItem('access_token')}` }
    })
      .then(() => {
        fetchCompany()
      })
      .catch(error => console.log(error))
  }

  const [userSearchText, setUserSearchText] = useState('')
  const [searchUserIsLoading, setSearchUserIsLoading] = useState(false)

  function clearSearch () {
    setUserSearchText('')
    setAssignedUsers(defaultAssignedUsers)
  }

  const searchUsers = useCallback(
    debounce((val) => {
      setSearchUserIsLoading(true)
      userActions.searchUsersByName(val).then((searchApiUsers) => {
        setAssignedUsers(searchApiUsers.users)
        setSearchUserIsLoading(false)
      })
    })
  )

  const handleChange = (e) => {
    setUserSearchText(e.target.value)
    if (e.target.value.length > 2) {
      searchUsers(e.target.value)
    }
    if (e.target.value === '') {
      clearSearch()
    }
  }

  const teamCompanyAdminsCols = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    // { title: 'Title', dataIndex: 'title', key: 'title' },
    // {
    //   title: 'Primary',
    //   dataIndex: 'primary',
    //   key: 'primary',
    //   className: 'conditional',
    //   render(rec) {
    //     return <span className={rec ? 'green' : ''} />
    //   }
    // },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            {row.edit
              ? <>
                <AdminEditUser companyId={companyId} userId={row.id} saveCB={fetchUsers} />
                <button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    const removeUser = async () => {
                      setSearchUserIsLoading(true)
                      await companyActions.removeUser(companyId, row.id)
                      const users = await companyActions.getUsers(companyId)
                      setAssignedUsers(users.users)
                      setDefaultAssignedUsers(users.users)
                      setSearchUserIsLoading(false)
                    }
                    removeUser()
                  }}
                >
                  Remove
                </button>
              </>
              : <button
                  onClick={() => {
                    const addUser = async () => {
                      setSearchUserIsLoading(true)
                      await companyActions.addUser(companyId, row.id)
                      const users = await companyActions.getUsers(companyId)
                      setAssignedUsers(users.users)
                      setDefaultAssignedUsers(users.users)
                      setSearchUserIsLoading(false)
                    }
                    addUser()
                  }}
                >
                Add
              </button>}
          </div>
        )
      }
    }
  ]

  const teamCompanyRecruitersCols = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Role', dataIndex: 'role', key: 'role' },
    // { title: 'Permissions', dataIndex: 'permissions', key: 'permissions' },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            {row.edit
              ? <>
                <AdminEditUser companyId={companyId} userId={row.id} saveCB={fetchUsers} />
                <button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    const removeUser = async () => {
                      setSearchUserIsLoading(true)
                      await companyActions.removeUser(companyId, row.id)
                      const users = await companyActions.getUsers(companyId)
                      setAssignedUsers(users.users)
                      setDefaultAssignedUsers(users.users)
                      setSearchUserIsLoading(false)
                    }
                    removeUser()
                  }}
                >
                  Remove
                </button>
              </>
              : <button
                  onClick={() => {
                    const addUser = async () => {
                      setSearchUserIsLoading(true)
                      await companyActions.addRecruiter(companyId, row.id)
                      const users = await companyActions.getUsers(companyId)
                      setAssignedUsers(users.users)
                      setDefaultAssignedUsers(users.users)
                      setSearchUserIsLoading(false)
                    }
                    addUser()
                  }}
                >
                Add
              </button>}
          </div>
        )
      }
    }
  ]

  const locationCols = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            <AdminEditLocation companyId={companyId} locationId={row.id} saveCB={fetchLocations} />
            <button
              style={{ marginLeft: 10, background: '#81BCBF', border: 'none', color: '#fff' }}
              onClick={() => {
                history.push(`/admin/locations/${row.id}/dashboard`)
              }}
            >
              Manage
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <AdminWrapper>
      <h1>Company Settings</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <TabWrapper>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab key='tab_1'>Company Information</Tab>
                <Tab key='tab_2'>Settings</Tab>
                <Tab key='tab_3'>Locations</Tab>
                <Tab key='tab_4'>Branding</Tab>
                <Tab key='tab_5'>Team</Tab>
              </TabList>
              <TabPanel key='tabPanel_1'>
                <FormContentWrapper style={{ maxWidth: '800px' }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>Company Information</h2>
                    <div style={{ display: 'flex', gap: '40px' }}>
                      <div className='form-element' style={{ width: '60%' }}>
                        <label>Company Display Name</label>
                        <input
                          defaultValue={apiCompany?.display_name}
                          name='display_name'
                          type='text'
                          {...register('display_name')}
                          className={`form-control ${errors.display_name ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.display_name?.message}
                        </div>
                      </div>
                      <div className='form-element' style={{ width: '20%' }}>
                        <label>EIN Number</label>
                        <input
                          defaultValue={apiCompany?.ein_number}
                          name='ein_number'
                          type='text'
                          {...register('ein_number')}
                          className={`form-control ${errors.ein_number ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>{errors.ein_number?.message}</div>
                      </div>
                      <div className='form-element' style={{ width: '20%' }}>
                        <label>ERX ID</label>
                        <input
                          defaultValue={apiCompany?.erx_id}
                          name='erx_id'
                          type='text'
                          {...register('erx_id')}
                          className={`form-control ${errors.erx_id ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.erx_id?.message}
                        </div>
                      </div>
                    </div>
                    <h2>Company Address</h2>
                    <div className='form-element'>
                      <label>Company Address</label>
                      <input
                        defaultValue={apiCompany?.address}
                        name='address'
                        type='text'
                        {...register('address')}
                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.address?.message}</div>
                    </div>
                    <div className='form-element'>
                      <label>Company Address 2 (Suite No.)</label>
                      <input
                        defaultValue={apiCompany?.address_2}
                        name='address_2'
                        type='text'
                        {...register('address_2')}
                        className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.address_2?.message}</div>
                    </div>
                    <div className='form-element'>
                      <label>City</label>
                      <input
                        defaultValue={apiCompany?.city}
                        name='city'
                        type='text'
                        {...register('city')}
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.city?.message}</div>
                    </div>
                    <div style={{ display: 'flex', gap: '40px' }}>
                      <div className='form-element'>
                        <label>State</label>
                        <Select
                          name='state'
                          required
                          value={state}
                          options={states}
                          isLoading={isLoading}
                          isDisabled={isLoading}
                          onChange={(newState) => setState(newState)}
                        />
                        <div className='invalid-feedback'>
                          {state === '' || state === undefined || state === null
                            ? 'State is required'
                            : ''}
                        </div>
                      </div>
                      <div className='form-element'>
                        <label>Postal Code</label>
                        <input
                          defaultValue={apiCompany?.postal_code}
                          name='postal_code'
                          type='text'
                          {...register('postal_code')}
                          className={`form-control ${errors.postal_code ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.postal_code?.message}
                        </div>
                      </div>
                    </div>
                    <h2>Contact Information</h2>
                    <div className='form-element'>
                      <label>Contact Name</label>
                      <input
                        defaultValue={apiCompany?.contact_name}
                        name='contact_name'
                        type='text'
                        {...register('contact_name')}
                        className={`form-control ${errors.contact_name ? 'is-invalid' : ''
                          }`}
                      />
                      <div className='invalid-feedback'>
                        {errors.contact_name?.message}
                      </div>
                    </div>
                    <div className='form-element'>
                      <label>Contact Phone Number</label>
                      <input
                        defaultValue={apiCompany?.contact_number}
                        name='contact_number'
                        type='text'
                        {...register('contact_number')}
                        className={`form-control ${errors.contact_number ? 'is-invalid' : ''
                          }`}
                      />
                      <div className='invalid-feedback'>
                        {errors.contact_number?.message}
                      </div>
                    </div>
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='btn btn-primary'
                    >
                      {isSubmitting && (
                        <span className='spinner-border spinner-border-sm mr-1' />
                      )}
                      Save
                    </button>
                    <button
                      onClick={() => history.push('/admin/companies')}
                      className='btn btn-link whiteButton'
                    >
                      Cancel
                    </button>
                  </form>
                </FormContentWrapper>
              </TabPanel>
              <TabPanel key='tabPanel_2'>
                <BonusList />
                <DepartmentsList />
                <AdminProductsList />
                <AdverseActionAssessList />
                <AdverseActionDaysEdit />
                <ConditionalOfferDaysEdit />
              </TabPanel>
              <TabPanel key='tabPanel_3'>
                <TableSearchWrapper>
                  {locationSearchText === ''
                    ? (
                      <IconSearch />
                      )
                    : (
                      <IconClose
                        onClick={() => {
                          clearSearch()
                        }}
                      />
                      )}
                  <input
                    htmlFor='search'
                    type='text'
                    name='Locations'
                    value={locationSearchText}
                    onChange={(e) => { setLocationSearchText(e.target.value) }}
                  />
                  <AdminAddLocation companyId={companyId} saveCB={fetchLocations} />
                </TableSearchWrapper>
                <ApplicantGenericTable
                  columns={locationCols}
                  data={locations.filter((l) => { return locationSearchText === '' ? true : l.title.toLowerCase().includes(locationSearchText.toLowerCase()) }).map((l) => { return { id: l.id, name: l.title, city: l.city, state: l.state } })}
                  tableTitle='Locations'
                  rowKey='id'
                  loading={false}
                />
              </TabPanel>
              <TabPanel key='tabPanel_4'>
                <h3>Employifi Custom Branding</h3>
                <UploadControl
                  accept='image/*'
                  multiple={false}
                  labelText='company logo | for dark backgrounds'
                  buttonText='Upload'
                  handleUploadCB={(img) => { uploadImage(img, 'dark_background') }}
                  hideTextbox
                  displayChipURL={apiCompany?.dark_background_url}
                  id='dark_background'
                />
                <UploadControl
                  accept='image/*'
                  multiple={false}
                  labelText='company logo | for light backgrounds'
                  buttonText='Upload'
                  handleUploadCB={(img) => { uploadImage(img, 'light_background') }}
                  hideTextbox
                  displayChipURL={apiCompany?.light_background_url}
                  id='light_background'
                />
                <h3>Branding for Forms & Documents</h3>
                <UploadControl
                  accept='image/*'
                  multiple={false}
                  labelText='company logo'
                  buttonText='Upload'
                  handleUploadCB={(img) => { uploadImage(img, 'company_logo') }}
                  hideTextbox
                  displayChipURL={apiCompany?.company_logo_url}
                  id='company_logo'
                />
              </TabPanel>
              <TabPanel key='tabPanel_5'>
                <TableSearchWrapper>
                  {userSearchText === ''
                    ? (
                      <IconSearch />
                      )
                    : (
                      <IconClose
                        onClick={() => {
                          clearSearch()
                        }}
                      />
                      )}
                  <input
                    htmlFor='search'
                    type='text'
                    name='Companies'
                    value={userSearchText}
                    onChange={handleChange}
                  />
                  <AdminAddUser companyId={companyId} saveCB={fetchUsers} />
                </TableSearchWrapper>
                <ApplicantGenericTable
                  columns={teamCompanyAdminsCols}
                  data={userSearchText ? assignedUsers.map((u) => { return { title: '', name: `${u.first_name || ''} ${u.last_name || ''}`, id: u.id, edit: u.roles.map((role) => role.description).includes('Employifi Company Admin') } }) : assignedUsers.filter((u) => u.roles.map((r) => r.description).includes('Employifi Company Admin')).map((u) => { return { title: '', name: `${u.first_name || ''} ${u.last_name || ''}`, id: u.id, edit: true } })}
                  tableTitle='Company Admins'
                  rowKey='id'
                  loading={searchUserIsLoading}
                />
                <ApplicantGenericTable
                  columns={teamCompanyRecruitersCols}
                  data={userSearchText ? assignedUsers.map((u) => { return { name: `${u.first_name || ''} ${u.last_name || ''}`, role: u.roles.map((role) => role.description).join(', '), id: u.id, edit: u.roles.map((role) => role.description).includes('Company Recruiter') } }) : assignedUsers.filter((u) => u.roles.map((r) => r.description).includes('Company Recruiter')).map((u) => { return { name: `${u.first_name || ''} ${u.last_name || ''}`, role: u.roles.map((role) => role.description).join(', '), id: u.id, edit: true } })}
                  tableTitle='Company Recruiters'
                  rowKey='id'
                  loading={searchUserIsLoading}
                />
              </TabPanel>
            </Tabs>
          </TabWrapper>
          )}
    </AdminWrapper>
  )
}

export default CompaniesEdit
