import { Controller, useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { useStateContext, useArrayContext } from '../../../contexts'

const HookFormArrayToggleSwitch = ({ label, fieldName }) => {
  const { control, setValue, formState } = useFormContext()
  const { state, setState } = useStateContext()
  const { index, arrayName } = useArrayContext()
  const { errors } = formState

  useEffect(() => {
    if (!state[arrayName][index][fieldName]) { setValue(`${arrayName}.${index}.${fieldName}`, false) }
  }, [])

  const onChange = (e, id) => {
    console.log(e.target.checked)
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.checked }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
    return e.target.checked
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue={false}
          render={({ field }) => (
            <label className='switch'>
              <input
                {...field}
                className='input-inline'
                type='checkbox'
                checked={field.value}
                onChange={(e) => field.onChange(onChange(e, index))}
              />
              <span className='slider round' />
            </label>
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayToggleSwitch }
