import './email.css'
import logo from './employifi-logo.svg'

export const InviteEmail = () => {
  return (
    <>
      <div className='email-wrapper'>
        <div className='email-content invite-content'>
          <div className='email-body invite-body'>
            <div className='top-logo'><img src={logo} alt='Employifi Logo' /></div>
            <div className='email-body-text'>
              <p>You have been invited to Employifi through [Company Name]. Employifi provides applicants like yourself with a platform to manage every aspect of the hiring process. To access your account, click the link below to get started.</p>
              <a className='button' href='#'>Login</a>
              <br />
              <p>Good luck & we’re glad you are here. </p>
              <br />
              <p>All the best,<br />The Employifi Team</p>
            </div>
          </div>
          <div className='email-footer'>
            <div className='footer-left'>
              <span>compliance & hiring powered by</span>
              <img src={logo} alt='Employifi Logo' />
            </div>
            <div className='footer-right'>
              <p>This email was intended for Joshua Forstot (ECD & Founder of dough. Creative + Tech | Problem Solver | Speaker | Advisor). </p>
              <p>Company and Employifi legal copy can go here. I suggest you try it again, Luke. This time, let go your conscious self and act on instinct. Don’t be too proud of this technological terror you’ve constructed. The ability to destroy a planet is insignificant next to the power of the Force.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
