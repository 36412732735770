// import * as React from "react";
import styled from 'styled-components'

// import {JobDetail} from '../shared/JobDetail'

const ApplicantDetailTimelineWrapper = styled.div`
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #707070;
  display: flex;
  margin-top: 30px;
`

interface Props {
  complete: boolean
}
const TimelineItem = styled.div<Props>`
  display: inline-flex;
  flex: 1;
  text-align: center;
  position: relative;
  flex-direction: column;
  align-items: center;

  .title {
    width: 80px;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  &:last-of-type {
    .status {
      border-radius: 0;
      transform: rotate(45deg);
    }
  }

  &:nth-last-child(2) {
    .status {
      &:after {
        content: '';
        width: calc(100% - 40px);
      }
    }
  }

  .status {
    width: 20px;
    height: 20px;
    border: 2px solid ${(props) => (props.complete ? '#81BCBF' : '#B9AFA4')};
    background-color: ${(props) =>
      props.complete ? '#81BCBF' : 'transparent'};
    border-radius: 50%;
    margin-bottom: 10px;

    &:after {
      content: '';
      width: calc(100% - 36px);
      height: 2px;
      background-color: ${(props) => (props.complete ? '#707070' : '#B9AFA4')};
      opacity: ${(props) => (props.complete ? '1' : '0.4')};
      display: block;
      position: absolute;
      top: 10px;
      left: calc(50% + 18px);
    }
  }
`

interface TimelineElement {
  title: string
  complete: boolean
}

interface ApplicantDetailTimelineProps {
  timeline: TimelineElement[]
}

export const ApplicantDetailTimeline = ({
  timeline
}: ApplicantDetailTimelineProps) => {
  return (
    <ApplicantDetailTimelineWrapper>
      {timeline.map((item, index) => (
        <TimelineItem complete={item.complete} key={index}>
          <span className="status" />
          <span className="title">{item.title}</span>
        </TimelineItem>
      ))}
    </ApplicantDetailTimelineWrapper>
  )
}
