import { ReactComponent as IconDashboard } from '../resources/images/icons/dashboard-icon.svg'
import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconSettings } from '../resources/images/icons/settings-icon.svg'
import { ReactComponent as IconGroup } from '../resources/images/icons/group.svg'

const sidebarButtonsApplicant = [
  { icon: IconDashboard, link: '/dashboard', name: 'dashboard' }
]

export { sidebarButtonsApplicant }
