import { useState } from 'react'
import styled from 'styled-components'
import { ToggleSwitch } from '../shared/ToggleSwitch'

const ProcessAndVerificationWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  min-height: 20px;
  box-sizing: border-box;
  border: 1px solid #f5f5f5;
  display: flex;
  font-size: 16px;
  margin-bottom: 25px;

  .toggle-label {
    color: #272d3b;
    display: inline-block;
    margin-top: 5px;
  }
`

const ActiveToggle = styled.div`
  width: 50%;

  .info {
    display: block;
    color: #707070;
    margin-top: 20px;
  }
`

// const ApplicationDropdown = styled.div`
//   padding-left: 80px;

//   span {
//     display: block;
//     font-size: 10px;
//     font-weight: bold;
//     text-transform: uppercase;
//     margin-bottom: 8px;
//   }

//   .select__control {
//     width: 400px;
//   }
// `

const ToggleWrapper = styled.div`
  display: inline-block;
  margin: 0 20px 0 0;
`

export const AdminApplicationProcessAndVerification = ({
  _applications,
  setSelected,
  setToggle
}) => {
  const [requireNewApplication, setRequireNewApplication] = useState(true)

  const toggleActiveButton = (val) => {
    setRequireNewApplication(val)
    setToggle(val)
  }

  const _handleSelectChange = (val) => {
    setSelected(val)
  }

  return (
    <ProcessAndVerificationWrapper>
      <ActiveToggle>
        <>
          <ToggleWrapper>
            <ToggleSwitch
              value={requireNewApplication}
              activeText='Require NEW Application'
              inactiveText='Require NEW Application'
              onToggleFunction={(value) => {
                toggleActiveButton(!value)
              }}
            />
          </ToggleWrapper>
        </>
        <span className='info'>
          If selected, applicant will be required to start a new application. If
          unselected, the application will be used for verifying previously
          submitted data or complete missing data
        </span>
      </ActiveToggle>
      {/* <ApplicationDropdown>
        <span>Choose Application</span>
        <Select
          className='basic-single'
          classNamePrefix='select'
          defaultValue={{ label: 'Please Select', value: '' }}
          onChange={(val) => {
            handleSelectChange(val)
          }}
          isSearchable={false}
          name='application'
          options={applications}
        />
      </ApplicationDropdown> */}
    </ProcessAndVerificationWrapper>
  )
}
