import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconAward } from '../resources/images/icons/award.svg'
import { ReactComponent as IconNewPerson } from '../resources/images/icons/icon_new_person.svg'
import { ReactComponent as IconMagnifyingGlass } from '../resources/images/icons/magnifying-glass.svg'

import { DashboardTile } from '../components/DashboardTile'
import { AdminApplicantsSearch } from '../components/Admin/AdminApplicantsSearch'
import { AdminQuickAction } from '../components/Admin/AdminQuickAction'
import AdminWrapper from './shared/AdminWrapper'
import ApplicantTable from './shared/ApplicantTable'
import { AdminApplicantsSearchWrapper } from './shared/AdminStyledComponets'

import { useLocationActions } from '../_actions'

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  .tile-controls {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    color: #707070;

    h2 {
      display: inline-block;
      color: #707070;
    }

    span {
      margin-right: 10px;
    }

    span:first-of-type {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .change-view {
    float: right;
    display: inline-block;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
      fill: #f0eeec;
    }

    &.blue {
      svg {
        fill: #81bcbf;
      }
    }
  }
`

const AdminTitle = styled.span`
  margin: 35px 0;
  font-size: 28px;
  font-weight: bold;
  color: #707070;
`

const AdminDashboard = () => {
  const { locationId } = useParams()
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const [apiLocation, setApiLocation] = useState({})
  const [applicantsSearchData, setApplicantsSearchData] = useState([])
  const [dashboardStats, setDashboardStats] = useState({
    in_progress: 0,
    applicants: 0,
    completed: 0
  })
  const [recentLoading, setRecentLoading] = useState(true)

  const dashboardTiles = () => [
    {
      icon: IconFolder,
      number: dashboardStats.in_progress,
      text: 'Jobs In Progrss'
    },
    {
      icon: IconMagnifyingGlass,
      number: dashboardStats.applicants,
      text: 'Total Applicants'
    },
    {
      icon: IconAward,
      number: dashboardStats.completed,
      text: 'Placements This Month'
    }
  ]

  useEffect(() => {
    locationActions.getLocation(locationId).then((apiData) => {
      setApiLocation(apiData.location)
    })
    locationActions.recentApplicants(locationId).then((recentApplicantsApi) => {
      setApplicantsSearchData(recentApplicantsApi.job_applicants)
      setRecentLoading(false)
    })
    locationActions.dashboardStats(locationId).then((statsApi) => {
      setDashboardStats(statsApi.stats)
    })
  }, [])

  return (
    <AdminWrapper>
      <h1 style={{ color: '#81BCBF' }}>{apiLocation.title} Dashboard</h1>
      <TilesWrapper>
        {dashboardTiles().map((tile, i) => (
          <DashboardTile key={i} tile={tile} />
        ))}
        <AdminQuickAction
          color='#E5955A'
          link={`/admin/locations/${locationId}/applicants/new`}
          icon={<IconNewPerson />}
          text='Create Applicant'
        />
        <AdminQuickAction
          color='#81BCBF'
          link={`/admin/locations/${locationId}/jobs/new`}
          icon={<IconFolder />}
          text='Create Job'
        />
      </TilesWrapper>
      <AdminTitle>Find an Applicant</AdminTitle>
      <AdminApplicantsSearch />
      <AdminApplicantsSearchWrapper>
        <AdminTitle>Recent Applicants</AdminTitle>
        <ApplicantTable loading={recentLoading} data={applicantsSearchData} />
      </AdminApplicantsSearchWrapper>
    </AdminWrapper>
  )
}

export default AdminDashboard
