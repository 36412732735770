import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

const usersAtom = atom({
  key: 'users',
  default: null
})

const userAtom = atom({
  key: 'user',
  default: {
    authenticated: false
  },
  effects: [persistAtom]
})

export { usersAtom, userAtom }
