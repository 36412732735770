import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userAtom } from '../_state'
import styled from 'styled-components'
import { history } from '../_helpers'
import { useAlertActions } from '../_actions'
import { formCss } from '../_helpers/shared-css'

const FormWrapper = styled.div`
  ${formCss}
`

const Auth0SignInWidget = ({ auth0 }) => {
  const alertActions = useAlertActions()
  const auth = new auth0()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  // eslint-disable-next-line
  const [_, setUser] = useRecoilState(userAtom)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown)
  }

  const signIn = async (e) => {
    e.preventDefault()

    auth
      .signin(email, password)
      .then((userInfo) => {
        const fetchUserInfo = async () => {
          const url = `${process.env.REACT_APP_API_URL}/users/info`
          const requestOptions = {
            method: 'GET',
            headers: {
              'X-AUTH0-TOKEN': `${localStorage.getItem('access_token')}`
            }
          }
          const apiResponse = await fetch(url, requestOptions)
          const apiUser = await apiResponse.json()
          const roles = apiUser?.user?.roles?.map((r) => r.name) || []

          // Have to make sure this is correct. In this order the phone number is populated
          // setUser({ authenticated: true, ...userInfo, ...apiUser?.user })
          // console.log(apiUser?.user)
          // console.log(userInfo)
          setUser({ authenticated: true, ...apiUser?.user, ...userInfo })
          if (roles.includes('Employifi Admin')) {
            history.push('/admin/companies')
          } else if (roles.includes('Company Admin')) {
            history.push('/admin/company/dashboard')
          } else {
            history.push('/dashboard')
          }
        }
        fetchUserInfo()
      })
      .catch((error) => {
        alertActions.error(error.description)
        console.error(error)
      })

    // if (transaction.status === 'SUCCESS') {
    //  // auth0.signInWithRedirect({sessionToken: transaction.sessionToken})
    //   console.warn("WE NEED TO REDIRECT TO THE DASHBOARD");
    // } else {
    //   throw new Error('Could not sign in: ' + transaction.status);
    // }
  }

  return (
    <FormWrapper>
      <form onSubmit={signIn} className='form-wrapper'>
        <div className='form-element'>
          <label>Email Address</label>
          <input
            type='text'
            placeholder='Email'
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className='form-element'>
          <label>Password</label>
          <input
            type={passwordShown ? 'text' : 'password'}
            placeholder='Password'
            value={password}
            onChange={handlePasswordChange}
            required
            autoComplete='off'
          />
          <a className='forget-password' href='/forgot-password'>
            Forget Password?
          </a>
          <span className='toggle-password' onClick={togglePasswordShown} />
        </div>
        <button className='button'>SIGN IN</button>
      </form>
      <div className='dont-have-account'>
        Don't have an account?{' '}
        <Link to='register' className='sign-up'>
          Sign Up
        </Link>
      </div>
    </FormWrapper>
  )
}

export { Auth0SignInWidget }
