import { useAlertActions } from '../_actions'

export { useFetchWrapper }

function useFetchWrapper (token = undefined) {
  const alertActions = useAlertActions()

  return {
    get: request('GET'),
    getSigned,
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    upload: request_upload()
  }
  function request_upload () {
    return (url, data) => {
      const requestOptions = baseRequestOptions('POST', url)
      requestOptions.headers['Content-Disposition'] = `attachment; filename="${data.file.name}"`
      const formData = new FormData()
      formData.append('file', data.file)
      if (Object.keys(data.metadata).length) {
        Object.keys(data.metadata).forEach((key) => { formData.append(key, data.metadata[key]) })
      }
      requestOptions.body = formData

      return fetch(url, requestOptions).then(handleResponse)
    }
  }

  function getSigned () {
    return (url) => {
      return fetch(url, {
        method: 'GET',
        mode: 'cors'
      }).catch((e) => console.log(e))
    }
  }

  function request (method) {
    return (url, body) => {
      const requestOptions = baseRequestOptions(method, url)
      if (body) {
        requestOptions.headers['Content-Type'] = 'application/json'
        requestOptions.headers.Accept = 'application/json'
        requestOptions.headers['Access-Control-Allow-Origin'] = '*'
        requestOptions.body = JSON.stringify(body)
      }
      return fetch(url, requestOptions).then(handleResponse)
    }
  }

  // helper functions

  function baseRequestOptions (method, url) {
    return {
      method,
      headers: authHeader(url)
    }
  }

  function authHeader (url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = !!token
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL)
    if (isLoggedIn && isApiUrl) {
      return { 'X-AUTH0-TOKEN': `${token}` }
    } else {
      return {}
    }
  }

  function handleResponse (response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text)

      if (!response.ok) {
        if ([401, 403].includes(response.status) && token) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('expires_at')
          localStorage.removeItem('recoil-persist')
          window.location.replace('/login')
        }

        let error
        if (![404].includes(response.status)) {
          error =
            (data && data.message) ||
            (data && data.error) ||
            response.statusText
          alertActions.error(error)
        }

        return Promise.reject(error)
      }

      return data
    })
  }
}
