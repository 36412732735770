import styled from 'styled-components'
import Table from 'rc-table'

import { ReactComponent as IconAlert } from '../../resources/images/icons/alert.svg'
import { ReactComponent as IconComment } from '../../resources/images/icons/comment.svg'

const SelectFlagsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  // TODO should probably componentize this

  .details-label {
    font-weight: bold;
  }

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;

      tr th {
        color: #272d3b;
      }
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;

      color: #272d3b;
      svg {
        fill: #272d3b;
      }
    }

    .alert {
      color: #DE7E53;

      td {
        color: #DE7E53;
      }

      svg {
        fill: #DE7E53;
      }
    }

    svg {
      width: 25px;
      fill: #b9afa4;
    }

    .selected,
    .not-selected {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #ccc;

      &:hover {
        cursor: pointer;
      }
    }

    .selected {
      background-color: #81bcbf;
      border: 1px solid #81bcbf;
    }
  }
`

export const SelectFlags = ({ data, selectedFlags }) => {
  const columns = [
    {
      title: 'Select',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render (_id, row) {
        return (
          <>
            <span
              className={selectedFlags.map((f) => f.trim()).includes(row.title.trim()) ? 'selected' : 'not-selected'}
              onClick={() => {
                row.setSelect(selectedFlags, row.title)
              }}
            />
          </>
        )
      }
    },
    { title: 'Date', dataIndex: 'date', key: 'date', width: 130 },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 85,
      className: 'icon',
      render (type) {
        let icon
        if (type) {
          icon = <IconComment />
        } else {
          icon = <IconAlert />
        }
        return (
          <>
            <span>{icon}</span>
          </>
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render (details, row) {
        return (
          <>
            <span key='1' className='details-label'>
              {row.title}
            </span>
            <span key='2'>{details}</span>
          </>
        )
      }
    }
  ]

  return (
    <SelectFlagsWrapper>
      <Table
        columns={columns}
        data={data}
        rowKey={(record) => record.id}
        rowClassName={(record) => {
          return !record.type ? 'alert' : ''
        }}
      />
    </SelectFlagsWrapper>
  )
}
