// import React, { useState } from 'react';
import styled from 'styled-components'

import { ActionButton } from './ActionButton'

const ActionsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  margin-top: 20px;
  max-width: 500px;
`

const ActionsHeader = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 26px;
    color: #272d3b;
    margin: 0;
    padding: 0;
    padding: 20px 30px;
  }

  span {
    display: block;
    padding: 0 30px 10px 30px;
    font-weight: bold;
    border-bottom: 1px solid #f0eeec;
  }
`

const ActionsList = styled.div`
  div {
    font-size: 14px;
    padding: 20px 30px;
    border-bottom: 1px solid #f0eeec;
    display: flex;
    align-items: center;

    button {
      margin-left: auto;
    }
  }
`

interface ApplicantDetailActionsProps {
  actions: any
}

export const ApplicantDetailActions = ({
  actions
}: ApplicantDetailActionsProps) => {
  return (
    <ActionsWrapper>
      <ActionsHeader>
        <h3>Actions</h3>
        <span>Item</span>
      </ActionsHeader>
      <ActionsList>
        {actions.map((action: any, index: any) => (
          <div key={index}>
            {action.title}
            <ActionButton
              buttonText={action.buttonText}
              buttonAction={action.buttonAction}
              buttonEnabled={action.buttonEnabled}
              buttonColor={action.buttonColor}
              buttonFresh={action.buttonFresh}
            />
          </div>
        ))}
      </ActionsList>
    </ActionsWrapper>
  )
}
