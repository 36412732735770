import React from 'react'

function FormTimeline ({ steps, currentStep, updatePage }) {
  const getStepClass = (step, currentStep) => {
    if (step <= currentStep) {
      return 'active'
    } else {
      return ''
    }
  }

  const getTimeline = () => {
    const divs = []
    for (let i = 0; i < steps; i++) {
      const s = React.createElement('span', {
        onClick: (e) => updatePage(i + 1)
      }, i + 1)
      const c = React.createElement('div', {
        className: `step-number ${getStepClass(i + 1, currentStep)}`,
        key: `timeline-${i}`
      }, s)
      divs.push(c)
    }
    return divs
  }

  return (
    <div className='form-timeline-wrapper'>
      {getTimeline()}
    </div>
  )
}

export { FormTimeline }
