import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const AssessmentWrapper = styled.div`
  width: 100%;
`

const AssessmentTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px 0px;
`

const AssessmentDescription = styled.div`
  font-size: 16px;
  color: #707070;
`

const AssessmentCauses = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  margin-top: 15px;

  .check-wrapper {
    font-size: 14px;
    padding: 20px 0;
    border-bottom: 1px solid #f0eeec;

    &:nth-child(3n + 1) {
      padding-left: 30px;
    }

    .custom-checkbox {
      width: 20px;
      height: 20px;
      background: #ddd;
      margin: 0;
      border-radius: 100%;
      position: relative;
      float: left;
    }

    .custom-checkbox label {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      background: #fff;
      border: 1px solid #707070;
    }

    .custom-checkbox input[type='checkbox']:checked + label {
      background: #81bcbf;
      border: 1px solid #81bcbf;

      &:before {
        content: '';
        display: inline-block;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 10px;
        width: 5px;
        margin-left: 60%;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        position: absolute;
        top: 2px;
        left: -6px;
      }
    }

    .custom-checkbox-label {
      font-size: 14px;
      margin: 2px 0 0 30px;
    }
  }
`

const AssessDropdowns = styled.div`
  margin-top: 35px;
  display: flex;

  > div:first-of-type {
    width: 300px;

    .select__control {
      width: 250px;
    }
  }

  > div:nth-child(2){
    width: 50%;

    input {
      border-radius: 20px;
      border: 1px solid #B9AFA4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
    }
  }
`

const AssessDropdown = styled.div`
  padding-right: 80px;

  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #272d3b;
    margin-bottom: 8px;
  }

  .select__control {
    width: 400px;
  }
`

export const Assess = ({ data }) => {
  return (
    <AssessmentWrapper>
      <AssessmentTitle>Assessment Cause</AssessmentTitle>
      <AssessmentDescription>
        Select all that apply. All selected items will be included in Applicant
        Pre-Adverse Action Notice.
      </AssessmentDescription>
      <AssessmentCauses>
        {data.assess.map((item, index) => (
          <div className='check-wrapper' key={index}>
            <div className={`custom-checkbox cause-${item.val}`}>
              <input
                checked={data.selectedAssess.includes(item.val)}
                type='checkbox'
                value={item.val}
                id={`cause-${item.val}`}
                name=''
                onChange={() => { item.setSelect(data.selectedAssess, item.val) }}
              />
              <label htmlFor={`cause-${item.val}`} />
            </div>
            <div className='custom-checkbox-label'>{item.label}</div>
          </div>
        ))}
      </AssessmentCauses>
      <AssessDropdowns>
        <AssessDropdown>
          <span>Time Since Offense</span>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={data.timeSinceOffenseOptions.find((to) => to.value === data.timeSinceOffense)}
            onChange={(val) => {
              data.setTimeSinceOffense(val.value)
            }}
            isSearchable={false}
            name='time_since_offense'
            options={data.timeSinceOffenseOptions}
          />
        </AssessDropdown>
        <AssessDropdown>
          <span>Business Implications</span>
          <input
            autoComplete='off'
            name='businessImplications'
            type='text'
            value={data.businessImplications}
            onChange={(e) => { data.setBusinessImplications(e.target.value) }}
          />
        </AssessDropdown>
      </AssessDropdowns>
    </AssessmentWrapper>
  )
}
