import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { loginWrapperCss } from '../_helpers/shared-css'
import { userAtom } from '../_state'

const ApplicantPortalWrapper = styled.div`
  ${loginWrapperCss}
`

const GraphicWrapper = styled.div`
  flex: 1;
  background-color: #ffd226;
`

const FormContentWrapper = styled.div`
  flex: 1;
  padding: 40px;
  text-align: center;

  .form-content {
    max-width: 450px;
    margin: auto;
  }

  .employifi-logo {
    width: 220px;
    height: 185px;
    margin: 40px auto;
    background: red;
  }

  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;

    button {
      border: none;
      font-family: 'Montserrat';
      font-size: 12px;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 15px 50px;
      box-sizing: border-box;
      width: 250px;
      box-shadow: 0px 20px 50px #0000004d;
      border-radius: 40px;
    }
  }

  .form-element {
    width: 100%;
    margin-bottom: 30px;

    label,
    input {
      display: block;
    }

    label {
      font-size: 10px;
      text-transform: uppercase;
      color: #707070;
      text-align: left;
      font-weight: bold;
      margin-bottom: 10px;
    }

    input[type='text'] {
      border-radius: 20px;
      border: 1px solid #b9afa4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
    }

    .forget-password {
      display: block;
      text-align: right;
      text-decoration: none;
      color: #de7e53;
      font-size: 14px;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  form {
  }

  .other-signin {
    position: relative;
    text-align: center;
    margin-top: 100px;

    .line {
      display: block;
      border-bottom: 1px solid #e9eff6;
    }

    .line-label {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      padding: 5px;
      color: #707070;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .options {
      margin: 25px auto;
      display: flex;

      button {
        border: none;
        height: 60px;
        margin-right: 5px;
        border-radius: 5px;
        background-color: #e0e0e0;
        flex: 1;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .no-account-message {
      font-size: 14px;
      span {
        color: #272d3b;
        display: inline-block;
        margin-right: 5px;
      }

      a {
        text-decoration: none;
        color: #de7e53;
      }
    }
  }
`

const ApplicantPortal = () => {
  const user = useRecoilValue(userAtom)
  console.log(user)
  return (
    <ApplicantPortalWrapper>
      <GraphicWrapper />
      <FormContentWrapper>
        <div className='form-content'>
          <div className='employifi-logo' />
          <div className='login-form-wrapper'>
            <form>
              <div className='form-element'>
                <label>Email Address or Mobile Number</label>
                <input type='text' name='email-or-mobile' />
              </div>
              <div className='form-element'>
                <label>Password</label>
                <input type='text' name='pasword' />
                <a className='forget-password' href='/somewhere'>
                  Forget Password?
                </a>
              </div>
              <button
                onClick={() => {
                  console.log('SIGN ME IN SCOTTY')
                }}
              >
                SIGN IN
              </button>
            </form>
          </div>
          <div className='other-signin'>
            <span className='line' />
            <span className='line-label'>or sign in with</span>
            <div className='options'>
              <button />
              <button />
              <button />
              <button />
            </div>
            <div className='no-account-message'>
              <span>Don't have an account?</span>
              <a href='/somewhere'>Sign up</a>
            </div>
          </div>
        </div>
      </FormContentWrapper>
    </ApplicantPortalWrapper>
  )
}

export { ApplicantPortal }
