import { useRecoilState, useSetRecoilState } from 'recoil'
import { useJobActions } from '../_actions'
import { useHistory } from 'react-router-dom'
import { job as jobAtom, jobs as jobsAtom } from '../_state'

const useReloadJob = () => {
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const setJob = useSetRecoilState(jobAtom)
  const [jobs, setJobs] = useRecoilState(jobsAtom)
  const history = useHistory()

  const reloadJob = (id) => new Promise((resolve) => {
    jobActions.getById(id).then((job) => {
      const newJobs = jobs.map((j) => {
        if (j.id === job.id) {
          setJob(job)
          return job
        } else return j
      })
      setJobs(newJobs)
      resolve(job)
    })
  })

  const backToJob = (id) => {
    reloadJob(id)
      .then((j) => {
        const path = '/jobs/' + id
        history.push(path)
      })
  }

  return { reloadJob, backToJob }
}

export { useReloadJob }
