import styled from 'styled-components'
import { useState } from 'react'

const UploadControlWrapper = styled.div`
  display: flex;
  margin: 50px 0;

  label {
    display: inline-block;
    color: #81BCBF;
    border: 1px solid #81BCBF;
    padding: 10px 25px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 20px;
    max-height: 15px;

    &:hover {
      background-color: #81BCBF;
      color: #fff;
      cursor: pointer;
    }
  }

  .file-namebox {
    width: 400px;
    border-radius: 20px;
    border: 1px solid #B9AFA4;
    padding: 8px 15px;
    font-size: 12px;
    color: #707070;
    margin-right: 15px;
    box-sizing: border-box;
    position: relative;

    span {
      position: absolute;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      top: -20px;
      left: 0px;
      color: #707070;
    }
  }

  .file-nameboxhidden{
    position: relative;
    width: 400px;

    span{
      position: absolute;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      top: -20px;
      left: -100px;
      color: #707070;
    }
  }
`

export const UploadControl = ({ buttonText, value, disabled, accept, labelText, handleUploadCB, multiple, hideTextbox, displayChipURL, id }) => {
  const [loadedImage, setLoadedImage] = useState('')

  const handleUpload = ({ target: { files } }) => {
    const img = files[0]
    setLoadedImage(img.name)
    // loadedImage will be a image file.
    handleUploadCB && handleUploadCB(img)
  }

  return (
    <UploadControlWrapper>
      {!hideTextbox && <div className='file-namebox'>
        <span>{labelText}</span>
        {loadedImage}
      </div>}
      <label htmlFor={id || 'contained-button-file'} className='m-0 w-100'>
        <input
          value={value}
          accept={accept}
          disabled={disabled}
          style={{ display: 'none' }}
          id={id || 'contained-button-file'}
          multiple={multiple}
          type='file'
          onChange={disabled ? () => {} : handleUpload}
        />
        {buttonText}
      </label>
      {hideTextbox && <div className='file-nameboxhidden'>
        <span>{labelText}</span>
      </div>}
      {displayChipURL && <img src={displayChipURL} style={{ width: 50, height: 50, borderRadius: 10 }} alt='example image' />}
    </UploadControlWrapper>
  )
}
