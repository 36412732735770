import styled from 'styled-components'
const H2 = styled.h2`
  font-size: 28px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: #81bcbf;
  line-spacing: 34;
`
export { Header2 }

function Header2 ({ user }) {
  return (
    <H2>Welcome {`${user?.given_name || ''} ${user?.family_name || ''}`}!</H2>
  )
}
