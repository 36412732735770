import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { CallbackPage } from './Auth0'
import RequireAuth from './Auth0/RequireAuth'
import RequireApplicant from './Auth0/RequireApplicant'
import RequireEmployifiAdmin from './Auth0/RequireEmployifiAdmin'
import RequireCompanyAdminOrRecruiter from './Auth0/RequireCompanyAdminOrRecruiter'
import RequireAnyAdmin from './Auth0/RequireAnyAdmin'
import RequireNoSession from './Auth0/RequireNoSession'
import { Alert } from './_components'
import { history } from './_helpers'
import Login from './account/Login'
import Registration from './account/registration'
import ForgotPassword from './account/ForgotPassword'
import ApplicantAuthorization from './components/Tasks/ApplicantAuthorization'
import ConditionalOffer from './components/Tasks/ConditionalOffer'
import AdverseActionNotification from './components/Tasks/AdverseActionNotification'
import { JobDetails } from './pages/jobs'
import { Applicants } from './applicants'
import {
  ApplicationVerification,
  SocialSecurityVerification,
  AuthorizationOfReleaseOfInformation
} from './components/Tasks'
import { Settings } from './pages/settings'
import {
  Dashboard,
  Playground,
  ApplicantPortal,
  ApplicantPlayground,
  AdminPlayground,
  EmailPlayground
} from './dashboard'
import AdminDashboard from './admin/Dashboard'
import AdminApplicantJobCreation from './admin/ApplicantJobCreation'
import AdminJobCreation from './admin/JobCreation'
import ApplicantReview from './admin/ApplicantReview'
import JobReview from './admin/JobReview'
import AdminApplicantCreation from './admin/ApplicantCreation'

import AdminCompanyDashboard from './admin/companies/Dashboard'
import AdminCompanyApplicants from './admin/companies/applicants'
import AdminCompanyJobs from './admin/companies/jobs'

import LocationsNew from './admin/locations/new'
import LocationsEdit from './admin/locations/edit'
import AddLocationUsers from './admin/locations/addUsers'

import BonusNew from './admin/companies/settings/bonuses/new'
import BonusEdit from './admin/companies/settings/bonuses/edit'
import DepartmentNew from './admin/companies/settings/departments/new'
import DepartmentEdit from './admin/companies/settings/departments/edit'
import AAANew from './admin/companies/settings/adverse_action_assess/new'
import AAAEdit from './admin/companies/settings/adverse_action_assess/edit'

import AlertsEdit from './admin/alerts/edit'

import CompaniesList from './admin/companies/list'
import CompaniesNew from './admin/companies/new'
import CompaniesEdit from './admin/companies/edit'
import TaskShow from './admin/tasks/show'
import PersonalInformation from './components/Tasks/PersonalInformation'
import DrugTesting from './components/Tasks/DrugTesting'

export { App }

function App () {
  return (
    <div className='app-container' id='app-container'>
      <Router history={history}>
        <Alert />
        <Switch>
          <Route path='/playground'>
            <RequireAuth>
              <Playground />
            </RequireAuth>
          </Route>
          <Route path='/applicant-playground'>
            <RequireAuth>
              <ApplicantPlayground />
            </RequireAuth>
          </Route>
          <Route path='/email-playground'>
            <RequireAuth>
              <EmailPlayground />
            </RequireAuth>
          </Route>
          <Route path='/admin/playground'>
            <RequireAuth>
              <AdminPlayground />
            </RequireAuth>
          </Route>
          <Route path='/applicant-account-portal'>
            <RequireAuth>
              <ApplicantPortal />
            </RequireAuth>
          </Route>
          <Route exact path='/jobs/:id'>
            <RequireAuth>
              <JobDetails />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/conditional_offer/:id'>
            <RequireAuth>
              <ConditionalOffer />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/adverse_action_notification/:id'>
            <RequireAuth>
              <AdverseActionNotification />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/personal_information/:id'>
            <RequireAuth>
              <PersonalInformation />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/application_verification/:id'>
            <RequireAuth>
              <ApplicationVerification />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/authorization/:id'>
            <RequireAuth>
              <ApplicantAuthorization />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/drug_testing/:id'>
            <RequireAuth>
              <DrugTesting />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/cbsv/:id'>
            <RequireAuth>
              <SocialSecurityVerification />
            </RequireAuth>
          </Route>
          <Route exact path='/applicant/release_authorization/:id'>
            <RequireAuth>
              <AuthorizationOfReleaseOfInformation />
            </RequireAuth>
          </Route>
          <Route path='/settings'>
            <RequireAuth>
              <Settings />
            </RequireAuth>
          </Route>
          <Route path='/dashboard'>
            <RequireApplicant>
              <Dashboard />
            </RequireApplicant>
          </Route>
          <Route path='/' exact>
            <RequireApplicant>
              <Dashboard />
            </RequireApplicant>
          </Route>
          <Route path='/login' exact>
            <RequireNoSession>
              <Login />
            </RequireNoSession>
          </Route>
          <Route path='/register' exact>
            <RequireNoSession>
              <Registration />
            </RequireNoSession>
          </Route>
          <Route path='/forgot-password' exact>
            <RequireNoSession>
              <ForgotPassword />
            </RequireNoSession>
          </Route>
          <Route path='/authorize'>
            <RequireNoSession>
              <CallbackPage />
            </RequireNoSession>
          </Route>
          <Route path='/admin/companies/:companyId/applicants'>
            <RequireCompanyAdminOrRecruiter>
              <AdminCompanyApplicants />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/companies/:companyId/jobs'>
            <RequireCompanyAdminOrRecruiter>
              <AdminCompanyJobs />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/companies/:companyId/bonuses/new'>
            <RequireAnyAdmin>
              <BonusNew />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/bonuses/:bonusId/edit'>
            <RequireAnyAdmin>
              <BonusEdit />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/aaas/new'>
            <RequireAnyAdmin>
              <AAANew />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/aaas/:aaaId/edit'>
            <RequireAnyAdmin>
              <AAAEdit />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/departments/new'>
            <RequireAnyAdmin>
              <DepartmentNew />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/departments/:departmentId/edit'>
            <RequireAnyAdmin>
              <DepartmentEdit />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/company/dashboard'>
            <RequireCompanyAdminOrRecruiter>
              <AdminCompanyDashboard />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/applicants'>
            <RequireCompanyAdminOrRecruiter>
              <Applicants />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/companies/new'>
            <RequireEmployifiAdmin>
              <CompaniesNew />
            </RequireEmployifiAdmin>
          </Route>
          <Route path='/admin/companies/:companyId/edit'>
            <RequireAnyAdmin>
              <CompaniesEdit />
            </RequireAnyAdmin>
          </Route>
          <Route path='/admin/companies'>
            <RequireEmployifiAdmin>
              <CompaniesList />
            </RequireEmployifiAdmin>
          </Route>
          <Route path='/admin/locations/new'>
            <RequireCompanyAdminOrRecruiter>
              <LocationsNew />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/jobs/new'>
            <RequireCompanyAdminOrRecruiter>
              <AdminJobCreation />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/applicants/new'>
            <RequireCompanyAdminOrRecruiter>
              <AdminApplicantCreation />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/users/:userId/jobs/:jobId/alerts/:alertId/edit'>
            <RequireCompanyAdminOrRecruiter>
              <AlertsEdit />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/users/:userId/jobs/new'>
            <RequireCompanyAdminOrRecruiter>
              <AdminApplicantJobCreation />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/users/:userId/jobs/:jobId/review'>
            <RequireCompanyAdminOrRecruiter>
              <ApplicantReview />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/users/:userId/jobs/:jobId'>
            <RequireCompanyAdminOrRecruiter>
              <JobReview />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/applicants/new'>
            <RequireCompanyAdminOrRecruiter>
              <AdminApplicantCreation />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/jobs/new'>
            <RequireCompanyAdminOrRecruiter>
              <AdminJobCreation />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/dashboard'>
            <RequireCompanyAdminOrRecruiter>
              <AdminDashboard />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId/edit'>
            <RequireCompanyAdminOrRecruiter>
              <LocationsEdit />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/locations/:locationId'>
            <RequireCompanyAdminOrRecruiter>
              <AddLocationUsers />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Route path='/admin/tasks/:taskId'>
            <RequireCompanyAdminOrRecruiter>
              <TaskShow />
            </RequireCompanyAdminOrRecruiter>
          </Route>
          <Redirect from='*' to='/dashboard' />
        </Switch>
      </Router>
    </div>
  )
}
