import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { Router } from 'react-router-dom'
import { userAtom } from '../_state'
import { history, sidebarButtonsApplicant } from '../_helpers'
import { SidebarNav } from '../components/SidebarNav'

const Wrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 90px;
  // padding: 40px;
`
const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  padding-bottom: 100px;
`
const Content = styled.div`
  padding: 40px;
  overflow: scroll;
  width: 100%;
`

// #F0EEEC; light grey
// #272D3B; med Grey
// #81BCBF; Light blue
// #DE7E53; Orange
// #304755; Sorta grey
// #707070; grey med-dark
// #BAAFA3; bronze grey

const ApplicantWrapper = ({ children }) => {
  const user = useRecoilValue(userAtom)

  return (
    <Wrapper>
      <Router history={history}>
        <SidebarNav
          buttons={sidebarButtonsApplicant}
          picture={user.picture}
          backgroundColor='#81BCBF'
        />
      </Router>
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  )
}

export { ApplicantWrapper }
