import { useFormContext, useFieldArray } from 'react-hook-form'
import { useEffect } from 'react'
import { useStateContext, ArrayProvider } from '../../../contexts'
import {
  HookFormArrayInput,
  HookFormArrayDateRange
} from '../../shared/HookFormComponents'

const Reference = () => {
  return (
    <>
      <HookFormArrayInput
        fieldName='firstName'
        label='First Name'
        type='text'
      />
      <HookFormArrayInput fieldName='lastName' label='Last Name' type='text' />
      <HookFormArrayInput
        fieldName='title'
        label='Title/Position'
        type='text'
      />
      <HookFormArrayDateRange
        fieldName='yearsAcquainted'
        label='Years Acquinted'
      />
      <HookFormArrayInput
        fieldName='phone'
        label='Phone Number'
        type='text'
        subType='phone'
      />
      <HookFormArrayInput fieldName='email' label='Email' type='text' />
    </>
  )
}
const References = ({ name }) => {
  const { control, formState } = useFormContext()
  const { errors } = formState
  const { state, setState } = useStateContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  const addReference = (e) => {
    const list = state[name].map((item) => {
      return item
    })
    list.push(e)
    setState({ ...state, [name]: list })
  }
  const removeReference = (idx) => {
    const list = state[name].filter((e, i) => {
      return i !== idx
    })
    setState({ ...state, [name]: list })
  }

  useEffect(() => {
    if (state[name].length === 0) {
      const list = []
      for (let index = 0; index < 3; index++) {
        list.push({})
      }
      setState({ ...state, [name]: list })
      append(list, { focusIndex: 0 })
    }
  }, [])

  return (
    <>

      <div className='form-element'>
        <div className='invalid-feedback'>{errors?.references?.message}</div>
      </div>
      <div className='form-element'>
        <button
          type='button'
          className='add-remove-button'
          onClick={() => {
            append({})
            addReference({})
          }}
        >
          + Add Reference Entry
        </button>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className='form-element'>
          <ArrayProvider arrayName='references' index={index}>
            <Reference id={item.id} />
          </ArrayProvider>
          <button
            type='button'
            className='add-remove-button'
            onClick={() => {
              if (state[name].length > 3) {
                remove(index)
                removeReference(index)
              }
            }}
          >
            - Remove Entry
          </button>
        </div>
      ))}
    </>
  )
}

export { References }
