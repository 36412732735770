import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import { tabsCss, formCss } from '../../_helpers/shared-css'
import { ApplicantGenericTable } from '../Applicant/ApplicantGenericTable'
import { AdminCompanyInformation } from './AdminCompanyInformation'
import { UploadControl } from '../shared/UploadControl'
import { AdminAddUser } from './AdminAddUser'
import { ProductsList } from '../ProductsList'
import Select from 'react-select'
import { AdminAtsManagement } from './AdminAtsManagement'

const AdminCreateAndManageCompanyTabsWrapper = styled.div`
  margin-top: 60px;

  h3 {
    font-size: 24px;
    color: #707070;
    margin-bottom: 15px;
  }

  .cols {
    display: flex;
    align-items:flex-start;

    >div:first-child {
      margin-right: 20px;
      width: 60%;
      height: auto;
    }
  }

  .conditional span {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #c8d1d3;

    &.green {
      background-color: #7ec749;
    }

    &.gray {
      background-color: #707070;
    }
  }

  ${tabsCss}

  .days-required {
    ${formCss}

    .form-element  {
      max-width: 200px;
    }

    .checkbox-wrapper {
      margin-bottom: 20px;
    }
  }
`

const SearchWrapper = styled.div`
  position: relative;
  display: flex;

  svg {
    width: 25px;
    position: absolute;
    top: 28px;
    left: 10px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 0;
    font-size: 16px;
    display: block;
    width: 400px;
  }
`

const teamCompanyAdminsCols = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Title', dataIndex: 'title', key: 'title' },
  {
    title: 'Primary',
    dataIndex: 'primary',
    key: 'primary',
    className: 'conditional',
    render (rec) {
      return <span className={rec ? 'green' : ''} />
    }
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 300,
    className: 'select-button',
    render (template, row) {
      return (
        <div>
          <button
            onClick={() => { console.log('clicked the edit button') }}
          >
            Edit
          </button>
        </div>
      )
    }
  }
]

const teamCompanyUsersCols = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Role', dataIndex: 'role', key: 'role' },
  { title: 'Permissions', dataIndex: 'permissions', key: 'permissions' },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 300,
    className: 'select-button',
    render (template, row) {
      return (
        <div>
          <button
            onClick={() => { console.log('clicked the edit button') }}
          >
            Edit
          </button>
        </div>
      )
    }
  }
]

const teamCompanyAdminsData = [
  {
    id: 1,
    name: 'Rachel Person',
    title: 'Head of HR',
    primary: true
  },
  {
    id: 2,
    name: 'Paul Blart',
    title: 'Vice Prez of stuff',
    primary: false
  }
]

const teamCompanyUsersData = [
  {
    id: 3,
    name: 'Rachel Person',
    role: 'Recruiter',
    permissions: 'Tier 1'
  },
  {
    id: 4,
    name: 'Paul Blart',
    role: 'Recruiter',
    permissions: 'Tier 2'
  }
]

const products = [
  { val: 6, label: 'Drug Testing - 10 Panel Urine' },
  { val: 7, label: 'Drug Testing - 10 Panel Urine + Alcohol + Cotonine' },
  { val: 8, label: 'Drug Testing - 5 Panel Urine' },
  { val: 9, label: 'Drug Testing - 9 Panel Urine (Marijuana not tested)' },
  { val: 10, label: 'Drug Testing - Hair Panel' },
  { val: 11, label: 'Education Verification' },
  { val: 12, label: 'Application Verification' },
  { val: 13, label: 'Federal Criminal All - Main Name Only' },
  { val: 14, label: 'Federal Criminal All with Alias Names' },
  { val: 15, label: 'ID Report' },
  { val: 16, label: 'Motor Vehicle Report' },
  { val: 17, label: 'National Criminal & Government Sanctions' },
  { val: 18, label: 'Professional License Verification' },
  { val: 19, label: 'Professional Reference' },
  { val: 20, label: 'CBSex Offender SearchSV' },
  { val: 21, label: 'Trace Report' }
]

export const AdminCreateAndManageCompanyTabs = ({ defaultTabIndex = 0 }) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0)
  const [searchText, setSearchText] = useState('')

  function clearSearch () {
    setSearchText('')
  }

  return (
    <AdminCreateAndManageCompanyTabsWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab key='tab_1'>Company Information</Tab>
          <Tab key='tab_2'>Settings</Tab>
          <Tab key='tab_3'>Branding</Tab>
          <Tab key='tab_4'>Team</Tab>
          <Tab key='tab_5'>Locations</Tab>
          <Tab key='tab_6'>Connections</Tab>
        </TabList>

        <TabPanel key='tabPanel_1'>
          <AdminCompanyInformation />
        </TabPanel>
        <TabPanel key='tabPanel_2'>
          <h3>ATS Management</h3>
          <AdminAtsManagement />
          <h3>Adverse Action Policies</h3>
          <div className='days-required'>
            <div className='form-element'>
              <label>Days Required</label>
              <Select
                name='days_required'
                required
                options={[]}
                onChange={() => console.log('select days required')}
              />
            </div>
            <div className='checkbox-wrapper'>
              <div className='custom-checkbox'>
                <input
                  type='checkbox'
                  checked
                  onChange={() => { console.log('change thing') }}
                />
                <label htmlFor='' />
              </div>
              <div className='custom-checkbox-label'>Do NOT include weekends & National Holidays</div>
            </div>
            <div className='checkbox-wrapper'>
              <div className='custom-checkbox'>
                <input
                  type='checkbox'
                  checked={false}
                  onChange={() => { console.log('change thing') }}
                />
                <label htmlFor='' />
              </div>
              <div className='custom-checkbox-label'>Auto-send Final Adverrse Action Letter on date expiration</div>
            </div>
          </div>
          <h3>Available Products</h3>
          <ProductsList
            title='Available Products'
            description='(Select All that apply)'
            products={products}
            editable
          />
        </TabPanel>
        <TabPanel key='tabPanel_3'>
          <h3>Employifi Custom Branding</h3>
          <UploadControl
            accept='image/*'
            labelText='company logo | for dark backgrounds'
            buttonText='Upload'
          />
          <UploadControl
            accept='image/*'
            labelText='company logo | for light backgrounds'
            buttonText='Upload'
          />
          <h3>Branding for Forms & Documents</h3>
          <UploadControl
            accept='image/*'
            labelText='company logo'
            buttonText='Upload'
          />
        </TabPanel>
        <TabPanel key='tabPanel_4'>
          <SearchWrapper>
            {searchText === ''
              ? (
                <IconSearch />
                )
              : (
                <IconClose
                  onClick={() => {
                    clearSearch()
                  }}
                />
                )}
            <input
              htmlFor='search'
              type='text'
              name='Companies'
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value) }}
            />
            <AdminAddUser />
          </SearchWrapper>
          <ApplicantGenericTable
            loading={false}
            columns={teamCompanyAdminsCols}
            data={teamCompanyAdminsData}
            tableTitle='Company Admins'
            rowKey='id'
          />
          <ApplicantGenericTable
            loading={false}
            columns={teamCompanyUsersCols}
            data={teamCompanyUsersData}
            tableTitle='Company Users'
            rowKey='id'
          />
        </TabPanel>
        <TabPanel key='tabPanel_5' />
        <TabPanel key='tabPanel_6' />
      </Tabs>
    </AdminCreateAndManageCompanyTabsWrapper>
  )
}
