import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocationActions, useCompanyActions, useJobActions } from '../../_actions'
import { history } from '../../_helpers'
import AdminWrapper from '../shared/AdminWrapper'
import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'
import { AdminCompanyDashboardTableWrapper, TableSearchWrapper } from '../shared/AdminStyledComponets'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import ModalWrapper from '../shared/ModalWrapper'
import AdminJobCreationModal from '../JobCreationModal'

const AdminCompanyJobs = () => {
  const { companyId } = useParams()
  const [locations, setLocations] = useState([])
  const [jobs, setJobs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [createJobModalOpen, setCreateJobModalOpen] = useState(false)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const [jobSearchText, setJobText] = useState('')

  function clearSearch () {
    setJobText('')
  }

  const saveJob = (data, templateName) => {
    const createTheJob = async () => {
      await jobActions.createJob(data, templateName)
    }
    createTheJob()
  }

  useEffect(() => {
    const fetchDashboardData = async () => {
      const locations = await locationActions.getLocationList(companyId)
      setLocations(locations)
      const apijobs = await companyActions.jobs(companyId)
      setJobs(apijobs)

      setIsLoading(false)
    }
    fetchDashboardData()
  }, [])

  const jobsColumns = [
    { title: 'Job', dataIndex: 'title', key: 'title' },
    { title: 'Location', dataIndex: 'location_name', key: 'location_name' },
    {
      title: 'Status',
      dataIndex: 'jank_display_status',
      key: 'jank_display_status',
      className: 'status-column',
      render (val, row) {
        return (
          <div>
            <span className={val === 'Available' ? '' : 'inprogress'}>
              {val}
            </span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            {
              row.status === 'Available' || row.applicant_user === undefined || row.applicant_user === null
                ? <button
                    onClick={() => {
                      history.push(
                        `/admin/locations/${locations[0].id}/dashboard`
                      )
                    }}
                  >Assign Applicant
                </button>
                : <button
                    onClick={() => {
                      history.push(
                        `/admin/locations/${row.location.id}/users/${row.applicant_user.id}/jobs/${row.id}`
                      )
                    }}
                  >View
                </button>
            }
            {/* <button
              onClick={() => {
                row.status === 'Available' || row.applicant_user === undefined || row.applicant_user === null
                  ? history.push(
                    `/admin/locations/${locations[0].id}/dashboard`
                  )
                  : history.push(
                    `/admin/locations/${row.location.id}/users/${row.applicant_user.id}/jobs/${row.id}`
                  )
              }}
            >
              View
            </button> */}
          </div>
        )
      }
    }
  ]

  return (
    <AdminWrapper>
      <TableSearchWrapper>
        {jobSearchText === ''
          ? (
            <IconSearch />
            )
          : (
            <IconClose
              onClick={() => {
                clearSearch()
              }}
            />
            )}
        <input
          htmlFor='search'
          type='text'
          name='Locations'
          value={jobSearchText}
          onChange={(e) => { setJobText(e.target.value) }}
        />
        {/* <div className='add-button-wrapper'>
          <button className='add-user-button' onClick={() => { history.push('/admin/jobs/new') }}>Add Job</button>
        </div> */}
        <div className='add-button-wrapper'>
          <button className='add-user-button' onClick={() => setCreateJobModalOpen(true)}>+ Create Job</button>
        </div>
        <ModalWrapper
          modalOpen={createJobModalOpen}
          closeModal={() => setCreateJobModalOpen(false)}
          modalTitleText='Create Applicant'
          modalContent={
            <AdminJobCreationModal
              saveJob={saveJob}
              saveLinkText='Save & Close'
              loading={isLoading}
              closeModal={() => setCreateJobModalOpen(false)}
            />
          }
          modalMinHeight={550}
        />
      </TableSearchWrapper>
      <AdminCompanyDashboardTableWrapper>
        <ApplicantGenericTable
          columns={jobsColumns}
          data={jobs.filter((a) => { return jobSearchText === '' ? true : a.title.toLowerCase().includes(jobSearchText.toLowerCase()) })}
          tableTitle='Jobs'
          rowKey='id'
          loading={isLoading}
        />
      </AdminCompanyDashboardTableWrapper>
    </AdminWrapper>
  )
}

export default AdminCompanyJobs
