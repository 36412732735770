import { Controller, useFormContext } from 'react-hook-form'
import { AsYouType } from 'libphonenumber-js'
import { useStateContext, useArrayContext } from '../../../contexts'
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import localeEn from 'air-datepicker/locale/en'
import { useEffect } from 'react'

const HookFormArrayInput = ({ label, fieldName, type, subType }) => {
  const { control, formState, setValue } = useFormContext()
  const { index, arrayName } = useArrayContext()
  const { state, setState } = useStateContext()
  const { errors } = formState
  const domId = `${arrayName}${index}${fieldName}`

  useEffect(() => {
    if (subType === 'date' || subType === 'dateRange') {
      const selector = `#${domId}`
      const range = subType === 'dateRange'
      new AirDatepicker(selector, {
        locale: localeEn,
        autoClose: true,
        dateFormat: 'dd/MM/yyyy',
        multipleDatesSeparator: ' - ',
        range,
        // multipleDates: multipleDates,

        onSelect ({ _date, formattedDate, datepicker }) {
          updateState(formattedDate, index)
          setValue(`${arrayName}.${index}.${fieldName}`, formattedDate)
        }
      })
    }
  }, [])

  const updateState = (v, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: v }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
  }
  const onchange = (e, id) => {
    if (subType && subType === 'phone') {
      const asYouType = new AsYouType('US')
      if (e.target.value) {
        if (e.target.value.length > 4) {
          const val = asYouType.input(e.target.value)
          updateState(val, id)
          return (e.target.value = val)
        }
      }
    }
    updateState(e.target.value, id)
    // setFocus(domId)
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          render={({ field }) => (
            <input
              id={domId}
              {...field}
              onChange={(e) => field.onChange(onchange(e, index))}
              className='input-inline'
              type={type}
            />
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayInput }
