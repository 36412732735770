import { useState } from 'react'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { AdminJobTemplateSearch } from './AdminJobTemplateSearch'
import { AdminJobDetails } from './AdminJobDetails'
import { BarLoader } from 'react-spinners'

const AdminLinkApplicantTabsWrapper = styled.div`
  // todo make this css reusable or make a generic tabs component
  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 0;
      margin-right: 50px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`

export const AdminLinkApplicantTabs = ({
  defaultTabIndex,
  jobsData,
  jobsLoading,
  searchJobs,
  templatesData,
  templatesLoading,
  searchTemplates,
  templateLoading,
  templateJobData,
  saveJob,
  saveJobText
}) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0)

  return (
    <AdminLinkApplicantTabsWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab key='tab_1'>Search Jobs</Tab>
          <Tab key='tab_2'>Company Templates</Tab>
          <Tab key='tab_3'>Create a Job</Tab>
        </TabList>

        <TabPanel key='tabPanel_1'>
          <AdminJobTemplateSearch
            searchThing='Job'
            data={jobsData}
            loading={jobsLoading}
            search={searchJobs}
          />
        </TabPanel>
        <TabPanel key='tabPanel_2'>
          <AdminJobTemplateSearch
            searchThing='Template'
            data={templatesData}
            loading={templatesLoading}
            search={searchTemplates}
          />
        </TabPanel>
        <TabPanel key='tabPanel_3'>
          {templateLoading
            ? (
              <div style={{ paddingLeft: 20 }}>
                <BarLoader color='#81BCBF' />
              </div>
              )
            : (
              <AdminJobDetails
                templateLoading={templateLoading}
                template={templateJobData}
                saveJob={saveJob}
                saveText={saveJobText}
              />
              )}
        </TabPanel>
      </Tabs>
    </AdminLinkApplicantTabsWrapper>
  )
}
