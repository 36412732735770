import styled from 'styled-components'

import { formCss, basicButton } from '../../_helpers/shared-css'

export const StyledTableButton = styled.div`
  ${basicButton}
  position: absolute;
  right: 50px;
  a:visited {
    color: white;
  }
  a {
    text-decoration: none;
  }
`

export const TabWrapper = styled.div`
  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;

    li {
      color: #272D3B;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 0;
      margin-right: 50px;
      position: relative;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #DE7E53;
        }
      }
    }
  }
`

export const FormContentWrapper = styled.div`
  ${formCss}
  max-width: 500px;

  button.whiteButton {
      background-color: white;
      margin-left: 20px;
      color: #707070;
    }
`

export const AdminApplicantsSearchWrapper = styled.div`
  margin-top: 30px;

  .search-row {
    display: flex;
    margin-bottom: 30px;

    .search-label {
      font-size: 14px;
      color: #707070;
    }

    button {
      margin-left: auto;
      border: none;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 8px 0px;
      border-radius: 20px;
      text-transform: uppercase;
      font-family: 'Montserrat';
      height: 40px;
      align-self: flex-end;

      &:hover {
        background-color: #699b9d;
        cursor: pointer;
      }
    }
  }

  .status-column div {
    display: flex;
    align-content: flex-end;
  }

  .status-column div span {
    align-self: center;
  }

  .status-column div button {
    margin-left: auto;
    width: 110px;
    justify-content: center;
  }

  .status-column div button.available {
    background-color: #81bcbf;
    color: #fff;
  }

  .status-column .available {
    color: #1dbc3c;
    font-weight: 600;
  }
`

export const SearchWrapper = styled.div`
  position: relative;

  svg {
    width: 25px;
    position: absolute;
    top: 46px;
    left: 10px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 0;
    font-size: 16px;
    display: block;
    width: 400px;
  }
`

export const AdminCompanyDashboardTableWrapper = styled.div`
  .status-column .inprogress {
    color: #E28A57;
  }
`

export const TableSearchWrapper = styled.div`
  position: relative;
  display: flex;

  svg {
    width: 25px;
    position: absolute;
    top: 28px;
    left: 10px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 0;
    font-size: 16px;
    display: block;
    width: 400px;
  }

  .add-button-wrapper{
    margin-left: auto;
    margin-top: 15px;

    button {
      background-color: #81BCBF;
      font-family: 'Montserrat';
      color: #fff;
      cursor: pointer;
      border: none;
      padding: 12px 20px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      box-sizing: border-box;
    }
  }
`

export default {
  SearchWrapper,
  AdminApplicantsSearchWrapper,
  FormContentWrapper,
  StyledTableButton,
  AdminCompanyDashboardTableWrapper,
  TableSearchWrapper
}
