import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { BarLoader } from 'react-spinners'
import { FormContentWrapper } from '../shared/AdminStyledComponets'

const AlertsNew = ({ save }) => {
  const [isLoading, setIsLoading] = useState(false)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    details: Yup.string().required('Details is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = async (data) => {
    setIsLoading(true)
    await save(data)
    setIsLoading(false)
  }

  return (
    <FormContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-element'>
          <label>Title</label>
          <input
            name='title'
            type='text'
            {...register('title')}
            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.title?.message}</div>
        </div>
        <div className='form-element'>
          <label>Details</label>
          <input
            name='details'
            type='text'
            {...register('details')}
            className={`form-control ${errors.details ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.details?.message}</div>
        </div>
        {isLoading
          ? (
            <div style={{ paddingLeft: 20 }}>
              <BarLoader color='#81BCBF' />
            </div>
            )
          : (
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-primary'
            >
              {isSubmitting && (
                <span className='spinner-border spinner-border-sm mr-1' />
              )}
              Send
            </button>
            )}
      </form>
    </FormContentWrapper>
  )
}

export default AlertsNew
