import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { useParams } from 'react-router'
import { userAtom, job as jobAtom } from '../../../_state'
import { useRecoilValue } from 'recoil'
import { StateProvider } from '../../../contexts'
import { useState, useEffect } from 'react'
import { useTasksActions, useQuestActions } from '../../../_actions'
import { PageLoader, useReloadJob, useTasksHelper } from '../../../_helpers'
import { MapComponent } from '../shared'
import { QuestForm } from '../shared/QuestForm'

const DrugTesting = () => {
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  console.log(GOOGLE_MAPS_API_KEY)
  const { id } = useParams()
  const user = useRecoilValue(userAtom)
  const tasksHelper = useTasksHelper()
  // const [loading, setLoading] = useRecoilState(loadingState)
  const [questForm, setQuestForm] = useState(null)
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState(null)
  const [address, setAddress] = useState(null)
  const [final, setFinal] = useState(null)

  const job = useRecoilValue(jobAtom)
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  const questActions = useQuestActions(localStorage.getItem('access_token'))
  const { backToJob } = useReloadJob()
  const [state, setState] = useState({ aliases: [] })
  console.log(job)
  console.log(tasksHelper.getPersonalInformation(job))

  const save = (_data) => {
    const submission = {
      data: { appointment: _data, final: true }
    }
    console.log(submission)
    taskActions.update(id, submission)
      .then((av) => {
        setLoading(false)
        backToJob(job.id)
      })
  }
  // console.log(questForm)
  const getLoadingItem = (idx) => {
    return loading[idx]
  }

  useEffect(() => {
    console.log(loading)
  }, [loading])

  useEffect(() => {
    setLoading(true)
    const piTask = tasksHelper.getPersonalInformation(job)
    taskActions.get(id)
      .then((ret) => {
        console.log(ret)
        if (ret.data) {
          setQuestForm(ret.data.appointment)
          setFinal(ret.data.final)
          setLoading(false)
        } else {
          taskActions.get(piTask.id)
            .then((pi) => {
              console.log(pi)
              setAddress(pi.data.streetLine1 + ',' + pi.data.city + ',' + pi.data.state + ',' + pi.data.postalCode)
              questActions.find_location(job.id)
                .then((ret) => {
                  console.log(ret)
                  setLocations(ret.Data)
                  setLoading(false)
                })
            })
        }
      })
  }, [])

  const getMapComponents = () => {
    console.log(loading)
    const mapComponents = []
    locations.forEach((location, i) => {
      mapComponents.push(
        <>
          <MapComponent location={location} job={job} address={address} key={location.IDQuestCollectionSite} index={i} setQuestForm={setQuestForm} />
        </>
      )
    })
    return mapComponents
  }
  return (
    <>
      <ApplicantWrapper>
        <FormContentWrapper>
          <StateProvider state={state} setState={setState} />
          {locations && !loading && !questForm && getMapComponents()}
          {!loading && questForm && <QuestForm questForm={questForm} job={job} backToJob={backToJob} save={save} final={final} />}
          {locations && loading && <PageLoader />}
        </FormContentWrapper>
      </ApplicantWrapper>
    </>
  )
}

export default DrugTesting
