import { useFetchWrapper } from '../_helpers'

export { useLocationActions }

function useLocationActions (token = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/locations`
  const fetchWrapper = useFetchWrapper(token)

  return {
    getLocationList,
    createLocation,
    getLocation,
    updateLocation,
    deleteLocation,
    getUsers,
    addUser,
    removeUser,
    getApplicants,
    searchApplicants,
    recentApplicants,
    dashboardStats,
    getHRUsers
  }

  function getLocationList (company_id) {
    return fetchWrapper.get(`${baseUrl}?company_id=${company_id}`)
  }

  function createLocation (location) {
    return fetchWrapper.post(`${baseUrl}`, { location })
  }

  function getLocation (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function updateLocation (id, location) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { location })
  }

  function deleteLocation (id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
  }

  function getUsers (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/users`)
  }

  function getHRUsers (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/hr_users`)
  }

  function addUser (id, user_id, role) {
    return fetchWrapper.post(`${baseUrl}/${id}/add_user`, {
      user_id,
      role
    })
  }

  function removeUser (id, user_id) {
    return fetchWrapper.delete(`${baseUrl}/${id}/remove_user`, {
      user_id
    })
  }

  function getApplicants (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/applicants`)
  }

  function searchApplicants (id, q) {
    return fetchWrapper.get(`${baseUrl}/${id}/search_applicants?q=${q}`)
  }

  function recentApplicants (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/recent_applicants`)
  }

  function dashboardStats (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/dashboard_stats`)
  }
}
