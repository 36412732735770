import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'

import AdminWrapper from '../../../shared/AdminWrapper'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useAdverseActionAssessActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'

import { FormContentWrapper } from '../../../shared/AdminStyledComponets'

const AdverseActionAssessEdit = () => {
  const { aaaId, companyId } = useParams()
  const aaaActions = useAdverseActionAssessActions(localStorage.getItem('access_token'))
  const [aaa, setAAA] = useState({ adverse_action_assess: {} })

  const [isLoading, setIsLoading] = useState(true)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    erx_id: Yup.string().required('ERX ID is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await aaaActions.update(aaaId, {
        ...data,
        company_id: companyId
      })
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  useEffect(() => {
    aaaActions.get(aaaId).then((apiData) => {
      setAAA(apiData)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminWrapper>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <>
            <h1>Edit Adverse Action Assess</h1>
            <FormContentWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-element'>
                  <label>Title</label>
                  <input
                    defaultValue={aaa.adverse_action_assess.title}
                    name='title'
                    type='text'
                    {...register('title')}
                    className={`form-control ${errors.title ? 'is-invalid' : ''
                      }`}
                  />
                  <div className='invalid-feedback'>{errors.title?.message}</div>
                </div>
                <div className='form-element'>
                  <label>ERX ID</label>
                  <input
                    defaultValue={aaa.adverse_action_assess.erx_id}
                    name='erx_id'
                    type='text'
                    {...register('erx_id')}
                    className={`form-control ${errors.erx_id ? 'is-invalid' : ''
                      }`}
                  />
                  <div className='invalid-feedback'>{errors.erx_id?.message}</div>
                </div>
                {isLoading
                  ? (
                    <div style={{ paddingLeft: 20 }}>
                      <BarLoader color='#81BCBF' />
                    </div>
                    )
                  : (
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='btn btn-primary'
                    >
                      {isSubmitting && (
                        <span className='spinner-border spinner-border-sm mr-1' />
                      )}
                      Save
                    </button>
                    )}
              </form>
            </FormContentWrapper>
          </>
          )}
    </AdminWrapper>
  )
}

export default AdverseActionAssessEdit
