import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { FormContentWrapper } from '../../../shared/FormContentWrapper'
import { StateProvider } from '../../../../contexts'
import { ButtonGroup } from './ButtonGroup'
import { HookFormToggleSwitch } from '../../../shared/HookFormComponents'
import { PageLoader } from '../../../../_helpers'
import { stepsAtom } from '../../../../_state'

const Step = ({ state, setState, pageNav, sectionHeading, validationSchema, step, stepName, id, save, isArray, job, loading, readOnly, ...rest }) => {
  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: state
  }
  if (!isArray) {
    formOptions.values = state
  }

  const methods = useForm(formOptions)
  const setSteps = useSetRecoilState(stepsAtom)
  /* else {
    if (pageNav.currentStep < pageNav.totalSteps) {
      pageNav.updatePage(pageNav.next)
    } else {
      pageNav.backToJob(job.id)
    }
  } */

  const onSubmit = (_data) => {
    // if (!readOnly) {
    save(id, stepName, _data).then((d) => {
      if (pageNav.currentStep < pageNav.totalSteps) {
        pageNav.updatePage(pageNav.next)
      } else {
        setSteps(1)
        pageNav.backToJob(job.id)
      }
    })
  }

  const onError = (errors, e) => {
    console.log(errors)
  }
  if (loading) {
    return (
      <FormContentWrapper>
        <PageLoader />
      </FormContentWrapper>
    )
  }
  return (
    <>
      <FormContentWrapper>
        <StateProvider state={state} setState={setState} subState={rest.subState}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit, onError)}
              className='form-wrapper'
            >
              {((process.env.NODE_ENV === 'development' || window.location.hostname === 'dev.employifi.com') && pageNav.currentStep === 1) &&
                <div className='form-element'>
                  <button type='button' className='btn btn-primary' onClick={() => pageNav.setPreFillData()}>Prefill Data</button>
                </div>}
              <h1>{sectionHeading}</h1>
              {rest.children}
              {pageNav.currentStep === pageNav.totalSteps && <HookFormToggleSwitch state={state} setState={setState} name='final' label='I am finished and ready to submit' />}
              <ButtonGroup pageNav={pageNav} formState={methods.formState} job={job} final={state.final} />
            </form>
          </FormProvider>
        </StateProvider>
      </FormContentWrapper>
    </>
  )
}

export { Step }
