import { Controller, useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts/useStateContext'

const HookFormTextArea = ({
  label,
  name,
  ...rest
}) => {
  const { control, formState } = useFormContext()
  const { state, setState } = useStateContext()
  const { errors } = formState

  const onchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        {label && <label>{label}</label>}
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <textarea
              {...field}
              onChange={(e) => field.onChange(onchange(e))}
              className='input-inline'
              {...rest}
            />
          )}
        />
        <div className='invalid-feedback'>
          {errors?.name?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormTextArea }
