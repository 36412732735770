import { useState } from 'react'
import DeleteConfirmation from './DeleteConfirmation'

const DeleteLink = ({ row, confirmationText, deleteAction, resourceName, fakeLink }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  return (
    <>
      <a
        href={fakeLink}
        onClick={(e) => {
          e.preventDefault()
          setDeleteModalOpen(true)
        }}
      >
        Delete
      </a>
      {deleteModalOpen &&
        <DeleteConfirmation
          resourceName={resourceName}
          confirmationText={confirmationText}
          deleteAction={deleteAction}
          closeModalCallback={() => { setDeleteModalOpen(false) }}
        />}
    </>
  )
}

export default DeleteLink
