import { useEffect } from 'react'
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form'
import { useStateContext } from '../../../contexts/useStateContext'
import { ArrayProvider, useArrayContext } from '../../../contexts/useArrayContext'
import {
  HookFormArrayInput,
  HookFormArrayRadioGroup,
  HookFormArrayInputDate
} from '../../shared/HookFormComponents'

const Referral = () => {
  const { index, arrayName } = useArrayContext()
  const { control } = useFormContext()

  const watchWorked = useWatch({
    control,
    name: `${arrayName}.${index}.worked`
  })

  const watchContact = useWatch({
    control,
    name: `${arrayName}.${index}.contact`
  })

  return (
    <>
      <HookFormArrayInput
        fieldName='source'
        label='How did you hear about us?'
        type='text'
      />
      <HookFormArrayRadioGroup
        fieldName='worked'
        label='Have you worked for this company before?'
        values={['Yes', 'No']}
        defaultValue='No'
      />
      {watchWorked === 'Yes' && (
        <>
          <HookFormArrayInputDate
            min='2000-01-01'
            max={new Date().toISOString().slice(0, 10)}
            fieldName='startDate'
            label='Start Date'
          />
          <HookFormArrayInputDate
            min='2000-01-01'
            max={new Date().toISOString().slice(0, 10)}
            fieldName='endDate'
            label='End Date'
          />
          <HookFormArrayInput
            fieldName='title'
            label='Title/Position'
            type='text'
          />
        </>
      )}
      <HookFormArrayRadioGroup
        fieldName='contact'
        label='Do you know anyone who works for our company'
        values={['Yes', 'No']}
        defaultValue='No'
      />
      {watchContact === 'Yes' && (
        <>
          <HookFormArrayInput
            fieldName='firstName'
            label='First Name'
            type='text'
          />
          <HookFormArrayInput
            fieldName='lastName'
            label='Last Name'
            type='text'
          />
          <HookFormArrayInput
            fieldName='phone'
            label='Phone Number'
            type='text'
            subType='phone'
          />
          <HookFormArrayInput fieldName='email' label='Email' type='text' />
        </>
      )}
    </>
  )
}
const Referrals = ({ name }) => {
  const { control } = useFormContext()
  const { state, setState } = useStateContext()
  const { fields, append } = useFieldArray({
    control,
    name
  })

  const addReferral = (e) => {
    append()
    const list = state[name].map((item) => {
      return item
    })
    list.push(e)
    setState({ ...state, [name]: list })
  }

  useEffect(() => {
    if (state[name].length === 0) {
      addReferral({})
    }
  }, [])

  return (
    <>
      <h3>Referral</h3>
      {fields.map((item, index) => (
        <div key={item.id} className='form-element'>
          <ArrayProvider arrayName='referrals' index={index}>
            <Referral id={item.id} />
          </ArrayProvider>
        </div>
      ))}
    </>
  )
}

export { Referrals }
