import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'

const StepOne = ({ save, defaultFirstName, defaultLastName }) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    save(data.firstName, data.lastName)
  }
  return (
    <>
      <h3>Step 1</h3>
      <div style={{ paddingBottom: 20 }}>
        Use the fields below to enter your legal first name and last name. This
        will be utilized throughout the hiring process.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-element'>
          <label>First Name</label>
          <input
            defaultValue={defaultFirstName}
            name='firstName'
            type='text'
            {...register('firstName')}
            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.firstName?.message}</div>
        </div>
        <div className='form-element'>
          <label>Last Name</label>
          <input
            defaultValue={defaultLastName}
            name='lastName'
            type='text'
            {...register('lastName')}
            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.lastName?.message}</div>
        </div>
        <button disabled={isSubmitting} className='btn btn-primary'>
          {isSubmitting && (
            <span className='spinner-border spinner-border-sm mr-1' />
          )}
          Next Step
        </button>
      </form>
      <Link to='login' className='btn btn-link cancel-button back-link'>
        <IconArrow /> BACK TO OPTIONS
      </Link>
    </>
  )
}

export default StepOne
