import { useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts/useStateContext'
import * as Yup from 'yup'
import { RecoilInput } from './RecoilInput'
import React, { useEffect } from 'react'
import { useUserActions } from '../../../_actions'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../../_state'
import Modal from 'react-modal'
import styled from 'styled-components'
import { ReactComponent as IconClose } from '../../../resources/images/icons/close.svg'
import { SignaturePad } from '../SignaturePad'

const ModalContent = styled.div`
  padding: 15px;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

const ApplySignatureValidationSchema = Object({
  consent: Yup.boolean().test(
    'is-true',
    'You must consent to submit this form',
    (value) => value === true
  )
})

const ApplySignature = (props) => {
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const user = useRecoilValue(userAtom)
  const { state, setState } = useStateContext()
  const { formState } = useFormContext()
  const [validSignature, setValidSignature] = React.useState(false)
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function closeModal () {
    setIsOpen(false)
  }
  function afterOpenModal () {
    // things to do after modal opening
  }

  useEffect(() => {
    if (state.consent === true) {
      userActions.getSignatureUrl(user.id).then((value) => {
        console.log(value)
        const consentDt = new Date().toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        setState({
          ...state,
          consentDate: consentDt,
          signatureSrc: value.signature.upload_url
        })
      }).catch((err) => {
        setState({ ...state, consentDate: '', signatureSrc: '' })
        setValidSignature(false)
        openModal()
      })
    } else {
      setState({ ...state, consentDate: '', signatureSrc: '' })
    }
  }, [state.consent])

  const setSignatureUrl = (url) => {
    const consentDt = new Date().toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
    setState({ ...state, consentDate: consentDt, signatureSrc: url })
    setValidSignature(true)
    closeModal()
  }
  const _bodyContent = () => {
    if (props.body) {
      return props.body
    } else {
      return 'By signing below I acknowledge that all information provided herein is true to the best of the my knowledge and that I consent to Employifi and its affiliates using this information to verify the information provided.'
    }
  }
  return (
    <>
      {props.children}
      <div className='form-element'>
        <label>Click here to conset and to use the signature on file</label>
        <RecoilInput
          type='checkbox'
          name='consent'
          className={`form-control ${
            formState.errors.consent ? 'is-invalid' : ''
          }`}
        />
        <div className='invalid-feedback'>
          {formState.errors.consent?.message}
        </div>
      </div>
      <div className='form-element'>
        <label>Date</label>
        <RecoilInput
          type='text'
          readOnly
          name='consentDate'
          className={`form-control ${
            formState.errors.consentDate ? 'is-invalid' : ''
          }`}
        />
        <div className='invalid-feedback'>
          {formState.errors.consentDate?.message}
        </div>
      </div>
      {state.signatureSrc !== '' && state.signatureSrc !== undefined && (
        <div className='form-element'>
          <label>Signature</label>
          <img src={state.signatureSrc} />
          <div className='invalid-feedback'>
            {formState.errors.date?.message}
          </div>
        </div>
      )}
      {validSignature === false && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel='Signature Modal'
        >
          <ModalContent>
            <h2 ref={(modalTitle) => modalTitle}>
              Please add a signature to your profile
            </h2>
            <IconClose onClick={closeModal} />
            <SignaturePad type='signature' canvasWidth={500} canvasHeight={300} setUrl={setSignatureUrl} />
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export { ApplySignature, ApplySignatureValidationSchema }
