import styled from 'styled-components'
import { JobDetail } from '../shared/JobDetail'

const ApplicantJobDetailsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;
  display: flex;
  padding: 45px 30px;
  box-sizing: border-box;
  margin: 25px 0;

  & > div {
    margin-right: 40px;
    flex-grow: 1;
  }
  & > div:last-of-type {
    margin-right: 0px;
  }
`

export const ApplicantJobDetails = ({ job }) => {
  return (
    <ApplicantJobDetailsWrapper>
      <JobDetail label='Start Date' detail={job.start_date} />
      <JobDetail label='Location' detail={job.location.title} />
      <JobDetail label='Department' detail={job.department.title} />
      <JobDetail label='Pay Amount' detail={String(job.amount)} />
      <JobDetail label='Pay Type' detail={job.pay_structure} />
      <JobDetail label='Vacation' detail={job.vacation} />
      <JobDetail label='Bonus' detail={job.bonus} />
    </ApplicantJobDetailsWrapper>
  )
}
