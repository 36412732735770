import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useUserActions, useJobActions } from '../_actions'
import { AdminLinkApplicantTabs } from '../components/Admin/AdminLinkApplicantTabs'
import { AdminStepHeader } from '../components/Admin/AdminStepHeader'
import { AdminApplicantInfo } from '../components/Admin/AdminApplicantInfo'
import AdminWrapper from './shared/AdminWrapper'
import HeaderWrapper from './shared/HeaderWrapper'
import queryString from 'query-string'
import { history } from '../_helpers'

const AdminApplicantJobCreation = () => {
  const { locationId, userId } = useParams()
  const userAction = useUserActions(localStorage.getItem('access_token'))
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const [applicantInfoDetails, setApplicantInfoDetails] = useState({})
  const [jobsData, setJobsData] = useState([])
  const [jobsLoading, setJobsLoading] = useState(true)
  const [templatesData, setTemplatesData] = useState([])
  const [templatesLoading, setTemplatesLoading] = useState(true)
  const [templateJobData, setTemplateJobData] = useState()
  const [templateLoading, setTemplateLoading] = useState(true)

  const urlParams = queryString.parse(window.location.search)

  useEffect(() => {
    if (urlParams.templateId) {
      const getTemplateJobInfo = async () => {
        const t = await jobActions.getTemplate(locationId, urlParams.templateId)
        setTemplateJobData(t.job_template)
        setTemplateLoading(false)
      }
      getTemplateJobInfo()
    } else {
      setTemplateLoading(false)
    }

    const getUserInfo = async () => {
      const { user } = await userAction.getUserInfo(userId)
      setApplicantInfoDetails({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        id: user.id
      })
    }
    getUserInfo()

    const getPrePopulatedJobsListInfo = async () => {
      const jts = await jobActions.recentTenJobsAndTemplates(locationId)
      setJobsData(jts)
      setJobsLoading(false)
    }
    getPrePopulatedJobsListInfo()

    const getPrePopulatedTemplatesListInfo = async () => {
      const ts = await jobActions.recentTenTemplates(locationId)
      setTemplatesData(ts)
      setTemplatesLoading(false)
    }
    getPrePopulatedTemplatesListInfo()
  }, [])

  const saveJob = async (data, templateName) => {
    let location_id = locationId
    if (location_id === 'null') {
      location_id = data.location_id
    }
    const createdJob = await jobActions.createJob(
      { ...data, location_id },
      templateName
    )
    history.push(
      `/admin/locations/${locationId}/users/${userId}/jobs/${createdJob.id}/review`
    )
  }

  const searchJobs = (q) => {
    setJobsLoading(true)
    jobActions.recentTenJobsAndTemplatesSearch(locationId, q).then((jts) => {
      setJobsData(jts)
      setJobsLoading(false)
    })
  }

  const searchTemplates = (q) => {
    setTemplatesLoading(true)
    jobActions.recentTenTemplatesSearch(locationId, q).then((jts) => {
      setTemplatesData(jts)
      setTemplatesLoading(false)
    })
  }

  return (
    <AdminWrapper>
      <HeaderWrapper
        title='Manage Applicant Onboarding'
        link={`/admin/locations/${locationId}/dashboard`}
      />

      <AdminStepHeader stepNumber={1} title='Review Applicant Information' />
      <AdminApplicantInfo details={applicantInfoDetails} />

      <AdminStepHeader stepNumber={2} title='Link Applicant to Job' />
      <AdminLinkApplicantTabs
        jobsData={jobsData}
        jobsLoading={jobsLoading}
        searchJobs={searchJobs}
        templatesData={templatesData}
        templatesLoading={templatesLoading}
        searchTemplates={searchTemplates}
        templateJobData={templateJobData}
        templateLoading={templateLoading}
        defaultTabIndex={
          urlParams.defaultTab ? parseInt(urlParams.defaultTab) : 2
        }
        saveJob={saveJob}
        saveJobText='Save & Continue'
      />
    </AdminWrapper>
  )
}

export default AdminApplicantJobCreation
