import { useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ApplicantGenericTable } from '../Applicant/ApplicantGenericTable'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import { history } from '../../_helpers'

const AdminJobTemplateSearchWrapper = styled.div`
  margin-top: 45px;

  .search-row {
    display: flex;
    margin-bottom: 30px;

    .search-label {
      font0size: 14px;
      color: #707070;
    }

    button {
      margin-left: auto;
      border: none;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 8px 15px;
      border-radius: 20px;
      text-transform: uppercase;
      font-family: 'Montserrat';
      height: 40px;
      align-self: flex-end;

      &:hover {
        background-color: #699b9d;
        cursor: pointer;
      }
    }
  }

  .select-button div {
    display: flex;
    align-content: flex-end;
  }

  .select-button div button {
    margin-left: auto;
  }

  .is-template {
    width: 25px;
    height: 25px;
    margin-top: 6px;
    background: #81bcbf;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 12px;
      background-color: #fff;
      left: 12px;
      top: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 3px;
      background-color: #fff;
      left: 7px;
      top: 14px;
    }
  }
`

const SearchWrapper = styled.div`
  position: relative;

  svg {
    width: 25px;
    position: absolute;
    top: 46px;
    left: 10px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 0;
    font-size: 16px;
    display: block;
    width: 400px;
  }
`

export const AdminJobTemplateSearch = ({
  searchThing,
  data,
  loading,
  search
}) => {
  const [searchText, setSearchText] = useState('')

  function clearSearch () {
    setSearchText('')
  }

  const dounceSearchThing = useCallback(
    debounce((val) => {
      search && search(val)
    }, 1000),
    []
  )

  const handleChange = (e) => {
    setSearchText(e.target.value)
    if (e.target.value.length > 2) {
      dounceSearchThing(e.target.value)
    }
  }

  const { locationId, userId } = useParams()

  const jobTemplateSearchColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Created Date', dataIndex: 'created_date', key: 'created_date' },
    {
      title: 'Template',
      dataIndex: 'template',
      key: '',
      width: 250,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            <span className={template ? 'is-template' : ''}>{template}</span>
            <button
              onClick={() => {
                if (template) {
                  // Because this is the same url but only query params change history.push doesn't work
                  window.location.href = `/admin/locations/${locationId}/users/${userId}/jobs/new?defaultTab=2&templateId=${row.id}`
                } else {
                  history.push(
                    `/admin/locations/${locationId}/users/${userId}/jobs/${row.id}/review`
                  )
                }
              }}
            >
              Select
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <AdminJobTemplateSearchWrapper>
      <div className='search-row'>
        <SearchWrapper>
          <span className='search-label'>
            Search for an existing {searchThing}
          </span>
          {searchText === ''
            ? (
              <IconSearch />
              )
            : (
              <IconClose
                onClick={() => {
                  clearSearch()
                }}
              />
              )}
          <input
            htmlFor='search'
            type='text'
            name='jobTemplateSearch'
            value={searchText}
            onChange={handleChange}
          />
        </SearchWrapper>
      </div>
      <ApplicantGenericTable
        loading={loading}
        columns={jobTemplateSearchColumns}
        data={data}
        rowKey='id'
      />
    </AdminJobTemplateSearchWrapper>
  )
}
