import { useEffect, useState } from 'react'
import { useUserActions } from '../../_actions'
import { useParams } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import { AdminAddApplicant } from '../../components/Admin/AdminAddApplicant'

const ApplicantEdit = ({ savedCallback }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({})
  const { locationId, jobId, userId } = useParams()
  const userActions = useUserActions(
    localStorage.getItem('access_token')
  )
  useEffect(() => {
    userActions.getUserInfo(userId).then((apiUser) => {
      setUser(apiUser.user)
      setIsLoading(false)
    })
  }, [])

  const updateJob = async (data) => {
    userActions.update(userId, data).then(() => {
      savedCallback()
    })
  }

  return (
    <>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (<AdminAddApplicant
            saveApplicant={updateJob}
            saveLinkText='Update'
            linkJob={`/admin/locations/${locationId}/users/${userId}/jobs/${jobId}`}
            loading={isLoading}
            hideEmail
            usePhone
            user={user}
           />)}
    </>
  )
}

export default ApplicantEdit
