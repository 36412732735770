import styled from 'styled-components'

const ReviewWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  color: #272d3b;

  .label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
`

const ReviewNotice = styled.div`
  width: 100%;
  background-color: #de7e53;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  font-size: 15px;
  margin-top: 25px;

  span {
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
  }
`

const Cols = styled.div`
  display: flex;
  margin: 25px 0;
  max-height: 400px;
  overflow: auto;
`

const Details = styled.div`
  width: 350px;

  span {
    display: block;

    &.orange {
      font-size: 14px;
      color: #de7e53;
    }
  }

  .flag {
    color: #de7e53;
    font-weight: bold;
    font-size: 14px;
  }

  div {
    margin-bottom: 20px;
  }

  .split {
    display: flex;

    div {
      flex: 1;
    }
  }
`

const Notes = styled.div`
  flex: 1;

  .notes {
    font-size: 14px;
    line-height: 30px;
    white-space: pre-wrap;
  }
`

export const Review = ({ data }) => {
  return (
    <ReviewWrapper>
      <ReviewNotice>
        Please review all information below to confirm Pre-Adverse Action
        details are correct. Upon review of information click the “Submit”
        button to complete the current assessment.
        <span>This will send the Pre-Adverse Action Notice to Applicant.</span>
      </ReviewNotice>
      <Cols>
        <Details>
          <div>
            <span className='label'>Applicant Name</span>
            <span className='info'>{data.applicantName}</span>
          </div>
          <div>
            <span className='label'>Job Title</span>
            <span className='info'>{data.jobTitle}</span>
          </div>
          <div>
            <span className='label'>Flags</span>
            {data.flags.map((flag, i) => {
              return (
                <span key={i} className='flag'>
                  {flag}
                </span>
              )
            })}
          </div>
          <div>
            <span className='label'>Offense</span>
            <span className='info orange'>{data.offense1}</span>
          </div>
          <div className='split'>
            <div>
              <span className='label'>Offense</span>
              <span className='info orange'>{data.offense2}</span>
            </div>
            <div>
              <span className='label'>Time Since</span>
              <span className='info orange'>{data.timeSince}</span>
            </div>
          </div>
        </Details>
        <Notes>
          <span className='label'>Notes</span>
          <div className='notes'>{data.notes}</div>
        </Notes>
      </Cols>
    </ReviewWrapper>
  )
}
