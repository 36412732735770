import { Controller, useFormContext } from 'react-hook-form'
import { useArrayContext, useStateContext } from '../../../contexts'

const HookFormArrayInputDate = ({ label, fieldName, min, max }) => {
  const { control, formState } = useFormContext()
  const { index, arrayName } = useArrayContext()
  const { state, setState } = useStateContext()
  const { errors } = formState
  const onChange = (e, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue=''
          render={({ field }) => (
            <input
              {...field}
              className='input-inline'
              type='date'
              min={min}
              max={max}
              onChange={(e) => field.onChange(onChange(e, index))}
            />
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayInputDate }
