import { useSetRecoilState } from 'recoil'

import { useFetchWrapper } from '../_helpers'
import { notificationsAtom } from '../_state'

function useNotificationActions (jobId) {
  const token = localStorage.getItem('access_token')

  const baseUrl = `${process.env.REACT_APP_API_URL}/jobs/${jobId}/notifications`

  const fetchWrapper = useFetchWrapper(token)
  const setNotifications = useSetRecoilState(notificationsAtom)

  const getAll = () => fetchWrapper.get(baseUrl).then(setNotifications)

  return {
    getAll
  }
}

export { useNotificationActions }
