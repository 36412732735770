import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { job as jobAtom } from '../../../_state'
import { useRecoilValue } from 'recoil'
import { StateProvider } from '../../../contexts'
import { useState, useEffect } from 'react'
import { useReloadJob } from '../../../_helpers'
import { useTasksActions } from '../../../_actions'
import * as Yup from 'yup'
import {
  RecoilInput,
  ApplySignature,
  ApplySignatureValidationSchema, HookFormToggleSwitch
} from '../../shared/HookFormComponents'

const ApplicantAuthorization = () => {
  const { id } = useParams()
  const job = useRecoilValue(jobAtom)
  const [state, setState] = useState({ driverLicense: {} })
  const { backToJob } = useReloadJob()
  const taskActions = useTasksActions(localStorage.getItem('access_token'))

  useEffect(() => {
    const taskId = job.tasks.find(item => item.title === 'Personal Information').id
    console.log(taskId)
    taskActions.get(taskId)
      .then((ret) => {
        if (ret.data !== null) {
          setState(ret.data)
        }
      })
  }, [])

  // form validation rules
  const validationSchema = Yup.object().shape(ApplySignatureValidationSchema)

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: state,
    values: state
  }

  const methods = useForm(formOptions)

  const onSubmit = (_data) => {
    const submission = {
      data: _data
    }
    taskActions.update(id, submission)
      .then((av) => {
        backToJob(job.id)
      })
  }

  const onError = (errors, e) => {
    console.log(errors, e)
  }

  const getFormatedAddress = () => {
    const nameArray = []
    if (state.streetLine1 && state.streetLine1 !== null && state.streetLine1 !== '') {
      nameArray.push(state.streetLine1)
    }
    if (state.streetLine2 && state.streetLine2 !== null && state.streetLine2 !== '') {
      nameArray.push(state.streetLine2)
    }
    if (state.city && state.city !== null && state.city !== '') {
      nameArray.push(state.city)
    }
    if (state.state && state.state !== null && state.state !== '') {
      nameArray.push(state.state)
    }
    if (state.postalCode && state.postalCode !== null && state.postalCode !== '') {
      nameArray.push(state.postalCode)
    }
    return nameArray.join(', ')
  }

  const getFormattedAlias = (a) => {
    const aliasArray = []
    if (a.firstName && a.firstName !== null) {
      aliasArray.push(a.firstName)
    }
    if (a.middleName && a.middleName !== null) {
      aliasArray.push(a.middleName)
    }
    if (a.lastName && a.lastName !== null) {
      aliasArray.push(a.lastName)
    }
    return aliasArray.join(' ')
  }

  const getFormattedAliases = () => {
    const aliasArray = []
    if (state.aliases !== undefined && state.aliases !== null && state.aliases.length > 0) {
      state.aliases.forEach((a) => {
        aliasArray.push(<div className='compliance-sub'>{getFormattedAlias(a)}</div>)
      })
    }
    return aliasArray
  }

  return (
    <>
      <ApplicantWrapper>
        <FormContentWrapper>
          <StateProvider state={state} setState={setState}>
            <FormProvider {...methods}>
              <form
                className='form-wrapper'
                onSubmit={methods.handleSubmit(onSubmit, onError)}
              >
                <h1>Compliance</h1>
                <h3>Application Certification and Authorization</h3>
                <ApplySignature>
                  <div className='compliance'>
                    I hereby give <em>{job.company.display_name}</em> and EMPLOYEE RELATIONS, INC. the right to conduct an investigation of my background.
                    I understand that the investigation may include inquiry into my past employment, education, and activities, including,
                    but not limited to, credit, criminal background information and driving record. I understand that you may contact my previous
                    employers and I authorize those employers to disclose to you all records pertinent to my employment with them. Should an
                    investigative consumer report be obtained from Employee Relations, Inc. in connection with my application for employment,
                    I understand that I have the right to receive a copy of my report, free of charge, by checking the box below.
                  </div>
                  <div className='form-element'>
                    <label>
                      Please provide a copy of my background investigation.
                    </label>
                    <RecoilInput
                      type='checkbox'
                      name='provideCopy'
                      className={`form-control ${methods.formState.errors.provideCopy ? 'is-invalid' : ''
                        }`}
                    />
                    <div className='invalid-feedback'>
                      {methods.formState.errors.provideCopy?.message}
                    </div>
                  </div>
                  <div className='compliance'>
                    <div><span className='bold'>Applicant Name:</span> {state.firstName} {state.lastName}</div>
                    <div><span className='bold'>Phone:</span> {state.primaryPhone}</div>
                    <div><span className='bold'>Address:</span> {getFormatedAddress()}</div>
                    <div><span className='bold'>Other Name(s) Under Which Records May Be Listed:</span>
                      {getFormattedAliases()}
                    </div>
                    <div><span className='bold'>Social Security Number:</span> {state.ssn}</div>
                    <div><span className='bold'>Driver's License Number:</span> {state.dlLicenseNumber}</div>
                    <div><span className='bold'>State:</span> {state.dlState}</div>
                    <div><span className='bold'>Expiration:</span> {state.dlExpirationDate}</div>
                  </div>
                </ApplySignature>
                <HookFormToggleSwitch state={state} setState={setState} name='final' label='I am finished and ready to submit' />
                <div className='input-element'>
                  <button
                    type='button'
                    onClick={() => {
                      backToJob(job.id)
                    }}
                  >
                    Go Back
                  </button>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </form>
            </FormProvider>
          </StateProvider>
        </FormContentWrapper>
      </ApplicantWrapper>
    </>
  )
}

export default ApplicantAuthorization
