import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'
import { ApplicantDetailActions } from '../ApplicantDetailActions'
import { ApplicantDetailDocumentLibrary } from '../ApplicantDetailDocumentLibrary'
import { ApplicantDetailAlertsAndNotifications } from '../ApplicantDetailAlertsAndNotifications'
import { PreAdverseActionAssessment } from '../PreAdverseActionAssessment'
import TableWithHeaderAndAddButton from '../../admin/shared/TableWithHeaderAndAddButton'
import OrdersNew from '../../admin/orders/new'
import { useOrderActions, useDBAlertsActions } from '../../_actions'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userAtom } from '../../_state'

const AdminDashboardTabsWrapper = styled.div`
  margin-top: 60px;

  .cols {
    display: flex;
    align-items:flex-start;

    >div:first-child {
      margin-right: 20px;
      width: 60%;
      height: auto;
    }
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;

    li {
      color: #272D3B;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 0;
      margin-right: 50px;
      position: relative;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #DE7E53;
        }
      }
    }
  }
`

const orderColumns = [
  { title: 'Product ID', dataIndex: 'productId', key: 'productId' },
  { title: 'Completed', dataIndex: 'completed', key: 'completed' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Product Name', dataIndex: 'productName', key: 'productName' }
]

export const AdminDashboardTabs = ({ defaultTabIndex, applicantName, jobTitle, tasks, alerts, orders, actions, flags, assesses, jobAdverseAction, submitData, job }) => {
  const [user] = useRecoilState(userAtom)
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0)
  const [selectedFlagIds, setSelectedFlagIds] = useState(jobAdverseAction?.adverse_action_flag_titles || [])
  const [selectedAssessIds, setSelectedAssessIds] = useState(jobAdverseAction?.adverse_action_assess_ids || [])
  const [timeSinceOffense, setTimeSinceOffense] = useState(jobAdverseAction?.time_since_offense || '')
  const [businessImplications, setBusinessImplications] = useState(jobAdverseAction?.business_implication || '')
  const [confirmedEmailText, setConfirmedEmailText] = useState(false)
  const [applicantNoticeText, setApplicantNoticeText] = useState(jobAdverseAction?.applicant_notice_text || '')
  const [notes, setNotes] = useState(jobAdverseAction?.notes || '')
  const [displayOrders, setDisplayOrders] = useState(orders)
  const [dbAlerts, setDBalerts] = useState(alerts || [])
  const { jobId, locationId, userId } = useParams()
  const dbAlertActions = useDBAlertsActions(jobId)
  const orderActions = useOrderActions(
    localStorage.getItem('access_token')
  )

  const [orderModalOpen, setOrderModalOpen] = useState(false)
  function closeOrderModal () {
    setOrderModalOpen(false)
  }
  function newOrderAdded () {
    orderActions.list(jobId).then((newOrders) => {
      setDisplayOrders(newOrders.map((o) => {
        return {
          id: o.id,
          ordered: o.ordered,
          completed: o.completed,
          status: o.status,
          productName: o.product_name,
          productId: o.product_id
        }
      }))
    })
    closeOrderModal()
  }
  function openOrderModal () {
    setOrderModalOpen(true)
  }

  const setSelectedFlag = (ids, id) => {
    if (ids.includes(id)) {
      setSelectedFlagIds(ids.filter((i) => i !== id))
    } else {
      setSelectedFlagIds([...ids, id])
    }
  }

  const setSelectedAssesses = (ids, id) => {
    if (ids.includes(id)) {
      setSelectedAssessIds(ids.filter((i) => i !== id))
    } else {
      setSelectedAssessIds([...ids, id])
    }
  }

  const reloadAlerts = async () => {
    const apialerts = await dbAlertActions.getAll()
    setDBalerts(apialerts?.map((a) => {
      return {
        id: a.id,
        date: a.display_date,
        type: a.type,
        display_type: a.display_type,
        details: a.details,
        alert_messages: a.alert_messages,
        title: a.title,
        editLink: a.sender_id === user?.id ? `/admin/locations/${locationId}/users/${userId}/jobs/${jobId}/alerts/${a.id}/edit` : undefined
      }
    }))
  }

  const assess = assesses.map((aaa) => { return { ...aaa, setSelect: setSelectedAssesses } })
  const adverseAction = (actions || []).find((a) => a.title === 'Adverse Action')
  return (
    <AdminDashboardTabsWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab key='tab_1'>Dashboard</Tab>
          <Tab key='tab_2'>Alerts & Notifications</Tab>
          <Tab key='tab_3'>Order</Tab>
          {adverseAction && !adverseAction.buttonEnabled &&
            <Tab key='tab_4'>Adverse Action</Tab>}
        </TabList>

        <TabPanel key='tabPanel_1'>
          <div className='cols'>
            <ApplicantDetailDocumentLibrary job={job} documents={tasks} admin />
            <ApplicantDetailActions actions={actions} />
          </div>
        </TabPanel>
        <TabPanel key='tabPanel_2'>
          <ApplicantDetailAlertsAndNotifications data={dbAlerts} reloadAlerts={reloadAlerts} />
        </TabPanel>
        <TabPanel key='tabPanel_3'>
          <TableWithHeaderAndAddButton
            headerText='Orders'
            modalOpen={orderModalOpen}
            closeModal={closeOrderModal}
            openModal={openOrderModal}
            modalContent={<OrdersNew orders={displayOrders} savedCallback={newOrderAdded} />}
            modalTitleText='Order Product'
            columns={orderColumns}
            data={displayOrders}
          />
        </TabPanel>
        {adverseAction && !adverseAction.buttonEnabled &&
          <TabPanel key='tabPanel_4'>
            <h2>Pre-Adverse Action Assessment</h2>
            <PreAdverseActionAssessment
              flags={(flags || []).filter((a) => a.type === null).map((a) => {
                return {
                  date: a.display_date,
                  type: a.type,
                  title: `${a.title} `,
                  details: a.details,
                  setSelect: setSelectedFlag
                }
              })}
              assess={assess}
              timeSinceOffense={timeSinceOffense}
              businessImplications={businessImplications}
              selectedFlags={selectedFlagIds}
              selectedAssess={selectedAssessIds}
              setTimeSinceOffense={setTimeSinceOffense}
              setBusinessImplications={setBusinessImplications}
              confirmedEmailText={confirmedEmailText}
              setConfirmedEmailText={setConfirmedEmailText}
              applicantNoticeText={applicantNoticeText}
              setApplicantNoticeText={setApplicantNoticeText}
              notes={notes}
              setNotes={setNotes}
              details={{
                applicantName,
                jobTitle,
                flags: assess.filter(t => selectedAssessIds.includes(t.val)).map(t => t.label),
                offense1: jobAdverseAction?.offense_one || '-',
                offense2: jobAdverseAction?.offense_two || '-',
                timeSince: timeSinceOffense,
                notes
              }}
              submitData={async () => {
                await submitData({ selectedFlagIds, selectedAssessIds, timeSinceOffense, businessImplications, applicantNoticeText, notes })
              }}
            />
          </TabPanel>}
      </Tabs>
    </AdminDashboardTabsWrapper>
  )
}
