import { Auth } from '../Auth0'
import { useRecoilState } from 'recoil'
import { userAtom } from '../_state'
import { Redirect, useLocation } from 'react-router-dom'

function RequireApplicant ({ children }) {
  const location = useLocation()
  const auth = new Auth()
  const [user] = useRecoilState(userAtom)
  const roles = user?.roles?.map((r) => r.name) || []

  if (!auth.isAuthenticated()) {
    return <Redirect to='/login' state={{ from: location }} replace />
  } else if (!roles.includes('User')) {
    return <Redirect to='/admin/company/dashboard' replace />
  }

  return children
}

export default RequireApplicant
