import * as Yup from 'yup'
import { EmploymentHistory } from '../EmploymentHistory'
import { Step } from './Step'
const EmploymentInfo = (props) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    streetLine1: Yup.string().required('Street Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required')
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps} isArray>
        <h3>Employment History</h3>
        <EmploymentHistory name='employmentHistory' readOnly={props.readOnly} />
      </Step>
    </>
  )
}
EmploymentInfo.componentName = 'EmploymentInfo'
export { EmploymentInfo }
