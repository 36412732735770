import styled from 'styled-components'

const JobDetailWrapper = styled.div`
  color: #272d3b;
  margin-right: 25px;

  .label {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: 7px;
    color: #707070;
  }

  .text {
    font-size: 16px;
    display: block;
    font-weight: 500;
    // word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;

    @media only screen and (max-width: 1300px) {
      font-size: 14px;
    }
  }
`

interface JobDetailProps {
  label: string
  detail: any
}

export const JobDetail = ({ label, detail }: JobDetailProps) => {
  return (
    <JobDetailWrapper>
      <span className="label">{label}</span>
      <span className="text">{detail}</span>
    </JobDetailWrapper>
  )
}
