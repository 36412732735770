import { useFormContext } from 'react-hook-form'
import { AsYouType } from 'libphonenumber-js'
import { useStateContext } from '../../../contexts/useStateContext'
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import localeEn from 'air-datepicker/locale/en'
import { useEffect } from 'react'

const RecoilInput = ({
  name,
  type,
  subType,
  preOnChange,
  postOnChange,
  readOnly,
  ...newProps
}) => {
  const { register, formState } = useFormContext()
  const { state, setState, subState } = useStateContext()
  useEffect(() => {
    if (subType === 'date') {
      new AirDatepicker(`#${name}`, {
        locale: localeEn,
        autoClose: true,
        dateFormat: 'MM/dd/yyyy',
        onSelect ({ date, formattedDate, datepicker }) {
          // setState((latestState) => ({ ...latestState, [name]: formattedDate }))
          if (subState) {
            const subStateValue = JSON.parse(JSON.stringify(state[subState]))
            subStateValue[name] = formattedDate
            console.log(subStateValue)
            setState({ ...state, [subState]: subStateValue })
          } else {
            setState({ ...state, [name]: formattedDate})
          }
        }
      })
    }
  }, [])

  // console.log("RecoilInput props: ", props)
  const onchange = (e) => {
    if (preOnChange) {
      preOnChange(e)
    }
    if (subType && subType === 'phone') {
      const asYouType = new AsYouType('US')
      if (e.target.value) {
        if (e.target.value.length > 4) {
          e.target.value = asYouType.input(e.target.value)
          setState({ ...state, [e.target.name]: asYouType.formattedOutput })
        }
      }
    } else {
      if (type === 'checkbox') {
        setState({ ...state, [e.target.name]: e.target.checked })
      } else if (type === 'file') {
        setState({ ...state, [e.target.name]: e.target.files[0] })
      } else {
        if (subState) {
          const subStateValue = JSON.parse(JSON.stringify(state[subState]))
          subStateValue[name] = e.target.value
          console.log(subStateValue)
          setState({ ...state, [subState]: subStateValue })
        } else {
          setState({ ...state, [e.target.name]: e.target.value })
        }
      }
    }
    if (postOnChange) {
      postOnChange(e)
    }
  }

  if (register) {
    return (
      <>
        <input
          name={name}
          type={type}
          className={`form-control ${
            formState.errors?.[name] ? 'is-invalid' : ''
          }`}
          {...register(name, {
            onChange: (e) => {
              onchange(e)
              // if (postOnChange) postOnChange(e)
            }
          })}
          {...newProps}
          readOnly={readOnly}
        />
        <div className='invalid-feedback'>
          {formState.errors?.[name]?.message}
        </div>
      </>
    )
  } else {
    return (
      <>
        <input
          name={name}
          type={type}
          {...newProps}
          onChange={onchange}
        />
        <div className='invalid-feedback'>
          {formState.errors[name]?.message}
        </div>
      </>
    )
  }
}

export { RecoilInput }
