import { useState } from 'react'
import styled from 'styled-components'
import { ToggleSwitch } from '../shared/ToggleSwitch'
import Select from 'react-select'

const HRManagementWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  min-height: 20px;
  box-sizing: border-box;
  border: 1px solid #f5f5f5;
  display: flex;
  font-size: 16px;
  margin-bottom: 15px;

  .toggle-label {
    color: #272d3b;
    display: inline-block;
    margin-top: 5px;
  }
`

const ActiveToggle = styled.div`
  width: 350px;

  .info {
    display: block;
    color: #707070;
    margin-top: 20px;
  }
`

const RecruiterDropdown = styled.div`
  padding-left: 80px;

  span {
    display: block;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .select__control {
    width: 400px;
  }
`

const ToggleWrapper = styled.div`
  display: inline-block;
  margin: 0 20px 0 0;
`

export const HRManagement = ({ hrUsers, setSelected, setToggle }) => {
  const [recruiterActive, setRecruiterActive] = useState(false)

  const toggleActiveButton = (val) => {
    setRecruiterActive(val)
    setToggle(val)
  }

  const handleSelectChange = (val) => {
    setSelected && setSelected(val)
  }

  return (
    <HRManagementWrapper>
      <ActiveToggle>
        <>
          <ToggleWrapper>
            <ToggleSwitch
              value={recruiterActive || false}
              activeText='Assign Recruiter is Active'
              inactiveText='Assign Recruiter is Inactive'
              onToggleFunction={(value) => {
                toggleActiveButton(!value)
              }}
            />
          </ToggleWrapper>
        </>
        <span className='info'>
          If you want this job to be managed by your entire organization, the
          toggle should indicate “Inactive”.
        </span>
      </ActiveToggle>
      <RecruiterDropdown>
        <span>Recruiter / Agent</span>
        <Select
          className='basic-single'
          classNamePrefix='select'
          defaultValue={{ label: 'Choose a Recruiter / Agent', value: '' }}
          onChange={(val) => {
            handleSelectChange(val)
          }}
          isSearchable={false}
          name='recruiter'
          options={hrUsers}
        />
      </RecruiterDropdown>
    </HRManagementWrapper>
  )
}
