import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'

import { useCompanyActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'

import { basicButton } from '../../../../_helpers/shared-css'

import styled from 'styled-components'

const StyledTitle = styled.div`
    font-size: 24px;
    color: #E28A57;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 20px;
`

const StyledFormWrapper = styled.div`
    width: 100%;
    box-shadow: 0px 3px 6px #272d3b33;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 40px;
    margin-top: 15px;
`

const StyledRadioButton = styled.div`
  padding: 15px;
`

const StyledLink = styled.div`
  padding-left: 20px;
  button {
    ${basicButton}
  }
`

const AdverseActionDaysEdit = () => {
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const [days, setDays] = useState(7)
  const { companyId } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    companyActions.getCompany(companyId).then((companyApi) => {
      setDays(companyApi?.company?.adverse_action_timeout_days)
      setIsLoading(false)
    })
  }, [])

  const onValueChange = (e) => {
    setDays(parseInt(e.target.value))
  }

  const formSubmit = (e) => {
    e.preventDefault()
    const save = async () => {
      setIsLoading(true)
      await companyActions.updateCompany(companyId, { adverse_action_timeout_days: days })
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  return (
    <StyledFormWrapper>
      <StyledTitle>Adverse Action Timeout Days</StyledTitle>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <form onSubmit={formSubmit}>
            <StyledRadioButton>
              <label>
                <input
                  type='radio'
                  value='7'
                  checked={days === 7}
                  onChange={onValueChange}
                />
                7 Days
              </label>
            </StyledRadioButton>
            <StyledRadioButton>
              <label>
                <input
                  type='radio'
                  value='10'
                  checked={days === 10}
                  onChange={onValueChange}
                />
                10 Days
              </label>
            </StyledRadioButton>
            <StyledLink>
              <button
                type='submit'
                disabled={isLoading}
                className='btn btn-primary'
              >
                Save
              </button>
            </StyledLink>
          </form>
          )}
    </StyledFormWrapper>
  )
}

export default AdverseActionDaysEdit
