import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconInfo } from '../../resources/images/icons/info.svg'
import { ReactComponent as IconEdit } from '../../resources/images/icons/editB.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Modal from 'react-modal'
import ProductEdit from '../../admin/companies/settings/products/edit'

const ProductsListWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  margin-bottom: 25px;
`

const ProductListTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px 30px;
  border-bottom: 1px solid #f0eeec;

  span {
    font-weight: normal;
  }
`

const ProductListItems = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  .check-wrapper {
    font-size: 14px;
    padding: 20px 0;
    border-bottom: 1px solid #f0eeec;
    display: flex;

    &:nth-child(3n + 1) {
      padding-left: 30px;
    }

    .custom-checkbox {
      width: 20px;
      height: 20px;
      background: #ddd;
      margin: 0;
      border-radius: 100%;
      position: relative;
    }

    .custom-checkbox label {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      background: #fff;
      border: 1px solid #707070;
    }

    .custom-checkbox input[type='checkbox']:checked + label {
      background: #81bcbf;
      border: 1px solid #81bcbf;

      &:before {
        content: '';
        display: inline-block;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 10px;
        width: 5px;
        margin-left: 60%;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        position: absolute;
        top: 2px;
        left: -6px;
      }
    }

    .custom-checkbox-label {
      font-size: 14px;
      margin: 2px 0 0 30px;
      height: 20px;
    }

    .checkbox-info-tooltip {
      height: 20px;
    }
  }
`
const ModalContent = styled.div`
  padding: 15px;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

export const ProductsList = ({
  products,
  title,
  description,
  editable,
  saveChecked,
  customTitle,
  selectedProducts,
  adminEditable,
  fetchProductList
}) => {
  // keeps a list of checked ids in state to be used wherever
  const [checked, setChecked] = useState(selectedProducts || [])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalProductId, setModalProductId] = useState(false)
  function openModal (productId) {
    setModalProductId(productId)
    setIsOpen(true)
  }

  function closeModal () {
    setModalProductId(undefined)
    setIsOpen(false)
  }

  const handleCheck = (event) => {
    let updatedList = [...checked]

    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
    editable && saveChecked && saveChecked(updatedList)
  }

  return (
    <ProductsListWrapper>
      <ProductListTitle>
        {title} <span>{description}</span>
        {customTitle}
      </ProductListTitle>
      <ProductListItems>
        {products.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => (
          <div className='check-wrapper' key={index}>
            <div className={`custom-checkbox product-${item.val}`}>
              <input
                type='checkbox'
                checked={editable ? item.checked : 'checked'}
                value={item.val}
                id={`product-${item.val}`}
                name=''
                onChange={editable ? handleCheck : null}
              />
              <label htmlFor={`product-${item.val}`} />
            </div>
            <div className='custom-checkbox-label'>{item.label}</div>
            {item.tooltip && <>
              <div
                className='checkbox-info-tooltip'
                data-tooltip-id='my-tooltip'
                data-tooltip-content={item.tooltip}
              >
                <IconInfo
                  style={{
                    width: 20,
                    height: 20,
                    fill: '#000',
                    marginLeft: 10
                  }}
                />
              </div>
              <Tooltip id='my-tooltip' />
            </>}
            {adminEditable && <div
              style={{ cursor: 'pointer' }}
              onClick={() => { openModal(item.val) }}
                              >
              <IconEdit
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 10,
                  marginRight: 10
                }}
              />
            </div>}
          </div>
        ))}
      </ProductListItems>
      {modalIsOpen && <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
                      >
        <ModalContent>
          <h2 ref={(modalTitle) => modalTitle}>
            Edit Product
          </h2>
          <IconClose onClick={closeModal} />
          <ProductEdit
            productId={modalProductId} saveCallback={() => {
              closeModal()
              fetchProductList()
            }}
          />
        </ModalContent>
      </Modal>}
    </ProductsListWrapper>
  )
}
