import styled from 'styled-components'

const NotesWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  color: #707070;
`

const NotesTextBox = styled.textarea`
  height: 330px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #707070;
  overflow: auto;
  font-size: 15px;
  padding: 15px;
  margin-bottom: 20px;
  line-height: 30px;
  font-family: 'Montserrat';
`

export const Notes = ({ text, setText }) => {
  return (
    <NotesWrapper>
      <NotesTextBox onChange={(e) => { setText(e.target.value) }} value={text} />
    </NotesWrapper>
  )
}
