import styled from 'styled-components'
import { React, useState } from 'react'
import Modal from 'react-modal'
import { formCss } from '../../_helpers/shared-css'
import { useCompanyActions, useUserActions } from '../../_actions'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { BarLoader } from 'react-spinners'

const AdminAddUserWrapper = styled.span`

  .edit-user-button {
    background-color: #fff;
    font-family: 'Montserrat';
    color: #272d3b;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #c8d1d3;
  }
`

const ModalContent = styled.div`
  padding: 0 30px;
  h3 {
    display: inline-block;
    color: #707070;
    margin: 5px 0 25px 0;
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    maxHeight: '700px',
    borderRadius: '15px',
    position: 'relative'
  }
}

// const DeleteUserButton = styled.button`
//   border: 1px solid #ccc;
//   color: #707070;
//   font-weight: bold;
//   text-transform: uppercase;
//   padding: 8px 15px;
//   font-size: 12px;
//   border-radius: 15px;
//   background: none;
//   display: inline-block;
//   float: right;
//   background: none;
//   color: #707070;
//   box-shadow: none;
// `

const FormWrapper = styled.div`
  margin-top: 25px;
  ${formCss}

  input[type="text"]{
    max-width: 500px;
  }

  button {
    padding: 15px 40px;
    border-radius: 30px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;

    &.save {
      border: 1px solid #81BCBF;
      background: #81BCBF;
      color: #fff;
      margin-right: 30px;
    }

    &.cancel {
      color: #707070;
      background: transparent;
      border: 1px solid #C8D1D3;
      box-shadow: none;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    .form-element {
      flex: 0 0 400px;
    }
  }

  .small {
    max-width: 400px !important;
    margin-right: 20px;
  }

  .email {
    display: inline-block;
  }

  .ext-wrapper {
    max-width: 100px;
    margin-right: auto;
    margin-left: 20px;
  }
`

export const AdminEditUser = ({ companyId, saveCB, userId }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const [isLoading, setIsLoading] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [defaultUser, setDefaultUser] = useState({})

  function openModal () {
    setIsOpen(true)
    fetchUser()
  }

  function afterOpenModal () {
    console.log('on after open')
  }

  function closeModal () {
    setIsOpen(false)
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string(),
    email: Yup.string().email('Email is invalid')
    // title: Yup.string(),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    setIsLoading(true)
    const updateCompanyUser = async () => {
      await companyActions.updateUser(companyId, { ...data, id: userId })
      setIsLoading(false)
      saveCB()
      closeModal()
    }
    updateCompanyUser()
  }

  const fetchUser = async () => {
    setIsUserLoading(true)
    const apiUser = await userActions.getUserInfo(userId)
    console.log('apiUser', apiUser)
    setDefaultUser(apiUser.user)
    setIsUserLoading(false)
  }

  return (
    <AdminAddUserWrapper>
      <button className='edit-user-button' onClick={openModal}>Edit</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=''
      >
        <ModalContent>
          <h3>Edit User</h3>
          {isUserLoading
            ? (
              <div style={{ paddingLeft: 20 }}>
                <BarLoader color='#81BCBF' />
              </div>
              )
            : (<FormWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-element'>
                  <label>First Name</label>
                  <input
                    defaultValue={defaultUser?.first_name || ''}
                    name='firstName'
                    type='text'
                    {...register('firstName')}
                    className={`form-control ${errors.firstName ? 'is-invalid' : ''
                      }`}
                  />
                </div>
                <div className='form-element'>
                  <label>Last Name</label>
                  <input
                    defaultValue={defaultUser?.last_name || ''}
                    name='lastName'
                    type='text'
                    {...register('lastName')}
                    className={`form-control ${errors.lastName ? 'is-invalid' : ''
                      }`}
                  />
                </div>
                {/* <div className='form-element'>
                <label>Title</label>
                <input
                  name='title'
                  type='text'
                  {...register('title')}
                  className={`form-control ${errors.title ? 'is-invalid' : ''
                    }`}
                />
              </div> */}
                <div className='form-element flex'>
                  <label>Phone</label>
                  <input
                    defaultValue={defaultUser?.phone || ''}
                    name='phone'
                    type='text'
                    {...register('phone')}
                    className={`form-control ${errors.phone ? 'is-invalid' : ''
                      }`}
                  />
                </div>
                <div className='form-element flex'>
                  <label>Email</label>
                  <input
                    defaultValue={defaultUser?.email || ''}
                    name='email'
                    type='text'
                    {...register('email')}
                    className={`form-control ${errors.email ? 'is-invalid' : ''
                      }`}
                  />
                </div>
                {isLoading && (
                  <div style={{ paddingLeft: 20 }}>
                    <BarLoader color='#81BCBF' />
                  </div>
                )}
                {!isLoading && (
                  <div className='buttons'>
                    <button disabled={isSubmitting} className='form-button save' onClick={() => { console.log('save') }}>Save</button>
                    <button className='form-button cancel' onClick={closeModal}>Cancel</button>
                  </div>
                )}
              </form>
            </FormWrapper>)}
        </ModalContent>
      </Modal>
    </AdminAddUserWrapper>
  )
}
