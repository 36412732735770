import { useRecoilState, useRecoilValue } from 'recoil'
import { stepsAtom, job as jobAtom, loadingState, applicationVerificationAtom } from '../../../_state'
import {
  FormTimeline,
  GeneralEmploymentInfo,
  TerminatedOrResignedInfo,
  EmploymentInfo,
  ReferenceInfo,
  LegalNotice,
  ReferralInfo
} from '../shared/steps'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useFakeData, useReloadJob } from '../../../_helpers'
import { useTasksActions } from '../../../_actions'
import { EducationInfo } from '../shared/steps/EducationInfo'

const ApplicationVerification = () => {
  const [applicationVerification, setApplicationVerification] = useRecoilState(applicationVerificationAtom)
  const [loading, setLoading] = useRecoilState(loadingState)
  const [steps, setSteps] = useRecoilState(stepsAtom)
  const { id } = useParams()
  const sectionHeading = 'Application Verification'
  const { setApplicationVerificationData } = useFakeData()
  const { backToJob } = useReloadJob()
  const job = useRecoilValue(jobAtom)
  const taskActions = useTasksActions(localStorage.getItem('access_token'))

  console.log(id)
  useEffect(() => {
    setLoading(true)
    setSteps(1)
    taskActions.get(id)
      .then((ret) => {
        console.log(ret)
        if (ret.data !== null) {
          if (!ret.data.employmentHistory) {
            ret.data.employmentHistory = []
          }
          if (!ret.data.referrals) {
            ret.data.referrals = []
          }
          if (!ret.data.educationHistory) {
            ret.data.educationHistory = []
          }
          if (!ret.data.references) {
            ret.data.references = []
          }
          setApplicationVerification(ret.data)
        } else {
          const taskId = job.tasks.find(item => item.title === 'Personal Information').id
          taskActions.get(taskId)
            .then((ret) => {
              ret.data.final = false
              if (!ret.data.employmentHistory) {
                ret.data.employmentHistory = []
              }
              if (!ret.data.referrals) {
                ret.data.referrals = []
              }
              if (!ret.data.educationHistory) {
                ret.data.educationHistory = []
              }
              if (!ret.data.references) {
                ret.data.references = []
              }
              setApplicationVerification(ret.data)
            })
          // resetPersonalInformation()
        }
        setLoading(false)
      })
  }, [])

  const _save = (id, stepName, _data) => new Promise((resolve) => {
    setLoading(true)
    const submission = {
      data: {}
    }
    if (stepName === 'DriverLicenseInfo') {
      submission.data.driverLicense = _data
    } else {
      submission.data = _data
    }

    console.log(submission)
    taskActions.update(id, submission)
      .then((av) => {
        setLoading(false)
        resolve(av)
      })
  })

  const updatePage = (page) => {
    setSteps(page)
  }

  const setPreFillData = () => {
    setApplicationVerificationData()
  }

  const getStepComponent = (type, step) => {
    const pageNav = { updatePage, setPreFillData, backToJob, totalSteps: flow.length, currentStep: step, id }
    if (step < flow.length) {
      pageNav.next = step + 1
    }
    if (step > 1) {
      pageNav.prev = step - 1
    }

    return React.createElement(type, {
      pageNav,
      state: applicationVerification,
      setState: setApplicationVerification,
      sectionHeading,
      job,
      id,
      stepName: type.name,
      save: _save,
      loading,
      readOnly: true
    })
  }

  const flow = [GeneralEmploymentInfo, TerminatedOrResignedInfo, ReferralInfo, EducationInfo, EmploymentInfo, ReferenceInfo, LegalNotice]

  const getCurrentPage = () => {
    return getStepComponent(flow[steps - 1], steps)
  }

  return (
    <ApplicantWrapper>
      <FormContentWrapper>
        <FormTimeline steps={flow.length} currentStep={steps} updatePage={updatePage} />
        {getCurrentPage()}
      </FormContentWrapper>
    </ApplicantWrapper>
  )
}

export default ApplicationVerification
