import * as Yup from 'yup'
import { Step } from './Step'
import React from 'react'
import { Aliases } from '../Alias'

const AliasInfo = (props) => {
  const aliasSchema = {
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required')
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    aliases: Yup.array().of(Yup.object().shape(aliasSchema))
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps} isArray>
        <h3>Please list your aliases below</h3>
        <div style={{ paddingBottom: 20 }}>
          Use the fields below to enter your legal first name and last name.
          This will be utilized throughout the hiring process.
        </div>
        <Aliases name='aliases' readOnly={props.readOnly} />
      </Step>
    </>
  )
}

AliasInfo.componentName = 'AliasInfo'
export { AliasInfo }
