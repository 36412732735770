import pureListOfCountries from 'iso3166-2-db/i18n/en'
import { getDataSet } from 'iso3166-2-db/api'

const listOfCountries = getDataSet('en', pureListOfCountries)

const blank = {
  admin: 'blank',
  fips: '',
  iso: '',
  name: ''
}

const RegionSelector = ({
  name,
  countryFieldName,
  register,
  state,
  stateSetter
}) => {
  const selectedCountry = state[countryFieldName] !== undefined ? state[countryFieldName] : 'US'

  if (!selectedCountry) {
    return null
  }

  const regions = listOfCountries[selectedCountry].regions.sort((a, b) =>
    a.iso.localeCompare(b.iso)
  )

  /* This just looks better. When switching to a new country a blank value is preferrable because not state is selected */
  if (regions[0].admin !== 'blank') regions.unshift(blank)

  return (
    <>
      <select
        name={name}
        value={state.state}
        {...register(name, {
          onChange: (e) => {
            if (state) { stateSetter({ ...state, [e.target.name]: e.target.value }) }
          }
        })}
      >
        {regions.map((region) => (
          <option key={region.admin} value={region.iso}>
            {region.name}
          </option>
        ))}
      </select>
    </>
  )
}

const CountrySelector = ({
  name,
  register,
  regionFieldName,
  state,
  stateSetter,
  setValue,
  disabled = false
}) => {
  return (
    <>
      <select
        name={name}
        {...disabled ? 'disabled' : ''}
        {...register(name, {
          onChange: (e) => {
            if (state) { stateSetter({ ...state, [e.target.name]: e.target.value }) }
            stateSetter({ ...state, [regionFieldName]: '' })
            setValue(regionFieldName, '')
          },
          deps: [regionFieldName]
        })}
      >
        {Object.keys(listOfCountries).map((isoCode) => (
          <option key={isoCode} value={isoCode}>
            {listOfCountries[isoCode].name}
          </option>
        ))}
      </select>
    </>
  )
}

export { CountrySelector, RegionSelector }
