import { Controller, useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts/useStateContext'
import { useArrayContext } from '../../../contexts/useArrayContext'

const HookFormArrayTextArea = ({
  label,
  fieldName,
  type,
  subType,
  ...rest
}) => {
  const { control, formState } = useFormContext()
  const { index, arrayName } = useArrayContext()
  const { state, setState } = useStateContext()
  const { errors } = formState

  const onchange = (e, id) => {
    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    setState({ ...state, [arrayName]: newList })
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue=''
          render={({ field }) => (
            <textarea
              {...field}
              onChange={(e) => field.onChange(onchange(e, index))}
              className='input-inline'
              {...rest}
            />
          )}
        />
        <div className='invalid-feedback'>
          {errors?.[arrayName]?.[index]?.[fieldName]?.message}
        </div>
      </div>
    </>
  )
}

export { HookFormArrayTextArea }
