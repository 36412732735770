import { ReactComponent as IconHome } from '../resources/images/icons/house.svg'
import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconSettings } from '../resources/images/icons/settings.svg'
import { ReactComponent as IconGroup } from '../resources/images/icons/group.svg'

export const sidebarNavButtons = [
  { icon: IconHome, link: '/playground', name: 'home' },
  { icon: IconFolder, link: '/jobs', name: 'jobs' },
  { icon: IconGroup, link: '/applicants', name: 'applicants' },
  { icon: IconSettings, link: '/settings', name: 'settings' }
]
