import { useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ApplicantGenericTable } from '../Applicant/ApplicantGenericTable'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

const AdminCompaniesWrapper = styled.div`
  margin-top: 45px;

  .search-row {
    display: flex;
    margin-bottom: 30px;

    button {
      margin-left: auto;
      border: none;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 8px 15px;
      border-radius: 20px;
      text-transform: uppercase;
      font-family: 'Montserrat';
      height: 40px;
      align-self: flex-end;

      &:hover {
        background-color: #699b9d;
        cursor: pointer;
      }
    }
  }

  .select-button div {
    display: flex;
    align-content: flex-end;
  }

  .select-button div button {
    margin-left: auto;
  }

  .is-template {
    width: 25px;
    height: 25px;
    margin-top: 6px;
    background: #81bcbf;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 12px;
      background-color: #fff;
      left: 12px;
      top: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 3px;
      background-color: #fff;
      left: 7px;
      top: 14px;
    }
  }
`

const SearchWrapper = styled.div`
  position: relative;

  svg {
    width: 25px;
    position: absolute;
    top: 28px;
    left: 10px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 0;
    font-size: 16px;
    display: block;
    width: 400px;
  }
`

export const AdminCompaniesSearch = ({
  data,
  dataColumns,
  loading,
  search
}) => {
  const [searchText, setSearchText] = useState('')

  function clearSearch () {
    setSearchText('')
  }

  const dounceSearchThing = useCallback(
    debounce((val) => {
      search && search(val)
    }, 1000),
    []
  )

  const handleChange = (e) => {
    setSearchText(e.target.value)
    if (e.target.value.length > 2) {
      dounceSearchThing(e.target.value)
    }
  }

  const { _locationId, _userId } = useParams()

  return (
    <AdminCompaniesWrapper>
      <div className='search-row'>
        <SearchWrapper>
          {searchText === ''
            ? (
              <IconSearch />
              )
            : (
              <IconClose
                onClick={() => {
                  clearSearch()
                }}
              />
              )}
          <input
            htmlFor='search'
            type='text'
            name='Companies'
            value={searchText}
            onChange={handleChange}
          />
        </SearchWrapper>
      </div>
      <ApplicantGenericTable
        loading={loading}
        columns={dataColumns}
        data={data}
        rowKey='id'
      />
    </AdminCompaniesWrapper>
  )
}
