import React, { useState } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'
import { ReactComponent as IconChevron } from '../../resources/images/icons/chevron.svg'

interface Props {
  height: any
}

const NavJobListWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: flex-grow 1s;
  flex: 1 0 48px;
  max-height: min-content;
  border-bottom: 1px solid #dedcda;

  span {
    display: block;
    font-size: 14px;
    &:first-child {
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: bold;
    }
  }
`

const Header = styled.div<Props>`
  padding: 10px 20px;
  display: flex;
  height: 48px;
  flex-shrink: 0;
  justify-content: flex-start;

  &:hover {
    background-color: #e1dedb;
    cursor: pointer;
  }

  .header-content span {
    display: block;
  }

  .header-arrow {
    width: 20px;
    float: right;
    padding-right: 10px;
    flex: 0;
    margin: 8px 0 0 auto;

    svg {
      fill: #272d3b;
      width: 30px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      transform: rotate(${(p) => (p.height === 0 ? '270deg' : '90deg')});
    }
  }
`

const ListItem = styled.a`
  padding: 10px 20px;
  display: block;
  background: #fff;
  border-bottom: 1px solid #f0eeec;
  text-decoration: none;
  color: #272d3b;

  &:last-child {
    border-bottom: 0px solid transparent;
  }

  &:hover {
    background-color: #81bcbf;
    color: #fff;
    cursor: pointer;
  }
`

const ListLink = styled.a`
  display: flex;
  text-decoration: none;
  color: #707070;
  padding: 23px 20px 20px;
  background: #fff;

  svg {
    fill: #707070;
    padding-right: 5px;
    width: 25px;
    margin-left: auto;
    transform: rotate(180deg);
  }

  &:hover {
    color: #fff;
    background-color: #81bcbf;

    svg {
      fill: #fff;
    }
  }
`

interface NavJobListProps {
  list: {
    title: string
    allLink: string
    jobs: any[]
  }
}

export const NavJobList = ({ list }: NavJobListProps) => {
  const [height, setHeight] = useState<any | null>(0)

  return (
    <NavJobListWrapper height={height}>
      <Header
        height={height}
        onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
        <div className="header-content">
          <span className="job-list-title">{list.title}</span>
          <span className="job-list-count">{list.jobs.length} Jobs</span>
        </div>
        <span className="header-arrow">
          <IconChevron />
        </span>
      </Header>
      <AnimateHeight height={height}>
        {list.jobs.slice(0, 5).map((job, i) => (
          <ListItem key={i} href={job.link}>
            <span className="job-list-title">{job.title}</span>
            <span className="job-list-location">{job.location}</span>
          </ListItem>
        ))}
        <ListLink href={list.allLink}>
          <span>View All</span>
          <IconArrow />
        </ListLink>
      </AnimateHeight>
    </NavJobListWrapper>
  )
}
