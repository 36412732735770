import { useFetchWrapper } from '../_helpers'

export { useUserActions }

function useUserActions (token = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/users`
  const fetchWrapper = useFetchWrapper(token)

  return {
    register,
    getCurrentUserInfo,
    getSignatureUrl,
    getRegisterSignatureUrl,
    getRegisterInitialsUrl,
    getInitialsUrl,
    forgotPassword,
    logout,
    searchUsersByName,
    getUserInfo,
    createApplicant,
    update
  }

  function searchUsersByName (name) {
    return fetchWrapper.get(`${baseUrl}/search?q=${name}`)
  }

  function register (user) {
    return fetchWrapper.post(`${baseUrl}/register`, { user })
  }

  function getCurrentUserInfo () {
    return fetchWrapper.get(`${baseUrl}/info`)
  }

  function getUserInfo (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, user) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { user })
  }

  // user not logged in
  function forgotPassword (email) {
    return fetchWrapper.post(`${baseUrl}/forgotPassword`, { email })
  }

  // user not logged in
  function getRegisterInitialsUrl (user_id) {
    return fetchWrapper.get(`${baseUrl}/register/initials?user_id=${user_id}`)
  }

  // user not logged in
  function getRegisterSignatureUrl (user_id) {
    return fetchWrapper.get(`${baseUrl}/register/signature?user_id=${user_id}`)
  }

  // user is logged in
  function getSignatureUrl () {
    return fetchWrapper.get(`${baseUrl}/signature`)
  }

  // user is logged in
  function getInitialsUrl () {
    return fetchWrapper.get(`${baseUrl}/initials`)
  }

  // user is logged in
  function createApplicant (user, locationId, companyId) {
    return fetchWrapper.post(`${baseUrl}/create_applicant`, { user, location_id: locationId, company_id: companyId })
  }

  function logout () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('recoil-persist')
    window.location.replace('/login')
  }
}
