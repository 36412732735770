import { Loader } from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: 'weekly'
})

const useGoogleMapsHelper = () => {
  const init = () => new Promise((resolve) => {
    const libs = ['maps', 'core', 'geocoding', 'marker', 'routes']
    const libPromises = []

    libs.forEach((lib) => {
      libPromises.push(new Promise((resolve) => {
        loader.importLibrary(lib)
          .then((l) => resolve({ [lib]: l }))
      }))
    })
    Promise.all(libPromises)
      .then((values) => {
        const apis = {}
        values.forEach((v) => {
          Object.keys(v).forEach((k) => {
            apis[k] = v[k]
          })
        })
        resolve(apis)
      })
  })

  return { init }
}

export { useGoogleMapsHelper }
