import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { history } from '../../_helpers'

import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useLocationActions } from '../../_actions'

import { useRecoilState } from 'recoil'
import { userAtom } from '../../_state'

import AdminWrapper from '../shared/AdminWrapper'
import states from '../../_helpers/states'

import { BarLoader } from 'react-spinners'

import { FormContentWrapper } from '../shared/AdminStyledComponets'

const LocationsEdit = () => {
  const [user] = useRecoilState(userAtom)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const { locationId } = useParams()
  const [apiLocation, setApiLocation] = useState({})
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    locationActions.getLocation(locationId).then((apiData) => {
      setApiLocation(apiData.location)
      setState(
        states.find((s) => s.value === apiData.location.state) || state[0]
      )
      setIsLoading(false)
    })
  }, [])

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    address: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    city: Yup.string().required('City is required'),
    zipcode: Yup.string().length(5).matches(/^[0-9]{5}/).required('Zip Code is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const saveLocation = async () => {
      await locationActions.updateLocation(locationId, {
        ...data,
        state: state.value,
        company_id: user?.company?.id
      })
      history.push('/admin/company/dashboard')
    }
    saveLocation()
  }

  return (
    <AdminWrapper>
      <h1>Edit Location</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <FormContentWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-element'>
                <label>Title</label>
                <input
                  defaultValue={apiLocation?.title}
                  name='title'
                  type='text'
                  {...register('title')}
                  className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.title?.message}</div>
              </div>
              <div className='form-element'>
                <label>Location Address</label>
                <input
                  defaultValue={apiLocation?.address}
                  name='address'
                  type='text'
                  {...register('address')}
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.address?.message}</div>
              </div>
              <div className='form-element'>
                <label>Location Address 2 (Suite No.)</label>
                <input
                  defaultValue={apiLocation?.address_2}
                  name='address_2'
                  type='text'
                  {...register('address_2')}
                  className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.address_2?.message}</div>
              </div>
              <div className='form-element'>
                <label>City</label>
                <input
                  defaultValue={apiLocation?.city}
                  name='city'
                  type='text'
                  {...register('city')}
                  className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.city?.message}</div>
              </div>
              <div className='form-element'>
                <label>State</label>
                <Select
                  name='state'
                  required
                  value={state}
                  options={states}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onChange={(newState) => setState(newState)}
                />
                <div className='invalid-feedback'>
                  {state === '' || state === undefined || state === null
                    ? 'State is required'
                    : ''}
                </div>
              </div>
              <div className='form-element'>
                <label>Zip Code</label>
                <input
                  defaultValue={apiLocation?.zipcode}
                  name='zipcode'
                  type='text'
                  {...register('zipcode')}
                  className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.zipcode?.message}</div>
              </div>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-primary'
              >
                {isSubmitting && (
                  <span className='spinner-border spinner-border-sm mr-1' />
                )}
                Save
              </button>
            </form>
          </FormContentWrapper>
          )}
    </AdminWrapper>
  )
}

export default LocationsEdit
