import * as Yup from 'yup'
import { Step } from './Step'
import { Contact } from '../'
const ContactInfo = (props) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email().required('Email address is required'),
    primaryPhone: Yup.string().required('Primary Phone is required')
  })

  // This is a bit of a shameful hack, but it works beautifully.

  // const formState = useRecoilValue(formStateAtom)
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>Your Contact Information</h3>
        <Contact readOnly={props.readOnly} />
      </Step>
    </>
  )
}
ContactInfo.componentName = 'ContactInfo'
export { ContactInfo }
