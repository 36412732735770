// import React, { useState } from 'react';
import styled from 'styled-components'

interface StyleProps {
  buttonEnabled: boolean
  buttonColor: string
  buttonFresh: boolean
}

const ButtonWrapper = styled.button<StyleProps>`
  border-radius: 25px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 135px;
  font-family: 'Montserrat';
  font-size: 12px;
  background: ${(props) =>
    props.buttonFresh
      ? props.buttonColor === 'blue'
        ? '#81BCBF'
        : '#BAAFA3'
      : 'transparent'};
  border: 1px solid
    ${(props) =>
      props.buttonEnabled
        ? props.buttonColor === 'blue'
          ? '#81BCBF'
          : '#BAAFA3'
        : '#B9AFA4'};
  opacity: ${(props) => (props.buttonEnabled ? '1' : '0.4')};
  color: ${(props) =>
    props.buttonFresh
      ? '#fff'
      : props.buttonColor === 'blue'
      ? '#81BCBF'
      : '#BAAFA3'};

  &:hover {
    cursor: ${(props) => (props.buttonEnabled ? 'pointer' : 'not-allowed')};
    ${(props) => {
      if (props.buttonEnabled) {
        return `
          background-color: ${
            props.buttonColor === 'blue' ? '#81BCBF' : '#BAAFA3'
          };
          color: #fff;
          box-shadow: 0px 2px 3px #ccc;
        `
      }
      return ''
    }}
  }
`

interface ButtonProps {
  buttonText: any
  buttonAction: any
  buttonEnabled: any
  buttonColor: any
  buttonFresh: any
}

export const ActionButton = ({
  buttonText,
  buttonAction,
  buttonEnabled,
  buttonColor,
  buttonFresh
}: ButtonProps) => {
  const handleClick = (buttonAction: any) => {
    if (buttonAction) {
      buttonAction()
    }
  }

  return (
    <ButtonWrapper
      buttonEnabled={buttonEnabled}
      buttonColor={buttonColor}
      buttonFresh={buttonFresh}
      onClick={() => handleClick(buttonAction)}>
      {buttonText}
    </ButtonWrapper>
  )
}
