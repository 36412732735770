import { useFetchWrapper } from '../_helpers'

export { useJobAdverseActionActions }

function useJobAdverseActionActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/job_adverse_actions`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    add,
    get,
    update
  }

  function add (job_adverse_action, extras) {
    return fetchWrapper.post(`${baseUrl}`, { job_adverse_action, ...extras })
  }

  function get (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, job_adverse_action, extras) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { job_adverse_action, ...extras })
  }
}
