import { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useForm } from 'react-hook-form'
import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'

const StepFour = ({ save, backToStepThree }) => {
  const signatureRef = useRef(null)
  const typedSignatureRef = useRef(null)
  const typedTextboxRef = useRef(null)
  const canvasWidth = 225
  const canvasHeight = 175
  const { handleSubmit, formState } = useForm()
  const { isSubmitting } = formState

  const [signatureType, setSignatureType] = useState('Draw')

  const changeSignatureType = (e) => {
    setSignatureType(e.target.value)
    if (e.target.value === 'Type') {
      setTimeout(() => {
        typedTextboxRef.current.focus()
      }, 500)
    }
  }

  const onSubmit = () => {
    if (signatureType === 'Draw') {
      save(signatureRef.current.getTrimmedCanvas().toDataURL())
    } else {
      cropCanvas()
      save(typedSignatureRef.current.toDataURL())
    }
  }

  const writeText = (info, style = {}) => {
    typedSignatureRef.current.width = canvasWidth
    typedSignatureRef.current.height = canvasHeight
    const ctx = typedSignatureRef.current.getContext('2d')
    ctx.clearRect(0, 0, canvasWidth, canvasHeight)

    const { text, x, y } = info
    const {
      fontSize = 20,
      fontFamily = 'Arial',
      color = 'black',
      textAlign = 'left',
      textBaseline = 'top'
    } = style

    ctx.beginPath()
    ctx.font = fontSize + 'px ' + fontFamily
    ctx.textAlign = textAlign
    ctx.textBaseline = textBaseline
    ctx.fillStyle = color
    ctx.fillText(text, x, y)
    ctx.stroke()
  }

  function cropCanvas () {
    const ctx = typedSignatureRef.current.getContext('2d')
    const canvas = typedSignatureRef.current
    const pix = { x: [], y: [] }
    const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight)
    let w = canvasWidth
    let h = canvasHeight
    let x
    let y
    let index

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x)
          pix.y.push(y)
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b
    })
    pix.y.sort(function (a, b) {
      return a - b
    })
    const n = pix.x.length - 1

    w = 1 + pix.x[n] - pix.x[0]
    h = 1 + pix.y[n] - pix.y[0]
    const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h)

    canvas.width = w
    canvas.height = h
    ctx.putImageData(cut, 0, 0)
  }

  return (
    <>
      <h3>Step 4</h3>
      <div style={{ paddingBottom: 20 }}>
        Save your signature to expedite the process of signing documents and
        forms durning the hirigin process.
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: 300, margin: 'auto' }}
      >
        <div className='draw-radio-wrapper'>
          <input
            type='radio'
            value='Draw'
            name='signatureType'
            onChange={changeSignatureType}
            checked={signatureType === 'Draw'}
          />{' '}
          Draw It
          <input
            type='radio'
            value='Type'
            name='signatureType'
            onChange={changeSignatureType}
            checked={signatureType === 'Type'}
          />{' '}
          Type It
        </div>

        <div style={{ paddingBottom: 20 }}>
          {signatureType} your initials here.
        </div>

        <div
          style={{
            display: signatureType === 'Draw' ? 'flex' : 'none',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: canvasWidth,
              height: canvasHeight,
              margin: '0 auto',
              border: '1px solid black',
              borderRadius: 10
            }}
          >
            <SignatureCanvas
              ref={signatureRef}
              penColor='black'
              canvasProps={{
                width: canvasWidth,
                height: canvasHeight,
                className: 'sigCanvas'
              }}
            />
          </div>
          <div
            className='clear-button'
            onClick={() => {
              signatureRef.current.clear()
            }}
            style={{ marginRight: 30 }}
          >
            Clear
          </div>
        </div>

        <div style={{ display: signatureType === 'Type' ? 'block' : 'none' }}>
          <input
            ref={typedTextboxRef}
            name='typedSignature'
            type='text'
            className='form-control type-it-input initials'
            onChange={(e) =>
              writeText(
                { text: e.target.value, x: 110, y: 60 },
                {
                  fontSize: 25,
                  fontFamily: 'cursive',
                  color: 'black',
                  textAlign: 'center'
                }
              )}
          />
          <div
            style={{
              width: canvasWidth,
              height: canvasHeight,
              margin: '0 auto',
              border: '1px solid black',
              borderRadius: 10
            }}
          >
            <canvas ref={typedSignatureRef} />
          </div>
        </div>

        <button
          type='submit'
          disabled={isSubmitting}
          className='btn btn-primary'
        >
          {isSubmitting && (
            <span className='spinner-border spinner-border-sm mr-1' />
          )}
          Save & Create Account
        </button>
      </form>
      <div className='back-link' onClick={backToStepThree}>
        <IconArrow /> Back to Step 3
      </div>
    </>
  )
}

export default StepFour
