import React from 'react'

const ButtonGroup = ({ formState, pageNav, job, final }) => {
  return (
    <>
      <div className='input-group'>
        <button
          type='button'
          onClick={() => {
            if (pageNav.currentStep === 1) {
              pageNav.backToJob(job.id)
            } else {
              pageNav.updatePage(pageNav.prev)
            }
          }}
        >
          {pageNav.currentStep === 1 ? 'Go Back' : 'Previous Step'}
        </button>
        <button
          disabled={formState.isSubmitting}
          className='btn btn-primary'
        >
          {formState.isSubmitting && (
            <span className='spinner-border spinner-border-sm mr-1' />
          )}
          {pageNav.currentStep === pageNav.totalSteps ? final === true ? 'Submit' : 'Save' : 'Next Step'}
        </button>
      </div>
    </>
  )
}

export { ButtonGroup }
