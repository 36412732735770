import * as React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useUserActions } from '../../_actions'

import { ReactComponent as IconLogout } from '../../resources/images/icons/logout.svg'
import logoFull from '../../resources/images/logo-dark-bkg.svg'
import profileImage from '../../resources/images/profile_image.jpg'
import { ProfileButton } from './ProfileButton'

const SidebarNavWrapper = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  background-color: ${(props) => props.backgroundColor || '#304755'};
  color: #fff;
`

const SidebarNavLogo = styled.div`
  display: block;
  background-image: url(${logoFull});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 185px;
  height: 50px;
  margin: 20px auto;
`

const SidebarNavButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;

  a {
    text-align: left;
    text-decoration: none;
  }
`

const StyledSidebarButton = styled.div`
  display: inline-flex;
  align-items: center;
  width: 180px;
  height: 70px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  
  text-decoration: none;
  text-align: left;

  span {
    display: inline-block;
    padding-top: 5px;
  }

  svg {
    opacity: 0.5;
    width: 35px;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;

    svg {
      opacity: 1;
    }
  }
`

const StyledSidebarBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-bottom: 20px;
  margin-left: 25px;

  svg {
    margin: auto;
  }

  > a:first-of-type {
    display: flex;
    align-items: center;
    width: 200px !important;
    text-decoration: none;

    > div:first-of-type {
      width: auto !important;
      margin-right: 10px;
    }
  }

  > div:last-of-type {
    display: inline-block;
    margin-right: auto;
  }

  span {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }
`

const profileImg = (img) => {
  if (img) {
    return img
  } else {
    return profileImage
  }
}

export const SidebarNav = ({ buttons, backgroundColor, picture }) => {
  const userActions = useUserActions()
  const logout = async () => {
    userActions.logout()
  }

  return (
    <SidebarNavWrapper backgroundColor={backgroundColor}>
      <NavLink as='div' exact to='/' className='nav-item nav-link'>
        <SidebarNavLogo />
      </NavLink>

      <SidebarNavButtonsWrapper>
        {buttons.map((btn, i) => (
          <NavLink exact to={btn.link} className='nav-item nav-link' key={i}>
            <StyledSidebarButton key={i} href={btn.link}>
              <btn.icon /> <span>{btn.name}</span>
            </StyledSidebarButton>
          </NavLink>
        ))}
      </SidebarNavButtonsWrapper>
      <StyledSidebarBottom>
        <StyledSidebarButton onClick={logout}>
          <IconLogout fill='#fff' />
        </StyledSidebarButton>
        <NavLink exact to='/profile' className='nav-item nav-link'>
          <ProfileButton profileImg={profileImg(picture)} /> <span>PROFILE</span>
        </NavLink>
      </StyledSidebarBottom>
    </SidebarNavWrapper>
  )
}
