import { RecoilInput } from '../../shared/HookFormComponents'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useStateContext } from '../../../contexts/useStateContext'

const Name = ({ readOnly }) => {
  const { state } = useStateContext()
  const { formState } = useFormContext()
  return (
    <>
      <div className='form-element'>
        <label>First Name</label>
        <RecoilInput
          type='text'
          name='firstName'
          value={state.firstName}
          className={`form-control ${
                        formState.errors.firstName ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Middle Name</label>
        <RecoilInput
          type='text'
          name='middleName'
          value={state.middleName}
          className={`form-control ${
                        formState.errors.middleName ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Last Name</label>
        <RecoilInput
          type='text'
          name='lastName'
          value={state.lastName}
          className={`form-control ${
                        formState.errors.lastName ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Suffix (ex. Sr. Jr. III Esq.)</label>
        <RecoilInput
          type='text'
          name='suffix'
          value={state.suffix}
          className={`form-control ${
                        formState.errors.suffix ? 'is-invalid' : ''
                    }`}
          readOnly={readOnly}
        />
      </div>
    </>
  )
}

export { Name }
