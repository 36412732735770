import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import html2pdf from 'html2pdf.js/src'
import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as Yup from 'yup'
import { useTasksActions } from '../../../_actions'
import { PageLoader, useReloadJob } from '../../../_helpers'
import { job as jobAtom, loadingState } from '../../../_state'
import { AdverseActionNotification } from '../../../admin/documents/AdverseActionNotification'
import { StateProvider } from '../../../contexts'
import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { ApplySignatureValidationSchema } from '../../shared/HookFormComponents'
import styled from 'styled-components'

const AdverseActionContent = () => {
  const { id } = useParams()
  const job = useRecoilValue(jobAtom)
  const [state, setState] = useState(null)
  const [loading, setLoading] = useRecoilState(loadingState)
  const { backToJob } = useReloadJob()
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  const componentRef = React.createRef()

  const validationSchema = Yup.object().shape(ApplySignatureValidationSchema)
  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: state,
    values: state
  }

  const onSubmit = (data) => {
    setLoading(true)
    console.log(data)
    const offerLetter = componentRef.current
    const opts = {
      margin: 0,
      html2canvas: { useCORS: false },
      jsPDF: { format: 'letter' }
    }
    html2pdf().set(opts).from(offerLetter).output('blob', 'offer-letter.pdf').then((o) => {
      const offerLetterFile = new File([o], 'offer-letter.pdf', { type: 'application/pdf' })
      taskActions.add_artifact(id, offerLetterFile, {}).then((r) => {
        const submission = {
          data: {}
        }
        submission.data = data
        submission.data.final = true
        console.log(r)
        taskActions.update(id, submission)
          .then((oc) => {
            setLoading(false)
            backToJob(job.id)
          })
      })
    })
  }

  const LetterFormContentWrapper = styled(FormContentWrapper)`
  .form-content {
    max-width: 450px;
    margin: auto;
  }
  .form-wrapper {
    max-width: 580px
  }
  `
  const methods = useForm(formOptions)

  if (loading) {
    return <PageLoader />
  }
  return (
    <>
      <ApplicantWrapper>
        <LetterFormContentWrapper>
          <StateProvider state={state} setState={setState}>
            <FormProvider {...methods}>
              <form className='form-wrapper' onSubmit={methods.handleSubmit(onSubmit)}>
                <AdverseActionNotification job={job} register={methods.register} ref={componentRef} />
                <div className='form-element'>
                  <button
                    type='button'
                    onClick={() => {
                      backToJob(job.id)
                    }}
                  >
                    Go Back
                  </button>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </form>
            </FormProvider>
          </StateProvider>
        </LetterFormContentWrapper>
      </ApplicantWrapper>
    </>
  )
}

export default AdverseActionContent
