import { RecoilInput } from './RecoilInput'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const RadioItem = ({ name, value }) => {
  const { register } = useFormContext()

  return (
    <>
      <div className='input-group' {...register(name)}>
        <div className='form-element'>
          <RecoilInput type='radio' value={value} name={name} />
          <label>{value}</label>
        </div>
      </div>
    </>
  )
}

const RadioGroup = ({ label, name, values }) => {
  const { formState } = useFormContext()
  const { errors } = formState

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <div className='input-group-container'>
          {React.Children.toArray(
            values.map((value) => <RadioItem name={name} value={value} />)
          )}
        </div>
        <div className='invalid-feedback'>{errors[name]?.message}</div>
      </div>
    </>
  )
}

export { RadioGroup }
