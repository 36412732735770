import { useEffect, useState } from 'react'
import { FormContentWrapper } from '../shared/AdminStyledComponets'
import { useProductsActions, useOrderActions } from '../../_actions'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { BarLoader } from 'react-spinners'

const OrdersNew = ({ orders, savedCallback }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const { locationId, jobId } = useParams()
  const productActions = useProductsActions(
    localStorage.getItem('access_token')
  )
  const orderActions = useOrderActions(
    localStorage.getItem('access_token')
  )
  useEffect(() => {
    productActions.all(locationId).then((apiProducts) => {
      const alreadyOrderedProducts = orders.map((o) => o.productId)
      setProducts(apiProducts.filter((p) => !alreadyOrderedProducts.includes(p.id)).map((ap) => { return { value: ap.id, label: ap.title } }).sort((a, b) => a.label.localeCompare(b.label)))
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <FormContentWrapper>
            <div className='form-element'>
              <label>Which Product?</label>
              <Select
                name='product_id'
                required
                value={selectedProduct}
                options={products}
                isLoading={isLoading}
                isDisabled={isLoading}
                onChange={(newState) => setSelectedProduct(newState)}
              />
            </div>
            <button
              type='submit'
              disabled={isLoading || !selectedProduct}
              className='btn btn-primary'
              onClick={async () => {
                await orderActions.add({ product_id: selectedProduct.value, job_id: jobId })
                savedCallback()
              }}
            >
              Order
            </button>
          </FormContentWrapper>)}
    </>
  )
}

export default OrdersNew
