import { useEffect } from 'react'
import { Router, useParams } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import { history, sidebarButtonsApplicant, PageLoader } from '../../_helpers'
import { job as jobAtom, userAtom, loadingState, documentsAtom, notificationsAtom } from '../../_state'
import styled from 'styled-components'

import { SidebarNav } from '../../components/SidebarNav'
// import {DrawerNav} from "../components/DrawerNav";

import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'
import { ApplicantTasks } from '../../components/Applicant/ApplicantTasks'
import { ApplicantJobTitleRow } from '../../components/Applicant/ApplicantJobTitleRow'
import { Alert } from '../../components/shared/Alert'
import { AlertsAndNotifications } from '../../components/Applicant/AlertsAndNotifications'
import { MyDocuments } from '../../components/Applicant/MyDocuments'
import { useDocumentActions, useNotificationActions, useJobActions } from '../../_actions'

const Wrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 90px;

  h2 {
    margin-top: 50px;
  }

  .cols {
    display: flex;
    align-items: flex-start;

    > div:first-child {
      margin-right: 20px;
      width: 40%;
      height: auto;
    }
  }
`
const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  padding: 25px 25px 100px 25px;
  overflow: auto;

  .welcome {
    font-size: 28px;
    color: #81bcbf;
    font-weight: 700;
    margin-bottom: 25px;
  }
  div.loader {
    width: 100px;
    height: 100px;
  }
`

const resourcesColumns = [
  { title: 'Document', dataIndex: 'title', key: 'title' },
  {
    title: '',
    dataIndex: 'url',
    key: 'e',
    width: 130,
    className: 'view-button',
    render (row) {
      const buttonText = row.views === 0 || !row.views ? 'View' : `Viewed (${row.views})`
      return (
        <div key={row}>
          <button
            key={row}
            onClick={(e) => {
              e.preventDefault()
              row.action(row.job, row.resource)
                .then((ret) => {
                  window.open(row.link, '_blank')
                })
            }}
          >
            {buttonText}
          </button>
        </div>
      )
    }
  }
]

export { JobDetails }
function JobDetails () {
  const [loading, _setLoading] = useRecoilState(loadingState)
  const user = useRecoilValue(userAtom)
  const job = useRecoilValue(jobAtom)
  const [documents] = useRecoilState(documentsAtom)
  const [notifications] = useRecoilState(notificationsAtom)
  const { id: jobId } = useParams()
  const documentActions = useDocumentActions(jobId)
  const notificationActions = useNotificationActions(jobId)
  const jobActions = useJobActions(localStorage.getItem('access_token'))

  useEffect(() => {
    documentActions.getAll()
    notificationActions.getAll()
  }, [])

  const setResourceViewed = (jobId, resourceId) => {
    return jobActions.viewedResource(jobId, resourceId)
  }

  const getResourceData = (job) => {
    const resourceData = []
    job.resources.forEach((resource) => {
      const rViews = job.job_resource.find(x => x.resource_id === resource.id)
      resourceData.push({ id: `job-${job.id}_resource-${resource.id}`, job: job.id, resource: resource.id, title: resource.title, url: { link: resource.url, job: job.id, resource: resource.id, action: setResourceViewed, views: rViews.views } })
    })
    return resourceData
  }

  function ToggleAlert () {
    if (job.tasks.find((o) => o.alert === true)) {
      return (
        <Alert
          alertText='There is an alert that needs your attention.'
          alertButtonText='View'
          alertButtonOnClick={() => {
            console.log('clicked the alert button')
          }}
        />
      )
    }
  }
  if (loading || !job) {
    return (
      <Wrapper>
        <ContentWrapper>
          <PageLoader />
        </ContentWrapper>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Router history={history}>
        <SidebarNav
          buttons={sidebarButtonsApplicant}
          picture={user.picture}
          backgroundColor='#81BCBF'
        />
      </Router>
      <ContentWrapper>
        {/* <DrawerNav title={'Jobs'} /> */}
        <span className='welcome'>
          Welcome {`${user?.given_name || ''} ${user?.family_name || ''}`}!
        </span>
        <ApplicantJobTitleRow
          jobTitle={job.title}
          company={job.company.display_name}
          DetailsDashboard
          job={job}
        />
        <ToggleAlert />
        <ApplicantTasks data={job.tasks} job={job} />
        <AlertsAndNotifications jobId={job.id} data={notifications} />
        <ApplicantGenericTable
          tableTitle='Resources'
          columns={resourcesColumns}
          data={getResourceData(job)}
          buttonText=''
        />
        <MyDocuments data={documents} />
      </ContentWrapper>
    </Wrapper>
  )
}
