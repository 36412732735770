import styled from 'styled-components'
import logo from './employifi-logo.svg'

const EmailWrapper = styled.div`
  border: 1px solid #eee;
  margin: auto;
  max-width: 620px;
  color: #707070;
  background: #fff;
  padding: 0 20px;

  .email-main-content {
    display: flex;
  }

  .email-left-content {

    svg {
      width: 20px;
      height: 20px;
    }

    .label {
      text-transform: uppercase;
      font-size: 7px;
      color: #81BCBF;
      font-weight: bold;
      display: block;
      margin: 35px 0 10px 0;
    }

    span {
      display: block;
    }

    .report-title {
      color: #272D3B;
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
    }

    .detail {
      color: #707070;
      font-size: 10px;

      &.big {
        color: #272D3B;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }

    .report-summary-table {
      table {
        font-size: 10px;
        margin-right: 20px;

        thead {
          background-color: #7B7C7C;
          border: 1px solid #7B7C7C;
          text-align: left;
          color: #fff;
          text-transform: uppercase;
          padding: 5px;
        }

        td, tr {
          border: 1px solid #7B7C7C;
          padding: 5px;
        }
      }
    }
  }

  .email-sidebar {
    margin-left: auto;
    padding: 20px;
    background-color: #f1efed;
    width: 205px;
    box-sizing: border-box;

    img {
      display: block;
      width: 140px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    .label {
      font-size: 7px;
      color: #1A1A1A;
      text-transform: uppercase;
      font-weight: bold;
      display: block;
      margin: 10px 0;
    }

    .detail {
      font-size: 10px;
      margin-bottom: 5px;
      display: block;
      color: #7C7C7C;
    }

    .bold {
      font-weight: bold;
    }

    .margin-bottom-small {
      margin-bottom:10px;
    }

    .margin-bottom-medium {
      margin-bottom:20px;
    }

    .margin-bottom-large {
      margin-bottom:50px;
    }

    .border-top {
      border-top: 1px solid #ccc;
    }

    .report-details {
      padding: 10px 0;
      margin-bottom: 10px;
    }

    .ft8 {
      font-size: 8px;
      line-height: 1.4;
    }

  }

  .email-footer {
    display: flex;
    background: #fff !important;

    img {
      width: 75px;
    }

    .footer-details {
      margin-left: auto;
      font-size: 8px;
    }
  }
`

export const ConsolidatedReportEmail = ({ emailData }) => {
  return (
    <EmailWrapper>
      <div className='email-main-content'>
        <div className='email-left-content'>
          <span className='label'>Report</span>
          <div className='report-title'>Consolidated Report</div>
          <span className='detail'>Status: {emailData.status}</span>
          <span className='label'>Applicant Information</span>
          <span className='detail big'>{emailData.applicantInformation.name}</span>
          <span className='detail'>{emailData.applicantInformation.address1}</span>
          <span className='detail'>{emailData.applicantInformation.address2}</span>
          <span className='detail'>{emailData.applicantInformation.address3}</span>
          <span className='label'>Report Summary</span>
          <div className='report-summary-table'>
            <table>
              <thead>
                <tr>
                  {Object.keys(emailData.reportSummary[0]).map(heading => {
                    return <th key={heading}>{heading}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {emailData.reportSummary.map((row, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(emailData.reportSummary[0]).map((key, index) => {
                        return <td key={row[key]}>{row[key]}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='email-sidebar'>
          <img src={logo} alt='employifi logo' />
          <div className='report-details'>
            <span className='label'>Report details</span>
            <span className='detail bold'>REQ: {emailData.reportDetails.req}</span>
            <span className='detail bold'>{emailData.reportDetails.date}</span>
            <span className='detail bold'>{emailData.reportDetails.time}</span>
          </div>
          <div className='report-details'>
            <span className='label'>Requested By</span>
            <span className='detail bold'>{emailData.requestedBy.name}</span>
            <span className='detail bold margin-bottom-small'>{emailData.requestedBy.company}</span>
            <span className='detail'>{emailData.requestedBy.address1}</span>
            <span className='detail margin-bottom-medium'>{emailData.requestedBy.address2}</span>
          </div>
          <div className='report-details'>
            <span className='label'>Requested By</span>
            <span className='detail bold'>{emailData.requestedBy.name}</span>
            <span className='detail bold'>{emailData.requestedBy.company}</span>
            <span className='detail'>{emailData.requestedBy.address1}</span>
            <span className='detail margin-bottom-small'>{emailData.requestedBy.address2}</span>
            <span className='detail'>P: {emailData.requestedBy.phone}</span>
            <span className='detail margin-bottom-large'>E: {emailData.requestedBy.email}</span>
          </div>
          <div className='report-details border-top'>
            <span className='label'>Prepared by</span>
            <span className='detail bold'>{emailData.preparedBy.company}</span>
            <span className='detail'>{emailData.preparedBy.address1}</span>
            <span className='detail margin-bottom-small'>{emailData.preparedBy.address2}</span>
            <span className='detail'>P: {emailData.preparedBy.phone}</span>
            <span className='detail margin-bottom-small'>E: {emailData.preparedBy.email}</span>
          </div>
          <div className='report-details'>
            <span className='label'>notice</span>
            <span className='detail ft8'>{emailData.notice.text}</span>
          </div>
        </div>
      </div>
      <div className='email-footer'>
        <img src={logo} alt='employifi logo' />
        <span className='footer-details'>Request No: {emailData.requestNo} / {emailData.completedDatetime}</span>
      </div>
    </EmailWrapper>
  )
}
