import { useEffect, useState } from 'react'
import { useJobActions } from '../../_actions'
import { useParams } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import { AdminJobDetails } from '../../components/Admin/AdminJobDetails'

const JobsEdit = ({ savedCallback }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [job, setJob] = useState({})
  const { jobId } = useParams()
  const jobActions = useJobActions(
    localStorage.getItem('access_token')
  )
  useEffect(() => {
    jobActions.getById(jobId).then((apiJob) => {
      setJob(apiJob)
      setIsLoading(false)
    })
  }, [])

  const updateJob = async (data) => {
    jobActions.update(jobId, data).then(() => {
      savedCallback()
    })
  }

  return (
    <>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (<AdminJobDetails
            hideHeader
            template={job}
            saveJob={updateJob}
            saveText='Save'
           />)}
    </>
  )
}

export default JobsEdit
