import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import styled from 'styled-components'
import { useUserActions, useAlertActions } from '../_actions'
import { Link } from 'react-router-dom'
import { ReactComponent as IconArrow } from '../resources/images/icons/arrow.svg'
import {
  formCss,
  loginWrapperCss
} from '../_helpers/shared-css'
import LoginGraphic from '../components/shared/LoginGraphic'

const Wrapper = styled.div`
  ${loginWrapperCss}
`

const FormContentWrapper = styled.div`
  flex: 1;
  padding: 40px;
  text-align: center;

  ${formCss}
`

const ForgotPassword = () => {
  const userActions = useUserActions()
  const alertActions = useAlertActions()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required')
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    userActions
      .forgotPassword(data.email)
      .then(() => {
        alertActions.success(
          'If an account exists with this email you will recieve an email soon.'
        )
      })
      .catch((err) => {
        alertActions.error(err)
      })
  }

  return (
    <Wrapper>
      <LoginGraphic
        description='Looks like you are having trouble logging in!'
      />
      <FormContentWrapper>
        <div className='form-wrapper'>
          <h3>Forgot Password?</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-element'>
              <label>Email Address</label>
              <input
                name='email'
                type='text'
                {...register('email')}
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              />
              <div className='invalid-feedback'>{errors.email?.message}</div>
            </div>
            <button disabled={isSubmitting} className='btn btn-primary'>
              {isSubmitting && (
                <span className='spinner-border spinner-border-sm mr-1' />
              )}
              Send Reset Password Email
            </button>
          </form>
        </div>
        <Link className='back-link' to='/login'>
          <IconArrow /> Back to login
        </Link>
      </FormContentWrapper>
    </Wrapper>
  )
}

export default ForgotPassword
