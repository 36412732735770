import * as React from 'react'
import styled from 'styled-components'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'
import { ReactComponent as IconBookmark } from '../../resources/images/icons/bookmark.svg'

interface Props {
  fullWidth: boolean
}

const JobTileWrapper = styled.a<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f1efed;
  border-radius: 10px;
  overflow: hidden;
  width: ${(props) => (props.fullWidth ? '100%' : '460px')};
  height: ${(props) => (props.fullWidth ? 'auto' : '300px')};
  min-height: 150px;
  color: #707070;
  padding: 15px 15px 15px 20px;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0 20px 20px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: 15px;
    background-color: #b0afae;
    display: flex;

    svg {
      margin: auto;
      width: 25px;
      transform: rotate(140deg);
      fill: #fff;
    }
  }

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    span {
      background-color: #de7e53;

      svg {
        fill: #fff;
        transform: rotate(180deg);
      }
    }
  }
`

const JobTileIcon = styled.div<Props>`
  margin-bottom: ${(props) => (props.fullWidth ? '-20px' : '50px')};
  text-align: right;

  svg {
    fill: #81bcbf;
    width: 30px;
  }

  img {
    width: 40px;
    height: auto;
  }
`

const JobTileTitle = styled.div<Props>`
  font-size: 24px;
  font-weight: bold;
  padding-right: ${(props) => (props.fullWidth ? '50px' : '0')};
`

const JobTileLocation = styled.div`
  font-size: 16px;
`

const JobTileDetails = styled.div`
  margin-top: auto;
  margin-bottom: 5px;
  font-size: 16px;
`

const JobTileStages = styled.div`
  color: #de7e53;
  margin-bottom: 5px;
`

const JobTileApplicants = styled.div`
  color: #707070;
`

interface JobTileProps {
  tile: {
    title: string
    location: string
    link: string
    totalStages: number
    completedStages: number
    applicants: number
  }
  fullWidth: boolean
}

export const DashboardPriorityJobTile = ({ tile, fullWidth }: JobTileProps) => {
  return (
    <JobTileWrapper href={tile.link} fullWidth={fullWidth}>
      <JobTileIcon fullWidth={fullWidth}>
        <IconBookmark />
      </JobTileIcon>
      <JobTileTitle fullWidth={fullWidth}>{tile.title}</JobTileTitle>
      <JobTileLocation>{tile.location}</JobTileLocation>
      <JobTileDetails>
        <JobTileStages>
          {tile.completedStages} / {tile.totalStages}
        </JobTileStages>
        <JobTileApplicants>{tile.applicants} Applicants</JobTileApplicants>
      </JobTileDetails>
      <span>
        <IconArrow />
      </span>
    </JobTileWrapper>
  )
}
