import { useFetchWrapper } from '../_helpers'

export { useProductsActions }

function useProductsActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/products`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    all,
    presets,
    employifiAll,
    companyAll,
    setCompanyProducts,
    get,
    update
  }

  function all (locationId) {
    return fetchWrapper.get(`${baseUrl}?location_id=${locationId}`)
  }

  function employifiAll () {
    return fetchWrapper.get(`${baseUrl}`)
  }

  function companyAll (company_id) {
    return fetchWrapper.get(`${baseUrl}?company_id=${company_id}`)
  }

  function setCompanyProducts (company_id, product_ids) {
    return fetchWrapper.put(`${baseUrl}`, { company_id, product_ids })
  }

  function presets (locationId) {
    return fetchWrapper.get(`${baseUrl}/presets?location_id=${locationId}`)
  }

  function get (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, product) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { product })
  }
}
