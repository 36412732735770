import { Router, useParams } from 'react-router-dom'
import { history } from '../../_helpers'
import styled from 'styled-components'
import { SidebarNav } from '../../components/SidebarNav'
import { DrawerNav } from '../../components/DrawerNav'
import { ReactComponent as IconHome } from '../../resources/images/icons/house.svg'
import { ReactComponent as IconFolder } from '../../resources/images/icons/folder.svg'
import { ReactComponent as IconSettings } from '../../resources/images/icons/settings.svg'
import { ReactComponent as IconGroup } from '../../resources/images/icons/group.svg'

import { useRecoilState } from 'recoil'
import { userAtom } from '../../_state'

const Wrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 240px;

  h2 {
    margin-top: 50px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  overflow: auto;
  padding-bottom: 100px;
`

const Content = styled.div`
  padding: 40px;
  width: 100%;
`

const AdminWrapper = ({ children, drawerTitle, showDrawerNav }) => {
  const [user] = useRecoilState(userAtom)
  const roles = user?.roles?.map((r) => r.name) || []
  const companyId = user?.company?.id
  const { locationId } = useParams()

  const sidebarButtons = () => {
    return roles.includes('Company Admin') && locationId
      ? [
          { icon: IconHome, link: '/admin/company/dashboard', name: 'home' },
          {
            icon: IconFolder,
            link: `/admin/companies/${companyId}/jobs`,
            name: 'jobs'
          },
          {
            icon: IconGroup,
            link: '/admin/applicants',
            name: 'applicants'
          },
          { icon: IconSettings, link: `/admin/companies/${companyId}/edit`, name: 'settings' }
        ]
      : roles.includes('Company Admin')
        ? [
            { icon: IconHome, link: '/admin/company/dashboard', name: 'home' },
            { icon: IconFolder, link: `/admin/companies/${companyId}/jobs`, name: 'jobs' },
            {
              icon: IconGroup,
              link: '/admin/applicants',
              name: 'applicants'
            },
            { icon: IconSettings, link: `/admin/companies/${companyId}/edit`, name: 'settings' }
          ]
        : roles.includes('Employifi Admin')
          ? [
              { icon: IconHome, link: '/admin/companies', name: 'home' }
            ]
          : roles.includes('Recruiter')
            ? [
                { icon: IconHome, link: '/admin/company/dashboard', name: 'home' },
                { icon: IconFolder, link: '/admin/jobs/new', name: 'jobs' },
                {
                  icon: IconGroup,
                  link: '/admin/applicants/new',
                  name: 'applicants'
                }
              ]
            : []
  }

  return (
    <Wrapper>
      <Router history={history}>
        <SidebarNav buttons={sidebarButtons()} />
      </Router>
      <ContentWrapper showDrawerNav={showDrawerNav}>
        {showDrawerNav && <DrawerNav title={drawerTitle} />}
        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  )
}

export default AdminWrapper
