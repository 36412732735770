import './email.css'
import logo from './employifi-logo.svg'

export const PreAANoticeEmail = () => {
  return (
    <>
      <div className='email-wrapper'>
        <div className='email-content'>
          <div className='email-body'>
            <div className='top-image-placeholder' />
            <div className='email-body-text'>
              [Date]<br /> <br />
              [Name]<br />
              [Address]<br />
              [City], [State] [Zip]<br />
              <br />
              <strong>Re:</strong> <br />
              Pre-Adverse Action<br />
              Position applied for: [Position] <br />
              Date of Application: [Application Date] <br />
              Date of Conditional Offer: [Conditional Offer Date] <br />
              Potential Disqualifying Event: [Disqualifying Event] <br />
              <br />
              <p />Dear [Applicant Name],
              <br />
              <p>This correspondence will confirm the potential withdrawal of the Company’s conditional offer of employment. As part of Consolidated Analytics, Inc. (“Company”) review for this position, you authorized the Company to obtain a consumer report, an investigative consumer report and/or a consumer credit report about you. </p>
              <p>Due in part to the contents of a report, the Company may take adverse action and may withdraw its conditional offer of employment. However, prior to taking final adverse action, the Company is providing you with an opportunity to dispute any inaccurate information. </p>
              <p>Additionally, you will find a copy of any report which the Company requested about you for employment purposes and other important information. </p>
              <p>The consumer reporting agency providing the report was:</p>
              <div className='bold-indent'>
                Employee Relations, Inc. <br />
                450 North Brand Boulevard, Suite 600 <br />
                Glendale, CA 91203 <br />
                Phone: 800.716.7773 <br />
                E-Mail: complianceofficer@erelations.com <br />
                Website: www.erelations.com
              </div>
              <br />
              <p>The above-named consumer reporting agency did not make the decision to take potential adverse employment action and is unable to provide you with specific information. Please review the entire consumer report, including any attachments and, if you believe any information in the report is incorrect and/or you have information that the Company should consider, please submit it immediately. You will have [seven (7)] calendar days from the date of this letter to provide any information or documentation that should be considered in the Company’s assessment including, but not limited to, information or documentation regarding the accuracy of and/or errors in the report. </p>
              <p>Any information or documentation that you submit will be considered, and you will be notified of the Company’s final decision. However, if we do not hear from you within [seven (7)] calendar days from the date of this letter, the Company will close your file. </p>
              <p>If you have any questions, please feel free to contact us.</p>
              <br />
              <p>Sincerely,<br /><br /> Jaffe Joffer <br />MacDowells<br /> Ruler Of Zamunda</p>
            </div>
          </div>
          <div className='email-footer'>
            <div className='footer-left'>
              <span>compliance & hiring powered by</span>
              <img src={logo} alt='Employifi Logo' />
            </div>
            <div className='footer-right'>
              <p>This email was intended for Joshua Forstot (ECD & Founder of dough. Creative + Tech | Problem Solver | Speaker | Advisor). </p>
              <p>You are receiving these communications from Employifi on behalf of [Company Name].</p>
              <p>Company and Employifi legal copy can go here. I suggest you try it again, Luke. This time, let go your conscious self and act on instinct. Don’t be too proud of this technological terror you’ve constructed. The ability to destroy a planet is insignificant next to the power of the Force.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
