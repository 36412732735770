import styled from 'styled-components'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'

const TileWrapper = styled.div`
  display: block;
  position: relative;
  background-color: ${(props) => (props.color ? props.color : '#707070')};
  border-radius: 10px;
  overflow: hidden;
  width: 220px;
  min-height: ${(props) => (props.link ? '176px' : '155px')};
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0 20px 20px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  svg {
    width: 25px;
    height: 25px;
  }

  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: 15px;
    background-color: #fff;
    display: flex;

    svg {
      margin: auto;
      width: 25px;
      transform: rotate(140deg);
      fill: ${(props) => (props.color ? props.color : '#707070')};
    }
  }

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    span {
      background-color: #fff;

      svg {
        fill: ${(props) => (props.color ? props.color : '#707070')};
        transform: rotate(180deg);
      }
    }
  }
`

const TileIcon = styled.div`
  margin-bottom: 10px;
  img {
    width: 40px;
    height: auto;
  }
`

const TileText = styled.div`
  font-size: 36px;
  font-weight: 700;
`

const TileSubText = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const handleClick = (link) => {
  window.location.href = link
}

export const DashboardTile = ({ tile }) => {
  return (
    <TileWrapper
      color={tile.color}
      link={tile.link}
      onClick={
        tile.link
          ? () => {
              handleClick(tile.link)
            }
          : undefined
      }
    >
      <TileIcon>
        <tile.icon />
      </TileIcon>
      <TileText>{tile.text}</TileText>
      <TileSubText>{tile.subtext}</TileSubText>
      {tile.link && (
        <span>
          <IconArrow />
        </span>
      )}
    </TileWrapper>
  )
}
