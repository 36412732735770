import { useSetRecoilState } from 'recoil'

import { useFetchWrapper } from '../_helpers'
import { documentsAtom } from '../_state'

function useDocumentActions (jobId) {
  const token = localStorage.getItem('access_token')
  const baseUrl = `${process.env.REACT_APP_API_URL}/jobs`

  const fetchWrapper = useFetchWrapper(token)
  const setDocuments = useSetRecoilState(documentsAtom)

  function getAll () {
    fetchWrapper.get(`${baseUrl}/${jobId}/documents`).then(setDocuments)
  }

  function getById (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function upload (file, metadata) {
    return fetchWrapper.upload(`${baseUrl}/${jobId}/documents`, { file, metadata }).then(response => {
      getAll(jobId)
      return response
    })
  }

  return {
    getAll,
    getById,
    upload
  }
}

export { useDocumentActions }
