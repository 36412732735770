import { loginGraphicCss } from '../../_helpers/shared-css'
import styled from 'styled-components'

const GraphicWrapper = styled.div`
  ${loginGraphicCss}
`

const LoginGraphic = ({ description, showText = true }) => {
  return (
    <GraphicWrapper>
      {showText && <div className='employifi-logo' />}
      {showText && (
        <span>Welcome to the simplest way to manage the hiring process.</span>
      )}
      <span>{description}</span>
    </GraphicWrapper>
  )
}

export default LoginGraphic
