const usealiasFormatter = () => {
  const formatAlias = (a) => {
    const aliasArray = []
    if (a.firstName && a.firstName !== null) {
      aliasArray.push(a.firstName)
    }
    if (a.middleName && a.middleName !== null) {
      aliasArray.push(a.middleName)
    }
    if (a.lastName && a.lastName !== null) {
      aliasArray.push(a.lastName)
    }
    return aliasArray.join(' ')
  }

  const formatAliases = (aliases) => {
    const aliasArray = []
    if (aliases !== undefined && aliases !== null && aliases.length > 0) {
      aliases.forEach((a) => {
        aliasArray.push(<div className='compliance-sub'>{formatAlias(a)}</div>)
      })
    }
    return aliasArray
  }

  return { formatAlias, formatAliases }
}

export { usealiasFormatter }
