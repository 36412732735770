import styled from 'styled-components'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
}

const AdminQuickActionWrapper = styled.div`
  display: inline-block;

  .quick-actions-title {
    font-size: 24px;
    color: #555;
    font-weight: bold;
  }

  .tiles {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }

  .tile {
    @media ${device.laptop} { 
      min-width: 320px;
    }
    @media ${device.desktop} {
      min-width: 420px;
    }
    box-sizing: border-box;
    display: inline-block;
    min-height: 176px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #272d3b33;
    padding: 20px;
    position: relative;
    margin-right: 20px;
    background-color: ${(props) => (props.color ? props.color : '#707070')};

    svg {
      width: 35px;
      height: 35px;
    }

    &:hover {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      .tile-arrow {
        background-color: #fff;

        svg {
          fill: ${(props) => (props.hover_color ? props.hover_color : '#e5955a')};
          transform: rotate(180deg);
        }
      }
    }
  }

  .tile-icon,
  .tile-title {
    display: block;
  }

  .tile-title {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
  }

  .tile-sub-title{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
  }

  .tile-arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: 15px;
    background-color: #fff;
    display: flex;

    svg {
      margin: auto;
      width: 25px;
      transform: rotate(140deg);
      fill: ${(props) => (props.hover_color ? props.hover_color : '#e5955a')};
    }
  }

  // .or {
  //   margin: 25px;
  //   position: relative;
  //   font-size: 12px;
  //   font-weight: bold;

  //   &:before, &:after {
  //     content: '';
  //     display: block;
  //     height: 55px;
  //     width: 2px;
  //     background-color: #ddd;
  //     opacity: 0.3;
  //     position: absolute;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }

  //   &:before {
  //     top: -60px;
  //   }

  //   &:after {
  //     bottom: -60px;
  //   }
  // }
`

const handleQuickActionClick = (link) => {
  link()
}

export const AdminQuickAction = ({ color, hover_color, link, icon, text, subtext }) => {
  return (
    <AdminQuickActionWrapper color={color} hover_color={hover_color}>
      <div
        className='tile'
        onClick={() => {
          handleQuickActionClick(link)
        }}
      >
        <span className='tile-icon'>{icon}</span>
        <span className='tile-title'>{text}</span>
        <span className='tile-sub-title'>{subtext}</span>
        <span className='tile-arrow'>
          <IconArrow />
        </span>
      </div>
    </AdminQuickActionWrapper>
  )
}
