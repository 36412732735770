import { useState } from 'react'

import { useUserActions, useAlertActions } from '../../_actions'
import styled from 'styled-components'

import StepOne from './stepone'
import StepTwo from './steptwo'
import StepThree from './stepthree'
import StepFour from './stepfour'
import StepFive from './stepfive'

import {
  formCss,
  loginWrapperCss
} from '../../_helpers/shared-css'
import LoginGraphic from '../../components/shared/LoginGraphic'

const Wrapper = styled.div`
  ${loginWrapperCss}
`

const FormContentWrapper = styled.div`
  flex: 1;
  padding: 40px;
  text-align: center;

  ${formCss}
`

const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const Registration = () => {
  const userActions = useUserActions()
  const alertActions = useAlertActions()
  const api_url = process.env.REACT_APP_API_URL || 'http://localhost:3000'
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [savedUser, setSavedUser] = useState()
  const [signatureUrl, setSignatureUrl] = useState('')
  const [initalsUrl, setInitalsUrl] = useState('')

  const saveStepOne = (inputFirstName, inputLastName) => {
    setFirstName(inputFirstName)
    setLastName(inputLastName)
  }

  const stepOneDone = () => {
    return firstName !== '' && lastName !== ''
  }

  const saveStepTwo = (inputEmail, inputPassword, inputPhone) => {
    setEmail(inputEmail)
    setPassword(inputPassword)
    setPhone(inputPhone)
    userActions
      .register({
        email: inputEmail,
        password: inputPassword,
        phone: inputPhone,
        firstName,
        lastName
      })
      .then((data) => {
        const userData = data.user
        setSavedUser(userData)
        userActions
          .getRegisterSignatureUrl(userData.id)
          .then((signatureInfo) => {
            setSignatureUrl(signatureInfo.signature.upload_url)
          })
        userActions.getRegisterInitialsUrl(userData.id).then((initialsInfo) => {
          setInitalsUrl(initialsInfo.initials.upload_url)
        })
        // check signature from back and if they have it redirect to login page
      })
      .catch((err) => {
        alertActions.error(err)
      })
  }

  const step2Done = () => {
    return savedUser !== undefined
  }

  const backToStepTwo = () => {
    setSavedUser(undefined)
  }

  const step3Done = () => {
    return signatureUrl !== ''
  }

  const saveStepThree = (image) => {
    const formData = new FormData()
    formData.append('user_id', savedUser.id)
    formData.append('upload_type', 'signature')
    formData.append('upload', dataURLtoBlob(image))
    fetch(api_url + '/users/signature', {
      method: 'POST',
      body: formData
    })
      .then(() => {
        userActions
          .getRegisterSignatureUrl(savedUser.id)
          .then((signatureInfo) => {
            setSignatureUrl(signatureInfo.signature.upload_url)
          })
        userActions
          .getRegisterInitialsUrl(savedUser.id)
          .then((initialsInfo) => {
            setInitalsUrl(initialsInfo.initials.upload_url)
          })
      })
      .catch((err) => alertActions.error(err))
  }

  const backToStepThree = () => {
    setSignatureUrl('')
  }

  const step4Done = () => {
    return initalsUrl !== ''
  }

  const saveStepFour = (image) => {
    const formData = new FormData()
    formData.append('user_id', savedUser.id)
    formData.append('upload_type', 'initials')
    formData.append('upload', dataURLtoBlob(image))
    fetch(api_url + '/users/initials', {
      method: 'POST',
      body: formData
    })
      .then(() => {
        userActions
          .getRegisterInitialsUrl(savedUser.id)
          .then((initialsInfo) => {
            setInitalsUrl(initialsInfo.initials.upload_url)
          })
      })
      .catch((err) => alertActions.error(err))
  }

  return (
    <Wrapper>
      <LoginGraphic
        description={"Let's create your account and get you hired!"}
      />
      <FormContentWrapper>
        <div className='form-wrapper'>
          {!stepOneDone()
            ? (
              <StepOne
                defaultFirstName={firstName}
                defaultLastName={lastName}
                save={saveStepOne}
              />
              )
            : !step2Done()
                ? (
                  <StepTwo
                    defaultEmail={email}
                    defaultPassword={password}
                    defaultPhone={phone}
                    save={saveStepTwo}
                  />
                  )
                : !step3Done()
                    ? (
                      <StepThree save={saveStepThree} backToStepTwo={backToStepTwo} />
                      )
                    : !step4Done()
                        ? (
                          <StepFour save={saveStepFour} backToStepThree={backToStepThree} />
                          )
                        : (
                          <StepFive />
                          )}
        </div>
      </FormContentWrapper>
    </Wrapper>
  )
}

export default Registration
