import React from 'react'

export const PageLoaderRef = React.forwardRef((props, ref) => {
  const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

  return (
    <div className='loader' ref={ref}>
      Loading <img src={loadingImg} alt='Loading...' />
    </div>
  )
})
PageLoaderRef.displayName = 'PageLoaderRef'
