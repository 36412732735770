import * as Yup from 'yup'
import { EducationHistory } from '../Education'
import { Step } from './Step'
const EducationInfo = (props) => {
  // form validation rules
  const educationHistorySchema = {
    graduated: Yup.string().oneOf(['Yes', 'No']).required('Did you graduate is Required'),
    type: Yup.string().required('Institution Type is Required'),
    name: Yup.string().required('Institution Name is Required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required')
  }

  const validationSchema = Yup.object().shape({
    educationHistory: Yup.array().of(Yup.object().shape(educationHistorySchema))
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps} isArray>
        <h3>Education</h3>
        <EducationHistory name='educationHistory' readOnly={props.readOnly} />
      </Step>
    </>
  )
}
EducationInfo.componentName = 'EducationInfo'
export { EducationInfo }
