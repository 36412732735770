import { HookFormArrayInput } from '../../shared/HookFormComponents'
import { ArrayProvider, useStateContext } from '../../../contexts'
import { useFieldArray } from 'react-hook-form'

const Alias = () => {
  return (
    <>
      <HookFormArrayInput
        fieldName='firstName'
        label='First Name'
        type='text'
      />
      <HookFormArrayInput
        fieldName='middleName'
        label='Middle Name'
        type='text'
      />
      <HookFormArrayInput
        fieldName='lastName'
        label='Last Name'
        type='text'
      />
    </>
  )
}

const Aliases = ({ name }) => {
  // const methods = useFormContext()
  // const { control } = methods
  const { state, setState } = useStateContext()
  const { fields, append, remove } = useFieldArray({
    name
  })

  if (state[name] === undefined) {
    setState({ ...state, [name]: [] })
  }

  const addAlias = (a) => {
    const list = state[name].map((item) => {
      return item
    })
    list.push(a)
    setState({ ...state, [name]: list })
  }

  const removeAlias = (idx) => {
    const list = state[name].filter((e, i) => {
      return i !== idx
    })
    setState({ ...state, [name]: list })
  }
  return (
    <>
      <div className='form-element'>
        <button
          type='button'
          className='add-remove-button'
          onClick={() => {
            append()
            addAlias({})
          }}
        >
          + Add Alias Entry
        </button>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className='form-element'>
          <ArrayProvider arrayName='aliases' index={index}>
            <Alias id={item.id} />
          </ArrayProvider>
          <button
            type='button'
            onClick={() => {
              remove(index)
              removeAlias(index)
            }}
          >
            - Remove Entry
          </button>
        </div>
      ))}
    </>
  )
}

export { Aliases }
