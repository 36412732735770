import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'

import { ApplicantGenericTable } from './ApplicantGenericTable'
import { ReactComponent as IconDots } from '../../resources/images/icons/dots.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/cancel.svg'
import { useDocumentActions } from '../../_actions'
import { formCss } from '../../_helpers/shared-css'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { RecoilInput } from '../../components/shared/HookFormComponents'
import { StateProvider } from '../../contexts/useStateContext'

const FormWrapper = styled.div`
  ${formCss}
`

const MyDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .document-controls {
    > div {
      display: flex;
      justify-content: flex-end;
    }

    svg {
      width: 45px;
      height: 40px;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
`

const StyledLabel = styled.label`
  margin-right: 10px;
`

const NewDocumentButton = styled.button`
  margin-left: auto;
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #81bcbf;
  padding: 10px 25px;
  border: none;
  border-radius: 20px;
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

const StyledInputWrapper = styled.div`
  margin-top: 15px;
`

const ModalContent = styled.div`
  position: relative;
  display: flex;
  padding: 5px;
  min-height: 200px;
  flex-direction: column;

  .file-selector {
    margin-top: 15px;
  }

  > svg {
    width: 35px;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .modal-title {
    text-align: center;
    font-size: 26px;
    color: #272d3b;
    font-weight: bold;
  }

  ${formCss};
  .form-element .invalid-feedback {
    top: -9px;
  }
`

export const MyDocuments = ({ data }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [modalDocumentNew, setModalDocumentNew] = React.useState(false)
  const intialNewDocumentState = {
    document: undefined,
    file_type: ''
  }
  const { id: jobId } = useParams()

  const [newDocumentState, setNewDocumentState] = useState(
    intialNewDocumentState
  )

  const documentActions = useDocumentActions(jobId)

  function openModalEdit () {
    setModalDocumentNew(false)
    setIsOpen(true)
  }

  function openModalNew () {
    setModalDocumentNew(true)
    setIsOpen(true)
  }

  function closeModal () {
    setIsOpen(false)
    methods.reset()
  }

  const isValidFile = (value) => {
    return value.length
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    document: Yup.mixed()
      .required('Required')
      .test('is-valid-file', 'Document is requried', (value) =>
        isValidFile(value)
      ),
    file_type: Yup.string().required('File type is required'),
    file_name_override: Yup.string().nullable(true),
    issued_date: Yup.string().nullable(true),
    expiration_date: Yup.string().nullable(true)
  })

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: intialNewDocumentState
  }

  const methods = useForm(formOptions)

  const myDocumentsColumns = [
    { title: 'Name', dataIndex: 'title', key: 'title' },
    { title: 'Type', dataIndex: 'document_type', key: 'document_type' },
    { title: 'Issued', dataIndex: 'issued_date', key: 'issued_date' },
    { title: 'Expires', dataIndex: 'expiration_date', key: 'expiration_date' },
    {
      title: '',
      dataIndex: 'id',
      key: 'buttons',
      className: 'document-controls',
      render (row) {
        return (
          <div>
            <button
              onClick={() => {
                // console.log('clicked the share button', row.name)
              }}
            >
              Share
            </button>
            <span
              onClick={() => {
                openModalEdit()
              }}
            >
              <IconDots />
            </span>
          </div>
        )
      }
    }
  ]

  const handleSubmission = async (_) => {
    const { document, ...metaData } = newDocumentState
    const _response = await documentActions.upload(document, metaData)

    // TODO: alert success
    setNewDocumentState(intialNewDocumentState)
    methods.reset()
    setIsOpen(false)
  }

  const handleSelectChanges = (e) => {
    setNewDocumentState({
      ...newDocumentState,
      [e.target.name]: e.target.value
    })
  }

  return (
    <MyDocumentsWrapper>
      <NewDocumentButton onClick={openModalNew}>
        + New Document
      </NewDocumentButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='New Document'
      >
        <ModalContent>
          <IconClose onClick={closeModal} />
          <span className='modal-title'>
            {modalDocumentNew ? 'New Document' : 'Edit Document'}
          </span>
          <div className='file-selector'>
            <StateProvider
              state={newDocumentState}
              setState={setNewDocumentState}
              errors={methods.formState.errors}
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmission)}>
                  <FormWrapper>
                    <div className='form-element'>
                      <StyledLabel for='document'>Document:</StyledLabel>
                      <RecoilInput id='document' type='file' name='document' />
                    </div>
                    <StyledInputWrapper className='form-element '>
                      <StyledLabel
                        style={{ marginRight: '10px' }}
                        for='file_name_override'
                      >
                        File Name (optional)
                      </StyledLabel>
                      <RecoilInput type='text' name='file_name_override' />
                    </StyledInputWrapper>
                    <StyledInputWrapper className='form-element '>
                      <StyledLabel
                        style={{ marginRight: '10px' }}
                        for='file_type'
                      >
                        Type of Document:
                      </StyledLabel>
                      <select
                        {...methods.register('file_type', {
                          onChange: (e) => {
                            handleSelectChanges(e)
                          }
                        })}
                        name='file_type'
                        id='file_type'
                      >
                        <option value='' disabled selected>
                          Select your option
                        </option>
                        <option>N/A</option>
                        <option>Drivers License</option>
                        <option>Commercial Drivers License</option>
                        <option>Military ID</option>
                        <option>Government ID</option>
                      </select>
                      <div className='invalid-feedback'>
                        {methods.formState.errors.file_type?.message}
                      </div>
                    </StyledInputWrapper>
                    <StyledInputWrapper className='form-element '>
                      <StyledLabel for='issued_date'>Issued Date: </StyledLabel>
                      <RecoilInput
                        name='issued_date'
                        id='issued_date'
                        subType='date'
                        type='text'
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper className='form-element '>
                      <StyledLabel for='expiration_date'>
                        Expiration Date:{' '}
                      </StyledLabel>
                      <RecoilInput
                        name='expiration_date'
                        id='expiration_date'
                        subType='date'
                        type='text'
                      />
                    </StyledInputWrapper>
                    {newDocumentState.document
                      ? (
                        <div>
                          <p>Filename: {newDocumentState.document.name}</p>
                          <p>Filetype: {newDocumentState.document.type}</p>
                          <p>Size in bytes: {newDocumentState.document.size}</p>
                          <p>
                            lastModifiedDate:{' '}
                            {newDocumentState.document.lastModifiedDate.toLocaleDateString()}
                          </p>
                        </div>
                        )
                      : (
                        <p>Select a file to show details</p>
                        )}
                    <div>
                      <button className='button-basic' type='submit'>
                        Submit
                      </button>
                    </div>
                  </FormWrapper>
                </form>
              </FormProvider>
            </StateProvider>
          </div>
        </ModalContent>
      </Modal>
      <ApplicantGenericTable
        tableTitle='My Documents'
        columns={myDocumentsColumns}
        data={data}
      />
    </MyDocumentsWrapper>
  )
}
