import { useFetchWrapper } from '../_helpers'

export { useTasksActions }

function useTasksActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/tasks`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    get,
    action,
    update,
    add_artifact,
    get_artifiact
  }

  function get (task_id) {
    return fetchWrapper.get(`${baseUrl}/${task_id}`)
  }

  function action (task_id, task_action) {
    return fetchWrapper.get(`${baseUrl}/${task_id}?task_action=${task_action}`)
  }

  function update (task_id, task) {
    return fetchWrapper.put(`${baseUrl}/${task_id}`, { task })
  }

  function add_artifact (task_id, file, metadata) {
    return fetchWrapper.upload(`${baseUrl}/add_artifact/${task_id}`, { file, metadata }).then(response => {
      return response
    })
  }

  function get_artifiact (task_id) {
    return fetchWrapper.get(`${baseUrl}/get_artifact/${task_id}`)
  }
}
