import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import { useRecoilState } from 'recoil'
import ModalWrapper from '../../admin/shared/ModalWrapper'
import { AdminAddApplicant } from './AdminAddApplicant'

import { debounce } from 'lodash'

import { useCompanyActions, useUserActions } from '../../_actions'
import ApplicantTableFull from '../../admin/shared/ApplicantTableFull'
import { userAtom } from '../../_state'

import {
  AdminApplicantsSearchWrapper,
  SearchWrapper
} from '../../admin/shared/AdminStyledComponets'

export const AdminApplicantsSearch = (props) => {
  const [search, setSearch] = useState('')
  const [applicantsSearchData, setApplicantsSearchData] = useState([])
  const companyActions = useCompanyActions(
    localStorage.getItem('access_token')
  )

  const [searchLoading, setSearchLoading] = useState(false)
  const [editApplicantModalOpen, setEditApplicantModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { locationId } = useParams()

  function clearSearch () {
    setSearch('')
    setApplicantsSearchData(props.initialApplicants || [])
  }

  useEffect(() => {
    if (props.initialApplicants?.length !== 0) {
      setApplicantsSearchData(props.initialApplicants)
    }
  }, [props.initialApplicants])

  const findApplicants = useCallback(
    debounce((val) => {
      setSearchLoading(true)
      companyActions
        .searchApplicants(locationId || props.companyId, val)
        .then((searchApiUsers) => {
          setApplicantsSearchData(searchApiUsers)
          setSearchLoading(false)
        })
    }, 1000),
    []
  )

  const handleChange = (e) => {
    setSearch(e.target.value)
    if (e.target.value.length > 2) {
      findApplicants(e.target.value)
    }
  }
  const [user] = useRecoilState(userAtom)
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const saveApplicant = (data) => {
    setIsLoading(true)
    const createTheApplicant = async () => {
      await userActions.createApplicant(data, locationId, user?.company?.id)
      companyActions.applicants(user?.company?.id).then((applicants) => {
        setApplicantsSearchData(applicants)
        setIsLoading(false)
        setEditApplicantModalOpen(false)
      })
    }
    createTheApplicant()
  }

  return (
    <AdminApplicantsSearchWrapper>
      <div className='search-row'>
        <SearchWrapper>
          <span className='search-label'>
            Search for an existing Applicant by Name or Email
          </span>
          {search === ''
            ? (
              <IconSearch />
              )
            : (
              <IconClose
                onClick={() => {
                  clearSearch()
                }}
              />
              )}
          <input
            htmlFor='search'
            type='text'
            name='applicantSearch'
            value={search}
            placeholder='Search'
            onChange={handleChange}
          />
        </SearchWrapper>
        {/* history.push('/admin/applicants/new') */}
        <div className='add-button-wrapper' style={{ marginLeft: 'auto' }}>
          <button className='add-user-button' style={{ padding: '0 10px', marginTop: '30px' }} onClick={() => setEditApplicantModalOpen(true)}>+ Create Applicant</button>
        </div>
        <ModalWrapper
          modalOpen={editApplicantModalOpen}
          closeModal={() => setEditApplicantModalOpen(false)}
          modalTitleText='Create Applicant'
          modalContent={
            <AdminAddApplicant
              saveApplicant={saveApplicant}
              saveLinkText='Save & Close'
              loading={isLoading}
            />
          }
          modalMinHeight={550}
        />
      </div>
      <ApplicantTableFull
        loading={searchLoading}
        title={search ? `Results for: ${search}` : ''}
        titleColor='#E28A57'
        data={applicantsSearchData}
      />
    </AdminApplicantsSearchWrapper>
  )
}
