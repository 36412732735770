import * as Yup from 'yup'
import { Step } from './Step'
import { DriverLicense } from '../'
import React from 'react'
const DriverLicenseInfo = (props) => {
  const { state } = props
  console.log(state)
  const validationSchema = Yup.object().shape({
    dlFirstName: Yup.string().required('First Name is required'),
    dlLastName: Yup.string().required('Last Name is required'),
    dlLicenseNumber: Yup.string().required('License Number is required'),
    dlDateOfBirth: Yup.string().required('Date of birth is required'),
    dlStreetLine1: Yup.string().required('Street Line 1 is required'),
    dlCity: Yup.string().required('City is required'),
    dlState: Yup.string().required('State is required'),
    dlPostalCode: Yup.string().required('Zip code is required'),
    dlExpirationDate: Yup.string().required('Expiration Date is required')
  })

  const fillNameData = () => {
    const driverLicenseData = {
      firstName: state.firstName,
      middleName: state.middleName,
      lastName: state.lastName,
      dateOfBirth: state.dateOfBirth,
      streetLine1: state.streetLine1,
      streetLine2: state.streetLine2,
      city: state.city,
      state: state.state,
      postalCode: state.postalCode
    }
    props.setState({
      ...state,
      dlFirstName: driverLicenseData.firstName,
      dlLastName: driverLicenseData.lastName,
      dlmiddleName: driverLicenseData.middleName,
      dlDateOfBirth: driverLicenseData.dateOfBirth,
      dlStreetLine1: driverLicenseData.streetLine1,
      dlStreeLinee2: driverLicenseData.streetLine2,
      dlCity: driverLicenseData.city,
      dlState: driverLicenseData.state,
      dlPostalCode: driverLicenseData.postalCode
    })
  }
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>Driver License Information</h3>
        <div className='form-element'>
          <button type='button' className='btn btn-primary' onClick={() => fillNameData()}>Use My Name, Date of Birth and Address</button>
        </div>
        <DriverLicense readOnly={props.readOnly} />
      </Step>
    </>
  )
}
DriverLicenseInfo.componentName = 'DriverLicenseInfo'
export { DriverLicenseInfo }
