import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import AdminWrapper from './shared/AdminWrapper'
import HeaderWrapper from './shared/HeaderWrapper'
import { AdminStepHeader } from '../components/Admin/AdminStepHeader'
import { AdminApplicantInfo } from '../components/Admin/AdminApplicantInfo'
import { JobDetails } from '../components/shared/JobDetails'
import { HRManagement } from '../components/HRManagement'
import { AdminApplicationProcessAndVerification } from '../components/Admin/AdminApplicationProcessAndVerification'
import { ProductsList } from '../components/ProductsList'
import { BarLoader } from 'react-spinners'

import { basicButton } from '../_helpers/shared-css'

import { history } from '../_helpers'

import {
  useUserActions,
  useJobActions,
  useProductsActions,
  useLocationActions,
  useApplicationVerificationActions
} from '../_actions'

const BasicButton = styled.button`
  ${basicButton}
`

const BasicButtonWhite = styled.button`
  ${basicButton}
  background-color: white;
  margin-left: 20px;
  color: #707070;
`

const ApplicantReview = () => {
  const { locationId, jobId, userId } = useParams()
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  const productActions = useProductsActions(
    localStorage.getItem('access_token')
  )
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )

  // const applicationsVerificationsActions = useApplicationVerificationActions(
  //   localStorage.getItem('access_token')
  // )
  const [user, setUser] = useState({})
  const [userLoading, setUserLoading] = useState(true)
  const [jobLoading, setJobLoading] = useState(true)
  const [job, setJob] = useState({})
  const [productsLoading, setProductsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [presetsLoading, setPresetsLoading] = useState(true)
  const [presets, setPresets] = useState([])
  const [hrUsers, setHrUser] = useState([])
  const [hrUsersLoading, setHrUsersLoading] = useState(true)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [recruiter, setRecruiter] = useState()
  const [hasRecruiter, setHasRecruiter] = useState(false)
  const [location_id, setLocationId] = useState(locationId)
  // const [applications, setApplications] = useState([])
  // const [applicationsLoading, setApplicationsLoading] = useState(true)
  const [application, setApplication] = useState()
  const [requireNewApplcation, setRequireNewApplcation] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      const userApi = await userActions.getUserInfo(userId)
      setUser({
        firstName: userApi.user?.first_name,
        lastName: userApi.user?.last_name,
        email: userApi.user?.email,
        id: userApi.user?.id
      })
      setUserLoading(false)
    }
    fetchUser()

    const fetchJob = async () => {
      const j = await jobActions.getById(jobId || 1)
      setJob(j)
      if (locationId === 'null') {
        setLocationId(j?.location?.id)
      }
      setJobLoading(false)
    }
    fetchJob()

    if (jobLoading === false) {
      const fetchPresets = async () => {
        const p = await productActions.presets(location_id)
        setPresets(p)
        setPresetsLoading(false)
      }
      fetchPresets()

      const fetchProducts = async () => {
        const p = await productActions.all(location_id)
        setProducts(p)
        setProductsLoading(false)
      }
      fetchProducts()

      const fetchHRUser = async () => {
        const hrs = await locationActions.getHRUsers(location_id)
        setHrUser(hrs.users)
        setHrUsersLoading(false)
      }
      fetchHRUser()
      // const fetchApplications = async () => {
      //   const as = await applicationsVerificationsActions.list()
      //   setApplications(as)
      //   setApplicationsLoading(false)
      // }
      // fetchApplications()
    }
  }, [jobLoading])

  const save = (url) => {
    const updateJob = async () => {
      await jobActions.finalize(jobId, {
        product_ids: selectedProducts,
        recruiter_id: recruiter?.value,
        recruiter_managed: hasRecruiter,
        application_verification_id: application?.value,
        require_new_application: requireNewApplcation,
        user: { id: userId }
      })
      history.push(url)
    }
    updateJob()
  }
  return (
    <AdminWrapper>
      <HeaderWrapper
        title='Complete Applicant Onboarding'
        link={`/admin/locations/${location_id}/dashboard`}
      />
      {jobLoading || userLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <>
            <AdminStepHeader stepNumber={1} title='Review Details' />
            <AdminApplicantInfo
              details={user}
              edit
              editClick={() => {
                history.push(`/admin/locations/${location_id}/dashboard`)
              }}
            />
            <JobDetails
              details={{
                jobTitle: job?.title || '-',
                openings: 'N/A',
                location: job?.location
                  ? `${job?.location?.city}, ${job?.location?.state}`
                  : '-',
                startDate: job?.start_date || '-',
                payAmount: job?.amount || '-',
                payType: job?.display_pay_structure || '-',
                department: job?.department?.title || '-',
                vacation: job?.vacation || '-',
                bonus: job?.bonus || '-',
                status: job?.display_exempt_status || '-'
              }}
              edit
              editClick={() => {
                history.push(
                  `/admin/locations/${location_id}/users/${userId}/jobs/new?defaultTab=0`
                )
              }}
            />
            <AdminStepHeader stepNumber={2} title='Set HR Preferences' />
            {hrUsersLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <HRManagement
                  hrUsers={hrUsers.map((u) => {
                    return { value: u.id, label: `${u.first_name} ${u.last_name}` }
                  })}
                  setSelected={setRecruiter}
                  setToggle={setHasRecruiter}
                />
                )}
            {/* {applicationsLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <AdminApplicationProcessAndVerification
                  applications={applications.map((a) => {
                    return { value: a.id, label: a.description }
                  })}
                  setSelected={setApplication}
                  setToggle={setRequireNewApplcation}
                />
                )} */}
            <AdminStepHeader stepNumber={3} title='Select Products' />
            {presetsLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <ProductsList
                  title='Company Presets'
                  description='(These products are mandatory for your Company/Organization)'
                  products={presets.map((p) => {
                    return { val: p.id, label: p.title, tooltip: p.description }
                  })}
                />
                )}
            <div style={{ margin: '25px 0' }}>
              Select from the available Products below to create the required
              hiring bundle.
            </div>
            {productsLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <ProductsList
                  title='Available Products'
                  description='(Select All that apply)'
                  products={products.map((p) => {
                    return { val: p.id, label: p.title }
                  })}
                  editable
                  saveChecked={setSelectedProducts}
                />
                )}
          </>
          )}
      <BasicButton
        onClick={() =>
          save(`/admin/locations/${location_id}/users/${userId}/jobs/${jobId}`)}
      >
        Save & Continue
      </BasicButton>
      <BasicButtonWhite
        onClick={() => save(`/admin/locations/${location_id}/dashboard`)}
      >
        Save & Exit
      </BasicButtonWhite>
    </AdminWrapper>
  )
}

export default ApplicantReview
