import styled from 'styled-components'
import { userAtom } from '../../../_state'
import { useRecoilState } from 'recoil'

const StyledMessageWrapper = styled.div`
  padding: 10px;
  padding-left: 0;
  border-top: 1px solid grey;
  margin-top: 20px;
`

const StyledReceiverWrapper = styled.div`
  color: #81BCBF;
`

const StyledSenderWrapper = styled.div`
  color: black;
`
const Message = ({ message, receiverText, senderText }) => {
  const [user] = useRecoilState(userAtom)

  return (
    <StyledMessageWrapper>
      {user.id === message.sender_id
        ? (
          <StyledReceiverWrapper><strong>{senderText}:</strong> {message.message}</StyledReceiverWrapper>
          )
        : (
          <StyledSenderWrapper><strong>{receiverText}:</strong> {message.message}</StyledSenderWrapper>
          )}
    </StyledMessageWrapper>
  )
}

export default Message
