import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as IconArrow } from '../../resources/images/icons/arrow.svg'
import { NavJobList } from './NavJobList'

interface Props {
  open: boolean
}

const DrawerNavWrapper = styled.div<Props>`
  background-color: #f1efed;
  border-right: 1px solid #e8e6e6;
  flex: 1 0 340px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  margin-left: ${(p) => (p.open ? '0px' : '-260px')};
  overflow: auto;
  padding: 20px 0 50px 0;

  > div:nth-of-type(3) {
    opacity: ${(p) => (p.open ? '100%' : '0%')};
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
`

const DrawerToggleButton = styled.div<Props>`
  position: absolute;
  top: 47px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d3d2d0;

  svg {
    fill: #e4e0dc;
    width: 35px;
    margin: 3px 0 0 2px;
    transform: ${(p) => (p.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`

const NavItems = styled.div`
  margin-top: 10px;
`

const DrawerNavTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 28px;
  color: #304755;
  padding: 35px 20px;
`

interface DrawerNavProps {
  title: string
}

const InactiveLink = styled.a`
  display: flex;
  text-decoration: none;
  color: #707070;
  padding: 20px;

  svg {
    fill: #707070;
    width: 25px;
    margin-left: auto;
    padding-right: 5px;
    transform: rotate(180deg);
  }

  .inactive-content span {
    display: block;
    font-size: 14px;

    &:first-child {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &:hover {
    color: #272d3b;
    cursor: pointer;

    svg {
      fill: #272d3b;
    }
  }
`

export const DrawerNav = ({ title }: DrawerNavProps) => {
  const [open, setOpen] = useState(false)

  const navItemList = [
    {
      title: 'Open',
      allLink: '/somewhere',
      jobs: [
        {
          title: 'Account Analyst',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Project Manager - Manufacturing',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Producer',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Jr. Production Assistant',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Research',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Sr. Data Scientist',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Account Coordinator',
          location: 'Irvine, CA',
          link: '/thing'
        }
      ]
    },
    {
      title: 'Drafts',
      allLink: '/somewhere',
      jobs: [
        {
          title: 'Account Analyst',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Project Manager - Manufacturing',
          location: 'Irvine, CA',
          link: '/thing'
        },
        {
          title: 'Producer',
          location: 'Irvine, CA',
          link: '/thing'
        }
      ]
    },
    {
      title: 'Paused',
      allLink: '/somewhere',
      jobs: []
    }
  ]

  return (
    <DrawerNavWrapper open={open}>
      <DrawerNavTitle>{title}</DrawerNavTitle>
      <DrawerToggleButton
        open={open}
        onClick={() => {
          setOpen(!open)
        }}>
        <IconArrow />
      </DrawerToggleButton>
      <NavItems>
        {navItemList.map((item, i) => (
          <NavJobList list={item} key={i} />
        ))}
        <InactiveLink href="/place">
          <div className="inactive-content">
            <span>Inactive</span>
            <span>94 Jobs</span>
          </div>
          <IconArrow />
        </InactiveLink>
      </NavItems>
    </DrawerNavWrapper>
  )
}
