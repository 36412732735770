import * as Yup from 'yup'
import { Address } from '../'
import { Step } from './Step'
const AddressInfo = (props) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    streetLine1: Yup.string().required('Street Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required')
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>Your Address</h3>
        <Address readOnly={props.readOnly} />
      </Step>
    </>
  )
}
AddressInfo.componentName = 'AddressInfo'
export { AddressInfo }
