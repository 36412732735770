import './email.css'
import logo from './employifi-logo.svg'

export const FinalAANoticeEmail = () => {
  return (
    <>
      <div className='email-wrapper'>
        <div className='email-content'>
          <div className='email-body'>
            <div className='top-image-placeholder' />
            <div className='email-body-text'>
              [Date]<br />
              <br />
              Position applied for: [Position] <br />
              Date of Application: [Application Date] <br />
              Date of Conditional Offer: [Conditional Offer Date] <br />
              Date of Pre-Adverse Action Letter: [Pre-A] <br />
              <br />
              <strong>Re:</strong> <br />
              <p>Disqualifying Event: [Disqualifying Event]</p>
              <p>Dear [Applicant Name], </p>
              <p>After consideration of all relevant information available to the Company at this time, your recent application for employment has been denied based in whole, or in part, on information contained in the consumer report. </p>
              <p>As part of the Company’s review of your qualifications you authorized the Company to obtain a consumer report. The consumer reporting agency providing this report was:</p>
              <div className='bold-indent'>
                Employee Relations, Inc. <br />
                450 North Brand Boulevard, Suite 600 <br />
                Glendale, CA 91203 <br />
                Phone: 800.716.7773 <br />
                E-Mail: complianceofficer@erelations.com <br />
                Website: www.erelations.com
              </div>
              <br />
              <p>The above-named agency did not make the decision to take the adverse employment action and is unable to provide you the specific reasons why the action was taken. In addition to receiving a free copy of your consumer report, you also have the right to dispute the accuracy or completeness of any information contained in your consumer report with the above agency. </p>
              <p>Previously, you were informed of, and provided, written notice of the proposed adverse action along with information about your rights and a copy of any reports which the Company requested about you for employment purposes. </p>
              <p>While the Company is unable to move forward with your employment for the position listed above at this time, we wish you the best in your job search.</p>
              <br />
              <p>Sincerely,<br /><br /> Jaffe Joffer <br />MacDowells<br /> Ruler Of Zamunda</p>
            </div>
          </div>
          <div className='email-footer'>
            <div className='footer-left'>
              <span>compliance & hiring powered by</span>
              <img src={logo} alt='Employifi Logo' />
            </div>
            <div className='footer-right'>
              <p>This email was intended for Joshua Forstot (ECD & Founder of dough. Creative + Tech | Problem Solver | Speaker | Advisor). </p>
              <p>You are receiving these communications from Employifi on behalf of [Company Name].</p>
              <p>Company and Employifi legal copy can go here. I suggest you try it again, Luke. This time, let go your conscious self and act on instinct. Don’t be too proud of this technological terror you’ve constructed. The ability to destroy a planet is insignificant next to the power of the Force.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
