import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { history } from '../../_helpers'

import { userAtom } from '../../_state'
import { useLocationActions, useCompanyActions } from '../../_actions'

import AdminWrapper from '../shared/AdminWrapper'
import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'

import { AdminCompanyDashboardTableWrapper } from '../shared/AdminStyledComponets'
import { DashboardTile } from '../../components/DashboardTileV2'
import { AdminQuickAction } from '../../components/Admin/AdminQuickActionV2'
import { ReactComponent as IconFolder } from '../../resources/images/icons/folder.svg'
import { ReactComponent as IconMagnifyingGlass } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconGroup } from '../../resources/images/icons/group.svg'

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  .tile-controls {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    color: #707070;

    h2 {
      display: inline-block;
      color: #707070;
    }

    span {
      margin-right: 10px;
    }

    span:first-of-type {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .change-view {
    float: right;
    display: inline-block;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
      fill: #f0eeec;
    }

    &.blue {
      svg {
        fill: #81bcbf;
      }
    }
  }
`

const AdminCompanyDashboard = () => {
  const [locations, setLocations] = useState([])
  const [topUsers, setTopUsers] = useState([])
  const [topJobs, setTopjobs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [user] = useRecoilState(userAtom)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))

  const recentApplicantsColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render (val, row) {
        return (
          <div>
            <span className={val === 'Available' ? '' : 'inprogress'}>
              {val}
            </span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            <button
              onClick={() => {
                row.status === 'Available'
                  ? history.push(
                    `/admin/locations/${locations[0].id}/users/${row.id}/jobs/new`
                  )
                  : history.push(
                    `/admin/locations/${row.job_location_id}/users/${row.id}/jobs/${row.job_id}`
                  )
              }}
            >
              View
            </button>
          </div>
        )
      }
    }
  ]

  const recentJobsColumns = [
    { title: 'Job', dataIndex: 'title', key: 'title' },
    { title: 'Location', dataIndex: 'location_name', key: 'location_name' },
    {
      title: 'Status',
      dataIndex: 'jank_display_status',
      key: 'jank_display_status',
      className: 'status-column',
      render (val, row) {
        return (
          <div>
            <span className={val === 'Available' ? '' : 'inprogress'}>
              {val}
            </span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            <button
              onClick={() => {
                row.status === 'Available' || row.applicant_user === undefined || row.applicant_user === null
                  ? history.push(
                    `/admin/locations/${locations[0].id}/dashboard`
                  )
                  : history.push(
                    `/admin/locations/${row.location.id}/users/${row.applicant_user.id}/jobs/${row.id}`
                  )
              }}
            >
              View
            </button>
          </div>
        )
      }
    }
  ]

  const dashboardTiles = () => [
    {
      icon: IconMagnifyingGlass,
      text: 'Settings',
      subtext: 'Edit & Configure',
      link: `/admin/companies/${user?.company?.id}/edit?tabId=1`
    },
    {
      icon: IconMagnifyingGlass,
      text: 'Team',
      subtext: 'Manage Users',
      link: `/admin/companies/${user?.company?.id}/edit?tabId=4`
    }
  ]

  useEffect(() => {
    const fetchDashboardData = async () => {
      const locations = await locationActions.getLocationList(user?.company?.id)
      setLocations(locations)
      const apitopusers = await companyActions.top5users(user?.company?.id)
      setTopUsers(apitopusers)
      const apitopjobs = await companyActions.top5jobs(user?.company?.id)
      setTopjobs(apitopjobs)

      setIsLoading(false)
    }
    fetchDashboardData()
  }, [])

  return (
    <AdminWrapper>
      <h1 style={{ color: '#81BCBF' }}>Dashboard</h1>
      <TilesWrapper>
        <AdminQuickAction
          color='#de7e53'
          hover_color='#E5955A'
          link={() => { history.push('/admin/applicants') }}
          icon={<IconGroup />}
          text='All Applicants'
          subtext='View & Manage'
        />
        <AdminQuickAction
          color='#81BCBF'
          hover_color='#81BCBF'
          link={() => { history.push(`/admin/companies/${user?.company?.id}/jobs`) }}
          icon={<IconFolder />}
          text='All Jobs'
          subtext='View & Manage'
        />
        {dashboardTiles().map((tile, i) => (
          <DashboardTile key={i} tile={tile} />
        ))}
      </TilesWrapper>
      <AdminCompanyDashboardTableWrapper>
        <ApplicantGenericTable
          columns={recentApplicantsColumns}
          data={topUsers}
          tableTitle='Recent Applicants'
          rowKey='id'
          loading={isLoading}
        />
        <ApplicantGenericTable
          columns={recentJobsColumns}
          data={topJobs}
          tableTitle='Recent Jobs'
          rowKey='id'
          loading={isLoading}
        />
      </AdminCompanyDashboardTableWrapper>
    </AdminWrapper>
  )
}

export default AdminCompanyDashboard
