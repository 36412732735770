import styled from 'styled-components'
import { formCss } from '../../_helpers/shared-css'
import Select from 'react-select'
import states from '../../_helpers/states'

const AdminCompanyInformationWrapper = styled.div`
  ${formCss}

  .two-col {
    display: flex;

    >div {
      flex: 1;
      padding-right: 25px;
    }

    >div:last-of-type {
      padding-right: 0;
    }
  }

  .max-width {
    max-width: 500px;
  }
`

export const AdminCompanyInformation = () => {
  return (
    <AdminCompanyInformationWrapper>
      <h3>Company Information</h3>
      <div className='two-col'>
        <div>
          <div className='form-element'>
            <label>Company Display Name</label>
            <input
              defaultValue=''
              name='display_name'
              type='text'
              className='form-control'
            />
            <div className='invalid-feedback'>
              {/* {errors.display_name?.message} */}
            </div>
          </div>
        </div>
        <div className='two-col'>
          <div className='form-element'>
            <label>Ein Number</label>
            <input
              defaultValue=''
              name='ein_number'
              type='text'
              className='form-control'
            />
            <div className='invalid-feedback'>
              {/* {errors.display_name?.message} */}
            </div>
          </div>
          <div className='form-element'>
            <label>ERX Id</label>
            <input
              defaultValue=''
              name='erx_id'
              type='text'
              className='form-control'
            />
            <div className='invalid-feedback'>
              {/* {errors.display_name?.message} */}
            </div>
          </div>
        </div>
      </div>
      <h3>Company Address</h3>
      <div className='max-width'>
        <div className='form-element'>
          <label>Company Name</label>
          <input
            defaultValue=''
            name='company_name'
            type='text'
            className='form-control'
          />
          <div className='invalid-feedback'>
            {/* {errors.display_name?.message} */}
          </div>
        </div>
        <div className='form-element'>
          <label>Company Address</label>
          <input
            defaultValue=''
            name='company_address'
            type='text'
            className='form-control'
          />
          <div className='invalid-feedback'>
            {/* {errors.display_name?.message} */}
          </div>
        </div>
        <div className='form-element'>
          <label>Company Address 2 (Suite No.)</label>
          <input
            defaultValue=''
            name='company_address_2'
            type='text'
            className='form-control'
          />
          <div className='invalid-feedback'>
            {/* {errors.display_name?.message} */}
          </div>
        </div>
        <div className='form-element'>
          <label>City</label>
          <input
            defaultValue=''
            name='city'
            type='text'
            className='form-control'
          />
          <div className='invalid-feedback'>
            {/* {errors.display_name?.message} */}
          </div>
        </div>
        <div className='two-col'>
          <div className='form-element'>
            <label>State</label>
            <Select
              name='state'
              required
              options={states}
              onChange={() => console.log('change state')}
            />
            <div className='invalid-feedback'>
              {/* {errors.display_name?.message} */}
            </div>
          </div>
          <div className='form-element'>
            <label>Postal Code</label>
            <input
              defaultValue=''
              name='postal_code'
              type='text'
              className='form-control'
            />
            <div className='invalid-feedback'>
              {/* {errors.display_name?.message} */}
            </div>
          </div>
        </div>
      </div>
    </AdminCompanyInformationWrapper>
  )
}
