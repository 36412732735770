// import * as React from "react";
import styled from 'styled-components'
import Table from 'rc-table'
import { PageLoader, useReloadJob } from '../../_helpers'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { job as jobAtom } from '../../_state'
import { useJobActions } from '../../_actions'
import { useEffect } from 'react'

// import {ReactComponent as IconEye} from "../../resources/images/icons/eye_on.svg"
// import {ReactComponent as IconEyeOff} from "../../resources/images/icons/eye_off.svg"
const Circle = styled.div`
  position: relative;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid #8cc63e;
  margin: 0 auto;
`
const Checkmark = styled.div`
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 45%;
  height: calc(30px / 3);
  width: calc(30px / 6);
  border-bottom: 3px solid #8cc63e;
  border-right: 3px solid #8cc63e;
`
const columns = [
  { title: 'Job', dataIndex: 'title', key: 'title' },
  { title: 'Location', dataIndex: ['location', 'state'], key: 'state' },
  { title: 'Action Date', dataIndex: 'action_date', key: 'action_date' },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render (row) {
      const getStatusText = (status) => {
        let text
        switch (status) {
          case 'completed':
            text = 'Complete'
            break
          case 'in_progress':
            text = 'In Progress'
            break
          case 'new':
            text = 'Not Started'
            break
          default:
            text = 'Not Started'
        }
        return text
      }
      return <div>{getStatusText(row)}</div>
    }
  },
  {
    title: 'Open Tasks',
    dataIndex: 'tasks_count',
    key: 'tasks_count',
    render (val, row) {
      if (row.status !== 'completed') {
        return (
          <Circle>
            <Checkmark />
          </Circle>
        )
      }
    }
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'e',
    className: 'table-buttons',
    render (val, row) {
      return (
        <div key={row}>
          <JobManageButton job={row} btn_text='manage' btn_class='manage' />
        </div>
      )
    }
  }
]

function JobManageButton (props) {
  const history = useHistory()
  const [_job, setJob] = useRecoilState(jobAtom)
  const jobActions = useJobActions(localStorage.getItem('access_token'))
  function handleClick () {
    switch (props.job.status) {
      case 'new':
        jobActions.start(props.job.id)
          .then((job) => {
            setJob(job)
            const path = 'jobs/' + props.job.id
            history.push(path)
          })
        break
      default:
        jobActions.getById(props.job.id)
          .then((job) => {
            setJob(job)
            const path = 'jobs/' + props.job.id
            history.push(path)
          })
    }
    /* jobActions.getById(props.job.id)
      .then((job) => {
        console.log(job)
        setJob(job)
        const path = 'jobs/' + props.job.id
        history.push(path)
      }) */
  }

  return (
    <button className={props.btn_class} type='button' onClick={handleClick}>
      {props.btn_text}
    </button>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;
    }

    .in-progress {
      color: #de7e53;
    }

    .table-buttons {
      display: flex;
      min-width: 130px;
    }

    button.manage {
      border: 1px solid #c8d1d3;
      padding: 8px 25px 10px 25px;
      display: inline-flex;
      border-radius: 20px;
      box-sizing: border-box;
      background-color: transparent;

      color: #334856;
      font-size: 12px;
      font-weight: bold;

      text-transform: uppercase;
      &:hover {
        background-color: #c2b9ae;
        color: #fff;
        cursor: pointer;
      }
    }

    a {
      border: 1px solid #c8d1d3;
      padding: 8px 25px 10px 25px;
      display: inline-flex;
      border-radius: 20px;
      height: 41px;
      box-sizing: border-box;
      text-decoration: none;
      color: #334856;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &:first-of-type {
        margin-right: 10px;
        padding: 10px 35px;
      }
    }
  }
`

export const DashboardJobsTable = ({ data, loading }) => {
  const { reloadJob } = useReloadJob()
  const job = useRecoilValue(jobAtom)
  useEffect(() => {
    if (job) { reloadJob(job.id).then((d) => console.log(d)) }
  }, [])

  if (loading) {
    return (
      <TableWrapper>
        <PageLoader />
      </TableWrapper>
    )
  }
  return (
    <TableWrapper>
      <Table
        columns={columns}
        data={data}
        rowKey='id'
        rowClassName={(record) => {
          return record.c === 'In Progress' ? 'in-progress' : ''
        }}
      />
    </TableWrapper>
  )
}
