import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { stepsAtom } from '../../_state'
import ApplicantTasksButtons from './ApplicantTasksButtons'
import { ApplicantGenericTable } from './ApplicantGenericTable'

const handleStatus = (status, border) => {
  let color
  switch (status) {
    case 'completed':
      color = '#7EC749'
      break
    case 'started':
    case 'awaiting_submission':
    case 'viewed':
      color = '#81BCBF'
      break
    case 'submitted':
      color = '#FFD42F'
      break
    case 'Processing':
      color = '#FFD42F'
      break
    case 'Needs Signature':
      color = '#DE7E53'
      break
    default:
      color = border ? '#707070' : 'transparent'
  }
  return color
}

const StatusWrapper = styled.div`
  padding-left: 5px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${({ status }) => handleStatus(status)};
    border: 1px solid ${({ status }) => handleStatus(status, true)};
  }
`

const ApplicantTasksWrapper = styled.div`
  .task-controls {
    text-align: right;

    button {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      padding: 8px 25px;
      min-width: 100px;
      border-radius: 20px;
      text-align: center;
      display: inline-block;
      margin-left: 10px;
    }

    button.view {
      border: 1px solid #81bcbf;
      color: #81bcbf;

      &:hover {
        background-color: #81bcbf;
        color: #fff;
        cursor: pointer;
      }
    }

    button.download {
      border: 1px solid #888;
      color: #888;
      opacity: 0.7;

      &:hover {
        background-color: transparent;
        border: 1px solid #333;
        color: #333;
        cursor: pointer;
      }
    }

    button.complete {
      border: 1px solid #81bcbf;
      background-color: #81bcbf;
      color: #fff;

      &:hover {
        background-color: #689a9d;
        border: 1px solid #689a9d;
        cursor: pointer;
      }
    }

    button.get-started {
      border: 1px solid #c2b9ae;
      background-color: transparent;
      color: #c2b9ae;

      &:hover {
        background-color: #c2b9ae;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`

export const ApplicantTasks = ({ data, job }) => {
  const setSteps = useSetRecoilState(stepsAtom)
  setSteps(1)
  data = data.slice().sort((d1, d2) => d1.ui_order - d2.ui_order)

  const applicantTasksColumns = [
    { title: 'Document', dataIndex: 'title', key: 'title' },
    { title: 'Delivered', dataIndex: 'delivered_at', key: 'delivered_at' },
    { title: 'Completed', dataIndex: 'completed_at', key: 'completed_at' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'status',
      width: 150,
      render (row) {
        const getStatusText = (status) => {
          let text
          switch (status) {
            case 'completed':
              text = 'Complete'
              break
            case 'delivered':
              text = 'Not Viewed'
              break
            case 'started':
            case 'awaiting_submission':
            case 'viewed':
              text = 'In Progress'
              break
            case 'submitted':
              text = 'Submitted'
              break
            case 'Needs Signature':
              text = 'Needs Signature'
              break
            case 'Processing':
              text = 'Processing'
              break
            default:
              text = 'Not Viewed'
          }
          return text
        }
        return (
          <div>
            <StatusWrapper status={row}>{getStatusText(row)}</StatusWrapper>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'button',
      className: 'task-controls',
      render (row) {
        return (
          <ApplicantTasksButtons row={row} job={job} />
        )
      }
    }
  ]
  return (
    <ApplicantTasksWrapper>
      <ApplicantGenericTable
        tableTitle='Tasks'
        columns={applicantTasksColumns}
        data={data}
      />
    </ApplicantTasksWrapper>
  )
}
