import '../../email/email.css'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useTasksActions, useUserActions } from '../../_actions'
import { userAtom } from '../../_state'
import logo from '../../email/employifi-logo.svg'
import { useParams } from 'react-router-dom'

const ConditionalOfferLetter = React.forwardRef(({ job, register }, ref) => {
  const [isUser, setIsUser] = useState(false)
  const [consent, setConsent] = useState(false)
  const [signatureUrl, setSignatureUrl] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const user = useRecoilValue(userAtom)
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  // TODO: There needs to be a better way to determine offer type.
  const offerType = 'conditional'
  const { id } = useParams()

  const payStructureText = () => {
    switch (job.pay_structure) {
      case 1: return 'Weekly'
      case 2: return 'Bi-Weekly'
      case 3: return 'Semi-Monthly'
      case 4: return 'Montly'
    }
  }

  useEffect(() => {
    setIsUser(user.roles.map((r) => r.name === 'User')[0])
    if (isUser === true) {
      userActions.getSignatureUrl()
        .then((u) => setSignatureUrl(u.signature.upload_url))
    }
    taskActions.get(id)
      .then((t) => {
        setExpirationDate(t.expires_at)
      })
  }, [])

  useEffect(() => {
    if (consent === true) {
      userActions.getSignatureUrl()
        .then((u) => setSignatureUrl(u.signature.upload_url))
    } else {
      setSignatureUrl(null)
    }
  }, [consent])

  const consentClicked = (e) => {
    if (e.target.checked === true) {
      setConsent(true)
    } else {
      setConsent(false)
    }
  }

  return (
    <>
      <div className='email-wrapper'>
        <div id='email-content' className='email-content' ref={ref}>
          <div className='email-body'>
            {job.company.company_logo_url
              ? <img className='top-image' src={job.company.company_logo_url} width='240' height='90' alt='company logo' />
              : <div className='top-image-placeholder' />}
            <div className='email-body-text'>
              <p>{job.date_applied}</p>
              <p>{job.applicant_user.first_name} {job.applicant_user.last_name}</p>
              <p>re: Conditional Offer of Employment as {job.title}</p>
              <p>Dear {job.applicant_user.first_name},</p>

              <p>{job.company.display_name} (the "Company") is pleased to offer you employment on the following terms:</p>
              <p><b>Conditional Offer:</b> This offer is conditioned on an acceptable background check and completion of all
                pre-hire tasks no later than 11:59 PM on {expirationDate}, after which time this conditional offer will expire.
              </p>

              <p><b>Position:</b> You will be employed in the position of {job.title}. In this position, you will report to&nbsp;
                {job.hiring_manager_name}, the {job.hiring_manager_title}, or as otherwise directed by the Company and your employment&nbsp;
                status will be {job.employment_type !== null ? job.employment_type === 1 ? 'Full Time' : 'Part Time' : 'Unknown'}.
              </p>

              <p><b>Start Date:</b> Your employment in this role will commence on, or about, {job.start_date !== null ? job.start_date : ' as soon as possible'} at&nbsp;
                {job.location.display.replace(/(\r\n|\n|\r)/gm, ' ')}</p>

              <p><b>Compensation:</b> In this position, you will earn {job.amount_type === 2 ? ' a yearly salary of ' : ' an hourly wage of '}
                {job.amount}.{job.amount_type === 2 ? `per year payable ${payStructureText()}` : ''}.{job.commission === 2
                  ? job.commission_notes
                  : ''}{job.bonus ? ` Additionally, you may be eligible to receive a bonus up to ${job.bonus}.` : ''}</p>

              <p>All compensation is payable less deductions authorized by you, all tax withholdings and other amounts as the Company, in&nbsp;
                its sole discretion, deems necessary or permitted by applicable law. Compensation will be paid in accordance with the Company's&nbsp;
                established policies and procedures.
              </p>
              <p><b>Employee Benefts:</b> You may be eligible for all employee benefits and to participate in all employee
                benefits the Company makes available to employees with similar employee status, subject to the terms and conditions
                of the personnel policies or benefit plans, as applicable, governing the benefits.
                As you work, you will accrue vacation at a rate equal to {job.vacation}&nbsp;
                of paid vacation each year. Details will be provided to you prior to commencing employment.
              </p>

              <p><b>At-Will Employment:</b> Your employment relationship with the Company is at all times “at will.”
                This means that both you and the Company retain the right to terminate the employment relationship at any
                time, with or without cause or any particular notice or procedures. It also means that the Company reserves
                the right to determine and change, in its sole business judgment and discretion, your job title, duties,
                reporting relationship, base of employment, sales territory, cash compensation, employee benefits and benefit
                plans it makes available to employees, and other policies and any other term and condition of your employment.
              </p>

              <p><b>Compliance Law & Company Policies:</b> As a condition of your employment with the Company, you will be
                required to comply with applicable laws, and abide by the Company’s policies and procedures, including but not
                limited to the policies set forth in the Company’s Employee Handbook, as may be in effect from time to time,
                including, but not limited to, its equal employment opportunity, anti-harassment, conflict of interest and business
                ethics policies. The Company reserves the right to change policies, compensation and benefits from time to time,
                as it deems appropriate or necessary.
              </p>

              <p><b>Representations & Warranties:</b> The following conditions apply to this offer, and employment pursuant to this offer:</p>
              <p>You represent and warrant that (i) you are not subject to any preexisting non-compete agreement with any company or business
                enterprise to whom you previously provided services; (ii) while you have disclosed that you are subject to a standard NDA and
                assignment of inventions agreement with your current employer, you are not subject to any pre-existing contractual or other
                legal obligation with any person, company or business enterprise that to your knowledge, would be an impediment to your employment
                with the Company; and (iii) you have not and shall not knowingly bring onto Company premises, or use or disclose, directly or
                indirectly, in the course of your employment with the Company, any confidential or proprietary information or trade secrets
                of another company or business enterprise to whom you previously provided services, including but not limited any client or
                employee information; and (iv) all information you provided to the Company regarding your experience, skills, accomplishments,
                credentials and background experience are true and correct and without omission; and (v) In accordance with federal law, you
                are able to provide the Company with documentation that establishes your identity and right to work for the Company in the
                United States within the first three (3) days of employment.
              </p>

              <p>The acceptance of this conditional offer will constitute your complete and exclusive agreement with the Company
                concerning your employment with the Company.  The terms in this letter supersede any other representations, negotiations
                or agreements made to you by the Company and any person associated with the Company, whether oral or written. The terms
                of this agreement cannot be changed (except with respect to those changes expressly reserved to the Company’s business
                judgment and discretion in this letter) without a written agreement signed by you and a duly authorized Executive Officer
                of the Company.  In case any provision contained in this agreement shall, for any reason, be held invalid or unenforceable
                in any respect, such invalidity or unenforceability shall not affect the other provisions of this agreement, and such provision
                will be construed and enforced so as to render it valid and enforceable consistent with the general intent of the parties insofar
                as possible under applicable law.  With respect to the enforcement of this agreement, no waiver of any right hereunder shall be
                effective unless it is in writing.
              </p>

              <p>If you wish to accept employment with the Company under the terms described above, please sign, date this {offerType}
                offer no later than 11:59 PM on {expirationDate}, after which time this {offerType} offer will expire and please feel free to
                contact us in regards to any questions you may have.
              </p>

              <p>On behalf of {job.company.display_name}, I look forward to a productive and enjoyable work relationship.</p>
              <p>Sincerely,</p>
              <p>{job.hiring_manager_name} | {job.hiring_manager_title}<br />
                {job.company.display_name}
              </p>
              <br />
              <p>I accept the foregoing offer of employment. I have read and understand and agree to its terms.  I understand
                that this conditional offer sets forth the entire agreement between myself and the Company, regarding
                the terms of employment and supersedes any prior agreements, understanding or discussion which I may have
                prior to signing this {offerType} offer.
              </p>
              {isUser && consent && <>
                <br />
                <br />
                <div><div style={{ float: 'left' }}><img src={signatureUrl} width='100px' /></div><div style={{ float: 'right' }}>{new Date().toLocaleDateString()}</div></div>
                <input type='hidden' {...register('consentDate')} value={new Date().toLocaleDateString()} />
                <br />
                <br />
              </>}
            </div>
          </div>
          <div className='email-footer'>
            <div className='footer-left'>
              <span>compliance & hiring powered by</span>
              <img src={logo} alt='Employifi Logo' />
            </div>
            <div className='footer-right'>
              <p>This email was intended for {job.applicant_user.first_name} {job.applicant_user.last_name}.</p>
              <p>You are receiving these communications from Employifi on behalf of {job.location.title}.</p>
            </div>
          </div>
        </div>
      </div>
      {isUser && <>
        <div className='form-element'>
          <label>I consent to using my electronic signature that is stored on file</label>
          <input type='checkbox' {...register('consent')} onClick={consentClicked} />
        </div>
      </>}
    </>
  )
})

ConditionalOfferLetter.displayName = 'ConditionalOfferLetter'
export { ConditionalOfferLetter }
