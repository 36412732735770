import { Controller, useFormContext } from 'react-hook-form'

const HookFormToggleSwitch = ({
  name,
  label,
  state,
  setState
}) => {
  const { formState } = useFormContext()
  const { errors } = formState
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked })
    return e.target.checked
  }
  return (
    <div className='form-element'>
      <label>{label}</label>
      <Controller
        name={name}
        defaultValue={false}
        render={({ field }) => (
          <label className='switch'>
            <input
              {...field}
              className='input-inline'
              type='checkbox'
              checked={field.checked}
              onChange={(e) => field.onChange(onChange(e))}
            />
            <span className='slider round' />
          </label>
        )}
      />
      <div className='invalid-feedback'>
        {errors?.[name]?.message}
      </div>
    </div>
  )
}

export { HookFormToggleSwitch }
