import styled from 'styled-components'

// TODO break out this into a shared object that can be used in multiple components
export const TableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  .details-label {
    font-weight: bold;
    padding: 20px 20px;
    min-width: 100px;
  }

  .edit {
    width: 70px;
    border-left: 1px solid #ccc;
    margin-left: auto;
    padding: 20px 0;
    flex: 0 0 50px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 18px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;

      .details-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .alert {
      color: #de7e53;

      svg {
        fill: #de7e53;
      }
    }

    svg {
      width: 25px;
      fill: #b9afa4;
    }

    .table-buttons {
      display: flex;
    }

    a {
      border: 1px solid #c8d1d3;
      padding: 8px 25px 10px 25px;
      display: inline-flex;
      border-radius: 20px;
      height: 41px;
      box-sizing: border-box;
      text-decoration: none;
      color: #334856;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &:first-of-type {
        margin-right: 10px;
        padding: 10px 35px;
      }
    }
  }
`

export const ProductListTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px 30px;
  border-bottom: 1px solid #f0eeec;

  span {
    font-weight: normal;
  }
`

// TODO break out this into a shared object that can be used in multiple components
export const TableHeader = styled.div`
  display: flex;
  padding: 20px;

  h3 {
    font-size: 26px;
    color: #272d3b;
    margin: 0;
    padding: 0;
  }
`

// TODO break out this into a shared object that can be used in multiple components
export const TableHeaderButtons = styled.div`
  display: inline-flex;
  margin-left: auto;

  span {
    display: inline-block;
    font-size: 16px;
    padding: 10px 25px;
    color: #fff;
    background-color: #81bcbf;
    border-radius: 20px;
    margin-left: 10px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      background-color: #73a8ab;
    }
  }
`

export default { TableWrapper, TableHeader, TableHeaderButtons, ProductListTitle }
