import { useFetchWrapper } from '../_helpers'

export { useOrderActions }

function useOrderActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/orders`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    list,
    add
  }

  function list (job_id) {
    return fetchWrapper.get(`${baseUrl}?job_id=${job_id}`)
  }

  function add (order) {
    return fetchWrapper.post(`${baseUrl}`, { order })
  }
}
