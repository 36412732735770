import styled from 'styled-components'
import Message from './Message'
import Modal from 'react-modal'
import { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { HookFormTextArea } from '../../../components//shared/HookFormComponents'
import { StateProvider } from '../../../contexts/useStateContext'
import { formCss } from '../../../_helpers/shared-css'
import { ReactComponent as IconClose } from '../../../resources/images/icons/cancel.svg'
import { useAlertMessageActions } from '../../../_actions'
import { useParams } from 'react-router-dom'
import { ReactComponent as IconEdit } from '../../../resources/images/icons/edit2.svg'
import { history } from '../../../_helpers'

const FormWrapper = styled.div`
  margin-top: 20px;
  ${formCss}
`

const AlertWrapper = styled.div`
  .flex-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  button {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 25px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
    margin-left: auto;
    font-family: 'Montserrat';
  }

  button.respond {
    border: 1px solid #c2b9ae;
    background-color: transparent;
    color: #c2b9ae;

    &:hover {
      background-color: #c2b9ae;
      color: #fff;
      cursor: pointer;
    }
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

const ModalContent = styled.div`
  position: relative;
  display: flex;
  padding: 5px;
  min-height: 200px;
  flex-direction: column;

  .file-selector {
    margin-top: 15px;
  }

  > svg {
    width: 35px;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .modal-title {
    text-align: center;
    font-size: 26px;
    color: #272d3b;
    font-weight: bold;
  }

  ${formCss};
  .form-element .invalid-feedback {
    top: -9px;
  }
`

const StyledLabel = styled.label`
  margin-right: 10px;
`
const StyledRespondingTo = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px dotted grey;
  padding: 5px;
  text-align: center;
`
const Alert = ({ alert, actions, receiverText, senderText }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [newMessage, setNewMessage] = useState({ message: '' })
  const { jobId, id } = useParams()
  const alertMessageActions = useAlertMessageActions(jobId || id, alert.id)

  const validationSchema = Yup.object().shape({
    message: Yup.string().required('A message is required')
  })

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: { message: '' }
  }

  const methods = useForm(formOptions)

  const handleSubmission = (data) => {
    alertMessageActions.sendMessage(data).then(actions.getAll)
    setIsOpen(false)
    methods.reset()
  }

  const openResponseModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    methods.reset()
  }

  const getLastMessage = () => {
    const lastMessage = alert.alert_messages[alert.alert_messages.length - 1]
    return lastMessage.message
  }

  const generateRespondingTo = () => {
    if (alert.alert_messages?.length) {
      return (
        <>
          <strong>Responding to: </strong> {getLastMessage()}
        </>
      )
    } else {
      return (
        <>
          <strong>Responding to: </strong> {alert.details}
        </>
      )
    }
  }

  const renderModal = (alert) => {
    if (alert.alert_messages === null) {
      return false
    }

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Notification Response'
        ariaHideApp={false}
      >
        <ModalContent>
          <IconClose onClick={closeModal} />
          <span className='modal-title'>Notification Response</span>
          <div className='file-selector'>
            <StateProvider
              state={newMessage}
              setState={setNewMessage}
              errors={methods.formState.errors}
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmission)}>
                  <FormWrapper>
                    <StyledRespondingTo>
                      {generateRespondingTo(alert)}
                    </StyledRespondingTo>
                    <div className='form-element'>
                      <StyledLabel
                        style={{ marginRight: '10px' }}
                        for='message'
                      >
                        Message to {receiverText}:
                      </StyledLabel>
                      <HookFormTextArea name='message' />
                    </div>
                    <button className='button-basic' type='submit'>
                      Submit
                    </button>
                  </FormWrapper>
                </form>
              </FormProvider>
            </StateProvider>
          </div>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <AlertWrapper>
      {renderModal(alert)}
      <div className='flex-cell'>
        <span>{alert.details}</span>
        {alert.type === 'Notification'
          ? (
            <button className='respond' onClick={() => openResponseModal(alert)}>
              Respond
            </button>
            )
          : null}
        {alert.editLink && (
          <div
            onClick={() => history.push(alert.editLink)}
            className='edit'
          >
            <IconEdit />
          </div>
        )}
      </div>
      {alert.type === 'Notification'
        ? alert.alert_messages.map((message) => (
          <Message
            message={message}
            receiverText={receiverText}
            senderText={senderText}
          />
        ))
        : null}
    </AlertWrapper>
  )
}

export default Alert
