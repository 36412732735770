import { useState } from 'react'
import { history } from '../../_helpers'

import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useLocationActions } from '../../_actions'

import { useRecoilState } from 'recoil'
import { userAtom } from '../../_state'

import AdminWrapper from '../shared/AdminWrapper'
import states from '../../_helpers/states'
import { BarLoader } from 'react-spinners'

import { FormContentWrapper } from '../shared/AdminStyledComponets'

const LocationsNew = () => {
  const [user] = useRecoilState(userAtom)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const [state, setState] = useState(states[0])
  const [isLoading, setIsLoading] = useState(false)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    address: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    city: Yup.string().required('City is required'),
    zipcode: Yup.string().length(5).matches(/^[0-9]{5}/).required('Zip Code is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const saveLocation = async () => {
      setIsLoading(true)
      await locationActions.createLocation({
        ...data,
        state: state.value,
        company_id: user?.company?.id
      })
      setIsLoading(false)
      history.push('/admin/company/dashboard')
    }
    saveLocation()
  }

  return (
    <AdminWrapper>
      <h1>New Location</h1>
      <FormContentWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-element'>
            <label>Title</label>
            <input
              name='title'
              type='text'
              {...register('title')}
              className={`form-control ${errors.title ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.title?.message}</div>
          </div>
          <div className='form-element'>
            <label>Location Address</label>
            <input
              name='address'
              type='text'
              {...register('address')}
              className={`form-control ${errors.address ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.address?.message}</div>
          </div>
          <div className='form-element'>
            <label>Location Address 2 (Suite No.)</label>
            <input
              name='address_2'
              type='text'
              {...register('address_2')}
              className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.address_2?.message}</div>
          </div>
          <div className='form-element'>
            <label>City</label>
            <input
              name='city'
              type='text'
              {...register('city')}
              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.city?.message}</div>
          </div>
          <div className='form-element'>
            <label>State</label>
            <Select
              name='state'
              required
              value={state}
              options={states}
              onChange={(newState) => setState(newState)}
            />
            <div className='invalid-feedback'>
              {state === '' || state === undefined || state === null
                ? 'State is required'
                : ''}
            </div>
          </div>
          <div className='form-element'>
            <label>Zip Code</label>
            <input
              name='zipcode'
              type='text'
              {...register('zipcode')}
              className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{errors.zipcode?.message}</div>
          </div>
          {isLoading
            ? (
              <div style={{ paddingLeft: 20 }}>
                <BarLoader color='#81BCBF' />
              </div>
              )
            : (
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-primary'
              >
                {isSubmitting && (
                  <span className='spinner-border spinner-border-sm mr-1' />
                )}
                Save
              </button>
              )}
        </form>
      </FormContentWrapper>
    </AdminWrapper>
  )
}

export default LocationsNew
