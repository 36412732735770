import React from 'react'

const stateContext = React.createContext(null)

const useStateContext = () => React.useContext(stateContext)

const StateProvider = (props) => {
  const { children, ...data } = props
  return (
    <>
      <stateContext.Provider value={data}>{children}</stateContext.Provider>
    </>
  )
}

export { useStateContext, StateProvider }
