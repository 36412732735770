import React, { useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { SelectFlags } from './SelectFlags'
import { Assess } from './Assess'
import { ApplicantNotice } from './ApplicantNotice'
import { Notes } from './Notes'
import { Review } from './Review'

import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

const PreAdverseActionAssessmentWrapper = styled.div``

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

// TODO: componentize this kind of button
const BeginButton = styled.button`
  border: 0px solid transparent;
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #81bcbf;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 20px 50px #0000004d;

  &:hover {
    background: #6a9c9f;
    cursor: pointer;
  }
`

const ModalContent = styled.div`
  padding: 15px;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`

const ModalButtons = styled.div`
  margin-top: auto;
  display: flex;

  button {
    border-radius: 40px;
    padding: 15px 50px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    font-family: 'Montserrat';
  }
`

const BackButton = styled.button`
  color: #707070;
  border: 1px solid #c8d1d3;
  background: none;

  &:hover {
    border: 1px solid #707070;
    cursor: pointer;
  }
`

const NextButton = styled.button`
  background-color: #81bcbf;
  border: 1px solid #81bcbf;
  box-shadow: 0px 20px 50px #0000004d;
  color: #fff;
  margin-left: auto;

  &:hover {
    background-color: #6ea2a5;
    border: 1px solid #6ea2a5;
    cursor: pointer;
  }
`

export const PreAdverseActionAssessment = ({ details, assess, flags, selectedFlags, selectedAssess, timeSinceOffense, businessImplications, setTimeSinceOffense, setBusinessImplications, confirmedEmailText, setConfirmedEmailText, applicantNoticeText, setApplicantNoticeText, notes, setNotes, submitData }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function afterOpenModal () {
    // things to do after modal opening
  }

  function closeModal () {
    setIsOpen(false)
  }

  const timeSinceOffenseOptions = [
    { label: 'Please Select', value: '' },
    { value: '< 1 Year', label: '< 1 Year' },
    { value: '2-5 Years', label: '2-5 Years' },
    { value: '6-9 Years', label: '6-9 Years' },
    { value: '10+ Years', label: '10+ Years' }
  ]

  const [tabIndex, setTabIndex] = useState(0)
  const tabCount = 5

  const nextTab = async () => {
    if ((tabIndex + 1) === tabCount) {
      await submitData()
      closeModal()
    } else {
      setTabIndex((tabIndex + 1) % tabCount)
    }
  }

  return (
    <PreAdverseActionAssessmentWrapper>
      <BeginButton onClick={openModal}>Begin Assessment</BeginButton>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Pre-Adverse Action Assessment Modal'
      >
        <ModalContent>
          <h2 ref={(modalTitle) => modalTitle}>
            Pre-Adverse Action Assessment
          </h2>
          <IconClose onClick={closeModal} />
          <div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab key='tab_1'>Select Flags</Tab>
                <Tab key='tab_2'>Assess</Tab>
                <Tab key='tab_3'>Applicant Notice</Tab>
                <Tab key='tab_4'>Notes</Tab>
                <Tab key='tab_5'>Review</Tab>
              </TabList>

              <TabPanel key='tabPanel_1'>
                <SelectFlags data={flags} selectedFlags={selectedFlags} />
              </TabPanel>
              <TabPanel key='tabPanel_2'>
                <Assess
                  data={{
                    assess,
                    selectedAssess,
                    timeSinceOffenseOptions,
                    timeSinceOffense,
                    businessImplications,
                    setTimeSinceOffense,
                    setBusinessImplications
                  }}
                />
              </TabPanel>
              <TabPanel key='tabPanel_3'>
                <ApplicantNotice text={applicantNoticeText} setText={setApplicantNoticeText} confirmedEmailText={confirmedEmailText} setConfirmedEmailText={setConfirmedEmailText} />
              </TabPanel>
              <TabPanel key='tabPanel_4'>
                <Notes text={notes} setText={setNotes} />
              </TabPanel>
              <TabPanel key='tabPanel_5'>
                <Review data={details} />
              </TabPanel>
            </Tabs>
          </div>
          <ModalButtons>
            {tabIndex !== 0 && (
              <BackButton
                onClick={() =>
                  setTabIndex((tabIndex + tabCount - 1) % tabCount)}
              >
                Go Back
              </BackButton>
            )}
            <NextButton onClick={nextTab} disabled={(tabIndex + 1 === tabCount) && !confirmedEmailText}>
              {tabIndex + 1 === tabCount ? 'Submit' : 'Next Step'}
            </NextButton>
          </ModalButtons>
        </ModalContent>
      </Modal>
    </PreAdverseActionAssessmentWrapper>
  )
}
