import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { userAtom } from '../_state'
import styled from 'styled-components'

import { AdminApplicantsSearch } from '../components/Admin/AdminApplicantsSearch'
import AdminWrapper from '../admin/shared/AdminWrapper'
import { useLocationActions, useCompanyActions } from '../_actions'

const AdminTitle = styled.span`
  margin: 35px 0;
  font-size: 28px;
  font-weight: bold;
  color: #707070;
`

export { Applicants }

function Applicants () {
  const [user] = useRecoilState(userAtom)
  const companyActions = useCompanyActions(
    localStorage.getItem('access_token')
  )
  const [initialApplicants, setInitialApplicants] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    companyActions.applicants(user?.company?.id).then((applicants) => {
      setInitialApplicants(applicants)
      setIsLoading(false)
    })
  }, [])
  return (
    <AdminWrapper>
      <AdminTitle>Find an Applicant</AdminTitle>
      <AdminApplicantsSearch initialApplicants={initialApplicants} companyId={user?.company?.id} />
    </AdminWrapper>
  )
}
