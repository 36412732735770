import styled from 'styled-components'
import iconEyeOff from '../../resources/images/icons/eye_off.png'

const FormContentWrapper = styled.div`
  .form-timeline-wrapper {
    display: flex;
    width: 100%;

    .step-number {
      flex: 1;
      position: relative;

      &:first-of-type {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: -50%;
        top: 12px;
        right: 50%;
        height: 10px;
        background-color: #ccc;
        z-index: 1;
      }
    }

    .step-number span {
      box-sizing: border-box;
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #ccc;
      margin: auto;
      padding-top: 8px;
      color: #fff;
      font-weight: bold;
      z-index: 2;
      position: relative;
    }

    .step-number.active span {
      background: #81bcbf;
    }

    .step-number.active {
      &:after {
        background-color: #81bcbf;
      }
    }
  }

  flex: 1;
  padding: 40px;
  text-align: center;

  .form-content {
    max-width: 450px;
    margin: auto;
    overflow-x: hidden;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-bottom: 50px;
    max-width: 480px;
    overflow: auto;
    box-sizing: border-box;
    overflow-x: hidden;

  
    input:read-only {
     background-color: dimgrey;
     color: linen;
     opacity: 1;
    }
    
    h1 {
      font-size: 30px;
      text-transform: uppercase;
      color: #707070;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 20px;
      text-transform: uppercase;
      color: #707070;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 15px;
      text-transform: uppercase;
      color: #707070;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .left-align {
      text-align: left;
      color: #707070;
      font-size: 15px;
    }

    .compliance{
      text-align: left;
      color: #707070;
      font-size: 14px;
      max-width: 480px;
      margin-bottom: 10px;
    }

    .bold{
      font-weight: bold;
    }

    .compliance-sub{
      text-align: left;
      color: #707070;
      font-size: 14px;
      max-width: 480px;
      padding-left: 10px;
    }

    .table {
      display: table;
      width: 100%;
    }

    .table-row {
      display: table-row;
    }

    .table-cell {
      display: table-cell;
    }

    .input-group-container {
      display: flex;
      justify-content: start;
      box-sizing: border-box;
    }

    .input-group {
      border-top: 1px solid #ececec;
      padding-top: 10px;
      box-sizing: border-box;
      display: flex;
      flex: 1;

      .form-element {
        display: flex;
        justify-content: flex-start;

        input {
          margin-right: 10px;
        }

        label {
          padding-top: 13px;
        }

        input,
        label {
          flex: 0;
        }
      }
    }

    button.add-remove-button {
      border-radius: 5px;
      padding: 10px;
    }

    .prev-next-wrapper {
      display: flex;
      padding-top: 10px;
      border-top: 1px solid #ececec;
    }

    .employment-entry,
    .entry-wrapper {
      border: 1px solid #ececec;
      border-radius: 5px;
      padding: 10px;
      box-sizing: border-box;
    }

    input[type='date'] {
      width: 100%;
      padding: 10px;
      border-radius: 25px;
      border: 1px solid #b9afa4;
      box-sizing: border-box;
    }

    button {
      border: none;
      font-family: 'Montserrat';
      font-size: 12px;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 40px;

      &:hover {
        cursor: pointer;
        background-color: #73a9ac;
      }

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }

    .little-button {
      border: none;
      font-family: 'Montserrat';
      font-size: 12px;
      background-color: #81bcbf;
      color: #fff;
      font-weight: bold;
      padding: 15px 10px;
      box-sizing: border-box;
      width: 25px;
      box-shadow: 0px 20px 50px #0000004d;
      border-radius: 40px;
      margin: auto;
      display: inline;
      text-align: center;

      &:hover {
        cursor: pointer;
        background-color: #73a9ac;
      }
    }
  }

  .form-element {
    width: 100%;
    margin-bottom: 30px;
    position: relative;

    label,
    input,
    select {
      display: block;
    }

    .input-inline {
      display: inline;
      box-sizing: border-box;
    }

    input[type='radio'] + label {
      float: left;
      text-align: center;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-left: 0px;
    }

    .toggle-password {
      display: block;
      position: absolute;
      top: 31px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-image: url(${iconEyeOff});
      background-size: contain;
      background-repeat: no-repeat;
    }

    label {
      font-size: 10px;
      text-transform: uppercase;
      color: #707070;
      text-align: left;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .invalid-feedback {
      font-size: 10px;
      text-transform: uppercase;
      color: #770000;
      text-align: left;
      font-weight: bold;
      margin-top: 10px;
    }

    select {
      border-radius: 20px;
      border: 1px solid #b9afa4;
      border-radius: 100px 100px 100px 100px;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
      background-image: linear-gradient(
          45deg,
          transparent 50%,
          rgb(0, 85, 155) 50%
        ),
        linear-gradient(135deg, rgb(0, 85, 155) 50%, transparent 50%),
        radial-gradient(#fff 70%, transparent 72%);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
      background-repeat: no-repeat;

      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    input[type='text'],
    input[type='password'] {
      border-radius: 20px;
      border: 1px solid #b9afa4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
    }

    input[type='radio'] {
      border-radius: 20px;
      border: 1px solid #b9afa4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
      float: left;
      margin-right: 20px;
      text-align: left;
    }

    textarea {
      border-radius: 5px;
      border: 1px solid #b9afa4;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      box-sizing: border-box;
      float: left;
      margin-right: 20px;
      display: inline;
    }

    .forget-password {
      display: block;
      text-align: right;
      text-decoration: none;
      color: #de7e53;
      font-size: 14px;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #81bcbf;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #81bcbf;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
`
export { FormContentWrapper }
