import { useState, useEffect } from 'react'
import { history } from '../../_helpers'
import { useParams } from 'react-router-dom'

import AdminWrapper from '../shared/AdminWrapper'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDBAlertsActions } from '../../_actions'
import { BarLoader } from 'react-spinners'

import { FormContentWrapper } from '../shared/AdminStyledComponets'

const AlertsEdit = () => {
  const { locationId, userId, jobId, alertId } = useParams()

  const alertsActions = useDBAlertsActions(jobId)
  const [alert, setAlert] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Amount is required'),
    details: Yup.string().required('Amount is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await alertsActions.update(alertId, {
        ...data,
        job_id: alert.job_id,
        sender_id: alert.sender_id,
        recipient_id: alert.recipient_id,
        type: alert.type
      })
      setIsLoading(false)
      history.push(
        `/admin/locations/${locationId}/users/${userId}/jobs/${jobId}?tabId=2`
      )
    }
    save()
  }

  useEffect(() => {
    alertsActions.get(alertId).then((apiData) => {
      setAlert(apiData.alert)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminWrapper>
      <h1>Edit Notification</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <FormContentWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-element'>
                <label>Title</label>
                <input
                  defaultValue={alert?.title}
                  name='title'
                  type='text'
                  {...register('title')}
                  className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.title?.message}</div>
              </div>
              <div className='form-element'>
                <label>Details</label>
                <input
                  defaultValue={alert?.details}
                  name='details'
                  type='text'
                  {...register('details')}
                  className={`form-control ${errors.details ? 'is-invalid' : ''
                    }`}
                />
                <div className='invalid-feedback'>
                  {errors.details?.message}
                </div>
              </div>
              {isLoading
                ? (
                  <div style={{ paddingLeft: 20 }}>
                    <BarLoader color='#81BCBF' />
                  </div>
                  )
                : (
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='btn btn-primary'
                  >
                    {isSubmitting && (
                      <span className='spinner-border spinner-border-sm mr-1' />
                    )}
                    Save
                  </button>
                  )}
            </form>
          </FormContentWrapper>
          )}
    </AdminWrapper>
  )
}

export default AlertsEdit
