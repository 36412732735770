import { history } from '../../_helpers'
import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'

const ApplicantTableFull = ({ loading, data, title, titleColor }) => {
  const applicantSearchColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Action Date', dataIndex: 'action_date', key: 'action_date' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Job/Position', dataIndex: 'job_position', key: 'job_position' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render (val, row) {
        return (
          <div>
            <span className={val === 'Available' ? 'available' : ''}>
              {val}
            </span>
            <button
              onClick={() => {
                val === 'Available'
                  ? history.push(
                    `/admin/locations/${row.job_location_id}/users/${row.id}/jobs/new`
                  )
                  : history.push(
                      `/admin/locations/${row.job_location_id}/users/${row.id}/jobs/${row.job_id}`
                  )
              }}
              className={val === 'Available' ? 'available' : ''}
            >
              {val === 'Available' ? 'Manage' : 'View'}
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <ApplicantGenericTable
      tableTitle={title}
      tableTitleColor={titleColor}
      loading={loading}
      columns={applicantSearchColumns}
      data={data}
      rowKey='id'
    />
  )
}

export default ApplicantTableFull
