import { useSetRecoilState } from 'recoil'
import { useFetchWrapper } from '../_helpers'
import { dbAlertsAtom } from '../_state'

export { useDBAlertsActions }

function useDBAlertsActions (jobId) {
  const token = localStorage.getItem('access_token')
  const baseUrl = `${process.env.REACT_APP_API_URL}/jobs/${jobId}/alerts`
  const fetchWrapper = useFetchWrapper(token)
  const setDBAlertsAtom = useSetRecoilState(dbAlertsAtom)

  return {
    getAll,
    add,
    get,
    update
  }

  function getAll () {
    const alerts = fetchWrapper
      .get(baseUrl)
    alerts.then((dbAlerts) => setDBAlertsAtom(dbAlerts))
    return alerts
  }

  function add (alert) {
    return fetchWrapper.post(baseUrl, { alert })
  }

  function get (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function update (id, alert) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { alert })
  }
}
