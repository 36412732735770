import styled from 'styled-components'

const ApplicantNoticeWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  color: #707070;

  input {
    margin-right: 10px;
  }
`

const NoticeText = styled.textarea`
  height: 330px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #707070;
  overflow: auto;
  font-size: 15px;
  padding: 15px;
  margin-bottom: 20px;
  line-height: 30px;
  font-family: 'Montserrat';
`

export const ApplicantNotice = ({ text, setText, confirmedEmailText, setConfirmedEmailText }) => {
  const handleChange = (event) => {
    setConfirmedEmailText(event.target.checked)
  }

  return (
    <ApplicantNoticeWrapper>
      <NoticeText value={text} onChange={(e) => { setText(e.target.value) }} />
      <>
        <input
          type='checkbox'
          defaultChecked={confirmedEmailText}
          ref={(confirmInput) => confirmInput}
          onChange={handleChange}
        />
        <span>
          I confirm that the above email is accurate and all identified flags
          have been selected and included in the notice.
        </span>
      </>
    </ApplicantNoticeWrapper>
  )
}
