import { useState, useEffect } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'
import AdminWrapper from '../../../shared/AdminWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDepartmentActions, useLocationActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'
import { FormContentWrapper } from '../../../shared/AdminStyledComponets'

const DepartmentEdit = () => {
  const { departmentId, companyId } = useParams()
  const departmentActions = useDepartmentActions(
    localStorage.getItem('access_token')
  )
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )

  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])
  const [department, setDepartment] = useState({ department: {} })

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Amount is required'),
    lookup: Yup.string().required('Amount is required'),
    location_id: Yup.string().required('Amount is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await departmentActions.update(departmentId, data)
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  useEffect(() => {
    departmentActions.get(departmentId).then((departmentApiData) => {
      setDepartment(departmentApiData)
      locationActions.getLocationList(companyId).then((apiData) => {
        setLocations(apiData)
        setIsLoading(false)
      })
    })
  }, [])

  return (
    <AdminWrapper>
      <h1>Edit Department</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <FormContentWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-element'>
                <label>Title</label>
                <input
                  defaultValue={department.department.title}
                  name='title'
                  type='text'
                  {...register('title')}
                  className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{errors.title?.message}</div>
              </div>
              <div className='form-element'>
                <label>Lookup</label>
                <input
                  defaultValue={department.department.lookup}
                  name='lookup'
                  type='text'
                  {...register('lookup')}
                  className={`form-control ${errors.lookup ? 'is-invalid' : ''
                    }`}
                />
                <div className='invalid-feedback'>{errors.lookup?.message}</div>
              </div>
              <div className='form-element'>
                <label>Location</label>
                <select
                  defaultValue={
                    department.department?.location?.id
                      ? String(department.department?.location?.id)
                      : ''
                  }
                  {...register('location_id')}
                  className={`form-control ${errors.location_id ? 'is-invalid' : ''
                    }`}
                  name='location_id'
                >
                  <option value='' disabled>
                    Please select
                  </option>
                  {locations.map((d) => {
                    return <option value={`${d.id}`}>{d.title}</option>
                  })}
                </select>
                <div className='invalid-feedback'>
                  {errors.location_id?.message}
                </div>
              </div>
              {isLoading
                ? (
                  <div style={{ paddingLeft: 20 }}>
                    <BarLoader color='#81BCBF' />
                  </div>
                  )
                : (
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='btn btn-primary'
                  >
                    {isSubmitting && (
                      <span className='spinner-border spinner-border-sm mr-1' />
                    )}
                    Save
                  </button>
                  )}
            </form>
          </FormContentWrapper>
          )}
    </AdminWrapper>
  )
}

export default DepartmentEdit
