import { useFetchWrapper } from '../_helpers'

export { useActionActions }

function useActionActions (auth = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/actions`
  const fetchWrapper = useFetchWrapper(auth)

  return {
    execute,
    sendConditionalOffer
  }

  function execute (id, type) {
    return fetchWrapper.put(`${baseUrl}/execute`, { id, type })
  }

  function sendConditionalOffer (job_id) {
    return fetchWrapper.post(`${baseUrl}/send_conditional_offer`, { job_id })
  }
}
