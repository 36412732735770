import { useState, useEffect, useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'

import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

import { useLocationActions, useUserActions } from '../../_actions'

import AdminWrapper from '../shared/AdminWrapper'

import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'

import {
  AdminApplicantsSearchWrapper,
  SearchWrapper
} from '../../admin/shared/AdminStyledComponets'

const AddLocationUsers = () => {
  const [assignedUsers, setAssignedUsers] = useState([])
  const [searchedUsers, setSearchedUsers] = useState([])
  const [searchUserText, setSearchUserText] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [searchUserIsLoading, setSearchUserIsLoading] = useState(false)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const userActions = useUserActions(localStorage.getItem('access_token'))

  const { locationId } = useParams()

  const [apiLocation, setApiLocation] = useState({})

  useEffect(() => {
    locationActions.getLocation(locationId).then((apiData) => {
      setApiLocation(apiData.location)
      locationActions.getUsers(locationId).then((users) => {
        setAssignedUsers(users.users)
        setIsLoading(false)
      })
    })
  }, [])

  const SEARCH_COLUMNS = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'Id'
    },
    {
      dataIndex: 'first_name',
      key: 'first_name',
      title: 'First Name'
    },
    {
      dataIndex: 'last_name',
      key: 'last_name',
      title: 'Last Name'
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email'
    },
    {
      dataIndex: 'phone',
      key: 'phone',
      title: 'Phone'
    },
    {
      title: 'Roles',
      dataIndex: 'row',
      key: 'row',
      className: 'status-column',
      render (_, row) {
        return (
          <span>
            {row.roles.map((role) => {
              return <span>{role.description} | </span>
            })}
          </span>
        )
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'status-column',
      render (_, row) {
        return (
          <div>
            <span>
              <a
                href={`/admin/locations/${row.id}/users#add`}
                onClick={(e) => {
                  e.preventDefault()
                  const addUser = async () => {
                    setIsLoading(true)
                    setSearchUserIsLoading(true)
                    await locationActions.addUser(locationId, row.id, 'hr')
                    const users = await locationActions.getUsers(locationId)
                    setAssignedUsers(users.users)
                    setIsLoading(false)
                    setSearchUserIsLoading(false)
                  }
                  addUser()
                }}
              >
                Add Recruiter
              </a>
            </span>{' '}
            |{' '}
            <span>
              <a
                href={`/admin/locations/${row.id}/users#add`}
                onClick={(e) => {
                  e.preventDefault()
                  const addUser = async () => {
                    setIsLoading(true)
                    setSearchUserIsLoading(true)
                    await locationActions.addUser(
                      locationId,
                      row.id,
                      'applicant'
                    )
                    const users = await locationActions.getUsers(locationId)
                    setAssignedUsers(users.users)
                    setIsLoading(false)
                    setSearchUserIsLoading(false)
                  }
                  addUser()
                }}
              >
                Add Applicant
              </a>
            </span>
          </div>
        )
      }
    }
  ]

  const searchColumns = useMemo(() => SEARCH_COLUMNS, [])

  const ASSIGNED_COLUMNS = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'Id'
    },
    {
      dataIndex: 'first_name',
      key: 'first_name',
      title: 'First Name'
    },
    {
      dataIndex: 'last_name',
      key: 'last_name',
      title: 'Last Name'
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email'
    },
    {
      dataIndex: 'phone',
      key: 'phone',
      title: 'Phone'
    },
    {
      title: 'Roles',
      dataIndex: 'row',
      key: 'row',
      className: 'status-column',
      render (_, row) {
        return (
          <span>
            {row.roles.map((role) => {
              return <span>{role.description} | </span>
            })}
          </span>
        )
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'status-column',
      render (_, row) {
        return (
          <span>
            <a
              href={`/admin/locations/${row.id}/users#remove`}
              onClick={(e) => {
                e.preventDefault()
                const removeUser = async () => {
                  setIsLoading(true)
                  setSearchUserIsLoading(true)
                  await locationActions.removeUser(locationId, row.id)
                  const users = await locationActions.getUsers(locationId)
                  setAssignedUsers(users.users)
                  setIsLoading(false)
                  setSearchUserIsLoading(false)
                }
                removeUser()
              }}
            >
              Remove
            </a>
          </span>
        )
      }
    }
  ]

  const assignedColumns = useMemo(() => ASSIGNED_COLUMNS, [])

  const searchUsers = useCallback(
    debounce((val) => {
      setSearchUserIsLoading(true)
      userActions.searchUsersByName(val).then((searchApiUsers) => {
        setSearchedUsers(searchApiUsers.users)
        setSearchUserIsLoading(false)
      })
    })
  )

  const handleChange = (e) => {
    setSearchUserText(e.target.value)
    if (e.target.value.length > 2) {
      searchUsers(e.target.value)
    }
  }

  return (
    <AdminWrapper>
      <div>
        <h1>
          Location: {apiLocation?.title ? `to ${apiLocation?.title}` : ''}
        </h1>
      </div>
      <AdminApplicantsSearchWrapper>
        <div className='search-row'>
          <SearchWrapper>
            <span className='search-label'>
              Search for an existing user by Name or Email
            </span>
            {searchUserText === ''
              ? (
                <IconSearch />
                )
              : (
                <IconClose
                  onClick={() => {
                    setSearchUserText('')
                  }}
                />
                )}
            <input
              htmlFor='search'
              type='text'
              name='applicantSearch'
              value={searchUserText}
              placeholder='Search'
              onChange={handleChange}
            />
          </SearchWrapper>
        </div>
        <ApplicantGenericTable
          loading={searchUserIsLoading}
          tableTitle='Searched Users'
          tableTitleColor='#E28A57'
          data={searchedUsers.filter(
            (au) => !assignedUsers.map((u) => u.id).includes(au.id)
          )}
          columns={searchColumns}
          rowKey='id'
        />
      </AdminApplicantsSearchWrapper>

      <ApplicantGenericTable
        loading={isLoading}
        tableTitle='Location Users'
        tableTitleColor='#E28A57'
        data={assignedUsers}
        columns={assignedColumns}
        rowKey='id'
      />
    </AdminWrapper>
  )
}

export default AddLocationUsers
