import { atom } from 'recoil'

const documentsAtom = atom({
  key: 'Documents',
  default: []
})

const documentAtom = atom({
  key: 'Document',
  default: undefined
})

export { documentsAtom, documentAtom }
