import { useState } from 'react'
import { FormContentWrapper } from './AdminStyledComponets'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'
import Modal from 'react-modal'
import styled from 'styled-components'

const ModalContent = styled.div`
  padding: 15px;
  min-height: 200px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

const DeleteConfirmation = ({ resourceName, confirmationText, deleteAction, closeModalCallback }) => {
  const [confirmationTextUser, setConfirmationTextUser] = useState('')
  function closeModal () {
    setConfirmationTextUser('')
    closeModalCallback()
  }

  return (
    <>
      <Modal
        isOpen
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <ModalContent>
          <h2 ref={(modalTitle) => modalTitle}>
            Confirm Deletion of {resourceName}
          </h2>
          <IconClose onClick={closeModal} />
          <FormContentWrapper>
            <form>
              <div className='form-element'>
                <label>Confirm deletion by typing: <b className='deletion'>{confirmationText}</b></label>
                <input
                  name='title'
                  type='text'
                  value={confirmationTextUser}
                  onChange={(e) => setConfirmationTextUser(e.target.value)}
                />
              </div>
              <button
                type='submit'
                disabled={confirmationTextUser !== confirmationText}
                className='btn btn-primary'
                onClick={async () => {
                  if (confirmationTextUser === confirmationText) {
                    await deleteAction()
                    closeModal()
                  }
                }}
              >
                Delete
              </button>
            </form>
          </FormContentWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteConfirmation
