import React from 'react'
import { Router } from 'react-router-dom'
import { history } from '../../_helpers'
import styled from 'styled-components'

import { SidebarNav } from '../../components/SidebarNav'
import { DrawerNav } from '../../components/DrawerNav'

import { sidebarNavButtons } from '../../_helpers/sidebarNavButtons'

const Wrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 90px;

  h2 {
    margin-top: 50px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
`

const Content = styled.div`
  padding: 40px;
  width: 100%;
`

export { Settings }

function Settings () {
  return (
    <Wrapper>
      <Router history={history}>
        <SidebarNav buttons={sidebarNavButtons} />
      </Router>
      <ContentWrapper>
        <DrawerNav title='Jobs' />
        <Content>
          <h1>Settings</h1>
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}
