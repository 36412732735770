import { StateProvider } from '../../../contexts'
import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { job as jobAtom } from '../../../_state'
import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useTasksActions } from '../../../_actions'
import { useReloadJob } from '../../../_helpers'
import { ApplySignature, HookFormToggleSwitch } from '../../shared/HookFormComponents'

const SocialSecurityVerification = () => {
  const { id } = useParams()
  const job = useRecoilValue(jobAtom)
  const { backToJob } = useReloadJob()
  const [state, setState] = useState({})
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  // form validation rules
  const validationSchema = Yup.object().shape({
    ssn: Yup.string()
      .matches('^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$', {
        message: 'SSN must be in the format ###-##-####'
      })
      .required('SSN is required'),
    confirmSsn: Yup.string()
      .oneOf([Yup.ref('ssn'), null], 'SSN fields must match')
      .required('Confirm SSN is required')
  })

  useEffect(() => {
    const taskId = job.tasks.find(item => item.title === 'Personal Information').id
    console.log(taskId)
    taskActions.get(taskId)
      .then((ret) => {
        if (ret.data !== null) {
          setState(ret.data)
        }
      })
  }, [])

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: state,
    values: state
  }

  const methods = useForm(formOptions)

  const onSubmit = (_data) => {
    const submission = {
      data: _data
    }
    taskActions.update(id, submission)
      .then((av) => {
        backToJob(job.id)
      })
  }

  return (
    <>
      <ApplicantWrapper>
        <FormContentWrapper>
          <StateProvider state={state} setState={setState}>
            <FormProvider {...methods}>
              <form
                className='form-wrapper'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h1>Social Security Verification</h1>
                <h3>Authorization for the Social Security Administration to Disclose Your Social Security Number Verification</h3>
                <ApplySignature>
                  <div style={{ paddingBottom: 20 }}>
                    I authorize the Social Security Administration (SSA) to verify and disclose to <em>{job.company.display_name}</em>&nbsp;
                    through <em>EMPLOYEE RELATIONS, INC.</em> for the purpose of seeking employment with <em>{job.company.display_name}</em>&nbsp;
                    whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA records.
                    My consent is for a one-time validation within the next 30 days.
                  </div>
                  <div className='form-element'>
                    <label>SSN</label>
                    <input
                      type='text'
                      name='ssn'
                      readOnly
                      {...methods.register('ssn')}
                      className={`form-control ${
                  methods.formState.errors.ssn ? 'is-invalid' : ''
                }`}
                    />
                    <div className='invalid-feedback'>
                      {methods.formState.errors.ssn?.message}
                    </div>
                  </div>
                  <div className='form-element'>
                    <label>Confirm SSN</label>
                    <input
                      type='text'
                      name='confirmSsn'
                      readOnly
                      {...methods.register('confirmSsn')}
                      className={`form-control ${
                  methods.formState.errors.confirmSsn ? 'is-invalid' : ''
                }`}
                    />
                    <div className='invalid-feedback'>
                      {methods.formState.errors.confirmSsn?.message}
                    </div>
                  </div>
                </ApplySignature>
                <HookFormToggleSwitch state={state} setState={setState} name='final' label='I am finished and ready to submit' />
                <div className='form-element'>
                  <button
                    type='button'
                    onClick={() => {
                      backToJob(job.id)
                    }}
                  >
                    Go Back
                  </button>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </form>
            </FormProvider>
          </StateProvider>
        </FormContentWrapper>
      </ApplicantWrapper>
    </>
  )
}

export default SocialSecurityVerification
