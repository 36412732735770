import styled from 'styled-components'
import ModalWrapper from '../../admin/shared/ModalWrapper'
import { useState } from 'react'
import { ConditionalOfferLetter } from '../../admin/documents/ConditionalOfferLetter'
import { GenericDocumentView } from '../../admin/documents/GenericDocumentView'
import { ReactComponent as IconDot } from '../../email/greendot.svg'

const DocumentLibraryWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  margin-top: 20px;
  flex: 1;
`

const DocumentLibraryHeader = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 26px;
    color: #272d3b;
    margin: 0;
    padding: 0;
    padding: 20px 30px;
  }

  span {
    display: block;
    padding: 0 30px 10px 30px;
    font-weight: bold;
    border-bottom: 1px solid #f0eeec;
  }
`

const DocumentLibraryList = styled.div`
  > div {
    font-size: 14px;
    padding: 20px 30px;
    border-bottom: 1px solid #f0eeec;
    display: flex;
    align-items: center;

    .buttons {
      margin-left: auto;
      display: flex;
    }
  }
`

const ButtonWrapper = styled.a`
  border-radius: 25px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: bold;
  width: 135px;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Montserrat';
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
  margin-left: 10px;
  border: 1px solid
    ${(props) => (props.buttonEnabled ? '#81BCBF' : '#505C624D')};
  opacity: ${(props) => (props.buttonEnabled ? '1' : '0.5')};
  color: ${(props) => (props.buttonEnabled ? '#81BCBF' : '#505C624D')};

  &:hover {
    cursor: ${(props) => (props.buttonEnabled ? 'pointer' : 'not-allowed')};
    background-color: ${(props) =>
    props.buttonEnabled ? '#81BCBF' : 'transparent'};
    color: ${(props) => (props.buttonEnabled ? '#fff' : '#505C624D')};
  }
`

export const ApplicantDetailDocumentLibrary = ({
  job,
  documents,
  admin
}) => {
  const [documentModalOpen, setDocumentModalOpen] = useState(false)
  const [documentModalData, setDocumentModalData] = useState({})
  const statusOnly = [
    'Personal Information',
    'Application Verification',
    'Summary of Rights Under the FCRA (English Version)',
    'Summary of Rights Under the FCRA (Spanish Version)',
    'California Consumer Privacy Act (CCPA)',
    'California Summary of Rights',
    'Applicant Authorization',
    'Drug Testing'
  ]
  return (
    <DocumentLibraryWrapper>
      <DocumentLibraryHeader>
        <h3>Reports</h3>
        <span>Document</span>
      </DocumentLibraryHeader>
      <DocumentLibraryList>
        {documents.map((doc, index) => (
          <div key={index}>
            {doc.title}
            {doc.title === 'Conditional Offer Letter' && <>
              {doc.status === 'completed' && <span style={{ paddingLeft: 10 }}><IconDot /></span>}
              <div style={{ paddingLeft: 10 }}>{doc.status === 'completed' ? '(Signed)' : '(Not Signed)'}</div>
            </>}
            <div className='buttons'>
              {doc.title !== 'Conditional Offer Letter' && <>
                {doc.status === 'completed' && <span style={{ paddingLeft: 10 }}><IconDot /></span>}
                {doc.status !== 'completed' && <div style={{ paddingLeft: 10 }}>{
                  doc?.status?.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key, p1) {
                    return key.toUpperCase()
                  }
                  )
                  }</div>}
              </>}
              {!statusOnly.includes(doc.title) && <>
                <ButtonWrapper
                  href={doc.link || '#'}
                  target='_blank'
                  buttonEnabled={doc.enabled && doc.link}
                  onClick={(e) => {
                    e.preventDefault()
                    if (admin || doc.title === 'Conditional Offer Letter') {
                      setDocumentModalData(doc)
                      setDocumentModalOpen(true)
                    } else {
                      window.location = doc.link
                    }
                  }}
                >
                  View
                </ButtonWrapper>
                {!admin && <ButtonWrapper
                  href={doc.download || '#'}
                  target='_blank'
                  buttonEnabled={doc.enabled && doc.download}
                  onClick={() => {
                    setDocumentModalOpen(true)
                  }}
                           >
                  Download
                </ButtonWrapper>}
              </>}
            </div>
          </div>
        ))}
        <ModalWrapper
          modalOpen={documentModalOpen}
          closeModal={() => setDocumentModalOpen(false)}
          modalTitleText={documentModalData.title}
          modalContent={documentModalData.title === 'Conditional Offer Letter' ? <ConditionalOfferLetter job={job} document={documentModalData} /> : <GenericDocumentView doc={documentModalData} />}
          modalMinHeight={450}
        />
      </DocumentLibraryList>
    </DocumentLibraryWrapper>
  )
}
