import styled from 'styled-components'
import ToggleButton from 'react-toggle-button'

const ToggleSwitchWrapper = styled.div`
  color: #272d3b;
  display: flex;

  .toggle-label {
    color: #272d3b;
    display: inline-block;
    margin: 5px 0px 0px 20px;
  }
`

export const ToggleSwitch = ({
  value,
  activeText,
  inactiveText,
  onToggleFunction
}) => {
  const trackStyle = { height: '30px', width: '60px' }
  const thumbStyle = { height: '26px', width: '26px' }

  return (
    <ToggleSwitchWrapper>
      <ToggleButton
        value={value || false}
        onToggle={(value) => {
          onToggleFunction(value)
        }}
        trackStyle={trackStyle}
        thumbStyle={thumbStyle}
        thumbAnimateRange={[2, 28]}
        inactiveLabel=''
        activeLabel=''
        colors={{
          active: {
            base: 'rgb(134,187,190)',
            hover: 'rgb(134,187,190)'
          },
          inactive: {
            base: 'rgb(65,66,68)',
            hover: 'rgb(95,96,98)'
          }
        }}
      />
      <span className='toggle-label'>{value ? activeText : inactiveText}</span>
    </ToggleSwitchWrapper>
  )
}
