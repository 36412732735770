import styled from 'styled-components'
import { BasicEmail } from '../email/BasicEmail'
import { InviteEmail } from '../email/InviteEmail'
import { PreAANoticeEmail } from '../email/PreAANoticeEmail'
import { FinalAANoticeEmail } from '../email/FinalAANoticeEmail'
import { ConsolidatedReportEmail } from '../email/ConsolidatedReportEmail'
import { EducationEmploymentReportEmail } from '../email/EducationEmploymentReportEmail'
import { ReactComponent as IconShield } from '../resources/images/icons/shield-check-regular.svg'
import { ReactComponent as IconAlert } from '../resources/images/icons/triangle-exclamation-light.svg'
import { ReactComponent as IconCircle } from '../resources/images/icons/circle-regular.svg'

const PlaygroundWrapper = styled.div`

`

const SplitLine = styled.div`
  width: 100%;
  height: 10px;
  margin: 50px auto;
  background: red;
`

const consolidatedReportEmailData = {
  status: 'Complete Report',
  requestNo: 'XXXXXXXXXXXX',
  completedDatetime: 'January 25, 2023 11:52AM PDT',
  reportDetails: {
    req: 'CA-000000-00-0000',
    date: '06/20/2023',
    time: '11:52 AM PDT'
  },
  requestedBy: {
    name: 'Leslie Garias',
    company: 'Consolidate Analytics, Inc.',
    address1: '1 Mac Arthur, Suite 100',
    address2: 'Santa Ana, CA 92707',
    phone: '(714) 815-0884',
    email: 'lgarfias@ca-usa.com'
  },
  preparedBy: {
    company: 'Employifi, LLC',
    address1: '450 N Brand Blvd 600',
    address2: 'Glendale, CA 91203',
    phone: '(818) 593-5555',
    email: 'support@employifi.com'
  },
  notice: {
    text: 'This Pre-Employment Background Information Report does not guarantee the accuracy or truthfulness of the information as to the subject of the investigation, but only that it is accurately copied from public records, and information generated as a result of identity theft, including evidence of criminal activity, may be inaccurately associated with the applicant who is the subject of the report. The information herein is provided for the exclusive use of Employee Relations Client, Consolidated Analytics.'
  },
  applicantInformation: {
    name: 'Jennifer Rubensteinberg',
    address1: '1025 Grand Central Pkwy',
    address2: 'Apt #376',
    address3: 'Saginaw, TX 76131'
  },
  reportSummary: [
    {
      product: 'CBSV',
      status: 'Complete',
      alert: <IconShield />
    },
    {
      product: 'Natioonal Criminal and Government Sanctionsa',
      status: 'Complete',
      alert: <IconShield />
    },
    {
      product: 'Trace Reprt',
      status: 'Complete',
      alert: <IconShield />
    },
    {
      product: 'Criminal Search',
      status: 'Complete - Flagged',
      alert: <IconAlert />
    },
    {
      product: 'Education Verification',
      status: 'In Progress',
      alert: <IconCircle />
    },
    {
      product: 'Employment Verification',
      status: 'Complete',
      alert: <IconShield />
    },
    {
      product: 'Drug Testing - 9 Panel Urine (Marijuana not tested)',
      status: 'Complete',
      alert: <IconShield />
    }
  ]
}

const EmailPlayground = () => {
  return (
    <PlaygroundWrapper>
      <BasicEmail />
      <SplitLine />
      <InviteEmail />
      <SplitLine />
      <PreAANoticeEmail />
      <SplitLine />
      <FinalAANoticeEmail />
      <SplitLine />
      <ConsolidatedReportEmail emailData={consolidatedReportEmailData} />
      <SplitLine />
      <EducationEmploymentReportEmail emailData={{}} />
    </PlaygroundWrapper>
  )
}

document.addEventListener('DOMContentLoaded', () => {})

export { EmailPlayground }
