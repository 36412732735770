import { HookFormArraySelect } from './HookFormArraySelect'

const HookFormArrayDateRange = ({ label, defaultValues = [0, 0] }) => {
  const year = new Date().getFullYear()
  const years = Array.from(Array(60).keys()).map((v) => ({
    label: year - v,
    value: year - v
  }))
  years.unshift({ label: '', value: '' })

  return (
    <>
      <label>{label}</label>
      <HookFormArraySelect fieldName='from' label='From' options={years} defaultValueIndex={defaultValues[0]} />
      <HookFormArraySelect fieldName='to' label='To' options={years} defaultValueIndex={defaultValues[1]} />
    </>
  )
}

export { HookFormArrayDateRange }
