import styled from 'styled-components'
import Table from 'rc-table'
import Alert from '../shared/Communication/Alert'
import { useNotificationActions } from '../../_actions'

import { ReactComponent as IconAlert } from '../../resources/images/icons/alert.svg'
import { ReactComponent as IconComment } from '../../resources/images/icons/comment.svg'

// TODO break out this into a shared object that can be used in multiple components
const TableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;
    }

    .alert {
      color: #de7e53;
      font-weight: bold;

      svg {
        fill: #de7e53;
      }
    }

    svg {
      width: 25px;
      fill: #b9afa4;
    }

    .table-buttons {
      display: flex;
    }

    .flex-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    button {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      padding: 8px 25px;
      min-width: 120px;
      white-space: nowrap;
      border-radius: 20px;
      text-align: center;
      display: inline-block;
      margin-left: auto;
      font-family: 'Montserrat';
    }

    button.respond {
      border: 1px solid #c2b9ae;
      background-color: transparent;
      color: #c2b9ae;

      &:hover {
        background-color: #c2b9ae;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`

// TODO break out this into a shared object that can be used in multiple components
const TableHeader = styled.div`
  display: flex;
  padding: 20px;

  h3 {
    font-size: 26px;
    color: #272d3b;
    margin: 0;
    padding: 0;
  }
`

export const AlertsAndNotifications = ({ data, jobId }) => {
  const notificationActions = useNotificationActions(jobId)
  const columns = [
    { title: 'Date', dataIndex: 'display_date', key: 'date', width: 130 },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 85,
      className: 'icon',
      render (type) {
        let icon
        switch (type) {
          case 'system_alert':
            icon = <IconAlert />
            break
          case 'internal':
            icon = <IconComment />
            break
          default:
            icon = <IconComment />
        }

        return (
          <>
            <span>{icon}</span>
          </>
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render (_details, row) {
        return <Alert actions={notificationActions} alert={row} senderText='Me' receiverText='Admin' />
      }
    }
  ]

  return (
    <TableWrapper>
      <TableHeader>
        <h3>Notifications</h3>
      </TableHeader>
      <Table
        columns={columns}
        data={data}
        rowKey={(record) => record.id}
        rowClassName={(record) => {
          return record.type === 'system_alert' ? 'alert' : ''
        }}
      />
    </TableWrapper>
  )
}
