import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAdverseActionAssessActions } from '../../../../_actions'
import { ApplicantGenericTable } from '../../../../components/Applicant/ApplicantGenericTable'

const AdverseActionAssessList = () => {
  const { companyId } = useParams()

  const aaaActions = useAdverseActionAssessActions(localStorage.getItem('access_token'))
  const [aaas, setAAAs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const COLUMNS = [
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title'
    },
    {
      dataIndex: 'erx_id',
      key: 'erx_id',
      title: 'ERX ID'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'status-column',
      render (_, row) {
        return (
          <span>
            <a href={`/admin/companies/${companyId}/aaas/${row.id}/edit`}>Edit</a> |
            <a
              href={`/admin/companies/${companyId}/aaas/${row.id}#delete`}
              onClick={(e) => {
                e.preventDefault()
                if (
                  window.confirm(
                    'Are you really sure you want to delete this Assess?!'
                  ) === true
                ) {
                  const deleteAAA = async () => {
                    setIsLoading(true)
                    await aaaActions.remove(row.id)
                    const apiData = await aaaActions.list(companyId)
                    setAAAs(apiData)
                    setIsLoading(false)
                  }
                  deleteAAA()
                }
              }}
            >
              Delete
            </a>
          </span>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])

  useEffect(() => {
    aaaActions.list(companyId).then((apiData) => {
      setAAAs(apiData)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <ApplicantGenericTable
        loading={isLoading}
        tableTitle='Adverse Action Assesses'
        tableTitleColor='#E28A57'
        data={aaas}
        columns={columns}
        rowKey='id'
        buttonLink={`/admin/companies/${companyId}/aaas/new`}
        buttonText='Add'
      />
    </>
  )
}

export default AdverseActionAssessList
