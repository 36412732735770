import { useEffect, useState } from 'react'
import { ProductsList } from '../../../../components/ProductsList'
import styled from 'styled-components'
import { basicButton } from '../../../../_helpers/shared-css'
import { useProductsActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'
import { useParams } from 'react-router-dom'

const TableTitleWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  height: 50px;
`

const StyledLink = styled.div`
  margin: 0 0 0 auto;

  button {
    ${basicButton}
    z-index: 5;
    margin: 0px;

    &:hover {
      cursor: pointer;
      background-color: #73a9ac;
    }
  }
`

const TableTitle = styled.div`
  font-size: 24px;
  color: #E28A57;
  font-weight: bold;
`

const AdminProductsList = () => {
  const { companyId } = useParams()
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const productActions = useProductsActions(localStorage.getItem('access_token'))
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    const apiProducts = await productActions.employifiAll()
    setProducts(apiProducts)
    const apiCompanyProducts = await productActions.companyAll(companyId)
    setSelectedProducts(apiCompanyProducts.map((cp) => `${cp.id}`))
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const saveCompanyProducts = () => {
    productActions.setCompanyProducts(companyId, selectedProducts).then((newProducts) => {
      setSelectedProducts(newProducts.map((cp) => `${cp.id}`))
    })
  }

  return (
    <>
      {loading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (<ProductsList
            customTitle={
              <TableTitleWrapper>
                <TableTitle>Products</TableTitle>
                <StyledLink><button onClick={saveCompanyProducts}>Save</button></StyledLink>
              </TableTitleWrapper>
          }
            products={products.map((p) => {
              return { val: p.id, label: p.title, tooltip: p.description, checked: selectedProducts.includes(`${p.id}`) }
            })}
            editable
            saveChecked={setSelectedProducts}
            selectedProducts={selectedProducts}
            adminEditable
            fetchProductList={fetchData}
           />
          )}
    </>
  )
}

export default AdminProductsList
