import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'
import { AdminCompaniesSearch } from './AdminCompaniesSearch'
import { ReactComponent as IconStar } from '../../resources/images/icons/star.svg'
import { tabsCss } from '../../_helpers/shared-css'

const AdminCompaniesTabsWrapper = styled.div`
  margin-top: 60px;

  .cols {
    display: flex;
    align-items:flex-start;

    >div:first-child {
      margin-right: 20px;
      width: 60%;
      height: auto;
    }
  }

  ${tabsCss}

  .table-star {
    padding: 0 0 0 15px;

    svg .st0 {
      fill: #de7e53;
    }

    .starred {
      svg .st0 {
        fill: #ccc;
      }
    }

    &:hover {
      cursor: pointer;
    }
    svg {
      width: 20px;
    }
  }
`

const activeCompaniesColumns = [
  {
    title: '',
    dataIndex: 'starred',
    key: 'starred',
    width: 10,
    className: 'table-star',
    onCell: (record, index) => ({
      onClick (e) {
        // console.log('Click cell', ` row ${index}`, record, e.target)
      }
    }),
    render (rec) {
      return (
        <span className={`star-wrapper ${rec ? 'starred' : ''}`}>
          <IconStar />
        </span>
      )
    }
  },
  { title: 'Company Name', dataIndex: 'company_name', key: 'company_name' },
  { title: 'City', dataIndex: 'city', key: 'city' },
  { title: 'State', dataIndex: 'state', key: 'state' },
  { title: 'Primary Admin', dataIndex: 'primary_admin', key: 'primary_admin' },
  { title: 'Secondary Admin', dataIndex: 'secondary_admin', key: 'secondary_admin' },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 300,
    className: 'select-button',
    render (template, row) {
      return (
        <div>
          <button
            onClick={() => { console.log('clicked the add user button') }}
          >
            Add User
          </button>
          <button
            onClick={() => { console.log('clicked the manage button') }}
          >
            Manage
          </button>
        </div>
      )
    }
  }
]

const companyAdminsColumns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Company Name', dataIndex: 'company_name', key: 'company_name' },
  { title: 'Role', dataIndex: 'role', key: 'role' },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 300,
    className: 'select-button',
    render (template, row) {
      return (
        <div>
          <button
            onClick={() => { console.log('clicked the manage button') }}
          >
            Manage
          </button>
        </div>
      )
    }
  }
]

const activeCompaniesData = [
  {
    id: 11,
    starred: true,
    company_name: 'Argonaut Capital LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Rachel Berntein',
    secondary_admin: 'Rachel Berntein'
  },
  {
    id: 12,
    starred: false,
    company_name: 'Argonaut Capital LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Rachel Berntein',
    secondary_admin: 'Rachel Berntein'
  },
  {
    id: 13,
    starred: true,
    company_name: 'Argonaut Capital LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Rachel Berntein',
    secondary_admin: 'Rachel Berntein'
  }
]

const myCompaniesData = [
  {
    id: 11,
    starred: true,
    company_name: 'Argonaut Capital LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Someone Else',
    secondary_admin: 'Someone else'
  },
  {
    id: 12,
    starred: false,
    company_name: 'Compannny LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Someone Else',
    secondary_admin: 'Someone else'
  },
  {
    id: 13,
    starred: true,
    company_name: 'Bizness LLC',
    city: 'Newport Beach',
    state: 'CA',
    primary_admin: 'Someone Else',
    secondary_admin: 'Someone else'
  }
]

const companyAdminsData = [
  {
    id: 15,
    name: 'Rachel Perrsonname',
    company_name: 'Argonaut Capital LLC',
    role: 'Primary'
  },
  {
    id: 16,
    name: 'Rachel Perrsonname',
    company_name: 'Argonaut Capital LLC',
    role: 'Primary'
  }
]

export const AdminCompaniesTabs = ({ defaultTabIndex = 0 }) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0)

  return (
    <AdminCompaniesTabsWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab key='tab_1'>Active Companies</Tab>
          <Tab key='tab_2'>My Companies</Tab>
          <Tab key='tab_3'>Archived Companies</Tab>
          <Tab key='tab_4'>Company Admins</Tab>
        </TabList>

        <TabPanel key='tabPanel_1'>
          <AdminCompaniesSearch
            data={activeCompaniesData}
            dataColumns={activeCompaniesColumns}
            loading={false}
            search='' // pulled this from AdminJobTemplatSearch, might need a little smarter logic to make it shareable across this new generic component
          />
        </TabPanel>
        <TabPanel key='tabPanel_2'>
          <AdminCompaniesSearch
            data={myCompaniesData}
            dataColumns={activeCompaniesColumns}
            loading={false}
            search='' // pulled this from AdminJobTemplatSearch, might need a little smarter logic to make it shareable across this new generic component
          />
        </TabPanel>
        <TabPanel key='tabPanel_3'>
          Archive companies here? no mockup for this
        </TabPanel>
        <TabPanel key='tabPanel_4'>
          <AdminCompaniesSearch
            data={companyAdminsData}
            dataColumns={companyAdminsColumns}
            loading={false}
            search=''
          />
        </TabPanel>
      </Tabs>
    </AdminCompaniesTabsWrapper>
  )
}
