import styled from 'styled-components'

const AlertWrapper = styled.div`
  color: #fff;
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : '#de7e53'};
  font-weight: bold;
  font-size: 24px;
  display: flex;
  padding: 40px 30px 35px 30px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #272d3b33;
  margin: 25px 0;

  button {
    color: #fff;
    font-weight: bold;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 10px 25px;
    text-transform: uppercase;
    border-radius: 20px;
    margin-left: auto;
    min-width: 120px;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: ${(p) => (p.backgroundColor ? p.backgroundColor : '#de7e53')};
    }
  }
`

export const Alert = ({
  backgroundColor,
  alertText,
  alertButtonText,
  alertButtonOnClick
}) => {
  return (
    <AlertWrapper backgroundColor={backgroundColor}>
      <span>ALERT: {alertText}</span>
      <button onClick={alertButtonOnClick}>{alertButtonText}</button>
    </AlertWrapper>
  )
}
