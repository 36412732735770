import { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useForm } from 'react-hook-form'
import { useUserActions } from '../../../_actions'
import { userAtom } from '../../../_state'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { formCss } from '../../../_helpers/shared-css'

const FormWrapper = styled.div`
  ${formCss}
`
// type is initials or signature
const SignaturePad = ({ type, canvasWidth, canvasHeight, setUrl }) => {
  const user = useRecoilValue(userAtom)
  const userActions = useUserActions(localStorage.getItem('access_token'))
  const signatureRef = useRef(null)
  const typedSignatureRef = useRef(null)
  const typedTextboxRef = useRef(null)
  const { handleSubmit, formState } = useForm()
  const { isSubmitting } = formState
  const api_url = process.env.REACT_APP_API_URL || 'http://localhost:3000'
  const [signatureType, setSignatureType] = useState('Draw')

  const changeSignatureType = (e) => {
    setSignatureType(e.target.value)
    if (e.target.value === 'Type') {
      setTimeout(() => {
        typedTextboxRef.current.focus()
      }, 500)
    }
  }

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const save = (image) => {
    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('upload_type', type)
    formData.append('upload', dataURLtoBlob(image))
    fetch(api_url + `/users/${type}`, {
      method: 'POST',
      body: formData
    })
      .then(() => {
        switch (type) {
          case 'initials':
            userActions
              .getInitialsUrl()
              .then((initialsInfo) => {
                if (setUrl) { setUrl(initialsInfo.initials.upload_url) }
              })
            break
          case 'signature':
            userActions
              .getSignatureUrl()
              .then((signatureInfo) => {
                if (setUrl) {
                  setUrl(signatureInfo.signature.upload_url)
                }
              })
        }
      })
      .catch((err) => console.log(err))
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    if (signatureType === 'Draw') {
      save(signatureRef.current.getTrimmedCanvas().toDataURL())
    } else {
      cropCanvas()
      save(typedSignatureRef.current.toDataURL())
    }
    e.nativeEvent.stopImmediatePropagation()
  }

  const writeText = (info, style = {}) => {
    typedSignatureRef.current.width = canvasWidth
    typedSignatureRef.current.height = canvasHeight
    const ctx = typedSignatureRef.current.getContext('2d')
    ctx.clearRect(0, 0, canvasWidth, canvasHeight)

    const { text, x, y } = info
    const {
      fontSize = 20,
      fontFamily = 'Arial',
      color = 'black',
      textAlign = 'left',
      textBaseline = 'top'
    } = style

    ctx.beginPath()
    ctx.font = fontSize + 'px ' + fontFamily
    ctx.textAlign = textAlign
    ctx.textBaseline = textBaseline
    ctx.fillStyle = color
    ctx.fillText(text, x, y)
    ctx.stroke()
  }

  function cropCanvas () {
    const ctx = typedSignatureRef.current.getContext('2d')
    const canvas = typedSignatureRef.current
    const pix = { x: [], y: [] }
    const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight)
    let w = canvasWidth
    let h = canvasHeight
    let x
    let y
    let index

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x)
          pix.y.push(y)
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b
    })
    pix.y.sort(function (a, b) {
      return a - b
    })
    const n = pix.x.length - 1

    w = 1 + pix.x[n] - pix.x[0]
    h = 1 + pix.y[n] - pix.y[0]
    const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h)

    canvas.width = w
    canvas.height = h
    ctx.putImageData(cut, 0, 0)
  }

  return (
    <>
      <div style={{ paddingBottom: 0, textAlign: 'left' }}>
        Save your {type}.
      </div>
      {/* onSubmit={handleSubmit(onSubmit)} */}
      <FormWrapper>
        <form id='signatureForm' style={{ width: canvasWidth }}>
          <div className='draw-radio-wrapper'>
            <input
              type='radio'
              value='Draw'
              name='signatureType'
              onChange={changeSignatureType}
              checked={signatureType === 'Draw'}
            />{' '}
            Draw It
            <input
              type='radio'
              value='Type'
              name='signatureType'
              onChange={changeSignatureType}
              checked={signatureType === 'Type'}
            />{' '}
            Type It
          </div>

          <div style={{ paddingBottom: 20, textAlign: 'left' }}>
            {signatureType} your {type} here.
          </div>

          <div
            style={{
              display: signatureType === 'Draw' ? 'flex' : 'none',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                width: canvasWidth,
                height: canvasHeight,
                margin: '0 auto',
                border: '1px solid black',
                borderRadius: 10,
                boxSizing: 'border-box'
              }}
            >
              <SignatureCanvas
                ref={signatureRef}
                penColor='black'
                canvasProps={{
                  width: canvasWidth,
                  height: canvasHeight,
                  className: 'sigCanvas'
                }}
              />
            </div>
            <div
              className='clear-button'
              onClick={() => {
                signatureRef.current.clear()
              }}
            >
              Clear
            </div>
          </div>

          <div style={{ display: signatureType === 'Type' ? 'block' : 'none' }}>
            <input
              ref={typedTextboxRef}
              name='typedSignature'
              type='text'
              className='form-control type-it-input'
              onChange={(e) =>
                writeText(
                  { text: e.target.value, x: 250, y: 85 },
                  {
                    fontSize: 25,
                    fontFamily: 'cursive',
                    color: 'black',
                    textAlign: 'center'
                  }
                )}
            />
            <div
              style={{
                width: canvasWidth,
                height: canvasHeight,
                margin: '0 auto',
                border: '1px solid black',
                borderRadius: 10
              }}
            >
              <canvas ref={typedSignatureRef} />
            </div>
          </div>

          <button
            type='button'
            onClick={handleSubmit((d, e) => { onSubmit(d, e) })}
            disabled={isSubmitting}
            className='btn btn-primary'
          >
            {isSubmitting && (
              <span className='spinner-border spinner-border-sm mr-1' />
            )}
            Save & Create {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        </form>
      </FormWrapper>
    </>
  )
}

export { SignaturePad }
