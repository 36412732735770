import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const StepTwo = ({ save, defaultEmail, defaultPassword, defaultPhone }) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    emailConfirmation: Yup.string().oneOf(
      [Yup.ref('email'), null],
      'Emails must match'
    ),
    phone: Yup.string(),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    )
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    save(data.email, data.password, data.phone)
  }
  return (
    <>
      <h3>Step 2</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-element'>
          <label>Email Address</label>
          <input
            defaultValue={defaultEmail}
            name='email'
            type='text'
            {...register('email')}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.email?.message}</div>
        </div>
        <div className='form-element'>
          <label>Confirm Email Address</label>
          <input
            autoComplete='off'
            name='emailConfirmation'
            type='text'
            {...register('emailConfirmation')}
            className={`form-control ${
              errors.emailConfirmation ? 'is-invalid' : ''
            }`}
          />
          <div className='invalid-feedback'>
            {errors.emailConfirmation?.message}
          </div>
        </div>
        <div className='form-element'>
          <label>Password</label>
          <input
            defaultValue={defaultPassword}
            name='password'
            type='password'
            {...register('password')}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.password?.message}</div>
        </div>
        <div className='form-element'>
          <label>Confirm Password</label>
          <input
            name='passwordConfirmation'
            type='password'
            {...register('passwordConfirmation')}
            className={`form-control ${
              errors.passwordConfirmation ? 'is-invalid' : ''
            }`}
          />
          <div className='invalid-feedback'>
            {errors.passwordConfirmation?.message}
          </div>
        </div>
        <div className='form-element'>
          <label>
            Mobile Phone (Optional - for alerts & notifications only)
          </label>
          <input
            defaultValue={defaultPhone}
            name='phone'
            type='text'
            {...register('phone')}
            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{errors.phone?.message}</div>
        </div>
        <button disabled={isSubmitting} className='btn btn-primary'>
          {isSubmitting && (
            <span className='spinner-border spinner-border-sm mr-1' />
          )}
          Next Step
        </button>
      </form>
    </>
  )
}

export default StepTwo
