import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { alertAtom } from '../_state'
import { useAlertActions } from '../_actions'
import { history } from '../_helpers'
import { ReactComponent as IconClose } from '../resources/images/icons/close.svg'

export { Alert }

function Alert () {
  const alert = useRecoilValue(alertAtom)
  const alertActions = useAlertActions()

  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen(alertActions.clear)

    // stop the listener when component unmounts
    return unlisten
  }, [])

  if (!alert) return null

  return (
    <div
      className='container'
      style={{
        zIndex: 999,
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-40px)',
        right: '-40px',
        backgroundColor: alert.type === 'alert-success' ? 'green' : '#e41a1a',
        color: 'white',
        padding: 10
      }}
    >
      <div className='m-3'>
        <div className={`alert alert-dismissable ${alert.type}`}>
          <a
            href='/'
            className='close-alert'
            onClick={alertActions.clear}
            style={{ height: 'auto' }}
          >
            <IconClose
              style={{
                width: 20,
                fill: '#fff',
                float: 'left',
                marginRight: 10
              }}
            />
          </a>
          {alert.message}
        </div>
      </div>
    </div>
  )
}
