import { Router } from 'react-router-dom'
import { history } from '../_helpers'
import styled from 'styled-components'

import { SidebarNav } from '../components/SidebarNav'
import { ReactComponent as IconHome } from '../resources/images/icons/house.svg'
import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconAlert } from '../resources/images/icons/alert.svg'
import { ReactComponent as IconAward } from '../resources/images/icons/award.svg'
import { ReactComponent as IconCheckmark } from '../resources/images/icons/circle-check-regular.svg'

import { ApplicantGenericTable } from '../components/Applicant/ApplicantGenericTable'
import { ApplicantTasks } from '../components/Applicant/ApplicantTasks'
import { ApplicantJobTitleRow } from '../components/Applicant/ApplicantJobTitleRow'
import { ApplicantJobDetails } from '../components/Applicant/ApplicantJobDetails'
import { Alert } from '../components/shared/Alert'
import { AlertsAndNotifications } from '../components/Applicant/AlertsAndNotifications'
import { MyDocuments } from '../components/Applicant/MyDocuments'

const PlaygroundWrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 90px;

  h2 {
    margin-top: 50px;
  }

  .cols {
    display: flex;
    align-items: flex-start;

    > div:first-child {
      margin-right: 20px;
      width: 40%;
      height: auto;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  padding: 25px 25px 100px 25px;
  overflow: auto;

  .welcome {
    font-size: 28px;
    color: #81bcbf;
    font-weight: 700;
    margin-bottom: 25px;
  }
`

const ApplicantPlayground = () => {
  const sidebarButtons = [
    { icon: IconHome, link: '/playground', name: 'home' },
    { icon: IconFolder, link: '/jobs', name: 'jobs' },
    { icon: IconAlert, link: '/applicants', name: 'applicants' },
    { icon: IconAward, link: '/settings', name: 'settings' }
  ]

  const applicantOpenJobsColumns = [
    { title: 'Company', dataIndex: 'company', key: 'company' },
    { title: 'Job', dataIndex: 'job', key: 'job' },
    { title: 'Action Date', dataIndex: 'actionDate', key: 'actionDate' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Open Tasks',
      dataIndex: 'openTasks',
      key: 'open_tasks',
      className: 'open-tasks',
      width: 120,
      render () {
        // TODO: we probably need logic to handle what all cases are possible for this column
        return <div><IconCheckmark fill='green' /></div>
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 150,
      className: 'manage-button',
      render (row) {
        return (
          <div>
            <button
              onClick={() => {
                console.log('clicked the manage button', row.id)
              }}
            >
              Manage
            </button>
          </div>
        )
      }
    }
  ]

  const applicantOpenJobsData = [
    {
      company: 'Cola-Cola',
      job: 'Project Manager - Manufacturing',
      actionDate: '10/10/2022',
      status: 'In Progress',
      openTasks: true,
      id: 1234
    },
    {
      company: 'Pepsi-Pepsi',
      job: 'Project Leader - Stuff',
      actionDate: '10/10/2022',
      status: 'In Progress',
      openTasks: false,
      id: 5678
    },
    {
      company: 'Cola-Cola',
      job: 'Things Manager - Logistics',
      actionDate: '10/10/2022',
      status: 'In Progress',
      openTasks: true,
      id: 9876
    }
  ]

  const applicantPreviousJobsColumns = [
    { title: 'Company', dataIndex: 'company', key: 'company' },
    { title: 'Job', dataIndex: 'job', key: 'job' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 130,
      className: 'view-button',
      render (row) {
        return (
          <div>
            <button
              onClick={() => {
                console.log('clicked the manage button', row.id)
              }}
            >
              View
            </button>
          </div>
        )
      }
    }
  ]

  const applicantPreviousJobsData = [
    {
      company: 'Cola-Cola',
      job: 'Project Manager - Manufacturing',
      date: '10/10/2022',
      id: 1234
    },
    {
      company: 'Smith Plumbing',
      job: 'Clerk: Level 1',
      date: '10/10/2022',
      id: 5678
    },
    {
      company: 'Bank of America',
      job: 'Account Representative',
      date: '10/10/2022',
      id: 9876
    },
    {
      company: 'Kohls',
      job: 'Chemical Engineer',
      date: '10/10/2022',
      id: 345663
    },
    {
      company: 'Toys R Us',
      job: 'Account Manager',
      date: '10/10/2022',
      id: 777
    }
  ]

  const applicantJob = {
    startDate: '12/22/2022',
    location: 'Irvine, CA',
    department: 'Manufacturing & Fabrication',
    payAmount: '$60,000',
    payType: 'Salary',
    vacation: '2 Weeks',
    bonus: 'Discretionary Bonus'
  }

  const applicantTasksData = [
    {
      document_title: 'Applicant Verification',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'complete',
      downloadLink: undefined,
      id: 1234
    },
    {
      document_title: 'Supplemental Questionnaire',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'in_progress',
      downloadLink: undefined,
      id: 222
    },
    {
      document_title: 'Conditional Offer Letter',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'not_viewed',
      downloadLink: 'https://google.com',
      id: 3333
    },
    {
      document_title: 'Criminal Questionnaire',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'needs_signature',
      downloadLink: undefined,
      id: 444
    },
    {
      document_title: 'Applicant Certification & Authorization',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'not_viewed',
      downloadLink: undefined,
      id: 555
    },
    {
      document_title: 'Applicant Notification',
      delivered: '10/12/2022',
      completed: '10/13/2022',
      status: 'not_viewed',
      downloadLink: undefined,
      id: 666
    }
  ]

  const alertsAndNotificationsTableData = [
    {
      id: 1,
      date: '10/18/2022',
      type: 'system_alert',
      details: 'Social Security number does not match name.',
      respondLink: '/somewhere'
    },
    {
      id: 2,
      date: '10/18/2022',
      type: 'internal',
      details: 'Spoke with Roberta and was able to correct SS#'
    },
    {
      id: 3,
      date: '10/18/2022',
      type: 'internal',
      details:
        'Spoke with Roberta in regards to modifying the official start date due to some previous personal engagements. She confirmed she will be able to start the first week of January.'
    }
  ]

  const resourcesColumns = [
    { title: 'Document', dataIndex: 'resourceTitle', key: 'resourceTitle' },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 130,
      className: 'view-button',
      render (row) {
        return (
          <div>
            <button
              onClick={() => {
                console.log('clicked the view button', row.resourceLink)
              }}
            >
              View
            </button>
          </div>
        )
      }
    }
  ]
  const resourcesData = [
    {
      resourceTitle: 'Summary of Rights',
      resourceLink: '/somewhere'
    },
    {
      resourceTitle: 'Cali Consumer Privacy Act (CCPA)',
      resourceLink: '/somewhere'
    },
    {
      resourceTitle: 'Summary of Rights',
      resourceLink: '/somewhere'
    },
    {
      resourceTitle: 'Cali Consumer Privacy Act (CCPA)',
      resourceLink: '/somewhere'
    }
  ]

  const myDocumentsData = [
    {
      name: 'Tier 1 Customer Service Certification',
      type: 'certificate',
      issued: '10/10/2022',
      expires: '10/10/2023',
      link: '/somewhere'
    },
    {
      name: 'Tier 1 Customer Service Certification',
      type: 'certificate',
      issued: '10/10/2022',
      expires: '10/10/2023',
      link: '/somewhere'
    },
    {
      name: 'Tier 1 Customer Service Certification',
      type: 'certificate',
      issued: '10/10/2022',
      expires: '10/10/2023',
      link: '/somewhere'
    },
    {
      name: 'Tier 1 Customer Service Certification',
      type: 'certificate',
      issued: '10/10/2022',
      expires: '10/10/2023',
      link: '/somewhere'
    }
  ]

  return (
    <PlaygroundWrapper>
      <Router history={history}>
        <SidebarNav buttons={sidebarButtons} backgroundColor='#81BCBF' />
      </Router>
      <ContentWrapper>
        <span className='welcome'>Welcome Roberta!</span>
        <ApplicantGenericTable
          tableTitle='Open Jobs'
          columns={applicantOpenJobsColumns}
          data={applicantOpenJobsData}
        />
        <ApplicantGenericTable
          tableTitle='Previous Jobs'
          columns={applicantPreviousJobsColumns}
          data={applicantPreviousJobsData}
        />
        <ApplicantJobTitleRow
          jobTitle='Project Manager - Manufacturing'
          company='Coca-Cola'
        />
        <ApplicantJobDetails job={applicantJob} />
        <Alert
          alertText='There is an alert that needs your attention.'
          alertButtonText='View'
          alertButtonOnClick={() => {
            console.log('clicked the alert button')
          }}
        />
        <ApplicantTasks data={applicantTasksData} />
        <AlertsAndNotifications data={alertsAndNotificationsTableData} />
        <ApplicantGenericTable
          tableTitle='Resources'
          columns={resourcesColumns}
          data={resourcesData}
        />
        <MyDocuments data={myDocumentsData} />
      </ContentWrapper>
    </PlaygroundWrapper>
  )
}

document.addEventListener('DOMContentLoaded', () => {})

export { ApplicantPlayground }
