import { useFetchWrapper } from '../_helpers'

const useQuestActions = (auth = undefined) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/quest`
  const fetchWrapper = useFetchWrapper(auth)

  const find_location = (job_id) => {
    return fetchWrapper.get(`${baseUrl}/location/${job_id}`)
  }
  const request_form = (job_id, location_id) => {
    return fetchWrapper.get(`${baseUrl}/request/${job_id}/${location_id}`)
  }

  return {
    find_location,
    request_form
  }
}

export { useQuestActions }
