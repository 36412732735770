import { useState } from 'react'
import { history } from '../../../../_helpers'
import { useParams } from 'react-router-dom'
import AdminWrapper from '../../../shared/AdminWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useAdverseActionAssessActions } from '../../../../_actions'
import { BarLoader } from 'react-spinners'
import { FormContentWrapper } from '../../../shared/AdminStyledComponets'

const AdverseActionAssessNew = () => {
  const aaaActions = useAdverseActionAssessActions(localStorage.getItem('access_token'))
  const { companyId } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    erx_id: Yup.string().required('ERX ID is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const save = async () => {
      setIsLoading(true)
      await aaaActions.add({ ...data, company_id: companyId })
      setIsLoading(false)
      history.goBack()
    }
    save()
  }

  return (
    <AdminWrapper>
      <h1>New Adverse Action Assess</h1>
      <FormContentWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-element'>
            <label>Title</label>
            <input
              name='title'
              type='text'
              {...register('title')}
              className={`form-control ${errors.title ? 'is-invalid' : ''
                }`}
            />
            <div className='invalid-feedback'>{errors.title?.message}</div>
          </div>
          <div className='form-element'>
            <label>ERX ID</label>
            <input
              name='erx_id'
              type='text'
              {...register('erx_id')}
              className={`form-control ${errors.erx_id ? 'is-invalid' : ''
                }`}
            />
            <div className='invalid-feedback'>{errors.erx_id?.message}</div>
          </div>
          {isLoading
            ? (
              <div style={{ paddingLeft: 20 }}>
                <BarLoader color='#81BCBF' />
              </div>
              )
            : (
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-primary'
              >
                {isSubmitting && (
                  <span className='spinner-border spinner-border-sm mr-1' />
                )}
                Save
              </button>
              )}
        </form>
      </FormContentWrapper>
    </AdminWrapper>
  )
}

export default AdverseActionAssessNew
