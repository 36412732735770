import Modal from 'react-modal'
import styled from 'styled-components'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

const ModalContent = styled.div`
  padding: 15px;
  min-height: ${props => props.modalMinHeight || 450}px;
  display: flex;
  flex-direction: column;
  max-height: ${props => props.modalMaxHeight || (window.innerHeight - 200)}px;
  overflow: auto;

  h2 {
    color: #272d3b;
  }

  > svg {
    width: 25px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #707070;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #707070;
  }

  .react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
      color: #272d3b;
      font-size: 20px;
      font-weight: bold;
      width: 175px;
      position: relative;
      opacity: 0.5;

      &:nth-of-type(3) {
        width: 250px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #ccc;
        }
      }

      &.react-tabs__tab--selected {
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          width: 40px;
          height: 6px;
          border-radius: 5px;
          background-color: #de7e53;
        }
      }
    }
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    borderRadius: '15px',
    position: 'relative'
  }
}

const ModalWrapper = ({ modalOpen, closeModal, modalTitleText, modalContent, modalMinHeight, modalMaxHeight }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalContent modalMinHeight={modalMinHeight} modalMaxHeight={modalMaxHeight}>
        <h2 ref={(modalTitle) => modalTitle}>
          {modalTitleText}
        </h2>
        <IconClose onClick={closeModal} />
        {modalContent}
      </ModalContent>
    </Modal>
  )
}

export default ModalWrapper
