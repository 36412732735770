import { useEffect } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import {
  HookFormArrayInput,
  HookFormArrayRegionSelector,
  HookFormArrayToggleSwitch, RadioGroup
} from '../../shared/HookFormComponents'
import { useArrayContext, ArrayProvider, useStateContext } from '../../../contexts'

const Employer = () => {
  const { arrayName, index } = useArrayContext()
  console.log(arrayName, index)
  const watchCurrent = useWatch({
    name: `${arrayName}.${index}.current`
  })

  return (
    <>
      <HookFormArrayToggleSwitch
        fieldName='current'
        label='This is my Current Employer'
      />
      <HookFormArrayInput fieldName='employer' label='Employer' type='text' />
      <HookFormArrayInput fieldName='jobTitle' label='Job Title' type='text' />
      <HookFormArrayInput
        fieldName='supervisor'
        label='Supervisor'
        type='text'
      />
      <HookFormArrayInput
        fieldName='supervisorTitle'
        label='Supervisor Title'
        type='text'
      />
      <HookFormArrayInput
        fieldName='supervisorPhone'
        label='Supervisor Phone'
        subType='phone'
        type='text'
      />
      <HookFormArrayInput fieldName='city' label='City' type='text' />
      <HookFormArrayRegionSelector
        countryName='US'
        fieldName='state'
        label='State'
      />
      <HookFormArrayInput
        type='text'
        subType='date'
        min='2000-01-01'
        max={new Date().toISOString().slice(0, 10)}
        fieldName='startDate'
        label='Start Date'
      />
      {(watchCurrent === '' || watchCurrent === false) && (
        <HookFormArrayInput
          type='text'
          subType='date'
          min='2000-01-01'
          max={new Date().toISOString().slice(0, 10)}
          fieldName='endDate'
          label='End Date'
        />
      )}
      <HookFormArrayInput
        fieldName='responsibilities'
        label='Job Responsibilities'
        type='text'
      />
      <HookFormArrayInput
        fieldName='reasonForLeaving'
        label='Reason for Leaving'
        type='text'
      />
    </>
  )
}

const EmploymentHistory = ({ name }) => {
  const { control, setValue, watch } = useFormContext()

  const { state, setState } = useStateContext()

  console.log(name, state)
  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  const watchCurrentlyEmployed = watch('currentlyEmployed')

  useEffect(() => {
    if (watchCurrentlyEmployed === 'Yes') {
      if (state.employmentHistory.length === 0) {
        append()
        addEmployer({ current: true })
      }
      setValue(`${name}.0.current`, true)
    } else if (watchCurrentlyEmployed === 'No') {
      if (state.employmentHistory.length > 0) { setValue(`${name}.0.current`, false) }
    }
  }, [watchCurrentlyEmployed])

  const addEmployer = (c) => {
    const list = state[name].map((item) => {
      return item
    })
    list.push(c)
    setState({ ...state, [name]: list })
  }

  const removeEmployer = (idx) => {
    const list = state[name].filter((e, i) => {
      return i !== idx
    })
    setState({ ...state, [name]: list })
  }

  return (
    <div className='employment-history-wrapper'>
      <RadioGroup
        name='currentlyEmployed'
        values={['Yes', 'No']}
        label='Are you currently employed?'
      />
      {watchCurrentlyEmployed === 'Yes' && (
        <RadioGroup
          name='contactCurrentEmployer'
          values={['Yes', 'No']}
          label='May we contact your current employer?'
        />
      )}
      <div className='form-element'>
        <button
          type='button'
          className='add-remove-button'
          onClick={() => {
            append()
            addEmployer({})
          }}
        >
          + Add Employment Entry
        </button>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className='employment-entry'>
          <ArrayProvider index={index} arrayName={name}>
            <Employer id={item.id} />
          </ArrayProvider>
          <button
            type='button'
            className='add-remove-button'
            onClick={() => {
              if (fields.length <= 1) {
                setValue('terminatedOrResigned', 'No')
              }
              remove(index)
              removeEmployer(index)
            }}
          >
            - Remove Entry
          </button>
        </div>
      ))}
    </div>
  )
}

export { EmploymentHistory }
