import React, { useState } from 'react'
import { Router } from 'react-router-dom'
import { history } from '../_helpers'
import styled from 'styled-components'

import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconMagnifyingGlass } from '../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconEdit } from '../resources/images/icons/edit.svg'
import { ReactComponent as IconEnvelope } from '../resources/images/icons/envelope.svg'
import { ReactComponent as IconGrid } from '../resources/images/icons/grid.svg'
import { ReactComponent as IconRows } from '../resources/images/icons/rows.svg'

import { ReactComponent as IconDashboard } from '../resources/images/icons/dashboard-icon.svg'
import { ReactComponent as IconApplicants } from '../resources/images/icons/applicants-icon.svg'
import { ReactComponent as IconShield } from '../resources/images/icons/settings-icon.svg'

import { SidebarNav } from '../components/SidebarNav'
import { DashboardTile } from '../components/DashboardTile'
import { DashboardJobsTable } from '../components/DashboardJobsTable'
import { DashboardPriorityJobTile } from '../components/DashboardPriorityJobTile'
import { JobDetails } from '../components/shared/JobDetails'
import { HRManagement } from '../components/HRManagement'
import { ProductsList } from '../components/ProductsList'
import { JobDetailStats } from '../components/JobDetailStats'
import { HiresTable } from '../components/HiresTable'
import { ApplicantsTable } from '../components/ApplicantsTable'
import { ApplicantDetails } from '../components/ApplicantDetails'
import { ApplicantDetailTimeline } from '../components/ApplicantDetailTimeline'
import { ApplicantDetailAlertsAndNotifications } from '../components/ApplicantDetailAlertsAndNotifications'
import { ApplicantDetailActions } from '../components/ApplicantDetailActions'
import { ApplicantDetailDocumentLibrary } from '../components/ApplicantDetailDocumentLibrary'
import { PreAdverseActionLog } from '../components/PreAdverseActionLog'
import { PreAdverseActionAssessment } from '../components/PreAdverseActionAssessment'

import { useRecoilValue } from 'recoil'
import { userAtom } from '../_state'

const PlaygroundWrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 240px;

  h2 {
    margin-top: 50px;
  }

  .cols {
    display: flex;
    align-items: flex-start;

    > div:first-child {
      margin-right: 20px;
      width: 40%;
      height: auto;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 240px;
}
`

const Content = styled.div`
  padding: 40px 40px 100px 40px;
  overflow: auto;
  box-sizing: border-box;
`

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tile-controls {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    color: #707070;

    h2 {
      display: inline-block;
      color: #707070;
    }

    span {
      margin-right: 10px;
    }

    span:first-of-type {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .change-view {
    float: right;
    display: inline-block;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
      fill: #f0eeec;
    }

    &.blue {
      svg {
        fill: #81bcbf;
      }
    }
  }
`

const TableWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`

const Playground = () => {
  const user = useRecoilValue(userAtom)
  const sidebarButtons = [
    { icon: IconDashboard, link: '/playground', name: 'Dashboard' },
    { icon: IconFolder, link: '/jobs', name: 'jobs' },
    { icon: IconApplicants, link: '/applicants', name: 'applicants' },
    { icon: IconShield, link: '/settings', name: 'settings' }
  ]

  const alertTiles = [
    {
      color: '#DE7E53',
      icon: IconEnvelope,
      number: 8,
      text: 'Notifications',
      link: '/somewhere'
    },
    {
      color: '#81BCBF',
      icon: IconEdit,
      number: 1,
      text: 'Offers in Progress',
      link: '/somewhere'
    },
    {
      icon: IconMagnifyingGlass,
      number: 2,
      text: 'Conditional Offers in Review',
      link: '/somewhere'
    },
    {
      icon: IconMagnifyingGlass,
      number: 8,
      text: 'Open Jobs',
      link: '/somewhere'
    },
    {
      icon: IconMagnifyingGlass,
      number: 5,
      text: 'Jobs to Import',
      link: '/somewhere'
    }
  ]

  const priorityJobTiles = [
    {
      title: 'Sr. Account Analyst',
      link: '/somewhere',
      location: 'Irvine, CA',
      totalStages: 1,
      completedStages: 1,
      applicants: 40
    },
    {
      title: 'Mechanic - Level 2',
      link: '/somewhere',
      location: 'Irvine, CA',
      totalStages: 5,
      completedStages: 2,
      applicants: 52
    },
    {
      title: 'Senior Data Scientist - Commerce',
      link: '/somewhere',
      location: 'Irvine, CA',
      totalStages: 3,
      completedStages: 3,
      applicants: 116
    },
    {
      title: 'Jr. Account Analyst',
      link: '/somewhere',
      location: 'Irvine, CA',
      totalStages: 3,
      completedStages: 1,
      applicants: 37
    }
  ]

  const jobsTableData = [
    {
      job_title: 'Account Coordinator',
      state: 'Newport Beach, CA',
      status: 'Available',
      date_posted: '22 June 2020'
    },
    {
      job_title: 'Systems Engineer - Level 2',
      state: 'Los Angeles, CA',
      status: 'In Progress',
      date_posted: '22 June 2020'
    },
    {
      job_title: 'Senior Vice President of Sales',
      state: 'Ft. Lauderdale, FL',
      status: 'Available',
      date_posted: '22 June 2020'
    },
    {
      job_title: 'Project Manager - Manufacturing',
      state: 'Irvine, CA',
      status: 'Available',
      date_posted: '22 June 2020'
    },
    {
      job_title: 'Account Director - West Coast',
      state: 'Ft. Worth, TX',
      status: 'Available',
      date_posted: '22 June 2020'
    }
  ]

  const hiresTableData = [
    {
      a: { id: 123, starred: true }, // thinking this could be an id for api calls
      b: 'Roberta Callaghan',
      c: 'In Progress',
      d: 'gray', // no idea what this should be TODO figure out proper logic
      e: '02:12:27',
      f: '10/18/2022',
      g: '10/20/2022',
      h: 'In Progress' // would prefer not to dupe this column but couldnt find a better way yet
    },
    {
      a: { id: 456, starred: false }, // thinking this could be an id for api calls
      b: 'Jonathan Epstein',
      c: 'Accepted',
      d: 'green', // no idea what this should be TODO figure out proper logic
      e: '01:08:13',
      f: '10/01/2022',
      g: '10/05/2022',
      h: 'Accepted' // would prefer not to dupe this column but couldnt find a better way yet
    },
    {
      a: { id: 789, starred: false }, // thinking this could be an id for api calls
      b: 'Rachel McAdams',
      c: 'In Progress',
      d: '', // no idea what this should be TODO figure out proper logic
      e: '03:0245',
      f: '10/26/2022',
      g: '10/27/2022',
      h: 'In Progress' // would prefer not to dupe this column but couldnt find a better way yet
    }
  ]

  const applicantsTableData = [
    {
      a: { id: 123, starred: false }, // thinking this could be an id for api calls
      b: 'Jennifer Bernstein',
      c: false,
      d: 'ATS',
      e: '10/18/2022',
      f: '10/20/2022'
    },
    {
      a: { id: 123, starred: true }, // thinking this could be an id for api calls
      b: 'Sam Gamgee',
      c: true,
      d: 'ATS',
      e: '11/16/2022',
      f: '11/20/2022'
    },
    {
      a: { id: 123, starred: false }, // thinking this could be an id for api calls
      b: 'Frodo Baggins',
      c: false,
      d: 'Manual',
      e: '12/05/2022',
      f: '12/12/2022'
    },
    {
      a: { id: 123, starred: false }, // thinking this could be an id for api calls
      b: 'Mary Brandybuck',
      c: false,
      d: 'ATS',
      e: '12/05/2022',
      f: '12/12/2022'
    },
    {
      a: { id: 123, starred: true }, // thinking this could be an id for api calls
      b: 'Pippin Took',
      c: false,
      d: 'Manual',
      e: '12/05/2022',
      f: '12/12/2022'
    }
  ]

  const [fullWidthTiles, setFullWidthTiles] = useState(false)

  const jobDetailsData = {
    applicantsToday: 6,
    totalApplicants: 116,
    daysActive: 17,
    alerts: 1,
    jobTitle: 'Project Manager - Manufacturing',
    openings: 5,
    location: 'Irvine, CA',
    startDate: '12/22/2022',
    payAmount: 60000,
    payType: 'Salary',
    department: 'Manufacturing & Fabrication',
    vacation: '2 Weeks',
    bonus: 'Discretionary Bonus',
    status: 'Exempt'
  }

  const applicantDetailsData = {
    dateApplied: '10/18/2022',
    daysSinceConditionalOffer: 2,
    address: '124 West 42nd Street\nApt. 137\nNewport Beach, CA 92663',
    email: 'roberta.callaghan@gmail.com',
    phone: '(949) 457-2684'
  }

  const applicantDetailsTimeline = [
    {
      title: 'Conditional Offer Sent',
      complete: true
    },
    {
      title: 'Application Verification',
      complete: true
    },
    {
      title: 'Conditional Offer Signed',
      complete: false
    },
    {
      title: 'Compliance Documents Signed',
      complete: false
    },
    {
      title: 'Products Ordered',
      complete: false
    },
    {
      title: 'Products Complete',
      complete: false
    },
    {
      title: 'Final Offer',
      complete: false
    },
    {
      title: 'Hired',
      complete: false
    }
  ]

  const products = [
    { val: 1, label: 'CBSV' },
    { val: 2, label: 'Credit Report' },
    { val: 3, label: 'Criminal All - Main Name Only' },
    { val: 4, label: 'Criminal All with All Alias' },
    { val: 5, label: 'Criminal Search' },
    { val: 6, label: 'Drug Testing - 10 Panel Urine' },
    { val: 7, label: 'Drug Testing - 10 Panel Urine + Alcohol + Cotonine' },
    { val: 8, label: 'Drug Testing - 5 Panel Urine' },
    { val: 9, label: 'Drug Testing - 9 Panel Urine (Marijuana not tested)' },
    { val: 10, label: 'Drug Testing - Hair Panel' },
    { val: 11, label: 'Education Verification' },
    { val: 12, label: 'Application Verification' },
    { val: 13, label: 'Federal Criminal All - Main Name Only' },
    { val: 14, label: 'Federal Criminal All with Alias Names' },
    { val: 15, label: 'ID Report' },
    { val: 16, label: 'Motor Vehicle Report' },
    { val: 17, label: 'National Criminal & Government Sanctions' },
    { val: 18, label: 'Professional License Verification' },
    { val: 19, label: 'Professional Reference' },
    { val: 20, label: 'CBSex Offender SearchSV' },
    { val: 21, label: 'Trace Report' }
  ]

  const alertsAndNotificationsTableData = [
    {
      id: 1,
      date: '10/18/2022',
      type: 'system_alert',
      details: 'Social Security number does not match name.'
    },
    {
      id: 2,
      date: '10/18/2022',
      type: 'internal',
      details: 'Spoke with Roberta and was able to correct SS#'
    },
    {
      id: 3,
      date: '10/18/2022',
      type: 'internal',
      details:
        'Spoke with Roberta in regards to modifying the official start date due to some previous personal engagements. She confirmed she will be able to start the first week of January.'
    }
  ]

  // probably gonna need some heavy controller/model logic to build these these
  // keeping these props objections as basic as possible for now
  // I think the logic belongs upstream of this but we can figure it out when the time comes
  // TODO lets discuss all the UX cases around these sometime
  // taking my best swing for now but surely missing ideas (going off a single mockup with no notes)
  const applicantDetailActionsList = [
    {
      title: 'Conditional Offer Letter',
      buttonText: 'Resend',
      buttonAction: () => {
        console.log('clicked resend button')
      },
      buttonEnabled: true, // disabled buttons are grayed out
      buttonColor: 'blue', // blue or brown buttons in design (disabled buttons are grayed out)
      buttonFresh: false // true or false to show if button action has been done already, disabled buttons should probably be undefined
    },
    {
      title: 'Order Products',
      buttonText: 'Submit',
      buttonAction: () => {
        console.log('clicked submit button')
      },
      buttonEnabled: true,
      buttonColor: 'blue',
      buttonFresh: true
    },
    {
      title: 'Final Offer',
      buttonText: 'Send',
      buttonAction: undefined,
      buttonEnabled: false,
      buttonColor: undefined,
      buttonFresh: undefined
    },
    {
      title: 'Adverse Action',
      buttonText: 'Activate',
      buttonAction: () => {
        console.log('clicked activate button')
      },
      buttonEnabled: true,
      buttonColor: 'brown',
      buttonFresh: false
    },
    {
      title: 'Mark as Hired',
      buttonText: 'Hired!',
      buttonAction: undefined,
      buttonEnabled: false,
      buttonColor: undefined,
      buttonFresh: undefined
    }
  ]

  const applicantDetailDocumentLibraryList = [
    {
      title: 'Contitional Offer',
      link: '/somewhere-linktofile',
      enabled: true
    },
    {
      title: 'Application',
      link: '/somewhere-linktofile',
      enabled: true
    },
    {
      title: 'Compliance Documents',
      link: '/somewhere-linktofile',
      enabled: true
    },

    {
      title: 'Criminal Questionnaire',
      link: '/somewhere-linktofile',
      enabled: true
    },
    {
      title: 'Drug Test',
      link: '/somewhere-linktofile',
      enabled: false
    },
    {
      title: 'Motor Vehicle Report',
      link: '/somewhere-linktofile',
      enabled: false
    },
    {
      title: 'Background Check',
      link: '/somewhere-linktofile',
      enabled: false
    }
  ]

  const preAdverseActionLogDetails = {
    automateFinalAdverseAction: true,
    showFinalAdverseAction: true,
    showResetTimerButton: true,
    preAdverseActionDetails: {
      date: '10/18/2022',
      offense: 'Fraud',
      timeSince: '1 - 5 Years',
      flags: [
        'Credit History',
        'Reference Information',
        'Application Inconsistency'
      ],
      offense2: 'Position of Trust',
      notificationSent: true,
      applicantStatus: 'No Response',
      responseDate: 'N/A',
      timeElapsedVal: '7',
      timeElapsedIncrement: 'Days',
      expirationDate: '10/18/2022'
    },
    finalAdverseActionDetails: {
      date: '10/18/2022',
      offense: 'Fraud',
      timeSince: '1 - 5 Years',
      flags: [
        'Credit History',
        'Reference Information',
        'Application Inconsistency'
      ],
      offense2: 'Position of Trust',
      notificationSent: false
    }
  }

  return (
    <PlaygroundWrapper>
      <Router history={history}>
        <SidebarNav buttons={sidebarButtons} picture={user.picture} />
      </Router>
      <ContentWrapper>
        {/* <DrawerNav title='Jobs' /> */}
        <Content>
          <h1>TESTING PLAYGROUND</h1>
          <TilesWrapper>
            {alertTiles.map((tile, i) => (
              <DashboardTile key={i} tile={tile} />
            ))}
          </TilesWrapper>
          <h2>ATS Jobs</h2>
          <TableWrapper>
            <DashboardJobsTable data={jobsTableData} />
          </TableWrapper>
          <TilesWrapper fullWidth={fullWidthTiles}>
            <div className='tile-controls'>
              <h2>Priority Jobs</h2>
              <span
                className={`change-view ${fullWidthTiles ? 'blue' : ''}`}
                onClick={() => {
                  setFullWidthTiles(true)
                }}
              >
                <IconRows />
              </span>
              <span
                className={`change-view ${fullWidthTiles ? '' : 'blue'}`}
                onClick={() => {
                  setFullWidthTiles(false)
                }}
              >
                <IconGrid />
              </span>
            </div>
            {priorityJobTiles.map((tile, i) => (
              <DashboardPriorityJobTile
                key={i}
                tile={tile}
                fullWidth={fullWidthTiles}
              />
            ))}
          </TilesWrapper>
          <h2>Job Details</h2>
          <JobDetails details={jobDetailsData} />
          <h2>HR Management</h2>
          <HRManagement />
          <h2>Products</h2>
          <ProductsList products={products} />
          <h2>Job Details Stats</h2>
          <JobDetailStats details={jobDetailsData} />
          <h2>Hires Table</h2>
          <HiresTable data={hiresTableData} />
          <h2>Applicants Table</h2>
          <ApplicantsTable data={applicantsTableData} />
          <h2>Applicant Detail</h2>
          <ApplicantDetails details={applicantDetailsData} />
          <ApplicantDetailTimeline timeline={applicantDetailsTimeline} />
          <h2>Alerts and Notifications Table</h2>
          <ApplicantDetailAlertsAndNotifications
            data={alertsAndNotificationsTableData}
          />
          <div className='cols'>
            <ApplicantDetailActions actions={applicantDetailActionsList} />
            <ApplicantDetailDocumentLibrary
              documents={applicantDetailDocumentLibraryList}
            />
          </div>
          <h2>Pre-Adverse Action Log</h2>
          <PreAdverseActionLog details={preAdverseActionLogDetails} />
          <h2>Pre-Adverse Action Assessment</h2>
          <PreAdverseActionAssessment
            flags={[
              {
                id: 1,
                date: '10/18/2022',
                type: null,
                title: 'Background Check : ',
                details: 'Social Security number does not match name.',
                setSelect: () => { }
              },
              {
                id: 2,
                date: '10/18/2022',
                type: null,
                title: 'Criminal Check : ',
                details: 'Misdemeanor - Guilty',
                setSelect: () => { }
              }
            ]}
            selectedFlags={[1]}
            assess={[
              {
                val: 1,
                label: 'Criminal History',
                setSelect: () => { }
              },
              {
                val: 2,
                label: 'Credit History',
                setSelect: () => { }
              },
              {
                val: 3,
                label: 'Employment History',
                setSelect: () => { }
              },
              {
                val: 4,
                label: 'Reference Information',
                setSelect: () => { }
              },
              {
                val: 5,
                label: 'Application Inconsistency',
                setSelect: () => { }
              },
              {
                val: 6,
                label: 'Driving Record',
                setSelect: () => { }
              },
              {
                val: 7,
                label: 'Unable to Verify Social Security Number',
                setSelect: () => { }
              },
              {
                val: 8,
                label: 'Other',
                setSelect: () => { }
              }
            ]}
            assesses={[
              { val: 1, label: 'Criminal History' },
              { val: 2, label: 'Credit History' },
              { val: 3, label: 'Employment History' },
              { val: 4, label: 'Reference Information' },
              { val: 5, label: 'Application Inconsistency' },
              { val: 6, label: 'Driving Record' },
              { val: 7, label: 'Unable to Verify Social Security Number' },
              { val: 8, label: 'Other' }
            ]}
            selectedAssess={[1, 2, 5]}
            timeSinceOffense='2-5 Years'
            setTimeSinceOffense={() => { }}
            businessImplications='Position of Trust'
            setBusinessImplications={() => { }}
            confirmedEmailText
            setConfirmedEmailText={() => { }}
            applicantNoticeText={`Dear Roberta Callaghan,

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consectetur a erat nam at lectus urna. Sed viverra tellus in hac habitasse. Convallis tellus id interdum velit laoreet. Hac habitasse platea dictumst vestibulum. Facilisi etiam dignissim diam quis. Id leo in vitae turpis massa sed elementum tempus egestas. Senectus et netus et malesuada fames. Urna cursus eget nunc scelerisque viverra mauris. Lacinia quis vel eros donec ac odio tempor. Nullam non nisi est sit amet.

Mattis enim ut tellus elementum sagittis vitae et leo duis. Lectus quam id leo in vitae. Sit amet commodo nulla facilisi nullam vehicula. Ut sem viverra aliquet eget sit amet tellus. Neque viverra justo nec ultrices dui sapien eget mi proin. Pulvinar pellentesque habitant morbi tristique senectus et netus et. Aliquet bibendum enim facilisis gravida neque. Congue mauris rhoncus aenean vel elit scelerisque mauris. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Varius quam quisque id diam vel quam elementum pulvinar etiam. Pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Non blandit massa enim nec. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Diam maecenas sed enim`}
            setApplicantNoticeText={() => { }}
            notes={`• Reached out via phone 7 times across numerous days.
• Called multiple numbers.
• No response on any calls.`}
            setNotes={() => { }}
            details={{
              applicantName: 'Roberta Callaghan',
              jobTitle: 'Project Manager - Manufacturing',
              flags: [
                'Credit History',
                'Reference Information',
                'Application Inconsistency'
              ],
              offense1: 'Position of Trust',
              offense2: 'Fraud',
              timeSince: '1 - 5 Years',
              notes:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consectetur a erat nam at lectus urna. Sed viverra tellus in hac habitasse. Convallis tellus id interdum velit laoreet. Hac habitasse platea dictumst vestibulum. Facilisi etiam dignissim diam quis. Id leo in vitae turpis massa sed elementum tempus egestas. Senectus et netus et malesuada fames. Urna cursus eget nunc scelerisque viverra mauris. Lacinia quis vel eros donec ac odio tempor. Nullam non nisi est sit amet. Mattis enim ut tellus elementum sagittis vitae et leo duis. Lectus quam id leo in vitae. Sit amet commodo nulla facilisi nullam vehicula. Ut sem viverra aliquet eget sit amet tellus. Neque viverra justo nec ultrices dui sapien eget mi proin.'
            }}
            submitData={() => {}}
          />
        </Content>
      </ContentWrapper>
    </PlaygroundWrapper>
  )
}

document.addEventListener('DOMContentLoaded', () => { })

export { Playground }
