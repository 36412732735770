import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { BarLoader } from 'react-spinners'

import { useTasksActions } from '../../_actions'

import AdminWrapper from '../shared/AdminWrapper'

const TaskShow = () => {
  const { taskId } = useParams()
  const [task, setTask] = useState({ id: taskId })

  const [isLoading, setIsLoading] = useState(true)
  const taskActions = useTasksActions(
    localStorage.getItem('access_token')
  )

  useEffect(() => {
    taskActions.get(taskId).then((apiData) => {
      setTask(apiData.task)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminWrapper>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : <div>
          <h1>
            Task
          </h1>
          <ul>
            <li>{task?.id}</li>
            <li>{task?.title}</li>
          </ul>
        </div>}
    </AdminWrapper>
  )
}

export default TaskShow
