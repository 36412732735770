import React from 'react'
import './index.css'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ProviderWithHistory } from './Auth0/ProviderWithHistory'

// setup fake backend
// import { fakeBackend } from './_helpers';
// fakeBackend();

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <ProviderWithHistory>
        <App />
      </ProviderWithHistory>
    </BrowserRouter>
  </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
