import { ApplicantWrapper } from '../../../dashboard/ApplicantWrapper'
import { FormContentWrapper } from '../../shared/FormContentWrapper'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { job as jobAtom } from '../../../_state'
import { useRecoilValue } from 'recoil'
import { StateProvider } from '../../../contexts/useStateContext'
import { useState, useEffect } from 'react'
import { useReloadJob, usealiasFormatter } from '../../../_helpers'
import * as Yup from 'yup'
import {
  ApplyInitials,
  ApplySignature,
  ApplySignatureValidationSchema, HookFormToggleSwitch
} from '../../shared/HookFormComponents'
import { useTasksActions } from '../../../_actions'

const AuthorizationOfReleaseOfInformation = () => {
  useEffect(() => {
    const taskId = job.tasks.find(item => item.title === 'Personal Information').id
    taskActions.get(taskId)
      .then((ret) => {
        if (ret.data !== null) {
          setState(ret.data)
        }
      })
  }, [])

  const { id } = useParams()
  const job = useRecoilValue(jobAtom)
  const [state, setState] = useState({})
  const { backToJob } = useReloadJob()
  const taskActions = useTasksActions(localStorage.getItem('access_token'))
  const aliasFormatter = usealiasFormatter()
  // form validation rules
  const validationSchema = Yup.object().shape(ApplySignatureValidationSchema)

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: state,
    values: state
  }

  const methods = useForm(formOptions)

  const onSubmit = (_data) => {
    const submission = {
      data: _data
    }
    taskActions.update(id, submission)
      .then((av) => {
        backToJob(job.id)
      })
  }

  const onError = (errors, e) => {
    console.log(errors, e)
  }

  return (
    <>
      <ApplicantWrapper>
        <FormContentWrapper>
          <StateProvider state={state} setState={setState}>
            <FormProvider {...methods}>
              <form
                className='form-wrapper'
                onSubmit={methods.handleSubmit(onSubmit, onError)}
              >
                <h1>Compliance</h1>
                <h3>Authorization of Release of Information</h3>
                <div className='compliance'>
                  <div><span className='bold'>Last Name:</span> {state.lastName}</div>
                  <div><span className='bold'>First Name:</span> {state.firstName}</div>
                  <div><span className='bold'>Middle Name:</span> {state.middleName}</div>
                </div>
                <div className='compliance'>
                  <div><span className='bold'>Date of Birth:</span> {state.dateOfBirth}</div>
                  <div><span className='bold'>Social Security Number:</span> {state.ssn}</div>
                </div>
                <div className='compliance'>
                  <div>
                    <span className='bold'>Alias of Prior Names:</span>
                    {aliasFormatter.formatAliases(state.aliases)}
                  </div>
                </div>
                <ApplySignature>
                  <div className='compliance'>In consideration for processing my application for employment or, if hired by the employer
                    named below or a subsidiary, I, the undersigned, whose name and personal identification information voluntarily
                    appears above, do hereby and irrevocably agree to the following:
                  </div>
                  <div className='compliance'>1. I hereby authorize the Nevada Department of Public Safety, the Las Vegas Metropolitan Police Department and any other agency of criminal justice, to search for and release criminal history record information to the employer, prospective employer or their designee named below. In giving this authorization, I expressly understand that the information may include information pertaining to notations of arrest, detainments, indictments, information or other charges for which the final court disposition is pending or is unknown to the above referenced agencies. For records containing final court disposition information, I understand that the release may include information pertaining to dismissals, acquittals, convictions, sentences, correctional supervision information and information concerning the status of my parole or probation when applicable. Further, I understand that the information may include similar information obtained from other local, state and federal criminal justice agencies and may include information pertaining to
                    https://x3lfuqmf.paperform.co/ Page 1 of 4
                    CA Background 4/11/23, 12:06
                    convicted person data, outstanding arrest warrants, missing persons, and current and/or prior gaming and non-gaming sheriff's work cards that were issued to me.
                  </div>
                  <div className='compliance'>
                    2. In giving the above authorization, I understand that all information provided may be reviewed by the employer, prospective employer, his designee(s) in Human Resources, contracted background screening companies and/or any other person approved by the Nevada Department of Public Safety to make an informed employment decision on behalf of the employer named below. This information is confidential, and may not be further disseminated without my expressed written permission or an order from a court of law having jurisdiction. *
                  </div>
                  <ApplyInitials />
                  <div className='compliance'>
                    3. I understand that I may review and challenge the accuracy of any and all criminal history records which are returned to the employer or their designee, and that the proper forms and procedures will be furnished to me by the Nevada Department of Public Safety upon request.
                  </div>
                  <div className='compliance'>
                    4. I hereby release from liability and promise to hold harmless under any and all causes of legal action, the State of Nevada, its officer(s), agent(s) and/or employee(s) who conducted my criminal history records search and provided information to the requestor for any statement(s), omission(s), or infringement(s) upon my current legal rights. I further release and promise to hold harmless and covenant not to sue any persons, firms, institutions or agencies providing such information to the State of Nevada on the basis of their disclosures. I have signed this release voluntarily and of my own free will.
                  </div>
                  <div className='compliance'>
                    A reproduction of this authorization for release of information by photocopy, facsimile or similar process, shall for all purposes be as valid as the original.
                  </div>
                </ApplySignature>
                <HookFormToggleSwitch state={state} setState={setState} name='final' label='I am finished and ready to submit' />
                <div className='input-element'>
                  <button
                    type='button'
                    onClick={() => {
                      backToJob(job.id)
                    }}
                  >
                    Go Back
                  </button>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </form>
            </FormProvider>
          </StateProvider>
        </FormContentWrapper>
      </ApplicantWrapper>
    </>
  )
}

export default AuthorizationOfReleaseOfInformation
