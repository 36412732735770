import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useArrayContext } from '../../../contexts/useArrayContext'
import { useStateContext } from '../../../contexts/useStateContext'

const HookFormArraySelect = ({
  label,
  fieldName,
  options,
  preOnChange,
  postOnchange,
  defaultValueIndex = 0
}) => {
  const { arrayName, index } = useArrayContext()
  const { control, formState, setValue } = useFormContext()
  const { errors } = formState
  const { state, setState } = useStateContext()

  useEffect(() => {
    if (!state[arrayName][index][fieldName] || defaultValueIndex !== 0) {
      setValue(`${arrayName}.${index}.${fieldName}`, options[defaultValueIndex].value)
      const newList = [...state[arrayName]].map((item, index) => {
        if (index === 0) return { ...item, [fieldName]: options[defaultValueIndex].value }
        else return item
      })
      setState({ ...state, [arrayName]: newList })
    }
  }, [])

  const onChange = (e, id) => {
    if (preOnChange) preOnChange(e, id)

    const newList = [...state[arrayName]].map((item, index) => {
      if (index === id) return { ...item, [fieldName]: e.target.value }
      else return item
    })
    setState({ ...state, [arrayName]: newList })

    if (postOnchange) postOnchange(e, id)
    return e.target.value
  }

  return (
    <>
      <div className='form-element'>
        <label>{label}</label>
        <Controller
          control={control}
          name={`${arrayName}.${index}.${fieldName}`}
          defaultValue={options[0].value}
          render={({ field }) => (
            <select
              {...field}
              onChange={(e) => field.onChange(onChange(e, index))}
              className='input-inline'
            >
              {options.map((v) => (
                <option
                  key={field.id + field.name + v.value}
                  label={v.label}
                  value={v.value}
                />
              ))}
            </select>
          )}
        />
      </div>
      <div className='invalid-feedback'>
        {errors?.[arrayName]?.[index]?.[fieldName]?.message}
      </div>
    </>
  )
}

export { HookFormArraySelect }
