import '../../email/email.css'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useTasksActions, useUserActions } from '../../_actions'
import { userAtom } from '../../_state'
import logo from '../../email/employifi-logo.svg'
import { useParams } from 'react-router-dom'

const AdverseActionNotification = React.forwardRef(({ job, register }, ref) => {
  return (
    <>pewpew</>
  )
})

AdverseActionNotification.displayName = 'AdverseActionNotification'
export { AdverseActionNotification }
