import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useLocationActions, useCompanyActions } from '../../_actions'
import { BarLoader } from 'react-spinners'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../_state'
import CurrencyInput from 'react-currency-input-field'
const AdminJobDetailsWrapper = styled.div`
  margin-top: 30px;

  span {
    display: block;
    margin-bottom: 35px;
  }

  .job-details-title {
    font-size: 28px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 20px;
    display: block;
  }
`

const JobDetailsForm = styled.div`
  width: 100%;

  form {
    display: flex;
    max-width: 1000px;
    padding-bottom: 50px;

    input,
    select {
      font-family: 'Montserrat';
    }

    input[type='radio'] {
      margin-top: 0;
      width: 15px;
      height: 15px;
    }

    input[type='checkbox'] {
      width: 15px;
      height: 15px;
    }

    input[type='text'] {
      height: 40px;
      box-sizing: border-box;
    }

    button[type='submit'] {
      background-color: #81bcbf;
      box-shadow: 0px 20px 50px #0000004d;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 25px;
      min-width: 200px;
      border-radius: 25px;
      border: none;
      margin-top: 25px;
    }

    fieldset {
      border: none;
    }

    .form-element {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      .small-select {
        width: 50%;
      }

      input[type='date'] {
        width: 40%;
      }

      input,
      select,
      textarea {
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #d4d3d3;
        color: #272d3b;
      }

      textarea {
        margin-top: 10px;
        min-height: 100px;
      }
    }

    .split > input {
      margin-right: 15px;
    }

    .form-element.split {
      fieldset,
      > div:first-of-type {
        display: flex;
        padding: 0;
      }

      fieldset > div:last-of-type {
        text-align: right;
      }

      input[type='radio'] {
        margin-left: 0;
        flex-grow: 0;
        width: 10%;
      }

      input[type='text'],
      select {
        width: 85%;
      }

      fieldset > div {
        flex: 1;
      }
    }

    #save_as_template {
      padding: 0;
      margin-top: 5px;
      display: flex;
      align-items: center;

      label,
      input {
        display: inline;
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
        margin-right: 10px;
      }

      label {
        margin-top: 7px;
      }
    }

    label {
      display: block;
      font-weight: bold;
      color: #272d3b;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  .left-col,
  .right-col {
    flex: 1;
  }

  .left-col {
    margin-right: 25px;
  }

  .invalid-feedback {
    position: absolute;
    color: #e41a1a;
    font-size: 12px;
    transform: translateX(-40px);
    top: 0;
    right: -40px;
    height: 30px;
    display: flex;
    align-items: center;
  }
`

export const AdminJobDetails = ({ template, saveJob, saveText, hideHeader }) => {
  const { locationId } = useParams()
  const user = useRecoilValue(userAtom)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )

  const [commission, setCommission] = useState(template?.commission || '1')
  const [hiringManagerName, setHiringManagerName] = useState(null)
  const [hiringManagerTitle, setHiringManagerTitle] = useState(null)
  const [conditionalOfferExpirationDays, setConditionalOfferExpirationDays] = useState(0)
  const [location_id, setLocationId] = useState(template?.location_id || locationId
    ? String(template?.location_id || locationId)
    : '')
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const [showNameTemplate, setShowNameTemplate] = useState(false)
  const [locationsLoading, setLocationsLoading] = useState(true)
  const [departments, setDepartments] = useState([])
  const [locations, setLocations] = useState({ title: 'Loading' })
  const [vacationError, setVacationError] = useState()
  const [vacationManual, setVacationManual] = useState(
    template?.vacation_type === 'vacation-text-choice' ? template?.vacation : ''
  )
  const [vacationDropDown, setVacationDropDown] = useState(
    template?.vacation_type === 'vacation-dropdown-choice'
      ? template?.vacation
      : ''
  )
  const [commissionNotesError, setCommissionNotesError] = useState()
  const [departmentLocationError, setDepartmentLocationError] = useState()
  const [templateName, setTemplateName] = useState('')

  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    department_id: Yup.number().required('Department is required'),
    location_id: Yup.number().required('Department is required'),
    employment_type: Yup.number().required('Employment Type is required'),
    pay_structure: Yup.number().required('Pay Structure is required'),
    start_date: Yup.date().required('Start Date is required'),
    amount: Yup.string().required('Amount is required'),
    amount_type: Yup.number().required('Amount Type is required'),
    vacation_type: Yup.string().required('Vacation Type is required'),
    bonus: Yup.string(),
    commission_notes: Yup.string(),
    exempt_status: Yup.number().required('Exempt Status is required'),
    hiring_manager_name: Yup.string().required('Hiring manager name is required'),
    hiring_manager_title: Yup.string().required('Hiring manager title is required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  /* useEffect(() => {
    companyActions.getCompany(user.company.id).then((companyApi) => {
      console.log(companyApi)
      //setDays(companyApi?.company?.conditional_offer_expiration_days)
      //setIsLoading(false)
    })
  }, []) */

  const onSubmit = (data) => {
    // Validate and set Vacation
    let vacation_error = false
    const vacation =
      data.vacation_type === 'vacation-text-choice'
        ? vacationManual
        : vacationDropDown
    if (!vacation) {
      vacation_error = true
      setVacationError('Vacation is required')
    }
    if (vacation_error) {
      return
    }

    // Validate Commision Notes
    if (data.commission === 2 && !data.commission_notes) {
      setCommissionNotesError('Commission Notes is required')
      return
    }

    // Validate department and location line up
    const location = locations.find(
      (l) => parseInt(l.id) === parseInt(data.location_id)
    )
    const validDepartmentIds =
      location?.departments?.map((d) => parseInt(d.id)) || []
    if (!validDepartmentIds.includes(parseInt(data.department_id))) {
      setDepartmentLocationError('Department Not in Location')
      return
    }
    saveJob && saveJob.saveJob(
      { ...data, vacation, commission },
      showNameTemplate ? templateName : undefined
    )
    saveJob.closeModal()
  }

  const handleSaveTemplateCheckboxChange = (e) => {
    setShowNameTemplate(e.target.checked)
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const apiLocations = await locationActions.getLocationList(
        user?.company?.id
      )
      if (location_id === 'null') {
        setLocationId(apiLocations[0]?.id)
      }
      setLocations(apiLocations)
      setDepartments(apiLocations?.flatMap((l) => l.departments.map((d) => { return { ...d, location_id: l.id } })))
      setLocationsLoading(false)
    }
    fetchLocations()
    companyActions.getCompany(user.company.id).then((companyApi) => {
      setConditionalOfferExpirationDays(companyApi.company.conditional_offer_expiration_days)
    })
  }, [])
  return (
    <AdminJobDetailsWrapper>
      {!hideHeader && <span>
        Create a new job utilizing the form below. All fields are required
      </span>}
      <JobDetailsForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='left-col'>
            <div className='form-element'>
              <label>Title</label>
              <input
                defaultValue={template?.title || ''}
                {...register('title')}
                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                name='title'
                type='text'
              />
              <div className='invalid-feedback'>{errors.title?.message}</div>
            </div>
            {locationsLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <div className='form-element'>
                  <label>Location</label>
                  <select
                    {...register('location_id')}
                    className={`form-control ${errors.location_id
                      ? 'is-invalid'
                      : `${template?.location_id}`
                      }`}
                    name='location_id'
                    value={location_id}
                    onChange={(e) => { setLocationId(e.target.value) }}
                  >
                    <option value='' disabled>
                      Please select
                    </option>
                    {locations.map((d) => {
                      return <option key={d.id} value={`${d.id}`}>{d.title}</option>
                    })}
                  </select>
                  <div className='invalid-feedback'>
                    {errors.location_id?.message}
                  </div>
                </div>
                )}
            <div className='form-element'>
              <label>Employment Type</label>
              <select
                defaultValue={
                  template?.employment_type
                    ? String(template?.employment_type)
                    : ''
                }
                {...register('employment_type')}
                className={`form-control ${errors.employment_type
                  ? 'is-invalid'
                  : `${template?.employment_type}`
                  }`}
                name='employment_type'
              >
                <option value='' disabled>
                  Please select
                </option>
                <option value='1'>Full Time</option>
                <option value='2'>Part Time</option>
              </select>
              <div className='invalid-feedback'>
                {errors.employment_type?.message}
              </div>
            </div>
            <div className='form-element'>
              <label>Pay Structure</label>
              <select
                defaultValue={
                  template?.pay_structure ? String(template?.pay_structure) : ''
                }
                {...register('pay_structure')}
                className={`form-control ${errors.pay_structure ? 'is-invalid' : ''
                  }`}
                name='pay_structure'
              >
                <option value='' disabled>
                  Please select
                </option>
                <option value='1'>Weekly</option>
                <option value='2'>Bi-Weekly</option>
                <option value='4'>Semi-Monthly</option>
                <option value='3'>Monthly</option>
              </select>
              <div className='invalid-feedback'>
                {errors.pay_structure?.message}
              </div>
            </div>
            <div className='form-element'>
              <label>Commission</label>
              <select
                name='commission'
                {...register('commission')}
                value={commission}
                onChange={(e) => { setCommission(e.target.value) }}
                className={`form-control ${errors.commission ? 'is-invalid small-select' : 'small-select'
                  }`}
              >
                <option value='1'>No</option>
                <option value='2'>Yes</option>
              </select>
              {String(commission) === '2' &&
                <textarea
                  defaultValue={template?.commission_notes || ''}
                  name='commission_notes'
                  {...register('commission_notes')}
                  className={`form-control ${errors.commission ? 'is-invalid ' : ''
                    }`}
                  onChange={() => {
                    setCommissionNotesError()
                  }}
                />}
              <div className='invalid-feedback'>
                {errors.commission?.message}
              </div>
              <div className='invalid-feedback'>{commissionNotesError}</div>
            </div>
            <div className='form-element'>
              <label>Hiring Manager Name</label>
              <input
                name='hiring_manager_name'
                {...register('hiring_manager_name')}
                value={hiringManagerName || ''}
                onChange={(e) => { setHiringManagerName(e.target.value) }}
                className={`form-control ${errors.hiring_manager_name ? 'is-invalid small-select' : 'small-select'
                  }`}
              />
            </div>
            <div className='invalid-feedback'>{errors.hiring_manager_name?.message}</div>
            <div className='form-element'>
              <label>Hiring Manager Tiitle</label>
              <input
                name='hiring_manager_title'
                {...register('hiring_manager_title')}
                value={hiringManagerTitle || ''}
                onChange={(e) => { setHiringManagerTitle(e.target.value) }}
                className={`form-control ${errors.hiring_manager_title ? 'is-invalid small-select' : 'small-select'
                  }`}
              />
            </div>
            <div className='invalid-feedback'>{errors.hiring_manager_title?.message}</div>
            {!template && (
              <div className='form-element'>
                <fieldset id='save_as_template'>
                  <input
                    type='checkbox'
                    value='save-as-template'
                    name='save-as-template'
                    onChange={handleSaveTemplateCheckboxChange}
                  />
                  <label>Save Job as Template</label>
                </fieldset>
              </div>
            )}
            {showNameTemplate && (
              <div className='form-element'>
                <label>Name Template</label>
                <input
                  value={templateName || ''}
                  name='name_template'
                  type='text'
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
            )}
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-primary'
            >
              {isSubmitting && (
                <span className='spinner-border spinner-border-sm mr-1' />
              )}
              {saveText || 'Continue'}
            </button>
          </div>

          {/* // RIGHT COL */}
          <div className='right-col'>
            <div className='form-element'>
              <label>Department</label>
              {locationsLoading
                ? (
                  <div style={{ paddingLeft: 20 }}>
                    <BarLoader color='#81BCBF' />
                  </div>
                  )
                : (
                  <select
                    defaultValue={
                      template?.department_id
                        ? String(template?.department_id)
                        : ''
                    }
                    {...register('department_id')}
                    className={`form-control ${errors.department_id ? 'is-invalid' : ''
                      }`}
                    name='department_id'
                  >
                    <option value='' disabled>
                      Please select
                    </option>
                    {departments.filter((d) => `${d.location_id}` === `${location_id}`).map((d) => {
                      return <option key={d.id} value={`${d.id}`}>{d.title}</option>
                    })}
                  </select>
                  )}
              <div className='invalid-feedback'>
                {errors.department_id?.message}
              </div>
              <div className='invalid-feedback'>{departmentLocationError}</div>
            </div>
            <div className='form-element'>
              <label>Start Date</label>
              <input
                defaultValue={template?.display_started_date || ''}
                {...register('start_date')}
                className={`form-control ${errors.start_date ? 'is-invalid' : ''
                  }`}
                type='date'
                id='start_date'
                name='start_date'
                min={new Date().toISOString().split('T')[0]}
                max='2030-12-31'
              />
              <div className='invalid-feedback'>
                {errors.start_date?.message}
              </div>
            </div>
            <div className='form-element split'>
              <label>Vacation</label>
              <fieldset id='vacation'>
                <div>
                  <input
                    defaultChecked={
                      template?.vacation_type === 'vacation-dropdown-choice'
                    }
                    type='radio'
                    value='vacation-dropdown-choice'
                    name='vacation_type'
                    {...register('vacation_type')}
                    className={`form-control ${errors.vacation_type ? 'is-invalid' : ''
                      }`}
                  />
                  <select
                    value={vacationDropDown}
                    name='vacation-dropdown'
                    onChange={(e) => {
                      setVacationError(undefined)
                      setVacationDropDown(e.target.value)
                    }}
                  >
                    <option value='' disabled>
                      Please select
                    </option>
                    <option value='1 Week'>1 Week</option>
                    <option value='2 Weeks'>2 Weeks</option>
                    <option value='3 Weeks'>3 Weeks</option>
                    <option value='4 Weeks'>4 Weeks</option>
                  </select>
                </div>
                <div>
                  <input
                    defaultChecked={
                      template?.vacation_type === 'vacation-text-choice'
                    }
                    type='radio'
                    value='vacation-text-choice'
                    name='vacation_type'
                    {...register('vacation_type')}
                    className={`form-control ${errors.vacation_type ? 'is-invalid' : ''
                      }`}
                  />
                  <input
                    name='vacation-text'
                    type='text'
                    value={vacationManual}
                    onChange={(e) => {
                      setVacationError(undefined)
                      setVacationManual(e.target.value)
                    }}
                  />
                </div>
              </fieldset>
              <div className='invalid-feedback'>{vacationError}</div>
            </div>
            <div className='form-element split'>
              <label>Amount</label>
              <div className='split'>
                <CurrencyInput
                  {...register('amount')}
                  className={`form-control ${errors.amount ? 'is-invalid' : ''
                    }`}
                  defaultValue={template?.amount ? template?.amount.replace(/,/g, '') : ''}
                  allowNegativeValue={false}
                  name='amount'
                />
                <select
                  defaultValue={
                    template?.amount_type ? String(template?.amount_type) : ''
                  }
                  {...register('amount_type')}
                  className={`form-control ${errors.amount_type ? 'is-invalid' : ''
                    }`}
                  name='amount_type'
                >
                  <option value='' disabled>
                    Please select
                  </option>
                  <option value='1'>Hourly</option>
                  <option value='2'>Yearly</option>
                </select>
              </div>
              <div className='invalid-feedback'>{errors.amount?.message}</div>
              <div className='invalid-feedback'>
                {errors.amount_type?.message}
              </div>
            </div>
            <div className='form-element'>
              <label>Exempt Status</label>
              <select
                defaultValue={
                  template?.exempt_status ? String(template?.exempt_status) : ''
                }
                {...register('exempt_status')}
                className={`form-control ${errors.exempt_status ? 'is-invalid' : ''
                  }`}
                name='exempt_status'
              >
                <option value='' disabled>
                  Please select
                </option>
                <option value='1'>Exempt</option>
                <option value='2'>Non Exempt</option>
              </select>
            </div>
            {locationsLoading
              ? (
                <div style={{ paddingLeft: 20 }}>
                  <BarLoader color='#81BCBF' />
                </div>
                )
              : (
                <div className='form-element'>
                  <label>Bonus</label>
                  <select
                    defaultValue={template?.bonus ? String(template?.bonus) : ''}
                    {...register('bonus')}
                    className={`form-control ${errors.bonus ? 'is-invalid' : ''}`}
                    name='bonus'
                  >
                    <option value=''>
                      No Bonus
                    </option>
                    {(locations[0]?.bonuses || []).map((b) => {
                      return <option key={b.id} value={`${b.amount}`}>{b.amount}</option>
                    })}
                  </select>
                </div>)}
            <div className='invalid-feedback'>{errors.bonus?.message}</div>
            <div className='form-element'>
              <label>Conditional Offer Expiration Days</label>
              <input
                name='conditional_offer_expiration_days'
                {...register('conditional_offer_expiration_days')}
                value={conditionalOfferExpirationDays}
                onChange={(e) => { setConditionalOfferExpirationDays(e.target.value) }}
                className={`form-control ${errors.conditional_offer_expiration_days ? 'is-invalid small-select' : 'small-select'
                  }`}
              />
            </div>
          </div>
        </form>
      </JobDetailsForm>
    </AdminJobDetailsWrapper>
  )
}
