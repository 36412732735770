import styled from 'styled-components'
import ToggleButton from 'react-toggle-button'

import { ReactComponent as IconCheck } from '../../resources/images/icons/circle-check-regular.svg'
import { ReactComponent as IconExclamation } from '../../resources/images/icons/exclamation-circle.svg'

const PreAdverseActionLogWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  background-color: #f1efed;
  min-height: 180px;
  flex-direction: column;
`
const LogHeader = styled.div`
  display: flex;
  background: #fff;
  padding: 15px 15px 20px 15px;

  > span:first-child {
    font-size: 26px;
    color: #272d3b;
    font-weight: bold;
  }
`

const ToggleDivWrapper = styled.div`
  display: flex;
  margin-left: auto;
  padding: 30px 10px 0 0;

  > div:first-child {
    display: inline;
    margin-right: 25px;
  }

  .toggle-label {
    font-weight: 500;
    padding-top: 5px;
  }
`

const ToggleWrapper = styled.div``

const AdverseAction = styled.div`
  display: flex;
  box-sizing: border-box;

  .detail-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 110px;
    flex: 1;

    &:nth-of-type(4) {
      min-width: 200px;
    }
  }

  .detail-label {
    color: #272d3b;
    margin-bottom: 10px;
  }

  .detail-list {
    color: #de7e53;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .detail-value {
    color: #de7e53;
    font-weight: normal;

    svg {
      width: 30px;
      margin-left: 10px;
    }
  }

  .sent svg {
    fill: #6ccc4f;
  }

  .not-sent svg {
    fill: red;
  }

  .row {
    padding: 15px 0;
    display: flex;
    font-size: 14px;
    font-weight: bold;
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
    color: #272d3b;
  }

  .left-col {
    width: calc(100% - 225px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid #dcdbdb;

    .row {
      &:nth-child(3) {
        border-top: 1px solid #e1dfdf;
        margin-top: auto;
        padding-bottom: 50px;

        .detail-wrapper {
          flex: 0;
          min-width: 200px;
        }
      }
    }

    .add-notes-button {
      border-radius: 20px;
      text-transform: uppercase;
      font-size: 12px;
      color: #334856;
      padding: 10px 25px;
      border: 1px solid #334856;
      font-weight: bold;
      font-family: 'Montserrat';
      width: max-content;
      margin: 10px 0;
      background-color: transparent;

      &:hover {
        background-color: #334856;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .right-col {
    width: 225px;
    margin-left: auto;
    text-align: center;
    display: flex;
    flex-direction: column;

    .block {
      font-weight: bold;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .label {
        font-size: 14px;
      }
    }

    .time-elapsed {
      background-color: #de7e53;
      color: #fff;
      height: 210px;
      box-sizing: border-box;

      .days-value {
        font-size: 60px;
      }
      .days-label {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .expiration-date {
      height: 150px;
      box-sizing: border-box;
      background-color: #e6e2df;

      .label {
        color: #272d3b;
        margin-bottom: 5px;
      }
      .date-value {
        font-size: 24px;
        color: #de7e53;
      }
      .reset-timer-button {
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 12px;
        color: #334856;
        padding: 10px 25px;
        border: 1px solid #334856;
        font-weight: bold;
        font-family: 'Montserrat';
        width: max-content;
        margin: 10px auto;
        background-color: #e6e2df;

        &:hover {
          background-color: #334856;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .final-adverse-action {
    background: #fff;
    width: 100%;
    padding: 20px;
  }
`

const SendNoticeButton = styled.button`
  border: 0px solid transparent;
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #81bcbf;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 20px 50px #0000004d;

  &:hover {
    background: #6a9c9f;
    cursor: pointer;
  }
`

const trackStyle = { height: '30px', width: '60px' }
const thumbStyle = { height: '30px', width: '30px' }

export const PreAdverseActionLog = ({ details }) => {
  const toggleAutomate = (val) => {
    // console.log('toggle the thing', val)
  }

  return (
    <PreAdverseActionLogWrapper>
      <LogHeader>
        <span>Log</span>
        <ToggleDivWrapper>
          <ToggleWrapper>
            {/* TODO: should probably componentize this thing */}
            <ToggleButton
              value={details.automateFinalAdverseAction || false}
              onToggle={(value) => {
                toggleAutomate(!value)
              }}
              trackStyle={trackStyle}
              thumbStyle={thumbStyle}
              inactiveLabel=''
              activeLabel=''
              colors={{
                active: {
                  base: 'rgb(134,187,190)',
                  hover: 'rgb(134,187,190)'
                },
                inactive: {
                  base: 'rgb(65,66,68)',
                  hover: 'rgb(95,96,98)'
                }
              }}
            />
          </ToggleWrapper>
          <span className='toggle-label'>Automate Final Adverse Action</span>
        </ToggleDivWrapper>
      </LogHeader>
      <AdverseAction>
        <div className='left-col'>
          <div className='heading'>Pre-Adverse Action</div>
          <div className='row'>
            <div className='detail-wrapper'>
              <span className='detail-label'>Date</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.date}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Offense</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.offense}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Time Since</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.timeSince}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Flags</span>
              <span className='detail-list'>
                {details.preAdverseActionDetails.flags.map((flag, i) => {
                  return <span key={i}>{flag}</span>
                })}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Offense</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.offense2}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Notification Sent</span>
              <span
                className={`detail-value ${
                  details.preAdverseActionDetails.notificationSent
                    ? 'sent'
                    : 'not-sent'
                }`}
              >
                {details.preAdverseActionDetails.notificationSent
                  ? (
                    <IconCheck />
                    )
                  : (
                    <IconExclamation />
                    )}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='detail-wrapper'>
              <span className='detail-label'>Applicant Status</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.applicantStatus}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Response Date</span>
              <span className='detail-value'>
                {details.preAdverseActionDetails.responseDate}
              </span>
            </div>
            <div className='detail-wrapper'>
              <span className='detail-label'>Response Notes</span>
              {!details.preAdverseActionDetails.responseNotes && (
                <button
                  className='add-notes-button'
                  onClick={() => {
                    // console.log('add notes button click')
                  }}
                >
                  Add Notes
                </button>
              )}
              {details.preAdverseActionDetails.responseNotes && (
                <span className='detail-value'>
                  {details.preAdverseActionDetails.responseNotes}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='right-col'>
          <div className='block time-elapsed'>
            <span className='label'>Time Elapsed</span>
            <span className='days-value'>
              {details.preAdverseActionDetails.timeElapsedVal}
            </span>
            <span className='days-label'>
              {details.preAdverseActionDetails.timeElapsedIncrement}
            </span>
          </div>
          <div className='block expiration-date'>
            <span className='label'>Expiration Date</span>
            <span className='date-value'>
              {details.preAdverseActionDetails.expirationDate}
            </span>
            {details.showResetTimerButton && (
              <button
                className='reset-timer-button'
                onClick={() => {
                  // console.log('reset timer click')
                }}
              >
                Reset Timer
              </button>
            )}
          </div>
        </div>
      </AdverseAction>
      {details.showFinalAdverseAction && (
        <AdverseAction>
          <div className='final-adverse-action'>
            <div className='heading'>Final Adverse Action</div>
            <div className='row'>
              <div className='detail-wrapper'>
                <span className='detail-label'>Date</span>
                <span className='detail-value'>
                  {details.finalAdverseActionDetails.date}
                </span>
              </div>
              <div className='detail-wrapper'>
                <span className='detail-label'>Offense</span>
                <span className='detail-value'>
                  {details.finalAdverseActionDetails.offense}
                </span>
              </div>
              <div className='detail-wrapper'>
                <span className='detail-label'>Time Since</span>
                <span className='detail-value'>
                  {details.finalAdverseActionDetails.timeSince}
                </span>
              </div>
              <div className='detail-wrapper'>
                <span className='detail-label'>Flags</span>
                <span className='detail-list'>
                  {details.finalAdverseActionDetails.flags.map((flag, i) => {
                    return <span key={i}>{flag}</span>
                  })}
                </span>
              </div>
              <div className='detail-wrapper'>
                <span className='detail-label'>Offense</span>
                <span className='detail-value'>
                  {details.finalAdverseActionDetails.offense2}
                </span>
              </div>
              <div className='detail-wrapper'>
                <span className='detail-label'>Notification Sent</span>
                <span
                  className={`detail-value ${
                    details.finalAdverseActionDetails.notificationSent
                      ? 'sent'
                      : 'not-sent'
                  }`}
                >
                  {details.finalAdverseActionDetails.notificationSent
                    ? (
                      <IconCheck />
                      )
                    : (
                      <IconExclamation />
                      )}
                </span>
              </div>
              {!details.finalAdverseActionDetails.notificationSent && (
                <div className='detail-wrapper'>
                  <SendNoticeButton
                    onClick={() => {
                      // console.log('send notice click')
                    }}
                  >
                    Send Notice
                  </SendNoticeButton>
                </div>
              )}
            </div>
          </div>
        </AdverseAction>
      )}
    </PreAdverseActionLogWrapper>
  )
}
