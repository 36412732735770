
const useTasksHelper = () => {
  const getPersonalInformation = (job) => {
    return job.tasks.find(t => t.title === 'Personal Information')
  }

  return { getPersonalInformation }
}

export { useTasksHelper }
