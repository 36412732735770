import { useStateContext } from '../../../contexts'
import { useFormContext } from 'react-hook-form'
import { RecoilInput } from '../../shared/HookFormComponents'
import React from 'react'

const DateOfBirth = ({ readOnly }) => {
  const { state } = useStateContext()
  const { formState } = useFormContext()
  return (
    <>
      <div className='form-element'>
        <label>Date of Birth</label>
        <RecoilInput
          id='dateOfBirth'
          subType='date'
          type='text'
          name='dateOfBirth'
          value={state.dateOfBirth}
          className={`form-control ${
            formState.errors.dateOfBirth ? 'is-invalid' : ''
          }`}
          readOnly={readOnly}
        />
        <div className='invalid-feedback'>
          {formState.errors.dateOfBirth?.message}
        </div>
      </div>
    </>
  )
}

export { DateOfBirth }
