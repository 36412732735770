import auth0 from 'auth0-js'

export class Auth {
  auth0 = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
    clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
    audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    redirectUri: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}`,
    scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
    responseType: 'token id_token'
  })

  constructor () {
    this.signin = this.signin.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  signin (username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        { realm: `${process.env.REACT_APP_AUTH0_REALM}`, username, password },
        (err, authResult) => {
          if (err) {
            return reject(err)
          }
          this.setSession(authResult, resolve)
        }
      )
    })
  }

  handleAuthentication () {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          return resolve()
        } else if (err) {
          return reject(err)
        }
      })
    })
  }

  setSession (authResult, resolve) {
    // Set the time that the access token will expire at

    this.auth0.client.userInfo(authResult.accessToken, function (err, user) {
      // This method will make a request to the /userinfo endpoint
      // and return the user object, which contains the user's information,
      // similar to the response below.
      if (user?.email_verified) {
        const expiresAt = JSON.stringify(
          authResult.expiresIn * 1000 + new Date().getTime()
        )
        localStorage.setItem('access_token', authResult.accessToken)
        localStorage.setItem('expires_at', expiresAt)
      } else {
        window.alert(
          'Please verify your email address, by clicking the confirm button from auth0 in your inbox.'
        )
      }
      resolve(user)
    })
  }

  isAuthenticated () {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return (
      new Date().getTime() < expiresAt &&
      localStorage.getItem('access_token') !== undefined
    )
  }
}
