import * as React from 'react'
import styled from 'styled-components'
import Table from 'rc-table'

import { ReactComponent as IconStar } from '../../resources/images/icons/star.svg'
import { ReactComponent as IconStarFilled } from '../../resources/images/icons/star-filled.svg'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

const ApplicantsTableWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #fff;
  border-radius: 15px;

  .rc-table {
    table {
      width: 100%;
      border-spacing: 0px;
      color: #272d3b;
      border-collapse: collapse;
    }

    thead {
      text-align: left;
      border-bottom: 1px solid #dfe0e2;

      tr:hover td {
        background: none;
      }

      tr .verified-indicator {
        text-align: center;
      }
    }

    tr {
      border-bottom: 1px solid #dfe0e2;

      &:hover td {
        background: #f3f6f7;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      position: relative;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 14px;

      white-space: normal;
      word-break: break-word;
      border-top: 0;
      border-left: 0;
      transition: box-shadow 0.3s;
    }

    .accepted {
      color: #de7e53;
    }

    .table-star {
      padding: 0 0 0 15px;

      svg .st0 {
        fill: #de7e53;
      }

      &:hover {
        cursor: pointer;
      }
      svg {
        width: 20px;
      }
    }

    .verified-indicator span {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #c8d1d3;

      &.verified {
        background-color: #81bcbf;
      }

      &.not-veriried {
        background-color: transparent;
      }
    }
  }
`

const SearchWrapper = styled.div`
  position: relative;

  svg {
    width: 25px;
    position: absolute;
    top: 28px;
    left: 30px;

    path {
      fill: #707070;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .search-input {
    border: 1px solid #b9afa4;
    border-radius: 20px;
    padding: 10px 20px 10px 45px;
    margin: 20px 0 0 20px;
    font-size: 16px;
  }
`

const TableHeader = styled.div`
  display: flex;
  padding: 20px;

  h3 {
    font-size: 26px;
    color: #272d3b;
    margin: 0;
    padding: 0;
  }
`

const TableHeaderButtons = styled.div`
  display: inline-flex;
  margin-left: auto;

  span {
    display: inline-block;
    font-size: 16px;
    padding: 10px 25px;
    color: #fff;
    background-color: #81bcbf;
    border-radius: 20px;
    margin-left: 10px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      background-color: #73a8ab;
    }
  }
`

interface ApplicantsTableProps {
  data: any
}

export const ApplicantsTable = ({ data }: ApplicantsTableProps) => {
  const [search, setSearch] = React.useState('')

  const handleSearch = (event: any) => {
    setSearch(event.target.value)
  }

  const filteredData =
    data && data.filter((item: any) => item.b.includes(search))

  const columns = [
    {
      title: '',
      dataIndex: 'a',
      key: 'a',
      width: 10,
      className: 'table-star',
      onCell: (record: any, index: any) => ({
        onClick(e: any) {
          // console.log('Click cell', ` row ${index}`, record, e.target)
        }
      }),
      render(rec: any) {
        return (
          <span className="star-wrapper">
            {rec.starred ? <IconStarFilled /> : <IconStar />}
          </span>
        )
      }
    },
    { title: 'Name', dataIndex: 'b', key: 'b' },
    {
      title: 'App Verified',
      dataIndex: 'c',
      key: 'c',
      className: 'verified-indicator',
      render(rec: any) {
        return <span className={rec ? 'verified' : 'not-verified'}></span>
      }
    },
    { title: 'Source', dataIndex: 'd', key: 'd' },
    { title: 'Applied', dataIndex: 'e', key: 'e' },
    { title: 'Updated', dataIndex: 'f', key: 'f' }
  ]

  const onRowClick = (record: any, index: number, event: any) => {
    // console.log(`Click nth(${index}) row of parent, record.name: ${record.b}`)
  }

  function clearSearch() {
    setSearch('')
  }

  return (
    <ApplicantsTableWrapper>
      <SearchWrapper>
        {search === '' ? (
          <IconSearch />
        ) : (
          <IconClose
            onClick={() => {
              clearSearch()
            }}
          />
        )}
        <label htmlFor="search">
          <input
            className="search-input"
            id="search"
            type="text"
            onChange={handleSearch}
            value={search}
            placeholder="Search"
          />
        </label>
      </SearchWrapper>
      <TableHeader>
        <h3>Applicants</h3>
        <TableHeaderButtons>
          <span
            onClick={() => {
              // console.log('clicked the filter button')
            }}>
            Filter
          </span>
          <span
            onClick={() => {
              // console.log('clicked the add new button')
            }}>
            + Add New
          </span>
        </TableHeaderButtons>
      </TableHeader>
      <Table
        columns={columns}
        data={filteredData}
        rowClassName={(record) => {
          return record.c === 'Accepted' ? `accepted` : ''
        }}
        rowKey={(record) => record.b}
        onRow={(record, index) => ({
          onClick: onRowClick.bind(null, record, index)
        })}
      />
    </ApplicantsTableWrapper>
  )
}
