import { Step } from './Step'

import * as Yup from 'yup'
import { GeneralEmployment } from '../GeneralEmployment'

const GeneralEmploymentInfo = (props) => {
  const validationSchema = Yup.object().shape({
    eligibleToWorkInUS: Yup.string().required('This field is required'),
    eighteenOrOlder: Yup.string().required('This field is required'),
    ableToPerformWithoutAccommodation: Yup.string().required(
      'This field is required'
    )
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps}>
        <h3>General Questions</h3>
        <GeneralEmployment readOnly={props.readOnly} />
      </Step>
    </>
  )
}
GeneralEmploymentInfo.componentName = 'GeneralEmploymentInfo'
export { GeneralEmploymentInfo }
