import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFakeData } from '../../../../_helpers'
import { References } from '../References'
import { Step } from './Step'

const ReferenceInfo = (props) => {
  const [prefill, setPrefill] = useState(false)

  useEffect(() => {

  }, [prefill])

  const referenceSchema = {
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    title: Yup.string().required('Title is Required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().required('Email is required'),
    to: Yup.string().required('To Date is required'),
    from: Yup.string().required('From Date is required')
  }

  const validationSchema = Yup.object().shape({
    references: Yup.array()
      .of(Yup.object().shape(referenceSchema))
      .min(3, 'You must have at least ${min} references')
  })
  const stepProps = { ...props, validationSchema }
  const { getReferenceStubData } = useFakeData()
  const setPreFillData = () => {
    console.log('Setting preFill data...')
    const stubData = getReferenceStubData()
    console.log(stubData)
    props.setState({ ...props.state, references: getReferenceStubData() })
    setPrefill(true)
  }
  return (
    <>
      <Step {...stepProps} isArray>
        {((process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost')) &&
          <div className='form-element'>
            <button type='button' className='btn btn-primary' onClick={() => setPreFillData()}>Prefill Data</button>
            <input type='hidden' name={`${name}-prefill`} value={prefill.string} />
          </div>}
        <h3>Professional References (Provide at least 3)</h3>
        <References name='references' readOnly={props.readOnly} />
      </Step>
    </>
  )
}
ReferenceInfo.componentName = 'ReferenceInfo'
export { ReferenceInfo }
