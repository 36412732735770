import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocationActions, useCompanyActions } from '../../_actions'
import { history } from '../../_helpers'
import AdminWrapper from '../shared/AdminWrapper'
import { ApplicantGenericTable } from '../../components/Applicant/ApplicantGenericTable'
import { AdminCompanyDashboardTableWrapper, TableSearchWrapper } from '../shared/AdminStyledComponets'
import { ReactComponent as IconSearch } from '../../resources/images/icons/magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../resources/images/icons/close.svg'

const AdminCompanyApplicants = () => {
  const { companyId } = useParams()
  const [locations, setLocations] = useState([])
  const [applicants, setApplicants] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const locationActions = useLocationActions(
    localStorage.getItem('access_token')
  )
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))
  const [applicantSearchText, setApplicantSearchText] = useState('')

  function clearSearch () {
    setApplicantSearchText('')
  }

  useEffect(() => {
    const fetchDashboardData = async () => {
      const locations = await locationActions.getLocationList(companyId)
      setLocations(locations)
      const apiapplicants = await companyActions.applicants(companyId)
      setApplicants(apiapplicants)

      setIsLoading(false)
    }
    fetchDashboardData()
  }, [])

  const applicantsColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render (val, row) {
        return (
          <div>
            <span className={val === 'Available' ? '' : 'inprogress'}>
              {val}
            </span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 300,
      className: 'select-button',
      render (template, row) {
        return (
          <div>
            <button
              onClick={() => {
                row.status === 'Available'
                  ? history.push(
                    `/admin/locations/${locations[0].id}/users/${row.id}/jobs/new`
                  )
                  : history.push(
                    `/admin/locations/${row.job_location_id}/users/${row.id}/jobs/${row.job_id}`
                  )
              }}
            >
              View
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <AdminWrapper>
      <TableSearchWrapper>
        {applicantSearchText === ''
          ? (
            <IconSearch />
            )
          : (
            <IconClose
              onClick={() => {
                clearSearch()
              }}
            />
            )}
        <input
          htmlFor='search'
          type='text'
          name='Locations'
          value={applicantSearchText}
          onChange={(e) => { setApplicantSearchText(e.target.value) }}
        />
        <div className='add-button-wrapper'>
          <button className='add-user-button' onClick={() => { history.push('/admin/applicants/new') }}>Add Applicant</button>
        </div>
      </TableSearchWrapper>
      <AdminCompanyDashboardTableWrapper>
        <ApplicantGenericTable
          columns={applicantsColumns}
          data={applicants.filter((a) => { return applicantSearchText === '' ? true : a.name.toLowerCase().includes(applicantSearchText.toLowerCase()) })}
          tableTitle='Applicants'
          rowKey='id'
          loading={isLoading}
        />
      </AdminCompanyDashboardTableWrapper>
    </AdminWrapper>
  )
}

export default AdminCompanyApplicants
