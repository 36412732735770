import { useState } from 'react'
import { history } from '../../_helpers'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useCompanyActions } from '../../_actions'

import states from '../../_helpers/states'

import AdminWrapper from '../shared/AdminWrapper'
import { BarLoader } from 'react-spinners'

import { FormContentWrapper, TabWrapper } from '../shared/AdminStyledComponets'

const CompaniesNew = () => {
  const companyActions = useCompanyActions(localStorage.getItem('access_token'))

  const [state, setState] = useState(states[0])
  const [isLoading, setIsLoading] = useState(false)

  // form validation rules
  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required('Display Name is required'),
    ein_number: Yup.string().matches(/^\d{2}-?\d{7}$/).required('EIN Number is required'),
    address: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    city: Yup.string().matches(/^[aA-zZ\s]+$/).required('City is required'),
    postal_code: Yup.string().length(5).matches(/^[0-9]{5}/).required('Postal Code is required'),
    contact_name: Yup.string().required('Contact Name is required'),
    contact_number: Yup.string().required('Contact Number is required'),
    erx_id: Yup.string()
  })
  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isSubmitting } = formState

  const onSubmit = (data) => {
    const saveCompany = async () => {
      setIsLoading(true)
      const newCompany = await companyActions.createCompany({
        ...data,
        state: state.value
      })
      setIsLoading(false)
      history.push(`/admin/companies/${newCompany.company.id}/edit`)
    }
    saveCompany()
  }

  return (
    <AdminWrapper>
      <h1>Create Company</h1>
      {isLoading
        ? (
          <div style={{ paddingLeft: 20 }}>
            <BarLoader color='#81BCBF' />
          </div>
          )
        : (
          <TabWrapper>
            <Tabs>
              <TabList>
                <Tab key='tab_1'>Company Information</Tab>
              </TabList>
              <TabPanel key='tabPanel_1'>
                <FormContentWrapper style={{ maxWidth: '800px' }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>Company Information</h2>
                    <div style={{ display: 'flex', gap: '40px' }}>
                      <div className='form-element' style={{ width: '60%' }}>
                        <label>Company Display Name</label>
                        <input
                          name='display_name'
                          type='text'
                          {...register('display_name')}
                          className={`form-control ${errors.display_name ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.display_name?.message}
                        </div>
                      </div>
                      <div className='form-element' style={{ width: '20%' }}>
                        <label>EIN Number</label>
                        <input
                          name='ein_number'
                          type='text'
                          {...register('ein_number')}
                          className={`form-control ${errors.ein_number ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>{errors.ein_number?.message}</div>
                      </div>
                      <div className='form-element' style={{ width: '20%' }}>
                        <label>ERX ID</label>
                        <input
                          name='erx_id'
                          type='text'
                          {...register('erx_id')}
                          className={`form-control ${errors.erx_id ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.erx_id?.message}
                        </div>
                      </div>
                    </div>
                    <h2>Company Address</h2>
                    <div className='form-element'>
                      <label>Company Address</label>
                      <input
                        name='address'
                        type='text'
                        {...register('address')}
                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.address?.message}</div>
                    </div>
                    <div className='form-element'>
                      <label>Company Address 2 (Suite No.)</label>
                      <input
                        name='address_2'
                        type='text'
                        {...register('address_2')}
                        className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.address_2?.message}</div>
                    </div>
                    <div className='form-element'>
                      <label>City</label>
                      <input
                        name='city'
                        type='text'
                        {...register('city')}
                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                      />
                      <div className='invalid-feedback'>{errors.city?.message}</div>
                    </div>
                    <div style={{ display: 'flex', gap: '40px' }}>
                      <div className='form-element'>
                        <label>State</label>
                        <Select
                          name='state'
                          required
                          value={state}
                          options={states}
                          isLoading={isLoading}
                          isDisabled={isLoading}
                          onChange={(newState) => setState(newState)}
                        />
                        <div className='invalid-feedback'>
                          {state === '' || state === undefined || state === null
                            ? 'State is required'
                            : ''}
                        </div>
                      </div>
                      <div className='form-element'>
                        <label>Postal Code</label>
                        <input
                          name='postal_code'
                          type='text'
                          {...register('postal_code')}
                          className={`form-control ${errors.postal_code ? 'is-invalid' : ''
                            }`}
                        />
                        <div className='invalid-feedback'>
                          {errors.postal_code?.message}
                        </div>
                      </div>
                    </div>
                    <h2>Contact Information</h2>
                    <div className='form-element'>
                      <label>Contact Name</label>
                      <input
                        name='contact_name'
                        type='text'
                        {...register('contact_name')}
                        className={`form-control ${errors.contact_name ? 'is-invalid' : ''
                          }`}
                      />
                      <div className='invalid-feedback'>
                        {errors.contact_name?.message}
                      </div>
                    </div>
                    <div className='form-element'>
                      <label>Contact Phone Number</label>
                      <input
                        name='contact_number'
                        type='text'
                        {...register('contact_number')}
                        className={`form-control ${errors.contact_number ? 'is-invalid' : ''
                          }`}
                      />
                      <div className='invalid-feedback'>
                        {errors.contact_number?.message}
                      </div>
                    </div>
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className='btn btn-primary'
                    >
                      {isSubmitting && (
                        <span className='spinner-border spinner-border-sm mr-1' />
                      )}
                      Create
                    </button>
                    <button
                      onClick={() => history.push('/admin/companies')}
                      className='btn btn-link whiteButton'
                    >
                      Cancel
                    </button>
                  </form>
                </FormContentWrapper>
              </TabPanel>
            </Tabs>
          </TabWrapper>
          )}
    </AdminWrapper>
  )
}

export default CompaniesNew
