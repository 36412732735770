import styled from 'styled-components'
import { JobDetail } from '../shared/JobDetail'
import { ReactComponent as IconEdit } from '../../resources/images/icons/edit2.svg'

const AdminApplicantInfoWrapper = styled.div`
  margin-bottom: 20px;

  .applicant-info-title {
    font-size: 28px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 20px;
    display: block;
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;
  display: flex;
  box-sizing: border-box;

  .details-group {
    display: flex;
    padding: 30px;
    width: 100%;

    div {
      flex-grow: 1;
      max-width: 250px;
    }
  }

  .edit {
    width: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border-left: 1px solid #ccc;

    &:hover {
      cursor: pointer;
    }
  }
`

export const AdminApplicantInfo = ({ details, edit, editClick }) => {
  return (
    <AdminApplicantInfoWrapper>
      <DetailsWrapper>
        <div className='details-group'>
          <JobDetail label='First Name' detail={details.firstName} />
          <JobDetail label='Last Name' detail={details.lastName} />
          <JobDetail label='Email' detail={details.email} />
          {/* <JobDetail label="Job/Position" detail={details.job_position || '-'} /> */}
        </div>
        {edit && (
          <span className='edit'>
            <IconEdit
              onClick={() => {
                editClick && editClick()
              }}
            />
          </span>
        )}
      </DetailsWrapper>
    </AdminApplicantInfoWrapper>
  )
}
