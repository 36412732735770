import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Alert from '../shared/Communication/Alert'

import { ReactComponent as IconAlert } from '../../resources/images/icons/alert.svg'
import { ReactComponent as IconComment } from '../../resources/images/icons/comment.svg'
import { useDBAlertsActions } from '../../_actions'

import TableWithHeaderAndAddButton from '../../admin/shared/TableWithHeaderAndAddButton'

import { useRecoilState } from 'recoil'
import { userAtom } from '../../_state'
import AlertsNew from '../../admin/alerts/new'

export const ApplicantDetailAlertsAndNotifications = ({ data, reloadAlerts }) => {
  const { userId, jobId } = useParams()
  const dbAlertActions = useDBAlertsActions(jobId)
  const [user] = useRecoilState(userAtom)

  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', width: 130 },
    {
      title: 'Type',
      dataIndex: 'display_type',
      key: 'display_type',
      width: 85,
      className: 'icon',
      render (type) {
        let icon
        switch (type) {
          case 'system_alert':
            icon = <IconAlert />
            break
          case 'internal':
            icon = <IconComment />
            break
          default:
            icon = <IconComment />
        }

        return (
          <>
            <span>{icon}</span>
          </>
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      className: 'details-td',
      render (_details, row) {
        return <Alert actions={dbAlertActions} alert={row} receiverText='Applicant' senderText='Me' />
      }
    }
  ]

  const [modalOpen, setModalOpen] = useState(false)
  function closeModal () {
    setModalOpen(false)
  }
  async function saveNotification (data) {
    const save = async () => {
      await dbAlertActions.add({
        ...data,
        job_id: jobId,
        sender_id: user?.id,
        recipient_id: userId,
        type: 'Notification'
      })
      console.log('reloadAlerts', reloadAlerts)
      reloadAlerts && await reloadAlerts()
    }
    await save()
    closeModal()
  }
  function openModal () {
    setModalOpen(true)
  }

  return (
    <TableWithHeaderAndAddButton
      headerText='Alerts and Notifications'
      modalOpen={modalOpen}
      closeModal={closeModal}
      openModal={openModal}
      modalContent={<AlertsNew save={saveNotification} />}
      modalTitleText='New Notification'
      columns={columns}
      data={data}
    />
  )
}
