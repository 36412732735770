import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { ArrayProvider, useStateContext } from '../../../contexts'
import {
  HookFormArrayInput,
  HookFormArrayTextArea,
  HookFormArrayRegionSelector,
  RadioGroup
} from '../../shared/HookFormComponents'

const TerminatedOrResigned = () => {
  return (
    <>
      <HookFormArrayInput
        fieldName='employerName'
        label='Please Provide Company Name'
        type='text'
      />
      <HookFormArrayInput
        fieldName='employeeJobTitle'
        label='Title'
        type='text'
      />
      <HookFormArrayInput fieldName='employerCity' label='City' type='text' />
      <HookFormArrayRegionSelector
        countryName='US'
        fieldName='employerState'
        label='State'
      />
      <HookFormArrayTextArea fieldName='details' label='...And Details' />
    </>
  )
}

const TerminatedOrResignedHistory = ({ name }) => {
  const { control, setValue } = useFormContext()

  const { state, setState } = useStateContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name
  })

  console.log(state)

  const watchTerminatedOrResigned = useWatch({
    control,
    name: 'terminatedOrResigned'
  })

  const addTerminatedOrResignedCompany = (c) => {
    const newList = state[name]
      ? state[name].map((item) => {
        return item
      })
      : []
    newList.push(c)
    setState({ ...state, [name]: newList })
  }

  const removeTerminatedOrResignedCompany = (idx) => {
    const cList = state[name].filter((e, i) => {
      return i !== idx
    })
    setState({ ...state, [name]: cList })
  }

  return (
    <>
      <RadioGroup
        name='terminatedOrResigned'
        values={['Yes', 'No']}
        label='Have you ever been terminated from employment or asked to resign?'
      />
      {watchTerminatedOrResigned === 'Yes' && (
        <div className='form-element'>
          <button
            type='button'
            onClick={() => {
              append()
              addTerminatedOrResignedCompany({})
            }}
          >
            + Add Details
          </button>
        </div>
      )}
      {watchTerminatedOrResigned === 'Yes' &&
        fields.map((item, index) => (
          <div key={item.id} className='entry-wrapper'>
            <ArrayProvider index={index} arrayName={name}>
              <div className='form-element' key={item.id}>
                <TerminatedOrResigned id={item.id} />
              </div>
            </ArrayProvider>
            <button
              type='button'
              onClick={() => {
                if (fields.length <= 1) {
                  setValue('terminatedOrResigned', 'No')
                }
                remove(index)
                removeTerminatedOrResignedCompany(index)
              }}
            >
              - Remove Details
            </button>
          </div>
        ))}
    </>
  )
}

export { TerminatedOrResignedHistory }
