import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

const jobs = atom({
  key: 'Jobs',
  default: []
})

const job = atom({
  key: 'Job',
  default: undefined,
  effects: [persistAtom]
})

export { jobs, job }
