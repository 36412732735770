import { Router } from 'react-router-dom'
import { history } from '../_helpers'
import styled from 'styled-components'

import { ReactComponent as IconHome } from '../resources/images/icons/house.svg'
import { ReactComponent as IconFolder } from '../resources/images/icons/folder.svg'
import { ReactComponent as IconNewPerson } from '../resources/images/icons/icon_new_person.svg'
import { ReactComponent as IconAward } from '../resources/images/icons/award.svg'
import { ReactComponent as IconSettings } from '../resources/images/icons/settings.svg'
import { ReactComponent as IconGroup } from '../resources/images/icons/group.svg'
import { ReactComponent as IconMagnifyingGlass } from '../resources/images/icons/magnifying-glass.svg'

import { SidebarNav } from '../components/SidebarNav'
import { DashboardTile } from '../components/DashboardTile'
import { AdminQuickAction } from '../components/Admin/AdminQuickAction'
import { AdminApplicantsSearch } from '../components/Admin/AdminApplicantsSearch'
import { AdminApplicantInfo } from '../components/Admin/AdminApplicantInfo'
import { AdminAddApplicant } from '../components/Admin/AdminAddApplicant'
import { JobDetails } from '../components/shared/JobDetails'
import { HRManagement } from '../components/HRManagement'
import { AdminApplicationProcessAndVerification } from '../components/Admin/AdminApplicationProcessAndVerification'
import { ProductsList } from '../components/ProductsList'
import { ApplicantDetails } from '../components/ApplicantDetails'
import { AdminDashboardTabs } from '../components/Admin/AdminDashboardTabs'
import { AdminCompaniesTabs } from '../components/Admin/AdminCompaniesTabs'
import { AdminStepHeader } from '../components/Admin/AdminStepHeader'
import { AdminLinkApplicantTabs } from '../components/Admin/AdminLinkApplicantTabs'
import { AdminCreateAndManageCompanyTabs } from '../components/Admin/AdminCreateAndManageCompanyTabs'

const PlaygroundWrapper = styled.div`
  display: block;
  background: #fff;
  margin-left: 90px;

  h2 {margin-top: 50px; color:#707070;}
  h2.blue {color: #81BCBF;}

  .cols {
    display: flex;
    align-items:flex-start;

    >div:first-child {
      margin-right: 20px;
      width: 40%;
      height: auto;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
  justify-content: flex-start;
  padding-bottom: 50px;
`

const Content = styled.div`
  padding: 40px;
  overflow: scroll;
  flex-grow: 1;
`

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tile-controls {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    color: #707070;

    h2 {display: inline-block; color: #707070;}

    span {
      margin-right: 10px;
    }

    span:first-of-type {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .change-view {
    float: right;
    display: inline-block;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
      fill: #F0EEEC;
    }

    &.blue {
      svg {
        fill: #81BCBF;
      }
    }
  }
`

const AdminPlayground = () => {
  const sidebarButtons = [
    { icon: IconHome, link: '/playground', name: 'home' },
    { icon: IconFolder, link: '/jobs', name: 'jobs' },
    { icon: IconGroup, link: '/applicants', name: 'applicants' },
    { icon: IconSettings, link: '/settings', name: 'settings' }
  ]

  const dashboardTiles = [
    {
      icon: IconFolder,
      number: 8,
      text: 'Jobs In Progrss'
    },
    {
      icon: IconMagnifyingGlass,
      number: 27,
      text: 'Total Applicants'
    },
    {
      icon: IconAward,
      number: 2,
      text: 'Placements This Month'
    }
  ]

  const applicantInfoDetails = {
    firstName: 'Jennifer',
    lastName: 'Bernstein',
    email: 'jbernstein@gmail.com'
  }

  const jobDetailsData = {
    applicantsToday: 6,
    totalApplicants: 116,
    daysActive: 17,
    alerts: 1,
    jobTitle: 'Project Manager - Manufacturing',
    openings: 5,
    location: 'Irvine, CA',
    startDate: '12/22/2022',
    payAmount: 60000,
    payType: 'Salary',
    department: 'Manufacturing & Fabrication',
    vacation: '2 Weeks',
    bonus: 'Discretionary Bonus',
    status: 'Exempt'
  }

  const presets = [
    { val: 1, label: 'CBSV' },
    { val: 2, label: 'Credit Report' },
    { val: 3, label: 'Criminal All - Main Name Only' },
    { val: 4, label: 'Criminal All with All Alias' },
    { val: 5, label: 'Criminal Search' }
  ]

  const products = [
    { val: 6, label: 'Drug Testing - 10 Panel Urine' },
    { val: 7, label: 'Drug Testing - 10 Panel Urine + Alcohol + Cotonine' },
    { val: 8, label: 'Drug Testing - 5 Panel Urine' },
    { val: 9, label: 'Drug Testing - 9 Panel Urine (Marijuana not tested)' },
    { val: 10, label: 'Drug Testing - Hair Panel' },
    { val: 11, label: 'Education Verification' },
    { val: 12, label: 'Application Verification' },
    { val: 13, label: 'Federal Criminal All - Main Name Only' },
    { val: 14, label: 'Federal Criminal All with Alias Names' },
    { val: 15, label: 'ID Report' },
    { val: 16, label: 'Motor Vehicle Report' },
    { val: 17, label: 'National Criminal & Government Sanctions' },
    { val: 18, label: 'Professional License Verification' },
    { val: 19, label: 'Professional Reference' },
    { val: 20, label: 'CBSex Offender SearchSV' },
    { val: 21, label: 'Trace Report' }
  ]

  const applicantDetailsData = {
    dateApplied: '10/18/2022',
    daysSinceConditionalOffer: 2,
    address: '124 West 42nd Street\nApt. 137\nNewport Beach, CA 92663',
    email: 'roberta.callaghan@gmail.com',
    phone: '(949) 457-2684'
  }

  const jobsData = [
    {
      id: 111,
      name: 'Vice President of Sales',
      created_date: '10/02/2022',
      template: false
    },
    {
      id: 222,
      name: 'Sales Associate',
      created_date: '10/02/2022',
      template: true
    },
    {
      id: 333,
      name: 'Director of Inside Sales',
      created_date: '10/02/2022',
      template: false
    }
  ]

  const templatesData = [
    {
      id: 111,
      name: 'Sales Associate - Template',
      created_date: '10/02/2022',
      template: true
    },
    {
      id: 222,
      name: 'General - Template',
      created_date: '10/02/2022',
      template: true
    },
    {
      id: 333,
      name: 'Executive - Template',
      created_date: '10/02/2022',
      template: true
    }
  ]

  const orderData = [
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Complete',
      productName: 'Education Verification'
    },
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Pending',
      productName: 'eCBSV'
    },
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Review Required',
      productName: 'Federal Criminal All'
    },
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Complete',
      productName: 'Motor Vehical Report'
    },
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Complete',
      productName: 'Drug Testing - 9'
    },
    {
      ordered: '10/18/2022',
      completed: '10/19/2022',
      status: 'Pending',
      productName: 'Sex Offender Search'
    }
  ]

  const applicantDetailActionsList = [
    {
      title: 'Conditional Offer Letter',
      buttonText: 'Resend',
      buttonAction: () => { console.log('clicked resend button') },
      buttonEnabled: true, // disabled buttons are grayed out
      buttonColor: 'blue', // blue or brown buttons in design (disabled buttons are grayed out)
      buttonFresh: false // true or false to show if button action has been done already, disabled buttons should probably be undefined
    },
    {
      title: 'Order Products',
      buttonText: 'Submit',
      buttonAction: () => { console.log('clicked submit button') },
      buttonEnabled: true,
      buttonColor: 'blue',
      buttonFresh: true
    },
    {
      title: 'Final Offer',
      buttonText: 'Send',
      buttonAction: undefined,
      buttonEnabled: false,
      buttonColor: undefined,
      buttonFresh: undefined
    },
    {
      title: 'Adverse Action',
      buttonText: 'Activate',
      buttonAction: () => { console.log('clicked activate button') },
      buttonEnabled: true,
      buttonColor: 'brown',
      buttonFresh: false
    },
    {
      title: 'Mark as Hired',
      buttonText: 'Hired!',
      buttonAction: undefined,
      buttonEnabled: false,
      buttonColor: undefined,
      buttonFresh: undefined
    }
  ]

  return (
    <PlaygroundWrapper>
      <Router history={history}>
        <SidebarNav buttons={sidebarButtons} />
      </Router>
      <ContentWrapper>
        <Content>
          <h1>ADMIN PLAYGROUND</h1>
          <button onClick={() => { history.push('/dashboard') }}>go to dashboard</button>
          <TilesWrapper>
            {dashboardTiles.map((tile, i) => (
              <DashboardTile
                key={i}
                tile={tile}
              />
            ))}
            <AdminQuickAction
              color='#E5955A'
              link='/create-applicant-link-here'
              icon={<IconNewPerson />}
              text='Create Applicant'
            />
            <AdminQuickAction
              color='#81BCBF'
              link='/create-job-link-here'
              icon={<IconFolder />}
              text='Create Job'
            />
          </TilesWrapper>
          <AdminApplicantsSearch />
          <h2 className='blue'>Manage Applicant Onboarding</h2>
          <AdminStepHeader stepNumber={1} title='Review Applicant Information' />
          <AdminApplicantInfo details={applicantInfoDetails} />

          <AdminStepHeader stepNumber={2} title='Link Applicant to Job' />
          <AdminLinkApplicantTabs jobsData={jobsData} templatesData={templatesData} />

          <h2 className='blue'>Complete Applicant Onboarding</h2>
          <AdminStepHeader stepNumber={1} title='Review Details' />
          <AdminApplicantInfo details={applicantInfoDetails} edit />
          <JobDetails details={jobDetailsData} edit />
          <AdminStepHeader stepNumber={2} title='Set HR Preferences' />
          <HRManagement />
          <AdminApplicationProcessAndVerification />

          <AdminStepHeader stepNumber={3} title='Select Products' />
          <ProductsList
            title='Company Presets'
            description='(These products are mandatory for your Company/Organization)'
            products={presets}
          />
          <div style={{ margin: '25px 0' }}>Select from the available Products below to create the required hiring bundle.</div>
          <ProductsList
            title='Available Products'
            description='(Select All that apply)'
            products={products}
            editable
          />

          <AdminAddApplicant />
          <h2 className='blue'>Jennifer Bernstein</h2>
          <ApplicantDetails details={applicantDetailsData} editable />
          <AdminDashboardTabs
            assesses={[
              { val: 1, label: 'Criminal History' },
              { val: 2, label: 'Credit History' },
              { val: 3, label: 'Employment History' },
              { val: 4, label: 'Reference Information' },
              { val: 5, label: 'Application Inconsistency' },
              { val: 6, label: 'Driving Record' },
              { val: 7, label: 'Unable to Verify Social Security Number' },
              { val: 8, label: 'Other' }
            ]}
            tasks={[{
              title: 'Contitional Offer',
              link: '/somewhere-linktofile',
              enabled: true
            },
            {
              title: 'Application',
              link: '/somewhere-linktofile',
              enabled: true
            },
            {
              title: 'Compliance Documents',
              link: '/somewhere-linktofile',
              enabled: true
            },

            {
              title: 'Criminal Questionnaire',
              link: '/somewhere-linktofile',
              enabled: true
            },
            {
              title: 'Drug Test',
              link: '/somewhere-linktofile',
              enabled: false
            },
            {
              title: 'Motor Vehicle Report',
              link: '/somewhere-linktofile',
              enabled: false
            },
            {
              title: 'Background Check',
              link: '/somewhere-linktofile',
              enabled: false
            }]}
            orders={orderData}
            actions={applicantDetailActionsList}
            alerts={[
              {
                id: 1,
                date: '10/18/2022',
                type: 'system_alert',
                title: 'System Alert:',
                details: 'Social Security number does not match name.'
              },
              {
                id: 2,
                date: '10/18/2022',
                type: 'internal',
                title: '[INTERNAL]',
                details: 'Spoke with Roberta and was able to correct SS#',
                editLink: '/thing'
              },
              {
                id: 3,
                date: '10/18/2022',
                type: 'internal',
                title: '[INTERNAL]',
                details: 'Spoke with Roberta in regards to modifying the official start date due to some previous personal engagements. She confirmed she will be able to start the first week of January.',
                editLink: '/thing'
              }
            ]}
            submitData={() => {}}
          />

          <h2 className='blue'>Dashboard</h2>
          <AdminCompaniesTabs />

          <h2 className='blue'>Create & Manage Company</h2>
          <AdminCreateAndManageCompanyTabs />
        </Content>
      </ContentWrapper>
    </PlaygroundWrapper>
  )
}

document.addEventListener('DOMContentLoaded', () => { })

export { AdminPlayground }
