import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useBonusActions } from '../../../../_actions'
import { ApplicantGenericTable } from '../../../../components/Applicant/ApplicantGenericTable'

const BonusList = () => {
  const { companyId } = useParams()

  const bonusActions = useBonusActions(localStorage.getItem('access_token'))
  const [bonuses, setBonuses] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const COLUMNS = [
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Amount'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'status-column',
      render (_, row) {
        return (
          <span>
            <a href={`/admin/companies/${companyId}/bonuses/${row.id}/edit`}>Edit</a> |
            <a
              href={`/admin/companies/${companyId}/bonuses/${row.id}#delete`}
              onClick={(e) => {
                e.preventDefault()
                if (
                  window.confirm(
                    'Are you really sure you want to delete this bonus?!'
                  ) === true
                ) {
                  const deleteBonus = async () => {
                    setIsLoading(true)
                    await bonusActions.remove(row.id)
                    const apiData = await bonusActions.list(companyId)
                    setBonuses(apiData)
                    setIsLoading(false)
                  }
                  deleteBonus()
                }
              }}
            >
              Delete
            </a>
          </span>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])

  useEffect(() => {
    bonusActions.list(companyId).then((apiData) => {
      setBonuses(apiData)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <ApplicantGenericTable
        loading={isLoading}
        tableTitle='Bonuses'
        tableTitleColor='#E28A57'
        data={bonuses}
        columns={columns}
        rowKey='id'
        buttonLink={`/admin/companies/${companyId}/bonuses/new`}
        buttonText='Add'
      />
    </>
  )
}

export default BonusList
