import { faker } from '@faker-js/faker'
import { useRecoilState } from 'recoil'
import { personalInfoAtom, applicationVerificationAtom } from '../_state'

const useFakeData = () => {
  const [personalInfo, setPersonalInfo] = useRecoilState(personalInfoAtom)
  const [_applicationVerification, setApplicationVerification] = useRecoilState(applicationVerificationAtom)

  const setApplicationVerificationData = () => {
    // faker.phone.number('(###) ###-####')
    const ref1FirstName = faker.name.firstName()
    const ref1LastName = faker.name.lastName()
    const ref2FirstName = faker.name.firstName()
    const ref2LastName = faker.name.lastName()
    const ref3FirstName = faker.name.firstName()
    const ref3LastName = faker.name.lastName()
    const ref4FirstName = faker.name.firstName()
    const ref4LastName = faker.name.lastName()

    setApplicationVerification({
      ..._applicationVerification,
      eligibleToWorkInUS: 'Yes',
      eighteenOrOlder: 'Yes',
      terminatedOrResigned: 'Yes',
      terminatedOrResignedHistory: [
        {
          employerName: faker.company.name(),
          employeeJobTitle: faker.name.jobTitle(),
          employerCity: faker.address.cityName(),
          employerState: faker.address.stateAbbr(),
          details: faker.lorem.sentence()
        }
      ],
      ableToPerformWithoutAccommodation: 'Yes',
      currentlyEmployed: 'Yes',
      contactCurrentEmployer: 'Yes',
      employmentHistory: [
        {
          current: false,
          jobTitle: faker.name.jobTitle(),
          supervisor: faker.name.fullName(),
          supervisorTitle: faker.name.jobTitle(),
          supervisorPhone: faker.phone.number('(###) ###-####'),
          city: faker.address.cityName(),
          state: faker.address.stateAbbr(),
          employer: faker.company.name(),
          startDate: '03/01/2022',
          endDate: '01/01/2023',
          responsibilities: faker.lorem.sentence(),
          reasonForLeaving: faker.lorem.sentence()
        }
      ],
      educationHistory: [
        {
          to: '1994',
          type: 'HS',
          degree: 'High School Diploma',
          name: faker.name.lastName() + ' High',
          city: faker.address.cityName(),
          state: faker.address.stateAbbr(),
          from: '1998',
          graduated: 'Yes'
        }
      ],
      referrals: [
        {
          source: 'Indeed',
          worked: 'Yes',
          startDate: '2022-09-05',
          endDate: '2023-01-18',
          contact: 'Yes',
          firstName: ref1FirstName,
          lastName: ref1LastName,
          phone: faker.phone.number('(###) ###-####'),
          email: faker.internet.email(ref1FirstName, ref1LastName),
          title: faker.name.jobTitle()
        }
      ],
      references: [
        {
          to: '2003',
          firstName: ref2FirstName,
          lastName: ref2LastName,
          title: faker.name.jobTitle(),
          from: '2018',
          phone: faker.phone.number('(###) ###-####'),
          email: faker.internet.email(ref2FirstName, ref2LastName)
        },
        {
          firstName: ref3FirstName,
          lastName: ref3LastName,
          title: faker.name.jobTitle(),
          from: '2020',
          to: '2011',
          phone: faker.phone.number('(###) ###-####'),
          email: faker.internet.email(ref3FirstName, ref3LastName)
        },
        {
          firstName: ref4FirstName,
          lastName: ref4LastName,
          title: faker.name.jobTitle(),
          from: '2021',
          to: '2005',
          phone: faker.phone.number('(###) ###-####'),
          email: faker.internet.email(ref4FirstName, ref4LastName)
        }
      ],
      extralSkills: 'Yes',
      extraSkillsDetail: faker.lorem.sentence()
    })
  }

  const setPersonalInfoData = () => {
    const firstName = faker.name.firstName()
    const middleName = faker.name.middleName()
    const lastName = faker.name.lastName()
    const dateOfBirth = faker.date.birthdate().toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
    const streetLine1 = faker.address.streetAddress()
    const streetLine2 = faker.address.secondaryAddress()
    const city = faker.address.cityName()
    const state = faker.address.stateAbbr()
    const postalCode = faker.address.zipCodeByState(state)
    const fakeSsn = faker.random.numeric(3, { bannedDigits: ['9'] }) + '-' + faker.random.numeric(2) + '-' + faker.random.numeric(4)

    setPersonalInfo({
      firstName,
      middleName,
      lastName,
      suffix: faker.name.suffix(),
      dateOfBirth,
      ssn: fakeSsn,
      confirmSsn: fakeSsn,
      streetLine1,
      streetLine2,
      city,
      state,
      postalCode,
      country: 'US',
      emailAddress: faker.internet.email(firstName, lastName),
      primaryPhone: faker.phone.number('(###) ###-####'),
      secondaryPhone: faker.phone.number('(###) ###-####'),
      /* driverLicense: {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        streetLine1,
        streetLine2,
        city,
        state,
        postalCode,
        expirationDate: faker.date.future(10).toLocaleDateString(),
        licenseNumber: faker.random.alphaNumeric(10),
        country: 'US'
      } */
      aliases: getAliasStubData()
    })
  }

  const getAliasStubData = () => {
    return ([
      {
        firstName: faker.name.firstName(),
        middleName: faker.name.middleName(),
        lastName: faker.name.lastName()
      },
      {
        firstName: faker.name.firstName(),
        middleName: faker.name.middleName(),
        lastName: faker.name.lastName()
      }])
  }

  const getSSNStubData = () => {
    const fakeSsn = faker.random.numeric(3) + '-' + faker.random.numeric(2) + '-' + faker.random.numeric(4)
    return ({
      ssn: fakeSsn,
      confirmSsn: fakeSsn
    })
  }

  const getDLStubData = () => {
    return ({
      dlFirstName: personalInfo.firstName,
      dlMiddleName: personalInfo.middleName,
      dlLastName: personalInfo.lastName,
      dlDateOfBirth: personalInfo.dateOfBirth,
      dlStreetLine1: personalInfo.streetLine1,
      dlStreetLine2: personalInfo.streetLine2,
      dlCity: personalInfo.city,
      dlState: personalInfo.state,
      dlPostalCode: personalInfo.postalCode,
      dlExpirationDate: faker.date.future(10).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }),
      dlLicenseNumber: faker.random.alphaNumeric(10),
      dlCountry: 'US'
    })
  }

  const getReferenceStubData = () => {
    const ref2FirstName = faker.name.firstName()
    const ref2LastName = faker.name.lastName()
    const ref3FirstName = faker.name.firstName()
    const ref3LastName = faker.name.lastName()
    const ref4FirstName = faker.name.firstName()
    const ref4LastName = faker.name.lastName()
    return ([
      {
        to: '2003',
        firstName: ref2FirstName,
        lastName: ref2LastName,
        title: faker.name.jobTitle(),
        from: '2018',
        phone: faker.phone.number('(###) ###-####'),
        email: faker.internet.email(ref2FirstName, ref2LastName)
      },
      {
        firstName: ref3FirstName,
        lastName: ref3LastName,
        title: faker.name.jobTitle(),
        from: '2020',
        to: '2011',
        phone: faker.phone.number('(###) ###-####'),
        email: faker.internet.email(ref3FirstName, ref3LastName)
      },
      {
        firstName: ref4FirstName,
        lastName: ref4LastName,
        title: faker.name.jobTitle(),
        from: '2021',
        to: '2005',
        phone: faker.phone.number('(###) ###-####'),
        email: faker.internet.email(ref4FirstName, ref4LastName)
      }
    ])
  }

  return {
    setPersonalInfoData,
    getAliasStubData,
    getSSNStubData,
    getDLStubData,
    getReferenceStubData,
    setApplicationVerificationData
  }
}

export { useFakeData }
