import { RecoilInput } from '../../shared/HookFormComponents'
import { useFormContext } from 'react-hook-form'

const Contact = ({ readOnly }) => {
  const { formState } = useFormContext()
  return (
    <>
      <div className='form-element'>
        <label>Email</label>
        <RecoilInput
          type='text'
          name='emailAddress'
          className={`form-control ${
            formState.errors.emailAddress ? 'is-invalid' : ''
          }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Primary Phone</label>
        <RecoilInput
          type='text'
          subType='phone'
          name='primaryPhone'
          className={`form-control ${
            formState.errors.primaryPhone ? 'is-invalid' : ''
          }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Secondary Phone</label>
        <RecoilInput
          type='text'
          subType='phone'
          name='secondaryPhone'
          className={`form-control ${
            formState.errors.secondaryPhone ? 'is-invalid' : ''
          }`}
          readOnly={readOnly}
        />
        <div className='invalid-feedback'>
          {formState.errors.secondaryPhone?.message}
        </div>
      </div>
    </>
  )
}

export { Contact }
