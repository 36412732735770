import { useFetchWrapper } from '../_helpers'

export { useCompanyActions }

function useCompanyActions (token = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/companies`
  const fetchWrapper = useFetchWrapper(token)

  return {
    getCompanyList,
    createCompany,
    getCompany,
    updateCompany,
    deleteCompany,
    getUsers,
    addUser,
    removeUser,
    createUser,
    updateUser,
    addRecruiter,
    top5users,
    top5jobs,
    applicants,
    jobs,
    searchApplicants
  }

  function getCompanyList () {
    return fetchWrapper.get(`${baseUrl}`)
  }

  function createCompany (company) {
    return fetchWrapper.post(`${baseUrl}`, { company })
  }

  function getCompany (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function updateCompany (id, company) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { company })
  }

  function deleteCompany (id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
  }

  function getUsers (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/users`)
  }

  function addUser (id, user_id) {
    return fetchWrapper.post(`${baseUrl}/${id}/add_user`, { user_id })
  }

  function addRecruiter (id, user_id) {
    return fetchWrapper.post(`${baseUrl}/${id}/add_recruiter`, { user_id })
  }

  function removeUser (id, user_id) {
    return fetchWrapper.delete(`${baseUrl}/${id}/remove_user`, {
      user_id
    })
  }

  function createUser (id, user, role) {
    return fetchWrapper.post(`${baseUrl}/${id}/create_user`, { user, role })
  }

  function updateUser (id, user) {
    return fetchWrapper.post(`${baseUrl}/${id}/update_user`, { user })
  }

  function top5users (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/top_5_users`)
  }

  function top5jobs (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/top_5_jobs`)
  }

  function applicants (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/applicants`)
  }

  function searchApplicants (id, q) {
    return fetchWrapper.get(`${baseUrl}/${id}/search_applicants?q=${q}`)
  }

  function jobs (id) {
    return fetchWrapper.get(`${baseUrl}/${id}/jobs`)
  }
}
