import * as Yup from 'yup'
import { Referrals } from '../Referral'
import { Step } from './Step'

const ReferralInfo = (props) => {
  const referralSchema = {
    source: Yup.string().required('How you heard about us is required'),
    worked: Yup.string().required('Have you worked for this company before is required'),
    contact: Yup.string().required('Do you know anyone who works for our company'),
    startDate: Yup.string().when('worked', { is: 'Yes', then: (schema) => schema.required('Start Date is required') }),
    endDate: Yup.string().when('worked', { is: 'Yes', then: (schema) => schema.required('End Date is required') }),
    title: Yup.string().when('worked', { is: 'Yes', then: (schema) => schema.required('Title is required') }),
    firstName: Yup.string().when('contact', { is: 'Yes', then: (schema) => schema.required('First Name is required') }),
    lastName: Yup.string().when('contact', { is: 'Yes', then: (schema) => schema.required('Last Name is required') })
  }

  const validationSchema = Yup.object().shape({
    referrals: Yup.array().of(Yup.object().shape(referralSchema))
  })

  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps} isArray>
        <h3>Employment History</h3>
        <Referrals name='referrals' readOnly={props.readOnly} />
      </Step>
    </>
  )
}
ReferralInfo.componentName = 'ReferralInfo'
export { ReferralInfo }
