import styled from 'styled-components'

import { JobDetail } from '../shared/JobDetail'
import { ReactComponent as IconEdit } from '../../resources/images/icons/edit2.svg'

const ApplicantDetailsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  background-color: #f1efed;
  min-height: 180px;

  .stat-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 50px 25px 0 35px;

    @media only screen and (max-width: 1300px) {
      padding: 60px 25px 0 25px;
    }
  }

  .stat {
    font-size: 38px;
    font-weight: bold;
    display: block;

    @media only screen and (max-width: 1300px) {
      font-size: 24px;
    }
  }

  .stat-label {
    font-size: 16px;
    display: block;
  }

  .orange {
    background-color: #de7e53;
    color: #fff;
  }

  .dark-gray {
    background-color: #e6e2df;
    color: #707070;
    border-right: 1px solid #cfc8c1;

    &.alerts {
      color: #de7e53;
    }
  }

  .gray {
    display: flex;
    width: 100%;

    .job-details {
      padding: 15px 35px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        justify-content: top;
        width: 35%;
        height: 50%;
        margin-right: 0;

        &:nth-child(3) {
          max-width: 130px;
          margin-left: auto;
        }
      }
    }

    .edit {
      width: 50px;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      display: flex;
      border-left: 1px solid #ccc;

      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const ApplicantDetails = ({ details, editable, editClick }) => {
  return (
    <ApplicantDetailsWrapper>
      <div className='orange stat-wrapper'>
        <span className='stat'>{details.dateApplied || '-'}</span>
        <span className='stat-label'>Date Applied</span>
      </div>
      <div className='dark-gray stat-wrapper'>
        <span className='stat'>{details.daysSinceConditionalOffer || '-'}</span>
        <span className='stat-label'>Days Since Conditional Offer</span>
      </div>
      <div className='gray'>
        <div className='job-details'>
          <JobDetail
            key='address'
            label='address'
            detail={String(details.address)}
          />
          <JobDetail
            key='email'
            label='email'
            detail={String(details.email)}
          />
          <JobDetail
            key='phone'
            label='phone'
            detail={String(details.phone)}
          />
        </div>
        {editable && (
          <span className='edit'>
            <IconEdit onClick={() => {
              editClick && editClick()
            }}
            />
          </span>
        )}
      </div>
    </ApplicantDetailsWrapper>
  )
}
