import styled from 'styled-components'
import { Auth0SignInWidget } from './Auth0SignInWidget'
import { Auth } from '../Auth0'

import logoImg from '../resources/images/employifi_logo_full.png'
import { loginWrapperCss } from '../_helpers/shared-css'
import LoginGraphic from '../components/shared/LoginGraphic'

const Wrapper = styled.div`
  ${loginWrapperCss}
`

const FormContentWrapper = styled.div`
  flex: 1;
  padding: 40px;
  text-align: center;

  .form-content {
    max-width: 450px;
    margin: auto;
  }

  .employifi-logo {
    width: 220px;
    height: 185px;
    margin: 40px auto;
    background-image: url(${logoImg});
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const Login = () => {
  return (
    <Wrapper>
      <LoginGraphic showText={false} />
      <FormContentWrapper>
        <div className='employifi-logo' />
        <Auth0SignInWidget auth0={Auth} />
      </FormContentWrapper>
    </Wrapper>
  )
}

export default Login
