import { useSetRecoilState } from 'recoil'

import { useFetchWrapper } from '../_helpers'
import { jobs } from '../_state'

export { useJobActions }

function useJobActions (token = undefined) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/jobs`
  const fetchWrapper = useFetchWrapper(token)
  const setJobs = useSetRecoilState(jobs)

  return {
    getAll,
    getById,
    createJob,
    recentTenJobsAndTemplates,
    recentTenTemplates,
    recentTenJobsAndTemplatesSearch,
    recentTenTemplatesSearch,
    getTemplate,
    viewedResource,
    viewedResourceCount,
    assignApplicant,
    finalize,
    update,
    start
  }

  function getAll () {
    return fetchWrapper.get(`${baseUrl}`).then((jobs) => { setJobs(jobs); return jobs })
  }

  function getById (id) {
    return fetchWrapper.get(`${baseUrl}/${id}`)
  }

  function start (id) {
    return fetchWrapper.get(`${baseUrl}/start/${id}`)
  }

  function update (id, job) {
    return fetchWrapper.put(`${baseUrl}/${id}`, { job })
  }

  function createJob (job, templateName) {
    return fetchWrapper.post(`${baseUrl}`, {
      job,
      template_name: templateName
    })
  }

  function recentTenJobsAndTemplates (locationId) {
    return fetchWrapper.get(`${baseUrl}/recent_all?location_id=${locationId}`)
  }

  function recentTenTemplates (locationId) {
    return fetchWrapper.get(
      `${baseUrl}/recent_templates?location_id=${locationId}`
    )
  }

  function recentTenJobsAndTemplatesSearch (locationId, q) {
    return fetchWrapper.get(
      `${baseUrl}/recent_all_search?location_id=${locationId}&q=${q}`
    )
  }

  function recentTenTemplatesSearch (locationId, q) {
    return fetchWrapper.get(
      `${baseUrl}/recent_templates_search?location_id=${locationId}&q=${q}`
    )
  }

  function getTemplate (locationId, templateId) {
    return fetchWrapper.get(
      `${baseUrl}/template?location_id=${locationId}&template_id=${templateId}`
    )
  }

  function viewedResource (jobId, resourceId) {
    return fetchWrapper.get(
      `${baseUrl}/viewed_resource?job_id=${jobId}&resource_id=${resourceId}`
    )
  }

  function viewedResourceCount (jobId, resourceId) {
    return fetchWrapper.get(
      `${baseUrl}/viewed_resource_count?job_id=${jobId}&resource_id=${resourceId}`
    )
  }

  function assignApplicant (id, userId) {
    return fetchWrapper.post(`${baseUrl}/${id}/assign_applicant`, {
      user: { id: userId }
    })
  }

  function finalize (id, body) {
    return fetchWrapper.put(`${baseUrl}/${id}/finalize`, body)
  }
}
