import { atom, RecoilLoadable, selector } from 'recoil'
import { userAtom } from './users'

const userInfoEffect =
  () =>
    ({ getLoadable, setSelf }) => {
      const value = getLoadable(currentUserAtom).contents
      setSelf({
        firstName: value.first_name,
        middleName: '',
        lastName: value.family_name,
        suffix: '',
        ssn: '',
        dateOfBirth: '',
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'US',
        emailAddress: value.email,
        primaryPhone: value.phone,
        secondaryPhone: '',
        eligibleToWorkInUS: '',
        eighteenOrOlder: '',
        terminatedOrResigned: '',
        terminatedOrResignedHistory: [],
        ableToPerformWithoutAccommodation: '',
        currentlyEmployed: '',
        contactCurrentEmployer: '',
        employmentHistory: [],
        educationHistory: [],
        referrals: [],
        licenseList: [],
        references: [],
        aliases: []
      })
    }

export const personalInfoAtom = atom({
  key: 'personalInfoAtom',
  default: {
    driverLicense: {}
  },
  effects: [userInfoEffect()]
})

const currentUserAtom = selector({
  key: 'currentUserAtom',
  get: ({ get }) => {
    return get(userAtom)
  }
})

/* export const driverLicenseAtom = selector({
  key: 'driverLicenseAtom',
  get: ({ get }) => {
    const pi = get(personalInfoAtom)
    if (pi !== undefined && pi.driverLicense !== undefined) {
      return RecoilLoadable.of(pi.driverLicense)
    } else {
      return RecoilLoadable.of({})
    }
  },
  set: ({ set, get }, newValue) => {
    set(personalInfoAtom, { ...get(personalInfoAtom), driverLicense: newValue })
  }
}) */
