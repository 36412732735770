import { useStateContext } from '../../../contexts/useStateContext'
import { useFormContext } from 'react-hook-form'
import { RecoilInput } from '../../shared/HookFormComponents'
import React from 'react'

const SocialSecurityNumber = ({ readOnly }) => {
  const { state } = useStateContext()
  const { formState } = useFormContext()

  return (
    <>
      <div className='form-element'>
        <label>SSN</label>
        <RecoilInput
          type='text'
          name='ssn'
          value={state.ssn}
          className={`form-control ${
                  formState.errors.ssn ? 'is-invalid' : ''
              }`}
          readOnly={readOnly}
        />
      </div>
      <div className='form-element'>
        <label>Confirm SSN</label>
        <RecoilInput
          type='text'
          name='confirmSsn'
          value={state.confirmSsn}
          className={`form-control ${
                  formState.errors.confirmSsn ? 'is-invalid' : ''
              }`}
          readOnly={readOnly}
        />
      </div>
    </>
  )
}

export { SocialSecurityNumber }
