import { atom } from 'recoil'

export const applicationVerificationAtom = atom({
  key: 'applicationVerificationAtom',
  default: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    ssn: '',
    dateOfBirth: '',
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    emailAddress: '',
    primaryPhone: '',
    secondaryPhone: '',
    eligibleToWorkInUS: '',
    eighteenOrOlder: '',
    terminatedOrResigned: '',
    terminatedOrResignedHistory: [],
    ableToPerformWithoutAccommodation: '',
    currentlyEmployed: '',
    contactCurrentEmployer: '',
    driverLicense: {},
    employmentHistory: [],
    educationHistory: [],
    referrals: [],
    licenseList: [],
    references: []
  }
})
