import { TerminatedOrResignedHistory } from '../TerminatedOrResignedHistory'
import { Step } from './Step'

import { array, object, string } from 'yup'
import * as Yup from 'yup'

const TerminatedOrResignedInfo = (props) => {
  const terminatedOrResignedSchema = {
    employerName: string().required('Company Name is required'),
    employeeJobTitle: string().required('Title is required.'),
    employerCity: string().required('City is required.'),
    employerState: string().required('State is required.'),
    details: string().required('Details are required')
  }

  const validationSchema = Yup.object().shape({
    terminatedOrResigned: Yup.string().required('This field is required'),
    terminatedOrResignedHistory: array().of(
      object().shape(terminatedOrResignedSchema)
    )
  })
  const stepProps = { ...props, validationSchema }
  return (
    <>
      <Step {...stepProps} isArray>
        <h3>General Questions</h3>
        <TerminatedOrResignedHistory name='terminatedOrResignedHistory' readOnly={props.readOnly} />
      </Step>
    </>
  )
}
TerminatedOrResignedInfo.componentName = 'TerminatedOrResignedInfo'
export { TerminatedOrResignedInfo }
