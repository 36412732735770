import styled from 'styled-components'

const AdminStepHeaderWrapper = styled.div`
  margin: 35px 0;
  span {
    font-size: 28px;
    font-weight: bold;
  }

  .step-label {
    color: #e28a57;
  }
  .step-title {
    color: #707070;
  }
`

export const AdminStepHeader = ({ stepNumber, title }) => {
  return (
    <AdminStepHeaderWrapper>
      <span className='step-label'>Step {stepNumber} : </span>
      <span className='step-title'>{title}</span>
    </AdminStepHeaderWrapper>
  )
}
