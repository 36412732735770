import * as React from 'react'
import styled from 'styled-components'

import { JobDetail } from '../shared/JobDetail'

const JobDetailStatsWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #272d3b33;
  background: #f1efed;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  background-color: #f1efed;
  height: 180px;

  .stat-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 50px 35px 0 35px;
    max-width: 150px;
  }

  .stat {
    font-size: 38px;
    font-weight: bold;
    display: block;
  }

  .stat-label {
    font-size: 16px;
    display: block;
  }

  .orange {
    background-color: #de7e53;
    color: #fff;
  }

  .dark-gray {
    background-color: #e6e2df;
    color: #707070;
    border-right: 1px solid #cfc8c1;

    &.alerts {
      color: #de7e53;
    }
  }

  .gray {
    padding: 15px 35px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 33%;
      height: 50%;
      margin-right: 0;
    }
  }
`

interface JobDetailStatsProps {
  details: {
    applicantsToday: number
    totalApplicants: number
    daysActive: number
    alerts: number
    jobTitle: string
    openings: number
    location: string
    startDate: string
    payAmount: number
    payType: string
    department: string
    vacation: string
    bonus: string
    status: string
  }
}

export const JobDetailStats = ({ details }: JobDetailStatsProps) => {
  return (
    <JobDetailStatsWrapper>
      <div className="orange stat-wrapper">
        <span className="stat">{details.applicantsToday}</span>
        <span className="stat-label">Applicants Today</span>
      </div>
      <div className="dark-gray stat-wrapper">
        <span className="stat">{details.totalApplicants}</span>
        <span className="stat-label">Total Applicants</span>
      </div>
      <div className="dark-gray stat-wrapper">
        <span className="stat">{details.daysActive}</span>
        <span className="stat-label">Days Active</span>
      </div>
      <div className="dark-gray stat-wrapper alerts">
        <span className="stat">{details.alerts}</span>
        <span className="stat-label">Alerts</span>
      </div>
      <div className="gray">
        <JobDetail label="location" detail={details.location} />
        <JobDetail label="Pay Amount" detail={String(details.payAmount)} />
        <JobDetail label="Openings" detail={String(details.openings)} />
        <JobDetail label="Start Date" detail={details.startDate} />
        <JobDetail label="Pay Type" detail={details.payType} />
      </div>
    </JobDetailStatsWrapper>
  )
}
